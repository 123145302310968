import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-data-collectief-widget',
  templateUrl: './data-collectief-widget.component.html',
  styleUrls: ['./data-collectief-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataCollectiefWidgetComponent extends ListRendererWidgetBase {}
