import { LeadAddress } from '@core/interfaces/address.interface';
import { Injectable } from '@angular/core';
import { ApiService } from '@capturum/api';

@Injectable({
  providedIn: 'root',
})
export class AddressApiService extends ApiService<LeadAddress> {
  protected endpoint = 'address';
}
