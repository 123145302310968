<app-page-layout
  [title]="'market_discovery.navigation.roi' | translate"
  [showPrintButton]="true"
  (handlePrintCommand)="exportRoi()">
  <app-page-buttons actions>
    <ng-container *ngxPermissionsOnly="'project.roi.create'">
      <cap-button
        styleClass="primary"
        icon="fas fa-plus"
        [label]="'market_discovery.roi.add.button' | translate"
        (click)="openRoiDialog()">
      </cap-button>

      <cap-button
        styleClass="primary"
        icon="fas fa-pencil"
        *ngIf="!isEdit"
        [label]="'button.edit' | translate"
        (click)="editRoi()">
      </cap-button>

      <cap-button
        styleClass="primary"
        icon="fas fa-save"
        *ngIf="isEdit"
        [label]="'button.submit' | translate"
        (click)="submitMassUpdate()">
      </cap-button>

      <cap-button
        styleClass="secondary"
        icon="fas fa-ban"
        *ngIf="isEdit"
        [label]="'button.cancel' | translate"
        (click)="cancelEditing()">
      </cap-button>
    </ng-container>
  </app-page-buttons>

  <div content class="roi-overview">
    <cap-card
      [title]="'market_discovery.pipeline.selection' | translate"
      [class.hide]="hideSelection"
      [buttonLabel]="
        (hideSelection
          ? 'market_discovery.project.full_business.show_selection.label'
          : 'market_discovery.project.full_business.hide_selection.label'
        ) | translate
      "
      [buttonIcon]="hideSelection ? 'fas fa-eye' : 'fas fa-eye-slash'"
      (buttonClick)="hideSelection = !hideSelection"
      styleClass="mb-3">
      <ng-template capTemplate="content">
        <div [hidden]="hideSelection">
          <div class="d-flex justify-content-between align-items-center">
            <cap-dynamic-filters
              #dynamicFiltersComponent
              [showResetButton]="false"
              [filterConfig]="filterConfig"
              [(activeFilters)]="activeFilters"
              class="square-filter"
              [storageKey]="'roi_' + projectId"
              (activeFiltersChange)="setFilter($event)">
            </cap-dynamic-filters>

            <cap-button
              styleClass="secondary round"
              [label]="'market_discovery.button.reset_filters' | translate"
              icon="fas fa-trash-alt"
              (click)="dynamicFiltersComponent.resetFilters()">
            </cap-button>
          </div>
        </div>
      </ng-template>
    </cap-card>

    <p-table
      #plannedRoiTable
      [value]="tableData$ | async"
      class="simple-table roi-table planned-roi-table"
      [hidden]="loadingPlannedRoi"
      styleClass="cap-info-table"
      [autoLayout]="false"
      [columns]="tableColumns$ | async">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let column of columns; let first = first" [class.roi-first-cell]="first">
            <ng-container *ngIf="first; else defaultTemplate">
              {{ 'market_discovery.roi.planning' | translate }}
            </ng-container>

            <ng-template #defaultTemplate>
              {{
                'market_discovery.calendar-locale.month-names.' + (column | date : 'MMM' : null : 'en' | lowercase)
                  | translate
              }}
              -{{ column | date : 'yy' }}
            </ng-template>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-item let-columns="columns" let-rowIndex="rowIndex">
        <tr>
          <td
            *ngFor="let column of columns; let first = first"
            [class.highlight]="item.highlight"
            [class.edit-mode]="isEdit"
            [class.bold-title]="item.bold"
            [class.bold]="item.bold"
            [class.grey-cell]="first"
            [class.underlined]="item.underlined">
            <cap-info-table-cell-editor [editTable]="true" [isEdit]="isEdit && !first">
              <ng-template capTemplate="input">
                <ng-container *ngIf="showCurrency && item.totalField; else defaultInputNumber">
                  <cap-input-number
                    [class]="item['property']"
                    [selectOnFocus]="true"
                    [locale]="'nl-NL'"
                    mode="decimal"
                    [minFractionDigits]="0"
                    [prefix]="'€'"
                    [suffix]="item.percentage ? '%' : ''"
                    [(ngModel)]="item[column]"
                    [class.warning]="item[column] < tableDataOriginal[rowIndex][column]">
                  </cap-input-number>
                </ng-container>

                <ng-template #defaultInputNumber>
                  <cap-input-number
                    [class]="item['property']"
                    [selectOnFocus]="true"
                    [locale]="'nl'"
                    [maxFractionDigits]="item.decimal ? 1 : 0"
                    [suffix]="item.percentage ? '%' : ''"
                    [(ngModel)]="item[column]"
                    [class.warning]="item[column] < tableDataOriginal[rowIndex][column]">
                  </cap-input-number>
                </ng-template>
              </ng-template>

              <ng-template capTemplate="output">
                <div [class.text-center]="!first">
                  <ng-container *ngIf="first; else numberTemplate">
                    {{ item[column] }}
                  </ng-container>

                  <ng-template #numberTemplate>
                    <ng-container *ngIf="showCurrency && item.totalField; else amountTemplate">
                      {{ +item[column] | currency : 'EUR' : 'symbol' : '1.0-0' }}
                    </ng-container>

                    <ng-template #amountTemplate>
                      {{ item[column] | number : '1.0-1' }}{{ item.percentage ? '%' : '' }}
                    </ng-template>
                  </ng-template>
                </div>
              </ng-template>
            </cap-info-table-cell-editor>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <cap-info-table-skeleton *ngIf="loadingPlannedRoi"></cap-info-table-skeleton>

    <div class="mt-3">
      <app-realized-roi-table
        [loading]="loadingRealizedRoi"
        [showCurrency]="showCurrency"
        [roiData]="realizedRoiData$ | async"></app-realized-roi-table>
    </div>
  </div>
</app-page-layout>
