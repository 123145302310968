<cpb-readonly-container>
  <div class="contacts-type">
    <div class="contact-type-line" *ngFor="let contact of contacts$ | async">
      <div class="contact-name">
        {{
          contact.salutation_base_data_value_id ? (contact.salutation_base_data_value_id | capBaseDataTranslation) : ''
        }}
        {{ contact.initials }} {{ contact.last_name }}
        <ng-container *ngIf="contact.function_base_data_value_id">
          ({{ contact.function_base_data_value_id | capBaseDataTranslation }})
        </ng-container>
      </div>

      <div class="contact-links">
        <ng-container *ngIf="contact.email">
          <a class="contact-href" href="mailto:{{ contact.email }}">{{ contact.email }}</a> |
        </ng-container>

        <ng-container *ngIf="contact.phone">
          <a class="contact-href" href="tel:{{ contact.phone }}">{{ contact.phone }}</a> |
        </ng-container>
        <ng-container *ngIf="contact.mobile_phone">
          <a class="contact-href" href="tel:{{ contact.mobile_phone }}">{{ contact.mobile_phone }}</a> |
        </ng-container>
        <a href="https://www.linkedin.com/" target="_blank"><i class="fa-brands fa-linkedin"></i></a>
      </div>
    </div>
  </div>
</cpb-readonly-container>
