import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectHourRoutingModule } from './project-hour-routing.module';
import { ProjectHourListComponent } from './pages/project-hour-list/project-hour-list.component';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { CapturumButtonModule } from '@capturum/ui/button';
import { LayoutModule } from '@shared/layout/layout.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CapturumCardModule } from '@capturum/ui/card';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumSharedModule } from '@capturum/ui/api';
import { TimeDifferencePipe } from './pipes/time-difference.pipe';
import { ProjectHourDifferenceComponent } from './components/project-hour-difference/project-hour-difference.component';
import { ProjectHourDialogComponent } from './pages/project-hour-dialog/project-hour-dialog.component';

@NgModule({
  declarations: [
    ProjectHourListComponent,
    TimeDifferencePipe,
    ProjectHourDifferenceComponent,
    ProjectHourDialogComponent,
  ],
  exports: [TimeDifferencePipe],
  imports: [
    CommonModule,
    ProjectHourRoutingModule,
    CapturumListRendererModule,
    CapturumButtonModule,
    LayoutModule,
    TranslateModule,
    NgxPermissionsModule,
    CapturumCardModule,
    CapturumFormRendererModule,
    CapturumSharedModule,
  ],
})
export class ProjectHourModule {}
