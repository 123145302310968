import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { InfoTableColumn } from '@capturum/ui/info-table';
import { XyzTableRow } from '@features/project/interfaces/xyz.interface';

@Component({
  selector: 'app-xyz-table',
  templateUrl: './xyz-table.component.html',
  styleUrls: ['./xyz-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class XyzTableComponent implements OnInit {
  @Input()
  public set xyz(value: XyzTableRow[]) {
    if (value) {
      this.getHighestValue(value);
      this.tableData = value;
    }
  }

  public tableData: any[] = [];

  public columns: InfoTableColumn[];
  public underlinedRowIndexes = [4, 5, 9, 13, 17];
  public boldIndexes = [18, 19, 20, 21];
  public columnClasses = {
    amount: 0,
    mutated: 0,
    expected: 0,
  };

  public ngOnInit(): void {
    this.columns = this.getColumns();
  }

  private getColumns(): InfoTableColumn[] {
    return [
      {
        field: 'code',
        title: 'CODE',
        titleClass: 'small-cell',
      },
      {
        field: 'description',
        title: 'DESCRIPTION',
      },
      {
        field: 'amount',
        title: 'AMOUNT',
        titleClass: 'text-center small-cell',
      },
      {
        field: 'percentage',
        title: 'PERCENTAGE',
        titleClass: 'text-center small-cell',
      },
      {
        field: 'mutated',
        title: 'DEVELOPED',
        titleClass: 'text-center small-cell',
      },
      {
        field: 'expected',
        title: 'EXPECTED',
        titleClass: 'text-center expected-cell',
      },
    ];
  }

  private getHighestValue(value: XyzTableRow[]) {
    value.forEach((element) => {
      if (element.amount > this.columnClasses.amount) {
        this.columnClasses.amount = element.amount;
      }
      if (element.mutated > this.columnClasses.mutated) {
        this.columnClasses.mutated = element.mutated;
      }
      if (element.expected > this.columnClasses.expected) {
        this.columnClasses.expected = element.expected;
      }
    });
  }
}
