import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-realized-roi-table',
  templateUrl: './realized-roi-table.component.html',
  styleUrls: ['./realized-roi-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RealizedRoiTableComponent {
  @Input()
  public loading: boolean;

  @Input()
  public showCurrency: boolean;

  public tableColumns: string[] = [];

  private _roiData: Record<string, number | string | boolean>[];

  get roiData(): Record<string, number | string | boolean>[] {
    return this._roiData;
  }

  @Input()
  set roiData(value: Record<string, number | string | boolean>[]) {
    this._roiData = value;

    this.tableColumns = this.getTableColumns(value);
  }

  private getTableColumns(roiData: Record<string, number | string | boolean>[]): string[] {
    return roiData?.[0]
      ? Object.keys(roiData[0]).filter((key) => {
          return !['property', 'underlined', 'bold', 'percentage', 'highlight', 'totalField', 'decimal'].includes(key);
        })
      : [];
  }
}
