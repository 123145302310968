import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { ToastService } from '@capturum/ui/api';
import { ManageDetailComponent } from '@features/manage/pages/manage-detail/manage-detail.component';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataIndexedDbModel } from '@capturum/complete';
import { LeadStatus } from '../../../lead/lead-status.enum';
import { distinctUntilChanged, from, map, Observable, switchMap } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { AddToCalendarDialogComponent } from '../../components/add-to-calendar-dialog/add-to-calendar-dialog.component';
import { filter, first } from 'rxjs/operators';
import { BuilderApiService, responseData } from '@capturum/builders/core';

@Component({
  selector: 'app-lead-accountmanager',
  templateUrl: './lead-accountmanager.component.html',
  styleUrls: ['./lead-accountmanager.component.scss'],
})
export class LeadAccountmanagerComponent extends ManageDetailComponent implements OnInit {
  public context: { lead_id: string; isEdit: boolean; project_id: string; is_edit: boolean; projectType: string };
  public showAddToCalendarButton$: Observable<boolean>;
  public defaultValue: Record<string, unknown>;
  public formRendererModelId: string;

  private formEndpoint = '/builder/source';

  constructor(
    formSaverService: FormSaverService,
    route: ActivatedRoute,
    toastService: ToastService,
    translateService: TranslateService,
    private projectStateService: ProjectStateService,
    private formRendererService: FormRendererService,
    private dialogService: DialogService,
    private router: Router,
    private builderApiService: BuilderApiService,
    private cdr: ChangeDetectorRef
  ) {
    super(formSaverService, route, toastService, translateService);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const project = this.projectStateService.getProjectSnapshot();

    this.builderApiService
      .get(this.formKey, `${this.formEndpoint}/${this.formKey}/${this.modelId}`)
      .pipe(responseData)
      .subscribe((response) => {
        this.context = {
          ...this.context,
          lead_id: this.modelId,
          project_id: project?.id,
          is_edit: true,
          projectType: project?.projectType?.value,
        };

        if (!response?.target_at) {
          this.defaultValue = {
            target_at: new Date(),
          };
        }

        this.formRendererModelId = this.modelId;
      });
    this.showAddToCalendarButton$ = this.getShowCalendarButton();
  }

  public submitAndAddToCalendar(): void {
    this.formSaverService
      .submit(this.formKey)
      .pipe(
        first(),
        switchMap((response) => {
          return this.dialogService
            .open(AddToCalendarDialogComponent, {
              header: this.translateService.instant('market_discovery.action.is_send_to_user.label'),
              data: {
                leadId: this.modelId,
                lead: response.response.data,
              },
            })
            .onClose.pipe(filter(Boolean), first());
        })
      )
      .subscribe(() => {
        this.successfullySubmitted();
      });
  }

  public submit(): void {
    this.formSaverService
      .submit(this.formKey)
      .pipe(first())
      .subscribe(() => {
        this.successfullySubmitted();
      });
  }

  private getShowCalendarButton(): Observable<boolean> {
    const statuses = [
      LeadStatus.tb,
      LeadStatus.x1,
      LeadStatus.x2,
      LeadStatus.y1,
      LeadStatus.y2,
      LeadStatus.z1,
      LeadStatus.z2,
      LeadStatus.z3,
    ];

    return from(BaseDataIndexedDbModel.query().where('key').equals('lead_status').first()).pipe(
      map((leadStatuses) => {
        return leadStatuses.values.reduce((acc, status) => {
          if (statuses.includes(status.value)) {
            return [...acc, status.id];
          }

          return acc;
        }, []);
      }),
      switchMap((statusIds) => {
        return this.formRendererService.getFormValueStreamByKey(this.formKey).pipe(
          map((formValue) => {
            return statusIds.includes(formValue?.lead_status_base_data_value_id);
          })
        );
      }),
      distinctUntilChanged()
    );
  }

  public successfullySubmitted(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
    this.toastService.success(
      this.translateService.instant('toast.success.title'),
      this.translateService.instant(`market_discovery.entity.toast.${this.modelId ? 'updated' : 'created'}`, {
        entity: this.getEntityTranslation(),
      })
    );
  }
}
