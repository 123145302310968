import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Question,
  QuestionInputTypeComponent,
  UpdateAnswer,
} from '@features/questionnaire/interfaces/questionnaire.interface';
import { BehaviorSubject, debounceTime, Subject, takeUntil } from 'rxjs';

interface OpenQuestionAnswer {
  answer_text: string;
}

interface OpenQuestionFormGroup {
  answer_text: FormControl<string>;
}

@Component({
  selector: 'app-question-input-type-open-question',
  templateUrl: './open-question-input-type.component.html',
  styleUrls: ['./open-question-input-type.component.scss'],
})
export class QuestionInputTypeOpenQuestionComponent
  implements QuestionInputTypeComponent<OpenQuestionAnswer>, OnInit, OnDestroy
{
  @Input()
  public question: Question;

  @Output()
  public updateAnswer: EventEmitter<UpdateAnswer> = new EventEmitter<UpdateAnswer>();

  public formGroup: FormGroup<OpenQuestionFormGroup>;
  public answer$: BehaviorSubject<OpenQuestionAnswer> = new BehaviorSubject<OpenQuestionAnswer>(null);

  private destroy$: Subject<boolean> = new Subject<boolean>();

  public get answerString(): FormControl<string> {
    return this.formGroup?.get('answer_text') as FormControl<string>;
  }

  public ngOnInit(): void {
    this.formGroup = this.generateFormGroup();

    this.answerString.valueChanges.pipe(debounceTime(250), takeUntil(this.destroy$)).subscribe((_) => {
      this.updateAnswer.emit({
        id: this.question.id,
        value: this.formGroup.value,
      });
    });

    this.answer$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          this.formGroup.patchValue(value);
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public validate(): boolean {
    if (this.formGroup.valid) {
      this.updateAnswer.emit({
        id: this.question.id,
        value: this.formGroup.value,
      });
    }

    return this.formGroup.valid;
  }

  public getAnswer(): OpenQuestionAnswer {
    return {
      answer_text: this.formGroup.value.answer_text,
    };
  }

  private generateFormGroup(): FormGroup<OpenQuestionFormGroup> {
    return new FormGroup<OpenQuestionFormGroup>({
      answer_text: this.question.is_required
        ? new FormControl<string>(null, Validators.required)
        : new FormControl<string>(null),
    });
  }
}
