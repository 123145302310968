import { Observable, OperatorFunction } from 'rxjs';

export function reduceValues<T, R>(
  accumulate: (acc: T | R, value: T, index: number) => T | R,
  seed?: T | R
): OperatorFunction<T, T | R> {
  let currentIndex = 0;
  let accumulated = seed;

  return (source) => {
    return new Observable((obs) => {
      const subscription = source.subscribe(
        (value) => {
          accumulated = accumulate(accumulated, value, currentIndex++);

          obs.next(accumulated);
        },
        (err) => {
          obs.error(err);
        },
        () => {
          obs.next(accumulated);
          obs.complete();
        }
      );

      return () => {
        return subscription.unsubscribe();
      };
    });
  };
}
