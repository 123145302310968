import { Injectable } from '@angular/core';
import { ActionOptions, ActionProvider } from '@capturum/builders/core';
import { SelectionApiService } from '@features/selection/services/selection-api.service';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@capturum/ui/api';

@Injectable({ providedIn: 'root' })
export class DownloadSelectionAction implements ActionProvider {
  constructor(
    private selectionService: SelectionApiService,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {}

  public execute(options: ActionOptions, item: { id: string }, context?: Record<string, any>): void {
    this.toastService.info(
      this.translateService.instant('market_discovery.selection.export'),
      this.translateService.instant('market_discovery.lead.export.started')
    );
    this.selectionService.export(item.id).pipe(first()).subscribe();
  }
}
