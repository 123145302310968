enum EntityRoutes {
  admin = 'admin',
  user = 'users',
  role = 'role',
  translation = 'translation',
  baseData = 'base-data',
  setting = 'setting',
  project = 'project',
  customer = 'customer',
  xyz = 'xyz',
  meta = 'meta',
  selection = 'selection',
  log = 'log',
  company = 'company',
  changelog = 'changelog',
  questionnaire = 'questionnaire',
}

enum PageRoutes {
  gripMeter = 'grip-meter',
  details = 'details',
  leads = 'leads',
  stakeholders = 'stakeholders',
  pipeline = 'pipeline',
  fullBusiness = 'full-business',
  importLead = 'import-lead',
  importSelection = 'import-selection',
  letter = 'letter',
  roi = 'roi',
  xyz = 'xyz',
  projectHours = 'project-hours',
  agentQuestionnaire = 'agent-questionnaire',
  fieldwork = 'fieldwork',
  mutation = 'mutation',
  imports = 'imports',
}

enum HelperRoutes {
  profile = 'profile',
  config = 'config',
}

enum GeneralRoutes {
  detail = 'detail',
  view = 'view',
  add = 'add',
  edit = 'edit',
}

enum AuthRoutes {
  auth = 'auth',
  login = 'login',
}

export type AppRoutes = typeof AppRoutes;
export const AppRoutes = {
  ...EntityRoutes,
  ...GeneralRoutes,
  ...HelperRoutes,
  ...AuthRoutes,
  ...PageRoutes,
};
