import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { debounceTime, Observable, switchMap } from 'rxjs';
import { ProjectApiService } from '@features/project/services/project-api.service';
import { TableService } from '@shared/services/table.service';
import { DestroyBase } from '@capturum/shared';
import { InfoTableColumn } from '@capturum/ui/info-table';
import { PipelineData } from '@features/project/interfaces/pipeline.interface';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, shareReplay } from 'rxjs/operators';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { formatCurrency } from '@angular/common';
import { parseISO } from 'date-fns';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from '@core/enums/routes.enum';

@Component({
  selector: 'app-pipeline-status-table',
  templateUrl: './pipeline-status-table.component.html',
  styleUrls: ['./pipeline-status-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PipelineStatusTableComponent extends DestroyBase implements OnInit {
  @Input()
  public isCurrency: boolean;

  public pipelineData = new Map<string, Observable<any>>();
  public pipelineStatusData = new Map<string, PipelineData[]>();
  public statusColumns: InfoTableColumn[];
  public columns: InfoTableColumn[] = [
    {
      title: this.translateService.instant('market_discovery.pipeline.status.label'),
      field: 'name',
    },
    {
      title: this.translateService.instant('market_discovery.pipeline.accountmanager_name.label'),
      field: 'accountmanager_name',
    },
    {
      title: this.translateService.instant('market_discovery.pipeline.company_name.label'),
      field: 'company_name',
    },
    {
      title: this.translateService.instant('market_discovery.pipeline.term.label'),
      field: 'target_at',
      titleClass: 'cell-small term',
    },
    {
      title: this.translateService.instant('market_discovery.pipeline.action.label'),
      field: 'action',
      titleClass: 'cell-small action',
    },
    {
      title: this.translateService.instant('market_discovery.pipeline.grip.label'),
      field: 'grip',
      titleClass: 'cell-small text-right',
    },
    {
      title: this.translateService.instant('market_discovery.pipeline.order.label'),
      field: 'delivered',
      titleClass: 'cell-small text-right',
    },
    {
      title: this.translateService.instant('market_discovery.pipeline.offer.label'),
      field: 'invoice',
      titleClass: 'cell-small text-right',
    },
    {
      title: this.translateService.instant('market_discovery.pipeline.potential.label'),
      field: 'potential',
      titleClass: 'cell-small text-right',
    },
  ];

  constructor(
    private projectApiService: ProjectApiService,
    private tableService: TableService,
    private translateService: TranslateService,
    private projectStateService: ProjectStateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  private _pipelineStatuses: PipelineData[] = [];

  get pipelineStatuses(): PipelineData[] {
    return this._pipelineStatuses;
  }

  @Input()
  set pipelineStatuses(value: PipelineData[]) {
    this._pipelineStatuses = value;
  }

  public ngOnInit(): void {
    this.statusColumns = this.getPipelineStatusColumns();
  }

  public loadPipelineData(event: { data: { id: string; status: string } }): void {
    if (!this.pipelineData.has(event.data.id)) {
      this.pipelineData.set(
        event.data.id,
        this.projectStateService.getProject().pipe(
          filter(Boolean),
          debounceTime(50),
          switchMap((project) => {
            return this.tableService.getUpdateTable().pipe(
              map((options) => {
                return {
                  options,
                  project,
                };
              })
            );
          }),
          switchMap(({ project, options }) => {
            return this.projectApiService
              .getPipelineDataByStatus(project.id, event.data.id, { parameters: options?.filters })
              .pipe(
                map((result) => {
                  return result.map((lead) => {
                    return {
                      ...lead,
                      target_at: lead.target_at ? parseISO(lead.target_at + 'Z') : null,
                      delivered: this.isCurrency
                        ? formatCurrency(lead.delivered as number, 'nl', '€', 'EUR', '0.0-2')
                        : lead.delivered,
                      potential: this.isCurrency
                        ? formatCurrency(lead.potential as number, 'nl', '€', 'EUR', '0.0-2')
                        : lead.potential,
                      invoice: this.isCurrency
                        ? formatCurrency(lead.invoice as number, 'nl', '€', 'EUR', '0.0-2')
                        : lead.invoice,
                    };
                  });
                })
              );
          }),
          shareReplay(1)
        )
      );
    }
  }

  public trackByIndex(index: number, item: PipelineData): number {
    return index;
  }

  public navigateToLeadDetail(id: string): void {
    this.router.navigate(['..', AppRoutes.leads, id], {
      relativeTo: this.route,
      queryParams: { redirectUrl: this.router.url },
    });
  }

  private getPipelineStatusColumns(): InfoTableColumn[] {
    return [
      {
        field: 'company.coc_number',
        cellClass: 'text-left',
      },
      {
        field: 'accountManager.name',
        cellClass: 'text-left',
      },
      {
        field: 'name',
        cellClass: 'text-left',
      },
      {
        field: 'target_at',
        cellClass: 'text-left',
      },
      {
        field: 'action',
      },
      {
        field: 'gripStatus',
      },
      {
        title: this.translateService.instant('market_discovery.pipeline.order.label'),
        field: 'delivered',
      },
      {
        title: this.translateService.instant('market_discovery.pipeline.offer.label'),
        field: 'invoice',
      },
      {
        title: this.translateService.instant('market_discovery.pipeline.potential.label'),
        field: 'potential',
      },
    ];
  }
}
