import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormSaverService } from '@capturum/builders/form-renderer';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manage-customer-detail',
  templateUrl: './manage-customer-detail.component.html',
  styleUrls: ['./manage-customer-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageCustomerDetailComponent implements OnInit {
  public formKey: string;
  public modelId: string;
  public entity: string;
  public titleTranslationKey: string;
  public customerContext: Record<string, any>;

  constructor(
    private formSaverService: FormSaverService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.entity = this.route.parent.snapshot.data.entity;
    this.modelId = this.route.snapshot.paramMap.get('id');
    this.formKey = this.getFormKey(this.entity);
    this.titleTranslationKey = this.getTitleTranslationKey();
    this.customerContext = {
      customer_id: this.modelId,
    };
  }

  public submit(): void {
    this.formSaverService
      .submit(this.formKey)
      .pipe(first())
      .subscribe(() => {
        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant(`market_discovery.entity.toast.${this.modelId ? 'updated' : 'created'}`, {
            entity: this.getEntityTranslation(),
          })
        );
      });
  }

  public cancel(): void {
    this.formSaverService.cancel(this.formKey);
  }

  private getFormKey(entity: string): string {
    return `form_${entity}`;
  }

  private getTitleTranslationKey(): string {
    if (this.modelId) {
      return `market_discovery.form.edit.title`;
    } else {
      return `market_discovery.form.add.title`;
    }
  }

  private getEntityTranslation(): string {
    return this.translateService.instant(`market_discovery.${this.entity}.entity.name`);
  }
}
