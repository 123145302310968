import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { RouterModule } from '@angular/router';
import { PageButtonsComponent } from './components/page-buttons/page-buttons.component';
import { DialogLayoutComponent } from './components/dialog-layout/dialog-layout.component';

@NgModule({
  declarations: [PageLayoutComponent, PageButtonsComponent, DialogLayoutComponent],
  imports: [CommonModule, RouterModule],
  exports: [PageLayoutComponent, PageButtonsComponent, DialogLayoutComponent],
})
export class LayoutModule {}
