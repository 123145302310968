<p-table
  [columns]="columns"
  [value]="tableData"
  [responsive]="false"
  [autoLayout]="true"
  class="simple-table clear-table"
  styleClass="cap-info-table">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let column of columns" [ngClass]="column.titleClass">
        {{ 'market_discovery.xyz.' + column.field + '.label' | translate }}
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-row let-rowIndex="rowIndex" let-columns="columns">
    <tr
      [ngClass]="row.tableRowStyleClass"
      [class.tr-footer]="boldIndexes.includes(rowIndex)"
      [class.first]="rowIndex === 18"
      [class.spacing-bottom]="rowIndex === 19 || rowIndex === 21">
      <td
        *ngFor="let column of columns; let index = index"
        [class.underlined]="underlinedRowIndexes.includes(rowIndex)"
        [class.bold]="boldIndexes.includes(rowIndex)"
        [class.text-center]="index > 1">
        <ng-container *ngIf="index > 1">
          <ng-container [ngSwitch]="column.field">
            <ng-container *ngSwitchCase="'amount'">
              <div>
                <span class="hidden-value">
                  {{ row[column.field] | number | countSpacer : columnClasses[column.field] }}
                </span>
                {{ row[column.field] | number }}
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'percentage'">
              <div class="alignment">
                {{ row[column.field] | number :'1.1-1' }}
                <ng-container *ngIf="row[column.field] !== null"> % </ng-container>
              </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <div>
                <span class="hidden-value">
                  {{ row[column.field] | number | countSpacer : columnClasses[column.field] }}
                </span>
                {{ row[column.field] | number : '1.0-0' }}
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="index < 2">
          {{ row[column.field] }}
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>
