import { ComponentType } from '@angular/cdk/portal';
import { Component, EventEmitter, Inject, Input, OnDestroy, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Lead } from '@features/lead/interfaces/lead.interface';
import {
  Question,
  QuestionAnswer,
  QuestionInputTypeComponent,
  QuestionInputTypeComponentMap,
  UpdateAnswer,
} from '@features/questionnaire/interfaces/questionnaire.interface';
import { QUESTION_INPUT_TYPE_COMPONENTS } from '@features/questionnaire/providers/questionnaire-question-type.provider';
import { Subject, takeUntil, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-question-input-types',
  template: ` <ng-template #container></ng-template> `,
  styleUrls: ['./question-input-types.component.scss'],
})
export class QuestionInputTypesComponent implements OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  public viewContainerRef: ViewContainerRef;

  @Output()
  public updateAnswer: EventEmitter<UpdateAnswer> = new EventEmitter<UpdateAnswer>();

  private _answers: Map<string, QuestionAnswer> = new Map();
  private _question: Question;
  private _componentInstance!: QuestionInputTypeComponent;
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _leadSubject: BehaviorSubject<Lead> = new BehaviorSubject<Lead>(null);

  constructor(
    @Inject(QUESTION_INPUT_TYPE_COMPONENTS)
    private questionInputTypeComponents: QuestionInputTypeComponentMap[]
  ) {}

  public get answers(): Map<string, QuestionAnswer> {
    return this._answers;
  }

  @Input()
  public set answers(answers: Map<string, QuestionAnswer>) {
    this._answers = answers;
  }

  @Input()
  public set question(value: Question) {
    if (value) {
      this._question = value;
      this.loadComponent(value);
    }
  }

  @Input()
  public set lead(lead: Lead) {
    if (lead) {
      this._componentInstance.lead$?.next(lead);
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  public isValid(): boolean {
    return this._componentInstance.validate();
  }

  public getAnswer(): unknown {
    return this._componentInstance.getAnswer();
  }

  private loadComponent(question: Question): void {
    const component = this.findComponentByName(question.question_type);
    const viewContainerRef = this.viewContainerRef;

    viewContainerRef.clear();

    if (component) {
      const componentReference = viewContainerRef.createComponent(component);

      this._componentInstance = componentReference.instance;
      componentReference.instance.question = question;

      componentReference.instance.updateAnswer.pipe(takeUntil(this._destroy$)).subscribe((value) => {
        this.updateAnswer.emit(value);
      });

      componentReference.instance.answer$.next(this._answers.get(this._question?.id));
    } else {
      throw Error(`input type ${question.question_type} not found. please add in the question input types mapping`);
    }
  }

  private findComponentByName(name: string): ComponentType<QuestionInputTypeComponent> {
    const componentMap = this.questionInputTypeComponents.find((component) => {
      return component.name === name;
    });

    return componentMap?.component || null;
  }
}
