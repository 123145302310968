<div class="row questionnaire-form">
  <div class="col-lg-6 questionnaire-section">
    <cap-card [title]="'market_discovery.questionnaire.entity.name' | translate">
      <ng-template capTemplate="content">
        <cpb-form-renderer
          [formKey]="formKey"
          [showHeader]="false"
          [context]="context"
          [contextKey]="contextKey"
          [readOnly]="disabled"
          [defaultValue]="questionnaire$ | async">
        </cpb-form-renderer>
      </ng-template>
    </cap-card>
  </div>

  <div class="col-lg-6" *ngIf="questionnaire$ | async">
    <cap-card
      [title]="'market_discovery.questionnaire.questions.label' | translate"
      class="question-card"
      [buttonLabel]="disabled ? null : ('market_discovery.questionnaire.question_add.label' | translate)"
      [buttonIcon]="'fas fa-plus'"
      (buttonClick)="openQuestionDialog()">
      <ng-template capTemplate="content">
        <div
          class="questions-list"
          cdkDropList
          *ngIf="questionnaireQuestions$ | async as questions"
          (cdkDropListDropped)="handleQuestionDrop($event)">
          <div
            class="question-item"
            cdkDrag
            [cdkDragDisabled]="disabled"
            *ngFor="let question of questions; let index = index">
            <div class="icon">
              <i cdkDragHandle class="fas fa-grip-dots-vertical"></i>
            </div>

            <div class="question-content">
              <span>{{ question.question_type_base_data_value_id | capBaseDataTranslation }}</span>
              <h6>
                <span class="question_number">{{ index + 1 }}.</span>
                <span class="question_name">{{ question.name }}</span>
              </h6>
              <ul>
                <li *ngFor="let option of question.questionOptions | slice : 0 : 5">
                  {{ option.value }}
                  <ng-container *ngIf="option.is_refer">
                    <span class="option-refer">
                      <i class="far fa-arrow-right"></i>&nbsp;

                      <span>{{ option.go_to_question_id | getQuestionNumber : questions }}</span>
                    </span>
                  </ng-container>
                </li>

                <li *ngIf="question.questionOptions.length > 5">
                  {{ 'market_discovery.questionnaire.more-options' | translate : { count: question.questionOptions.length - 5 } }}
                </li>
              </ul>
            </div>

            <div class="question-refer" *ngIf="question.go_to_question_id">
              <div class="question-refer-content">
                <i class="far fa-arrow-right"></i>&nbsp;<span>{{
                  question.go_to_question_id | getQuestionNumber : questions
                }}</span>
              </div>
            </div>

            <div class="actions" *ngIf="!disabled">
              <cap-row-action [actions]="rowActions" [item]="{question, index }"></cap-row-action>
            </div>
          </div>
        </div>
      </ng-template>
    </cap-card>
  </div>
</div>
