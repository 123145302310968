import { Pipe, PipeTransform } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Pipe({
  name: 'filterByPermissions',
})
export class FilterByPermissionsPipe implements PipeTransform {
  constructor(private ngxPermissionService: NgxPermissionsService) {}

  public transform(values: any[], permissionKey = 'permissions'): any[] {
    const permissions = this.ngxPermissionService.getPermissions();

    return values.filter((value) => {
      return (
        value[permissionKey].length === 0 ||
        value[permissionKey].some((permission: string) => {
          return !!permissions[permission];
        })
      );
    });
  }
}
