import { NotificationService } from '@shared/services/notification.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NotificationBadgeOptions, SidebarMenuItem, ToastService } from '@capturum/ui/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStateService } from '../../services/project-state.service';
import { DestroyBase } from '@capturum/shared';
import { takeUntil, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { ProjectApiService } from '@features/project/services/project-api.service';
import { filter, tap } from 'rxjs/operators';
import { AppRoutes } from '@core/enums/routes.enum';
import { AuthService } from '@shared/services/auth.service';

interface ProjectLayoutSideBarMenuitem extends SidebarMenuItem {
  notificationBadgeOptions?: Observable<NotificationBadgeOptions>;
}

@Component({
  selector: 'app-xyz-layout',
  templateUrl: './project-layout.component.html',
  styleUrls: ['./project-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectLayoutComponent extends DestroyBase implements OnInit, OnDestroy {
  public menuItems: ProjectLayoutSideBarMenuitem[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectStateService: ProjectStateService,
    private translateService: TranslateService,
    private permissionService: NgxPermissionsService,
    private projectApiService: ProjectApiService,
    private authService: AuthService,
    private toastService: ToastService,
    private notificationService: NotificationService
  ) {
    super();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.permissionService.getPermission('project.create')) {
      this.projectStateService.resetProject();
    }
  }

  public ngOnInit(): void {
    this.watchProjectChange();

    const id = this.route.snapshot.params.id;

    if (id && this.isValidId(id)) {
      this.projectApiService
        .get(id, { include: ['metaKeys.type', 'projectType'] })
        .pipe(
          tap((project) => {
            return this.projectStateService.setProject(project);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((project) => {
          // Update the id in the route
          this.router.navigateByUrl(this.router.url.replace(this.route.snapshot.paramMap.get('id'), project.id));
        });
    } else {
      this.toastService.error(
        this.translateService.instant('toast.error.title'),
        this.translateService.instant('market_discovery.project.no-project-selected')
      );
      this.router.navigate([AppRoutes.profile]);
    }
  }

  private isValidId(id: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    return uuidRegex.test(id);
  }

  private watchProjectChange(): void {
    this.projectStateService
      .getProject()
      .pipe(
        filter(Boolean),
        tap((project) => {
          this.notificationService.setProjectLeadsActionRequiredCount(project.id);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((project) => {
        this.notificationService.actionRequirednBadgeOptionsSubject.next(this.notificationService.defaultBadgeOptions);

        this.menuItems = [
          {
            label: this.translateService.instant('market_discovery.navigation.xyz'),
            routerLink: `/project/${project.id}/${AppRoutes.xyz}`,
            icon: null,
          },
          {
            label: this.translateService.instant('market_discovery.project.menu.details'),
            routerLink: `/project/${project.id}/${AppRoutes.details}`,
            icon: null,
            hidden: !this.permissionService.getPermission('project.manage.details'),
          },
          {
            label: this.translateService.instant('market_discovery.project.menu.log'),
            routerLink: `/project/${project.id}/${AppRoutes.log}`,
            icon: null,
            hidden: !this.permissionService.getPermission('activity.manage'),
          },
          {
            label: this.translateService.instant('market_discovery.project.menu.leads'),
            routerLink: `/project/${project.id}/${AppRoutes.leads}`,
            icon: null,
            hidden: !this.permissionService.getPermission('lead.show'),
          },
          {
            label: this.translateService.instant('market_discovery.project.menu.stakeholders'),
            routerLink: `/project/${project.id}/${AppRoutes.stakeholders}`,
            icon: null,
            hidden: !this.permissionService.getPermission('project.account_managers.manage'),
          },
          {
            label: this.translateService.instant('market_discovery.project.menu.grip_meter'),
            routerLink: `/project/${project.id}/${AppRoutes.gripMeter}`,
            icon: null,
          },
          {
            label: this.translateService.instant('market_discovery.project.menu.pipeline'),
            routerLink: `/project/${project.id}/${AppRoutes.pipeline}`,
            icon: null,
          },
          {
            label: this.translateService.instant('market_discovery.project.menu.full_business'),
            routerLink: `/project/${project.id}/${AppRoutes.fullBusiness}`,
            icon: null,
            hidden:
              !this.authService.canAccessFullBusiness() ||
              !this.permissionService.getPermission('project.full-business.show'),
          },
          {
            label: this.translateService.instant('market_discovery.navigation.selection'),
            routerLink: `/project/${project.id}/${AppRoutes.selection}`,
            icon: null,
            hidden: !this.permissionService.getPermission('project.selection.manage'),
            notificationBadgeOptions: this.notificationService.getProjectLeadsActionRequiredBadgeOptions(),
          },
          {
            label: this.translateService.instant('market_discovery.navigation.questionnaire'),
            routerLink: `/project/${project.id}/${AppRoutes.questionnaire}`,
            icon: null,
            hidden: !this.permissionService.getPermission('project.questionnaire.manage'),
          },
          {
            label: this.translateService.instant('market_discovery.project-hours.list.title'),
            routerLink: `/project/${project.id}/${AppRoutes.projectHours}`,
            icon: null,
            hidden: !this.permissionService.getPermission('project-hour.manage'),
          },
          {
            label: this.translateService.instant('market_discovery.navigation.letters'),
            routerLink: `/project/${project.id}/${AppRoutes.letter}`,
            icon: null,
            hidden: !this.permissionService.getPermission('letter.manage'),
          },
          {
            label: this.translateService.instant('market_discovery.navigation.roi'),
            routerLink: `/project/${project.id}/${AppRoutes.roi}`,
            icon: null,
            hidden: !this.permissionService.getPermission('project.roi.manage'),
          },
          {
            label: this.translateService.instant('market_discovery.navigation.imports'),
            routerLink: `/project/${project.id}/${AppRoutes.imports}`,
            icon: null,
            hidden: !this.permissionService.getPermission('import.manage'),
          },
          {
            label: this.translateService.instant('market_discovery.navigation.fieldwork'),
            routerLink: `/project/${project.id}/${AppRoutes.fieldwork}`,
            hidden: !this.permissionService.getPermission('project.fieldwork'),
            icon: null,
          },
        ];
      });
  }
}
