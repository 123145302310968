import { LocalStorageService } from './../../../shared/services/storage.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, startWith } from 'rxjs';
import { Project } from '../interfaces/project.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectStateService {
  private projectId = new BehaviorSubject<Project>(null);
  private leadsImportBusy = new BehaviorSubject<boolean>(false);
  private _leadsExportBusy = new BehaviorSubject<boolean>(false);
  private selectionsImportBusy = new BehaviorSubject<boolean>(false);
  private projectSelectorDisabled = new BehaviorSubject<boolean>(false);

  constructor(private localStorageService: LocalStorageService) {}

  public getProjectSnapshot(): Project {
    return this.projectId.getValue();
  }

  public get leadsExportBusy(): Observable<boolean> {
    return this._leadsExportBusy.asObservable();
  }

  public setLeadsExportBusy(busy: boolean): void {
    this._leadsExportBusy.next(busy);
  }

  public getProject(): Observable<Project> {
    return this.projectId.asObservable().pipe(startWith(this.localStorageService.get<Project>('active-project')));
  }

  public setProject(project: Project): void {
    this.projectId.next(project);

    this.localStorageService.set('active-project', project);
  }

  public resetProject(): void {
    this.projectId.next(null);
    this.localStorageService.remove('active-project');
  }

  public getLeadsImportBusy(): Observable<boolean> {
    return this.leadsImportBusy.asObservable();
  }

  public setLeadsImportBusy(busy: boolean): void {
    this.leadsImportBusy.next(busy);
  }

  public getSelectionsImportBusy(): Observable<boolean> {
    return this.selectionsImportBusy.asObservable();
  }

  public setSelectionsImportBusy(busy: boolean): void {
    this.selectionsImportBusy.next(busy);
  }

  public disableProjectSelection(): void {
    this.projectSelectorDisabled.next(true);
  }

  public enableProjectSelection(): void {
    this.projectSelectorDisabled.next(false);
  }

  public getProjectSelectionDisabled(): Observable<boolean> {
    return this.projectSelectorDisabled.asObservable();
  }
}
