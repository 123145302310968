import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Contact } from '@core/interfaces/contact.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-contacts-type',
  templateUrl: './contacts-type.component.html',
  styleUrls: ['./contacts-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactsTypeComponent extends FieldType implements OnInit {
  public contacts$: Observable<Contact[]>;

  private contactsSubject = new BehaviorSubject<Contact[]>([]);

  constructor() {
    super();

    this.contacts$ = this.contactsSubject.asObservable();
  }

  public ngOnInit(): void {
    if (this.formControl.value) {
      this.contactsSubject.next(mapData(this.formControl.value));
    }

    this.formControl.valueChanges.pipe(map(mapData)).subscribe((data) => {
      this.contactsSubject.next(data);
    });
  }
}

function mapData(data: Contact[]): Contact[] {
  if (!Array.isArray(data) && !!data) {
    return [data];
  }

  return data;
}
