import { FilterMatchMode } from '@capturum/ui/api';
import { first } from 'rxjs/operators';
import { DestroyBase } from '@capturum/shared';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { GoogleMap, MapGeocoder, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadsMap } from '@features/lead/interfaces/lead.interface';
import { LeadPin } from '@features/project/interfaces/lead-pin.interface';
import MarkerOptions = google.maps.MarkerOptions;

@Component({
  selector: 'app-leads-map',
  templateUrl: './leads-map.component.html',
  styleUrls: ['./leads-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectLeadsMapComponent extends DestroyBase implements OnInit {
  public defaultMapCenter = { lat: 52.0263009, lng: 5.5544309 };

  public markerOptions: MarkerOptions = {
    optimized: true,
  };

  @Input()
  public options: google.maps.MapOptions = {
    center: this.defaultMapCenter,
    zoom: 10,
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
    gestureHandling: 'cooperative',
  };

  public markerClustererImagePath =
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';

  @ViewChild('gmap')
  public gmap: GoogleMap;

  @ViewChild(MapInfoWindow)
  public infoWindow: MapInfoWindow;

  public circleCenter: google.maps.LatLngLiteral;
  public circleRadius: number;
  public mapCenter: google.maps.LatLngLiteral;
  public mapCenterSubject: Subject<google.maps.LatLngLiteral> = new Subject<google.maps.LatLngLiteral>();
  public circleOptions: google.maps.CircleOptions = {
    fillColor: '#009fec',
    fillOpacity: 0.1,
    strokeColor: '#009fec',
    strokeWeight: 1,
  };

  public infoWindowContext: { [key: string]: any };
  public infoWindowOptions: google.maps.InfoWindowOptions = {
    minWidth: 300,
    maxWidth: 400,
  };

  private _placesService: google.maps.places.PlacesService;
  private _mapCenter$: Observable<google.maps.LatLngLiteral> = this.mapCenterSubject.asObservable();

  private _data: LeadsMap;
  private _colorMap: Map<string, string> = new Map()
    .set('md_leads', '#0971f0')
    .set('customer', '#ca4751')
    .set('others', '#1a806a');

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly geocoder: MapGeocoder
  ) {
    super();
  }

  public get data(): LeadsMap {
    return this._data;
  }

  @Input()
  public set data(value: LeadsMap) {
    if (value) {
      this._data = this.enrichDataWithColor(value);

      const coordinates = value.lon ? { lng: value.lon, lat: value.lat } : null;

      this.setMapCenter(coordinates);
    }
  }

  public ngOnInit(): void {
    this.watchMapCenterChange();
  }

  public goToDetailPage(lead: LeadPin): void {
    this.router.navigate([lead.lead_id], { relativeTo: this.route });
  }

  public openInfoWindow(marker: MapMarker, index: number): void {
    this.infoWindowContext = {
      $implicit: this.data?.leads[index],
    };

    this.infoWindow.open(marker);
  }

  public mapInitialized(googleMap: google.maps.Map): void {
    this._placesService = new google.maps.places.PlacesService(googleMap);
  }

  private setMapCenter(coordinates?: { lng: number; lat: number }): void {
    if (!coordinates) {
      const zipcodeFilter = this.data?.filters.find((filter) => {
        if (filter.field === 'zipcode') {
          if (filter.operator === FilterMatchMode.LIKE) {
            filter.value = filter.value.replaceAll('%', '');
          }

          return true;
        }

        return false;
      });
      const cityFilter = this.data?.filters.find((filter) => {
        if (filter.field === 'city') {
          if (filter.operator === FilterMatchMode.LIKE) {
            filter.value = filter.value.replaceAll('%', '');
          }

          return true;
        }

        return false;
      });

      if (zipcodeFilter || cityFilter) {
        const request: google.maps.GeocoderRequest = {
          address: zipcodeFilter?.value || cityFilter?.value,
        };

        this.findPlaceFromGeoCode(request);
      } else if (this.data.leads.length) {
        const firstLead = this.data.leads[0];
        const firstLeadLocation = { lat: firstLead.lat, lng: firstLead.lon };

        this.mapCenterSubject.next(firstLeadLocation);
      } else {
        this.mapCenterSubject.next(this.defaultMapCenter);
      }
    } else {
      this.mapCenterSubject.next(coordinates);
    }
  }

  private findPlaceFromGeoCode(request: google.maps.GeocoderRequest): void {
    this.geocoder
      .geocode(request)
      .pipe(first())
      .subscribe(({ results, status }) => {
        if (status === google.maps.GeocoderStatus.OK && results) {
          const location = results[0].geometry!.location!;

          this.mapCenterSubject.next(location.toJSON());
        }
      });
  }

  private watchMapCenterChange(): void {
    this._mapCenter$.pipe(takeUntil(this.destroy$)).subscribe((center) => {
      this.gmap.googleMap.setCenter(center);

      this.circleCenter = center;
      const radius = this.data?.filters.find((filter) => {
        return filter.field === 'radius';
      })?.value;

      this.circleRadius = radius ? parseInt(radius, 10) : null;
    });
  }

  private enrichDataWithColor(mapData: LeadsMap): LeadsMap {
    return {
      ...mapData,
      leads: mapData.leads.map((lead) => {
        let color = this._colorMap.get(lead.source_value?.toLowerCase());

        if (!color) {
          color = this._colorMap.get('others');
        }

        return {
          ...lead,
          lon: +lead.lon,
          lat: +lead.lat,
          color,
        };
      }),
    };
  }
}
