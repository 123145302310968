import { Injectable } from '@angular/core';
import { ActionOptions, ActionProvider } from '@capturum/builders/core';
import { SelectionApiService } from '@features/selection/services/selection-api.service';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, FilterMatchMode } from '@capturum/ui/api';
import { Lead } from '@features/lead/interfaces/lead.interface';
import { FinishedBatchStatus } from '@capturum/complete';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExportSelectionAction implements ActionProvider {
  constructor(
    private selectionService: SelectionApiService,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {}

  public execute(
    options: ActionOptions,
    items: Lead[],
    context?: Record<string, any>
  ): Observable<FinishedBatchStatus> {
    const filters = [
      {
        field: 'id',
        value: items.map((item) => {
          return item.id;
        }),
        operator: FilterMatchMode.IN,
      },
    ];

    this.toastService.info(
      this.translateService.instant('market_discovery.selection.export'),
      this.translateService.instant('market_discovery.lead.export.started')
    );

    return this.selectionService.exportSelection(context.project_id, { filters }).pipe(first());
  }
}
