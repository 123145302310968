import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { BaseDataModule } from '@capturum/complete';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumCardModule } from '@capturum/ui/card';
import { QuestionnaireModule } from '@features/questionnaire/questionnaire.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';

import { LayoutModule } from '../../shared/layout/layout.module';
import { AgentQuestionnaireRoutingModule } from './agent-questionnaire-routing.module';
import { AgentQuestionnaireComponent } from './agent-questionnaire/agent-questionnaire.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AgentQuestionnaireComponent],
  imports: [
    CommonModule,
    CapturumListRendererModule,
    CapturumButtonModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxPermissionsModule,
    CapturumCardModule,
    CapturumSharedModule,
    AgentQuestionnaireRoutingModule,
    BaseDataModule,
    QuestionnaireModule,
  ],
})
export class AgentQuestionnaireModule {}
