<app-page-layout
  [title]="'market_discovery.lead.project_info.label' | translate"
  [keepQueryParams]="true"
  backRoute="../">
  <app-page-buttons actions>
    <cap-button
      icon="fas fa-xmark"
      [label]="'button.cancel' | translate"
      (click)="navigateBack()"
      styleClass="secondary">
    </cap-button>

    <cap-button icon="fas fa-save" [label]="'button.submit' | translate" (click)="submit()" styleClass="primary">
    </cap-button>
  </app-page-buttons>

  <div class="row" content>
    <div class="col-lg-6">
      <cap-card [title]="'market_discovery.lead.project_info.label' | translate">
        <ng-template capTemplate="content">
          <div class="project-info-form">
            <formly-form [model]="model" [fields]="fields" [form]="form"> </formly-form>
          </div>
        </ng-template>
      </cap-card>
    </div>
  </div>
</app-page-layout>
