import { AuthService } from '@capturum/auth';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { toMapItems } from '@capturum/builders/core';
import { MapItem } from '@capturum/ui/api';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ProjectApiService } from '@features/project/services/project-api.service';
import User from '@capturum/auth/lib/user.interface';
import { Project } from '../../../features/project/interfaces/project.interface';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enums/routes.enum';
import { NgxPermissionsService } from 'ngx-permissions';

interface DropdownEvent {
  originalEvent: PointerEvent;
  value: string;
}

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  public homePageUrl = '/';
  public title: string = environment.appName;
  public project$: Observable<Project>;
  public options$: Observable<MapItem[]>;
  public user: User;
  public options = [];
  public hasProjectCreatePermission: boolean;
  public projectSelectorDisabled$: Observable<boolean>;
  public environmentName: string;

  constructor(
    private projectApiService: ProjectApiService,
    private projectStateService: ProjectStateService,
    private authService: AuthService,
    private router: Router,
    private ngxPermissionService: NgxPermissionsService
  ) {}

  public ngOnInit(): void {
    if (!environment.production) {
      this.environmentName = environment.name;
    }

    this.projectSelectorDisabled$ = this.projectStateService.getProjectSelectionDisabled();
    this.user = this.authService.getUser();
    this.project$ = this.projectStateService.getProject();
    this.options$ = this.projectApiService.list().pipe(toMapItems);
    this.hasProjectCreatePermission = !!this.ngxPermissionService.getPermission('project.create');
  }

  public handleProjectChange(event: DropdownEvent): void {
    if (event.value) {
      this.projectApiService.get(event.value, { include: ['projectType'] }).subscribe((project) => {
        this.projectStateService.setProject(project);
        this.router.navigate([`${AppRoutes.project}/${event.value}`]);
      });
    }
  }
}
