import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormSaverService } from '@capturum/builders/form-renderer';

@Component({
  selector: 'app-bulk-status-dialog',
  templateUrl: './bulk-status-dialog.component.html',
  styleUrls: ['./bulk-status-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkStatusDialogComponent {
  public formKey = 'form_bulk_lead_status';

  constructor(
    public dialogRef: DynamicDialogRef,
    private formSaver: FormSaverService,
    private dialogConfig: DynamicDialogConfig
  ) {}

  public submit(): void {
    this.formSaver
      .submit(this.formKey, {
        leads: this.dialogConfig.data.item.map((item) => {
          return item.id;
        }),
      })
      .subscribe(() => {
        this.dialogRef.close();
      });
  }
}
