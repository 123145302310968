import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { FilterMatchMode, MapItem } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { MetaKey } from '../interfaces/meta-key.interface';

@Injectable({
  providedIn: 'root',
})
export class MetaKeyApiService extends ApiService<MetaKey> {
  protected endpoint = 'meta-key';

  constructor(apiHttp: ApiHttpService, private translateService: TranslateService) {
    super(apiHttp);
  }

  public getOperatorOptions(metaKey: MetaKey): MapItem[] {
    let matchModes: FilterMatchMode[] = [];

    switch (metaKey?.type?.value) {
      case 'double':
      case 'integer':
        matchModes = [
          FilterMatchMode.LESS,
          FilterMatchMode.LESS_OR_EQUAL,
          FilterMatchMode.GREATER,
          FilterMatchMode.GREATER_OR_EQUAL,
          FilterMatchMode.EQUALS,
          FilterMatchMode.NOT_EQUALS,
        ];

        break;
      case 'boolean':
        matchModes = [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS];

        break;
      case 'string':
        matchModes = [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS];

        break;
      case 'dropdown':
        matchModes = [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS];

        break;

      default:
        break;
    }

    return matchModes.map((matchMode) => {
      return {
        label: this.translateService.instant(`market_discovery.matchmode.${matchMode}`),
        value: matchMode,
      };
    });
  }
}
