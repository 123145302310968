import { Component, OnInit } from '@angular/core';
import { BatchStatusActionsService, VersionCheckService } from '@capturum/complete';
import { CSSVarNames, ThemeService } from '@capturum/ui/api';
import { DownloadUrlAction } from '@core/batch-status-actions/download-url.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Confirmation, ConfirmationService, PrimeNGConfig, TranslationKeys } from 'primeng/api';
import { BackendToastAction } from '@core/batch-status-actions/backend-toast.action';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [VersionCheckService],
})
export class AppComponent implements OnInit {
  public confirmation: Confirmation;
  public idleToastKey = 'idle-toast';

  constructor(
    private readonly versionCheckService: VersionCheckService,
    private readonly themeService: ThemeService,
    private readonly translateService: TranslateService,
    private readonly confirmationService: ConfirmationService,
    private batchStatusActionService: BatchStatusActionsService,
    private config: PrimeNGConfig
  ) {}

  public ngOnInit(): void {
    this.setTheme();
    this.confirmationService.requireConfirmation$.subscribe((result) => {
      return (this.confirmation = result);
    });
    this.batchStatusActionService.addActions({
      download_url: DownloadUrlAction,
      request_toast: BackendToastAction,
    });
    this.config.setTranslation({
      [TranslationKeys.FIRST_DAY_OF_WEEK]: 1,
    });
  }

  private setTheme(): void {
    this.themeService.setProps({
      [CSSVarNames.Primary]: '#2ba8e2',
      [CSSVarNames.Font]: 'Open Sans',
      [CSSVarNames.InputHeight]: '30px',
      [CSSVarNames.DynamicFilterInputHeight]: '30px',
      [CSSVarNames.DynamicFilterBorderRadius]: '25px',
      [CSSVarNames.DynamicFilterLabelFontSize]: '1rem',
      [CSSVarNames.DynamicFilterLabelFontColor]: '#666',
      [CSSVarNames.DynamicFilterLabelFontFamily]: 'Open Sans',
      [CSSVarNames.DynamicFilterLabelFontWeight]: '400',
      [CSSVarNames.DynamicFilterPlaceholderFontWeight]: '400',
    });
  }

  private fetchVersionChanging(): void {
    this.versionCheckService.initVersionCheck(`${environment.url}/version.json`, 10000);

    this.versionCheckService.onUpdateVersion.subscribe(() => {
      window.location.reload();
    });
  }
}
