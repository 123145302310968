import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormSaverService } from '@capturum/builders/form-renderer';
import { ToastService } from '@capturum/ui/api';
import { AppRoutes } from '@core/enums/routes.enum';
import { ManageDetailComponent } from '@features/manage/pages/manage-detail/manage-detail.component';
import { Question } from '@features/questionnaire/interfaces/questionnaire.interface';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-questionnaire-detail',
  templateUrl: './questionnaire-detail.component.html',
  styleUrls: ['./questionnaire-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class QuestionnaireDetailComponent extends ManageDetailComponent implements OnInit {
  public questions: Question[];

  constructor(
    protected formSaverService: FormSaverService,
    protected route: ActivatedRoute,
    private router: Router,
    protected toastService: ToastService,
    protected translateService: TranslateService
  ) {
    super(formSaverService, route, toastService, translateService);
  }

  public submit(): void {
    this.formSaverService
      .submit(this.formKey, { questions: this.questions, id: this.modelId })
      .pipe(first())
      .subscribe(({ response }) => {
        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant(`market_discovery.entity.toast.${this.modelId ? 'updated' : 'created'}`, {
            entity: this.getEntityTranslation(),
          })
        );

        this.router.navigate([
          this.modelId ? AppRoutes.questionnaire : `${AppRoutes.questionnaire}/${response.data.id}`,
        ]);
      });
  }

  public cancel(): void {
    this.router.navigate([AppRoutes.questionnaire]);
  }
}
