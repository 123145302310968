import { Component } from '@angular/core';
import { CapturumBuilderDefaultInputWrapperComponent } from '@capturum/builders/form-renderer';

@Component({
  selector: 'app-no-label-wrapper',
  template: ` <div
    class="form-group cbp-default-input-wrapper"
    [class.cpb-readonly]="formState.readonly"
    [class.has-error]="showError">
    <div class="field-wrapper">
      <ng-template #fieldComponent></ng-template>
    </div>
  </div>`,
})
export class NoLabelWrapperComponent extends CapturumBuilderDefaultInputWrapperComponent {}
