import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  @Input()
  public tabs: { label: string; key: string }[];

  @Output()
  public tabSelect = new EventEmitter<number>();

  public activeTab = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  public selectTab(index: number): void {
    this.tabSelect.emit(index);
    this.activeTab = index;
    this.cdr.detectChanges();
  }
}
