import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callbackTime',
})
export class CallbackTimePipe implements PipeTransform {
  public transform(value: string, date: string): Date {
    const [hours, minutes] = value.split(':');
    const returnDate = new Date(date);

    if (hours) {
      returnDate.setUTCHours(+hours, +minutes);
    }

    return returnDate;
  }
}
