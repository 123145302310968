import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManageRoutingModule } from './manage-routing.module';
import { ManageListComponent } from './pages/manage-list/manage-list.component';
import { ManageDetailComponent } from './pages/manage-detail/manage-detail.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@shared/layout/layout.module';
import { CapturumButtonModule } from '@capturum/ui/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SharedModule } from '@shared/shared.module';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { ManageCustomerDetailComponent } from './pages/manage-customer-detail/manage-customer-detail.component';
import { CapturumCardModule } from '@capturum/ui/card';
import { CapturumSharedModule } from '@capturum/ui/api';

@NgModule({
  declarations: [ManageListComponent, ManageDetailComponent, ManageCustomerDetailComponent],
  imports: [
    CommonModule,
    ManageRoutingModule,
    LayoutModule,
    ReactiveFormsModule,
    CapturumButtonModule,
    CapturumSharedModule,
    ConfirmDialogModule,
    SharedModule,
    CapturumListRendererModule,
    CapturumFormRendererModule,
    CapturumCardModule,
  ],
  exports: [ManageListComponent, ManageDetailComponent, ManageCustomerDetailComponent],
})
export class ManageModule {}
