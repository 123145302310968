<app-dialog-layout>
  <cpb-form-renderer content [showHeader]="false" [formKey]="formKey"></cpb-form-renderer>

  <ng-container buttons>
    <cap-button icon="fal fa-times" styleClass="secondary" [label]="'button.cancel' | translate" (onClick)="cancel()">
    </cap-button>

    <cap-button
      styleClass="primary"
      icon="fas fa-floppy-disk"
      [label]="'market_discovery.button.save' | translate"
      (onClick)="submit()">
    </cap-button>
  </ng-container>
</app-dialog-layout>
