import { MetaValue } from './../../meta-key/interfaces/meta-key.interface';
import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiService, ListOptions } from '@capturum/api';
import { Lead, LeadAttachmentRequest, LeadImportRequest, PrevNextLead } from '../interfaces/lead.interface';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { LeadAction } from '@features/project/interfaces/lead-action.interface';
import { responseData, toMapItems } from '@capturum/builders/core';
import { BlueprintFile } from '@core/interfaces/file.interface';
import { ProjectLeadMetaKeys } from '@features/meta-key/interfaces/meta-key.interface';
import { LeadGripMeter } from '@features/project/interfaces/grip-meter.interface';
import { MapItem } from '@capturum/ui/api';
import { BatchStatusService, FinishedBatchStatus } from '@capturum/complete';
import { LeadDetails } from '@features/project/interfaces/lead-details.interface';
import { LocalStorageService } from '@shared/services/storage.service';
import { Contact } from '@core/interfaces/contact.interface';
import { QuestionType } from '../../questionnaire/enums/question-type.enum';
import { Question, QuestionAnswer } from '../../questionnaire/interfaces/questionnaire.interface';

@Injectable({
  providedIn: 'root',
})
export class LeadApiService extends ApiService<Lead> {
  public dequeueIsDone$ = new BehaviorSubject<boolean>(false);

  protected endpoint = 'lead';

  constructor(
    apiHttp: ApiHttpService,
    private batchStatusService: BatchStatusService,
    private storageService: LocalStorageService
  ) {
    super(apiHttp);
  }

  public getProjectLeads(projectId: string, options?: ListOptions): Observable<ApiIndexResult<Lead>> {
    const apiOptions: ListOptions = {
      ...options,
      parameters: [
        {
          field: 'cacheKey',
          value: this.getProjectLeadsCacheKey(projectId),
        },
      ],
    };

    return this.apiHttp.get(`/${this.endpoint}/project/${projectId}${this.getOptionsQuery(apiOptions)}`);
  }

  public getPreviousLead(projectId: string, leadId: string, options?: ListOptions): Observable<PrevNextLead> {
    const apiOptions: ListOptions = {
      ...options,
      parameters: [
        {
          field: 'cacheKey',
          value: this.getProjectLeadsCacheKey(projectId),
        },
      ],
    };

    return this.apiHttp
      .get(`/${this.endpoint}/project/${projectId}/${leadId}/prev${this.getOptionsQuery(apiOptions)}`)
      .pipe(responseData);
  }

  public getNextLead(projectId: string, leadId: string, options?: ListOptions): Observable<PrevNextLead> {
    const apiOptions: ListOptions = {
      ...options,
      parameters: [
        {
          field: 'cacheKey',
          value: this.getProjectLeadsCacheKey(projectId),
        },
      ],
    };

    return this.apiHttp
      .get(`/${this.endpoint}/project/${projectId}/${leadId}/next${this.getOptionsQuery(apiOptions)}`)
      .pipe(responseData);
  }

  public uploadAttachment(leadId: string, data: LeadAttachmentRequest): Observable<BlueprintFile> {
    return this.apiHttp.post(`/${this.endpoint}/${leadId}/attachment`, data).pipe(responseData);
  }

  public removeAttachment(id: string): Observable<any> {
    return this.apiHttp.delete(`/${this.endpoint}/attachment/${id}`);
  }

  public getLeadGripInfo(leadId: string): Observable<LeadGripMeter[]> {
    return this.apiHttp.get(`/${this.endpoint}/${leadId}/grip`).pipe(responseData);
  }

  public saveGripMeterInfo(leadId: string, gripStatuses: string[]): Observable<number> {
    return this.apiHttp
      .post<{ data: number }>(`/${this.endpoint}/${leadId}/grip`, { grip_question_ids: gripStatuses })
      .pipe(responseData);
  }

  public importFromSelection(selectionId: string, projectId: string): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/import-selection`, {
      selection_id: selectionId,
      project_id: projectId,
    });
  }

  public getActions(id: string): Observable<LeadAction[]> {
    return this.apiHttp.get(`/${this.endpoint}/${id}/action`).pipe(responseData);
  }

  public getProjectLeadMetaKeys(leadId: string, options?: ListOptions): Observable<ProjectLeadMetaKeys[]> {
    return this.apiHttp
      .get(`/${this.endpoint}/${leadId}/meta-values${this.getOptionsQuery(options)}`)
      .pipe(responseData);
  }

  public storeProjectLeadMetaKeys(leadId: string, data: MetaValue[]): Observable<ProjectLeadMetaKeys[]> {
    return this.apiHttp.post(`/${this.endpoint}/${leadId}/meta-values`, { meta_values: data }).pipe(responseData);
  }

  public import(body: LeadImportRequest, importAsSelection = false): Observable<{ batch_id: string }> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this.apiHttp.post(`/${this.endpoint}/import?importAsSelection=${importAsSelection}`, {
      ...body,
      timezone,
    });
  }

  public export(projectId: string, options?: ListOptions): Observable<FinishedBatchStatus> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this.apiHttp
      .post<{ batch_id: string }>(`/${this.endpoint}/export${this.getOptionsQuery(options)}`, {
        project_id: projectId,
        timezone,
      })

      .pipe(
        switchMap((response) => {
          return this.batchStatusService.getIsUpdatedBatch(
            response.batch_id,
            true,
            'market_discovery.lead.export.success',
            true
          );
        })
      );
  }

  public exportDetailsPdf(projectId: string, leadId: string): Observable<FinishedBatchStatus> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this.apiHttp.post(`/${this.endpoint}/${leadId}/export`, { timezone }).pipe(
      responseData,
      switchMap((response) => {
        return this.batchStatusService.getIsUpdatedBatch(
          response.batch_id,
          true,
          'market_discovery.lead.details.export.success',
          true
        );
      })
    );
  }

  public xyzStatuses(): Observable<MapItem[]> {
    return this.apiHttp.get(`/${this.endpoint}/xyz-statuses`).pipe(toMapItems);
  }

  public getLeadLogs(leadId: string): Observable<LeadDetails[]> {
    return this.apiHttp
      .get(`/${this.endpoint}/${leadId}/logs${this.getOptionsQuery({ perPage: 99, include: ['createdByUser'] })}`)
      .pipe(responseData);
  }

  public dequeue(): Observable<void> {
    return this.apiHttp.post(`/${this.endpoint}/dequeue`, {});
  }

  /**
   * Get all DMUS for the current lead
   *
   * @description Get all DMUS for the current lead.
   * These DMUS are based on the dmus already attached to the lead and also the dmus which were selected in the questionnaire.
   * @param lead
   * @param questions
   * @param answers
   */
  public getNewDmus(lead: Lead, questions: Question[], answers: QuestionAnswer[]): Contact[] {
    const questionTypes = [QuestionType.DMUS];
    const existingDmus = [...(lead.dmus || []), ...(lead.contacts || [])] || [];

    // Get all questions which are of the "dmus"
    const dmuQuestions = questions
      .filter((question) => {
        return questionTypes.includes(question.type.value as QuestionType);
      })
      .map((question) => {
        return question?.id;
      });

    const dmus: Contact[] = [];

    // Get all answers belonging to the dmu questions
    const dmuAnswers = answers.filter((answer) => {
      return dmuQuestions.includes(answer.question_id);
    });

    if (dmuQuestions.length && dmuAnswers.length) {
      dmuAnswers.forEach((answer) => {
        // For each dmu answer, add the newly added dmus to the dmus array and mark them as "isUpdated"
        dmus.push(
          ...(answer?.contacts.map((contact) => {
            return {
              ...contact,
              questionId: answer.question_id,
              isUpdated: true,
              is_main_contact: false,
            };
          }) || [])
        );

        if (answer.contact_ids?.length) {
          // If existing dmus were selected, add them to the dmus ONLY if they are already dmu of this lead.
          dmus.push(
            ...existingDmus
              .filter((existingDmu) => {
                return answer.contact_ids.includes(existingDmu.id);
              })
              .map((dmu) => {
                return {
                  ...dmu,
                  questionId: answer.question_id,
                  isUpdated: true,
                  is_main_contact: false,
                };
              })
          );
        }
      });

      return dmus;
    }

    return existingDmus;
  }

  public getNewContact(lead: Lead, questions: Question[], answers: QuestionAnswer[]): Contact[] {
    const questionTypes = [QuestionType.CONTACT];
    const existingContacts = lead.mainContact ? [lead.mainContact] : [];

    const contactQuestion = questions
      .filter((question) => {
        return questionTypes.includes(question.type.value as QuestionType);
      })
      .map((question) => {
        return question?.id;
      });
    let contacts: Contact[] = existingContacts;

    if (contactQuestion.length) {
      answers.forEach((answer) => {
        if (contactQuestion.includes(answer.question_id)) {
          if (answer?.contacts?.length) {
            contacts = contacts.map((contact) => {
              return {
                ...contact,
                is_main_contact: contact.is_main_contact ?? false,
              };
            });

            contacts = [
              ...(answer?.contacts.map((contact) => {
                return {
                  ...contact,
                  questionId: answer.question_id,
                  isUpdated: true,
                  is_main_contact: true,
                };
              }) || []),
            ];
          }

          if (answer.contact_ids?.length) {
            contacts = contacts.map((contact) => {
              return {
                ...contact,
                is_main_contact: false,
              };
            });

            contacts.push(
              ...lead.contacts
                .filter((existingContact) => {
                  return answer.contact_ids.includes(existingContact.id);
                })
                .map((contact) => {
                  return {
                    ...contact,
                    questionId: answer.question_id,
                    isUpdated: answer.contact_ids[0]?.id !== lead.mainContact?.id,
                    is_main_contact: contact.is_main_contact ?? true,
                  };
                })
            );
          }
        }
      });

      return contacts.filter((contact, index, contactArray) => {
        return !(
          contactArray.filter((c) => {
            return c.id === contact.id;
          }).length > 1 && !contact.is_main_contact
        );
      });

      return contacts.length ? [contacts?.[0]] : [];
    }

    const dmuQuestionType = [QuestionType.DMUS];

    const dmuQuestions = questions
      .filter((question) => {
        return dmuQuestionType.includes(question.type.value as QuestionType);
      })
      .map((question) => {
        return question?.id;
      });

    if (dmuQuestions.length) {
      const answeredDmuQuestion = dmuQuestions.some((question) => {
        const questionAnswer = answers.find((answer) => {
          return answer.quesionId === question;
        });

        return questionAnswer?.contact_ids || questionAnswer?.contacts;
      });

      return answeredDmuQuestion ? [] : existingContacts;
    }

    return contacts.length ? [contacts?.[0]] : [];
  }

  private getProjectLeadsCacheKey(projectId: string): string {
    let key: string = this.storageService.get<string>(`project-leads-key.${projectId}`);

    if (!key) {
      key = Math.random().toString(36).slice(2, 9);

      this.storageService.set(`project-leads-key.${projectId}`, key);
    }

    return key;
  }
}
