<app-header></app-header>

<div id="wrapper">
  <div class="page-wrapper gray-bg">
    <router-outlet></router-outlet>
  </div>
</div>

<p-toast
  position="top-right"
  styleClass="cap-toast idle-toast"
  [key]="idleToastKey"
  (onClose)="idleToastClose()"
  [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="d-flex">
      <i class="toast-icon d-flex justify-content-center flex-column fas fa-hourglass-half"></i>

      <div class="toast-content">
        <h3 class="toast-summary">{{ message.summary }}</h3>

        <span class="toast-detail" [innerHTML]="message.detail"></span>

        <div class="toast-button">
          <cap-button
            styleClass="primary"
            [label]="'market_discovery.button.stay_online' | translate"
            (click)="idleToastClose()">
          </cap-button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
