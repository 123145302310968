import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-question-placeholder',
  templateUrl: './question-placeholder.component.html',
  styleUrls: ['./question-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionPlaceholderComponent extends FieldType {}
