import { Injectable } from '@angular/core';
import { XyzFormatedResult, XyzGraph, XyzResponse, XyzTableRow } from '@features/project/interfaces/xyz.interface';
import { TranslateService } from '@ngx-translate/core';
import { ChartData } from 'chart.js';
import { formatDate } from '@angular/common';
import { AuthService } from '@shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class XyzService {
  constructor(private translateService: TranslateService, private authService: AuthService) {}

  public formatXyz(xyz: XyzResponse): XyzFormatedResult {
    let result: XyzTableRow[] = [];
    const generalFields = ['total', 'already_customer', 'wrong_profile', 'not_yet_reached', 'not_yet_reported'];

    result = generalFields.map((field) => {
      return {
        code: null,
        tooltip: xyz.base[field]?.from !== undefined ? `-${xyz.base[field].from} / +${xyz.base[field].to}` : null,
        description: this.translateService.instant(`market_discovery.xyz.${field}.description`),
        amount: xyz.base[field]?.from !== undefined ? xyz.base[field].to - xyz.base[field].from : xyz.base[field],
        percentage: null,
        expected: null,
        mutated: xyz.mutations[field],
      };
    });

    result = [
      ...result,
      {
        code: null,
        description: this.translateService.instant(`market_discovery.xyz.reached.description`),
        amount: xyz.base.reached,
        percentage: xyz.base.reached_percentage,
        mutated: null,
        expected: null,
      },
    ];

    return {
      tableRows: [...result, ...this.getStatusRows(xyz), ...this.getOverallXyzRows(xyz)],
      graph: xyz.graph,
    };
  }

  public getStatusRows(xyz: XyzResponse): XyzTableRow[] {
    const fields = [
      {
        code: 'X',
        fields: ['x1', 'x2', 'x3'],
      },
      {
        code: 'Y',
        fields: ['y1', 'y2', 'y3'],
      },
      {
        code: 'Z',
        fields: ['z1', 'z2', 'z3'],
      },
    ];

    return fields.reduce((acc, field) => {
      return [
        ...acc,
        ...[
          {
            code: `Code ${field.code}`,
            description: this.translateService.instant(
              `market_discovery.xyz.code-${field.code.toLowerCase()}.description`
            ),
            amount: null,
            percentage: null,
            expected: null,
            tableRowStyleClass: 'bold',
          },
          ...field.fields.map((statusField) => {
            return {
              code: statusField.toUpperCase(),
              description: this.translateService.instant(`market_discovery.xyz.code-${statusField}.description`),
              tooltip:
                xyz.base[`total_${statusField}`]?.from !== undefined
                  ? `-${xyz.base[`total_${statusField}`].from} / +${xyz.base[`total_${statusField}`].to}`
                  : null,
              amount:
                xyz.base[`total_${statusField}`].to !== undefined
                  ? xyz.base[`total_${statusField}`].to - xyz.base[`total_${statusField}`].from
                  : xyz.base[`total_${statusField}`],
              percentage: xyz.base[`percentage_${statusField}`],
              expected: xyz.base[`expected_${statusField}`],
              mutated: xyz.mutations[`total_${statusField}`],
            };
          }),
        ],
      ];
    }, []);
  }

  public getOverallXyzRows(xyz: XyzResponse): XyzTableRow[] {
    const tableRowStyleClass = 'no-borders light-blue bold';

    return [
      {
        code: null,
        description: this.translateService.instant(`market_discovery.xyz.percentage_short_term.description`),
        amount: null,
        percentage: xyz.base.percentage_short_term,
        expected: null,
        mutated: null,
        tableRowStyleClass,
      },
      {
        code: null,
        description: this.translateService.instant(`market_discovery.xyz.expected_short_term.description`),
        amount: null,
        percentage: null,
        mutated: null,
        expected: xyz.base.expected_short_term,
        tableRowStyleClass,
      },
      {
        code: null,
        description: this.translateService.instant(`market_discovery.xyz.percentage_long_term.description`),
        amount: null,
        percentage: xyz.base.percentage_long_term,
        mutated: null,
        expected: null,
        tableRowStyleClass,
      },
      {
        code: null,
        description: this.translateService.instant(`market_discovery.xyz.expected_long_term.description`),
        amount: null,
        percentage: null,
        mutated: null,
        expected: xyz.base.expected_long_term,
        tableRowStyleClass,
      },
    ];
  }

  public generateGraphData(graphs: XyzGraph[]): ChartData {
    let labels = graphs.map((item) => {
      return formatDate(item.key, 'MMM-yy', this.authService.getUser()?.locale.code);
    });

    if (labels.length === 1) {
      labels = [...labels, ...labels];
    }

    const datasets = [
      {
        label: this.translateService.instant('market_discovery.xyz.graph.codes.x1'),
        data: this.getChartData('percentage_x1', graphs),
        fill: false,
        borderColor: '#EF342A',
      },
      {
        label: this.translateService.instant('market_discovery.xyz.graph.codes.y1'),
        data: this.getChartData('percentage_y1', graphs),
        fill: false,
        borderColor: '#258B3D',
      },
      {
        label: this.translateService.instant('market_discovery.xyz.graph.codes.z1'),
        data: this.getChartData('percentage_z1', graphs),
        fill: false,
        borderColor: '#FFC839',
      },
      {
        label: this.translateService.instant('market_discovery.xyz.graph.codes.xyz'),
        data: this.getChartData('reached_percentage', graphs),
        fill: false,
        borderColor: '#1303fc',
      },
      {
        label: this.translateService.instant('market_discovery.xyz.graph.codes.expected-orders'),
        data: this.getChartData('percentage_short_term', graphs),
        fill: false,
        borderColor: '#db03fc',
      },
      {
        label: this.translateService.instant('market_discovery.xyz.graph.codes.target-x1'),
        data: this.getChartData('target_percentage_x1', graphs),
        fill: false,
        borderColor: '#fc8403',
        backgroundColor: ['#ffffff'],
        borderDash: [10, 5],
      },
      {
        label: this.translateService.instant('market_discovery.xyz.graph.codes.target-y1'),
        data: this.getChartData('target_percentage_y1', graphs),
        fill: false,
        borderColor: '#03fc67',
        backgroundColor: ['#ffffff'],
        borderDash: [10, 5],
      },
      {
        label: this.translateService.instant('market_discovery.xyz.graph.codes.target-z1'),
        data: this.getChartData('target_percentage_z1', graphs),
        fill: false,
        borderColor: '#f4fc03',
        backgroundColor: ['#ffffff'],
        borderDash: [10, 5],
      },
    ];

    return {
      labels,
      datasets,
    };
  }

  private getChartData(status: string, graphs: XyzGraph[]): number[] {
    let graphsData = graphs;

    if (graphs.length === 1) {
      graphsData = [...graphs, ...graphs];
    }

    return graphsData.map((item: XyzGraph) => {
      return item[status];
    });
  }
}
