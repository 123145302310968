import { Company } from '@features/project/interfaces/company.interface';
import { ActionOptions, ActionProvider } from '@capturum/builders/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enums/routes.enum';

@Injectable({ providedIn: 'root' })
export class NavigateCompanyDetailAction implements ActionProvider {
  constructor(private router: Router) {}

  public execute(options: ActionOptions, item: Company, context?: Record<string, any>): void {
    this.router.navigate([`${AppRoutes.company}/${item.id}`], {
      queryParams: {
        is_data_collectief: item.is_data_collectief,
      },
    });
  }
}
