<ng-container *ngIf="availableOptions$ | async as stakeholderOptions">
  <cap-dropdown
    [placeholder]="'market_discovery.project.stakeholders.' + roleTranslationKey + '.placeholder' | translate"
    [options]="stakeholderOptions"
    [filter]="true"
    (changeSelection)="handleSelectStakeholder($event.value, stakeholderOptions)">
  </cap-dropdown>

  <cap-button class="add-btn" styleClass="secondary" [label]="'button.new' | translate" (click)="createNewUser()">
  </cap-button>

  <cap-card
    [title]="'market_discovery.project.stakeholders.selected-' + roleTranslationKey + '.title' | translate"
    [styleClass]="'no-padding-card text-no-wrap'">
    <ng-template capTemplate="content">
      <app-stakeholder-user-list [items]="selectedOptions$ | async" (deleteItemClick)="handleDelete($event)">
      </app-stakeholder-user-list>
    </ng-template>
  </cap-card>
</ng-container>
