<app-page-layout [title]="(customer$ | async)?.name" backRoute="../../" [showPrintButton]="false">
  <div class="xyz-container" content>
    <div class="row">
      <div class="col-6 col-md-9 col-xl-10 filters">
        <cap-dynamic-filters
          #dynamicFiltersComponent
          [filterConfig]="filters"
          [showResetButton]="false"
          [(activeFilters)]="activeFilters"
          (activeFiltersChange)="handleFilterChange($event)">
        </cap-dynamic-filters>
      </div>

      <div class="col-6 col-md-3 col-xl-2 reset-button text-right">
        <cap-button
          icon="fas fa-trash-alt"
          styleClass="secondary round"
          [label]="'market_discovery.button.reset_filters' | translate"
          (onClick)="dynamicFiltersComponent.resetFilters()">
        </cap-button>
      </div>
    </div>

    <ng-container *ngIf="xyz$ | async as xyz">
      <app-xyz [xyz]="xyz" [graph]="graph$ | async"></app-xyz>
    </ng-container>
  </div>
</app-page-layout>
