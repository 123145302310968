<div class="d-flex mb-4 row meta-value-filters" [formGroup]="form">
  <ng-container *ngIf="metaKeys$ | async as metaKeys">
    <ng-container *ngIf="!showOnlyMetaFilters">
      <div *ngxPermissionsOnly="'lead.filter-account-manager'" class="filter col">
        <cap-multi-select
          formControlName="account_manager_id"
          [options]="accountManagersOptions$ | async"
          [appendTo]="'body'"
          [filter]="true"
          [defaultLabel]="'placeholder.select' | translate"
          (change)="handleFilterChange('account_manager_id')"
          [label]="'market_discovery.project.account_managers.label' | translate">
        </cap-multi-select>
      </div>
    </ng-container>

    <div class="filter col">
      <cap-multi-select
        formControlName="lead_source_base_data_value_id"
        [options]="sourceOptions"
        [appendTo]="'body'"
        [label]="'market_discovery.pipeline.filter.source.label' | translate"
        [filter]="true"
        (change)="handleFilterChange('lead_source_base_data_value_id')"
        [defaultLabel]="'placeholder.select' | translate">
      </cap-multi-select>
    </div>

    <div class="filter col">
      <cap-dropdown
        formControlName="meta_key_id"
        dataKey="id"
        filterBy="name"
        optionLabel="name"
        optionValue="id"
        [options]="metaKeys"
        [label]="'market_discovery.lead.unit.label' | translate"
        [filter]="true"
        [placeholder]="'placeholder.select' | translate"
        (change)="setOperatorOptions($event?.value, metaKeys)">
      </cap-dropdown>
    </div>

    <div class="filter col">
      <cap-dropdown
        *ngIf="operatorOptions"
        formControlName="operator"
        [options]="operatorOptions"
        [filter]="true"
        [placeholder]="'placeholder.select' | translate"
        [label]="'market_discovery.lead.operator.label' | translate">
      </cap-dropdown>
    </div>

    <div class="filter col pr-0">
      <cap-multi-select
        formControlName="value"
        [options]="metaValueOptions$ | async"
        [appendTo]="'body'"
        [filter]="true"
        [emptyMessage]="
          ((valuesIsLoading$ | async)
            ? 'market_discovery.label.results-are-loading'
            : 'market_discovery.label.unit-not-selected'
          ) | translate
        "
        [defaultLabel]="'placeholder.select' | translate"
        [label]="'market_discovery.lead.value.label' | translate"
        [sortAlphabetically]="false"
        (change)="handleMetaValueOptionChange($event)">
      </cap-multi-select>
    </div>

    <div class="col d-flex flex-column justify-content-end">
      <div class="filter-buttons">
        <cap-button
          styleClass="primary round"
          icon="fas fa-plus"
          [disabled]="form.invalid"
          [label]="'button.add' | translate"
          (onClick)="addFilter()">
        </cap-button>

        <cap-button
          styleClass="secondary round reset"
          icon="fas fa-trash-alt"
          [disabled]="activeFilters.length < 1 && form.controls['meta_key_id'].invalid"
          [label]="'market_discovery.button.reset_filters' | translate"
          (onClick)="resetFilters()">
        </cap-button>
      </div>
    </div>
  </ng-container>
</div>

<div class="filter-chips" *ngIf="chipValues && chipValues.length > 0">
  <label>{{ 'market_discovery.lead.selected-units' | translate }}</label>
  <cap-chips field="label" [disabled]="false" [(ngModel)]="chipValues" (remove)="handleRemoveChip($event)"></cap-chips>
</div>
