<div class="contact-input-type">
  <form [formGroup]="formGroup">
    <div *ngIf="lead$ | async as lead" class="lead-contacts">
      <div class="contacts-details-grid mb-3">
        <div class="grid-header">
          <div class="header">{{ 'market_discovery.agent-questionnaire.lead.full_name.label' | translate }}</div>
          <div class="header">{{ 'market_discovery.agent-questionnaire.lead.function.label' | translate }}</div>
        </div>

        <div class="grid-body">
          <ng-container *ngFor="let contact of lead.contacts; let index = index">
            <div class="value checkbox">
              <p-radioButton [value]="contact.id" name="contact_id" [inputId]="contact.id" formControlName="contact_id">
              </p-radioButton>

              <div class="checkbox-label">
                {{
                  contact.salutation_base_data_value_id
                    ? (contact.salutation_base_data_value_id | capBaseDataTranslation)
                    : ''
                }}
                {{ contact.initials }}
                {{ contact.last_name }}
                <ng-container *ngIf="contact.is_main_contact"
                  >({{ 'market_discovery.agent-questionnaire.lead.contact_person.label' | translate }})</ng-container
                >
              </div>
            </div>

            <div class="value">
              {{
                contact.function_base_data_value_id
                  ? (contact.function_base_data_value_id | capBaseDataTranslation)
                  : '-'
              }}
            </div>
          </ng-container>
        </div>
      </div>

      <div class="contact-option">
        <p-radioButton value="new" name="contact_id" formControlName="contact_id"></p-radioButton>
        <label>{{ 'market_discovery.questionnaire.address.other' | translate }}</label>
      </div>
    </div>

    <div class="new-contact-form" [formGroup]="contactForm" *ngIf="showContactForm">
      <div class="row mb-3">
        <div class="col-md-3">
          <label>{{ 'market_discovery.agent-questionnaire.lead.function.label' | translate }} <span>*</span></label>
          <cap-dropdown
            formControlName="function_base_data_value_id"
            [options]="functionOptions$ | async"
            [filter]="true"
            [placeholder]="'placeholder.select' | translate"
            [autoDisplayFirst]="false"></cap-dropdown>
        </div>

        <div class="col-md-3">
          <label>{{ 'market_discovery.lead.contact.salutation.label' | translate }} <span>*</span></label>
          <cap-dropdown
            formControlName="salutation_base_data_value_id"
            [placeholder]="'placeholder.select' | translate"
            [options]="salutationOptions$ | async"
            [autoDisplayFirst]="false"></cap-dropdown>
        </div>

        <div class="col-md-3">
          <label>{{ 'market_discovery.lead.contact.initials.label' | translate }} <span>*</span></label>
          <cap-input formControlName="initials"></cap-input>
        </div>

        <div class="col-md-3">
          <label>{{ 'market_discovery.lead.contact.first_name.label' | translate }}</label>
          <cap-input formControlName="first_name"></cap-input>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3">
          <label>{{ 'market_discovery.lead.contact.last_name.label' | translate }} <span>*</span></label>
          <cap-input formControlName="last_name"></cap-input>
        </div>

        <div class="col-md-3">
          <label>{{ 'market_discovery.lead.contact.phone.label' | translate }}</label>
          <cap-input formControlName="phone"></cap-input>
        </div>

        <div class="col-md-3">
          <label>{{ 'market_discovery.lead.contact.mobile_phone.label' | translate }}</label>
          <cap-input formControlName="mobile_phone"></cap-input>
        </div>

        <div class="col-md-3">
          <label>{{ 'market_discovery.lead.contact.email.label' | translate }}</label>
          <cap-input formControlName="email"></cap-input>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3">
          <label>{{ 'market_discovery.lead.contact.gender.label' | translate }} <span>*</span></label>
          <cap-dropdown
            [options]="genderOptions$ | async"
            formControlName="gender_base_data_value_id"
            [autoDisplayFirst]="false"
            [placeholder]="'placeholder.select' | translate">
          </cap-dropdown>
        </div>
      </div>
    </div>
  </form>
</div>
