<app-dialog-layout>
  <ng-container content>
    <p [innerHTML]="'market_discovery.project.dialog_selector.label' | translate"></p>

    <div class="dropdown">
      <cap-dropdown
        [(ngModel)]="selectedProject"
        [options]="options"
        [filter]="true"
        [appendTo]="'body'"
        [autofocusFilter]="false"
        [placeholder]="'market_discovery.project.list.title' | translate"
        [filterPlaceholder]="'search.placeholder' | translate">
      </cap-dropdown>
    </div>
  </ng-container>

  <ng-container buttons>
    <cap-button
      icon="fal fa-times"
      styleClass="secondary"
      [label]="'button.cancel' | translate"
      (onClick)="dialogRef.close('cancel')">
    </cap-button>

    <cap-button
      styleClass="primary"
      [label]="'button.select' | translate"
      [disabled]="!selectedProject"
      (onClick)="dialogRef.close('select')">
    </cap-button>
  </ng-container>
</app-dialog-layout>
