import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectHourListComponent } from '@features/project-hour/pages/project-hour-list/project-hour-list.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectHourListComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectHourRoutingModule {}
