import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { DestroyBase, FormHelper } from '@capturum/shared';
import {
  Question,
  QuestionInputTypeComponent,
  QuestionOption,
  UpdateAnswer,
} from '@features/questionnaire/interfaces/questionnaire.interface';
import { BehaviorSubject, filter, Observable, takeUntil } from 'rxjs';
import { Lead } from '@features/lead/interfaces/lead.interface';
import { MapItem, ToastService } from '@capturum/ui/api';
import { BaseDataKeyService } from '@capturum/complete';
import { TranslateService } from '@ngx-translate/core';
import { Contact } from '@core/interfaces/contact.interface';
import { AuthService } from '@capturum/auth';

interface ContactAnswer {
  contact_ids: string[];
  contacts?: Partial<Contact>[];
}

interface ContactQuestionFormGroup {
  contact_id: FormControl<string>;
  contact: FormControl<Contact>;
}

interface QuestionnaireContactFormGroup {
  function_base_data_value_id: FormControl<string>;
  salutation_base_data_value_id: FormControl<string>;
  initials: FormControl<string>;
  first_name: FormControl<string>;
  last_name: FormControl<string>;
  phone: FormControl<string>;
  mobile_phone: FormControl<string>;
  email: FormControl<string>;
  gender_base_data_value_id: FormControl<string>;
}

@Component({
  selector: 'app-contact-input-type',
  templateUrl: './contact-input-type.component.html',
  styleUrls: ['./contact-input-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactInputTypeComponent
  extends DestroyBase
  implements QuestionInputTypeComponent<ContactAnswer>, OnInit
{
  public answer$: BehaviorSubject<ContactAnswer> = new BehaviorSubject<ContactAnswer>(null);
  public lead$: BehaviorSubject<Lead> = new BehaviorSubject<Lead>(null);
  public options: Observable<QuestionOption[]> | QuestionOption[];
  public question: Question;
  public updateAnswer: EventEmitter<UpdateAnswer> = new EventEmitter<UpdateAnswer>();
  public formGroup: FormGroup<ContactQuestionFormGroup>;
  public showContactForm: boolean;
  public contactForm: FormGroup<QuestionnaireContactFormGroup>;
  public newContactValue = 'new';
  public functionOptions$: Observable<MapItem[]>;
  public salutationOptions$: Observable<MapItem[]>;
  public genderOptions$: Observable<MapItem[]>;

  constructor(
    private formBuilder: FormBuilder,
    private baseDataKeyService: BaseDataKeyService,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {
    super();

    this.baseDataKeyService.extractBaseDataKeys(this.authService.getUser());
  }

  public ngOnInit(): void {
    this.formGroup = this.getForm();
    this.contactForm = this.getContactForm();
    this.functionOptions$ = this.baseDataKeyService.getBaseDataKeyValues('contact_function');
    this.salutationOptions$ = this.baseDataKeyService.getBaseDataKeyValues('salutation');
    this.genderOptions$ = this.baseDataKeyService.getBaseDataKeyValues('gender');

    this.answer$.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe((answer) => {
      return this.setAnswer(answer);
    });

    this.formGroup
      .get('contact_id')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.showContactForm = value === this.newContactValue;
      });
  }

  public validate(): boolean {
    FormHelper.markAsTouched(this.contactForm);
    this.cdr.detectChanges();

    if (this.formGroup.valid) {
      if (this.formGroup.value.contact_id === 'new') {
        if (this.contactForm.valid) {
          this.updateAnswer.emit({
            id: this.question.id,
            value: {
              contact_ids: [],
              contacts: this.contactForm.value ? [this.contactForm.value] : [],
            },
          });

          return true;
        } else {
          this.toastService.error(
            this.translateService.instant('toast.error.title'),
            this.translateService.instant('market_discovery.validation.questionnaire.question.required')
          );
        }
      } else {
        this.updateAnswer.emit({
          id: this.question.id,
          value: {
            contact_ids: this.formGroup.value.contact_id ? [this.formGroup.value.contact_id] : [],
            contacts: [],
          },
        });

        return true;
      }
    }

    return false;
  }

  public setAnswer(answer: ContactAnswer): void {
    if (answer?.contacts?.length) {
      const newContact = answer.contacts.find((contact) => {
        return !contact.lead_id;
      });

      if (newContact) {
        this.contactForm.patchValue(newContact);
        this.formGroup.patchValue({ contact_id: this.newContactValue });
        this.showContactForm = true;
      } else {
        this.formGroup.patchValue({
          contact_id: answer.contacts.find((contact) => {
            return contact.lead_id;
          })?.id,
          contact: null,
        });
      }
    } else if (answer?.contact_ids.length) {
      this.formGroup.patchValue({ contact_id: answer.contact_ids[0], contact: null });
    }

    if (answer.contact_ids?.[0] === 'new') {
      this.showContactForm = true;
    }
  }

  public getAnswer(): ContactAnswer {
    return {
      contacts: this.isEmptyContactForm() ? [] : [this.contactForm.value],
      contact_ids: [this.formGroup.value.contact_id],
    };
  }

  private getForm(): FormGroup<ContactQuestionFormGroup> {
    return this.formBuilder.group({
      contact: [],
      contact_id: ['', this.question?.is_required ? Validators.required : null],
    });
  }

  private getContactForm(): FormGroup<QuestionnaireContactFormGroup> {
    return this.formBuilder.group({
      function_base_data_value_id: ['', Validators.required],
      mobile_phone: [''],
      phone: [],
      first_name: [''],
      last_name: ['', Validators.required],
      email: [''],
      salutation_base_data_value_id: ['', Validators.required],
      initials: ['', Validators.required],
      gender_base_data_value_id: ['', Validators.required],
    });
  }

  private isEmptyContactForm(): boolean {
    return Object.values(this.contactForm.value).every((value) => {
      return value === null || value.trim() === '';
    });
  }
}
