import { ToastService } from '@capturum/ui/api';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { VaporFileUploadResponse, VaporFileUploadService } from '@capturum/api';
import { Observable, forkJoin, switchMap } from 'rxjs';
import { LeadApiService } from '@features/lead/services/lead-api.service';
import { HttpEventType } from '@angular/common/http';
import { filter, map, tap } from 'rxjs/operators';
import { FilePreviewListItem } from '@capturum/ui/file-preview-list';
import { BlueprintFile } from '@core/interfaces/file.interface';
import { CapturumBuilderFileInputComponent } from '@capturum/builders/form-renderer';
import { FileApiService } from '@features/file/services/file-api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lead-attachments',
  templateUrl: './lead-attachments.component.html',
  styleUrls: ['./lead-attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LeadAttachmentsComponent {
  @Input()
  public leadId: string;

  public previewFiles: FilePreviewListItem[] = [];
  public acceptedFileTypes: string[] = [
    'image/*',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/pdf',
  ];
  public acceptedFileExtensions: string[] = [
    '.msg',
    '.heic',
    '.heif',
  ]

  public inputAcceptAttribute = [...this.acceptedFileExtensions, ...this.acceptedFileTypes].join(",");

  constructor(
    private vaporFileUploadService: VaporFileUploadService,
    private leadApiService: LeadApiService,
    private cdr: ChangeDetectorRef,
    private fileService: FileApiService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  private _leadFiles: BlueprintFile[];

  public get leadFiles(): BlueprintFile[] {
    return this._leadFiles;
  }

  @Input()
  public set leadFiles(value: BlueprintFile[]) {
    this._leadFiles = value;

    if (value) {
      this.previewFiles = this._leadFiles.map((file) => {
        return {
          name: file.filename,
          id: file.id,
        };
      });
    }
  }

  public handleFileChange(files: File[]): void {
    const unsupportedFileTypes = [];
    const supportedFiles = files.reduce((acc, file) => {
      if (this.fileIsSupported(file)) {
        acc.push(file);
      } else {
        unsupportedFileTypes.push(file);
      }

      return acc;
    }, []);

    if (unsupportedFileTypes.length) {
      unsupportedFileTypes.forEach((file) => {
        this.toastService.error(
          this.translateService.instant('toast.error.title'),
          this.translateService.instant('market_discovery.lead.attachments.upload-error.message', {
            filename: file.name,
          })
        );
      });
    }

    const filesWithIds = supportedFiles.map((file) => {
      return {
        file,
        id: CapturumBuilderFileInputComponent.getRandomId(5),
      };
    });

    this.previewFiles = [
      ...this.previewFiles,
      ...filesWithIds.map((file) => {
        return {
          id: file.id,
          uploadProgress: 0,
          uploading: true,
          name: file.file.name,
        };
      }),
    ];

    const uploadFile$: Observable<BlueprintFile>[] = filesWithIds.map((file) => {
      return this.vaporFileUploadService.uploadFile(file.file).pipe(
        tap((response) => {
          if (response.type === HttpEventType.UploadProgress) {
            this.updatePreviewFiles(response, file.id);
          }
        }),
        filter((response) => {
          return response.type === HttpEventType.Response;
        }),
        switchMap((response) => {
          return this.leadApiService.uploadAttachment(this.leadId, {
            data: (response.data as { uuid: string }).uuid,
            filename: file.file.name,
          });
        }),
        map((response) => {
          return {
            ...response,
            oldFileId: file.id,
          };
        })
      );
    });

    forkJoin(uploadFile$).subscribe((responses: (BlueprintFile & { oldFileId: string })[]) => {
      this.setPreviewFilesCompleted(responses);

      this.cdr.detectChanges();
    });
  }

  public handleDeleteFile(file: { name: string; url: string; index: number }): void {
    this.leadApiService.removeAttachment(this.previewFiles[file.index].id).subscribe(() => {
      this.previewFiles = this.previewFiles.filter((previewFile) => {
        return previewFile.id !== this.previewFiles[file.index].id;
      });

      this.cdr.detectChanges();
    });
  }

  public handleDownloadFile(file: FilePreviewListItem): void {
    this.fileService.download(file.id, file.name);
  }

  private updatePreviewFiles(uploadProgress: VaporFileUploadResponse, id: string): void {
    this.previewFiles = this.previewFiles.map((previewFile) => {
      if (previewFile.id === id) {
        previewFile.uploadProgress = (uploadProgress.data as { progress: number }).progress;
      }

      return previewFile;
    });
  }

  private setPreviewFilesCompleted(responses: (BlueprintFile & { oldFileId: string })[]): void {
    this.previewFiles = this.previewFiles.map((previewFile) => {
      previewFile.uploading = false;
      previewFile.id =
        responses.find((response) => {
          return response.oldFileId === previewFile.id;
        })?.id ?? previewFile.id;

      return previewFile;
    });
  }

  private fileIsSupported(file: File): boolean {
    if (file.type.includes('image/') || this.acceptedFileTypes.includes(file.type)) {
      return true;
    }

    return this.acceptedFileExtensions.reduce(
      (isSupported: boolean, extension: string) => isSupported || file.name.endsWith(extension),
      false
    );
  }
}
