import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countSpacer',
})
export class CountSpacerPipe implements PipeTransform {
  public transform(value: number, maxNumber: number): string {
    let numberToFill =
      value !== null ? maxNumber.toString().length - value.toString().length : maxNumber.toString().length;

    if (value < 0) {
      numberToFill += 1;
    }

    return numberToFill > 0 ? new Array(numberToFill).fill(0).join('') : '';
  }
}
