<app-page-layout [title]="'market_discovery.selection.import.button' | translate">
  <app-page-buttons actions>
    <cap-button
      styleClass="secondary"
      [label]="'button.cancel' | translate"
      [routerLink]="['../selection']"
      icon="fas fa-xmark">
    </cap-button>

    <cap-button
      styleClass="primary"
      [label]="'market_discovery.button.import' | translate"
      (onClick)="submit()"
      capButtonLoading
      [icon]="(importBusy$ | async) ? 'fas fa-spinner fa-spin' : 'fas fa-save'"
      [submitting]="importBusy$ | async"
      [disabled]="importBusy$ | async">
    </cap-button>
  </app-page-buttons>

  <div content>
    <cap-card [title]="'market_discovery.selection.import.button' | translate">
      <ng-template capTemplate="content">
        <cpb-form-renderer [formKey]="formKey" [showHeader]="false" [context]="{ importAsSelection }"></cpb-form-renderer>
      </ng-template>
    </cap-card>
  </div>
</app-page-layout>
