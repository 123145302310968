<app-dialog-layout class="project-hour-dialog">
  <ng-container content>
    <cpb-form-renderer
      [formKey]="formKey"
      [showHeader]="false"
      [context]="context$ | async"
      [modelId]="modelId"
      [defaultValue]="defaultValue$ | async"
      class="meta-detail">
    </cpb-form-renderer>

    <app-project-hour-difference *ngIf="formValue$ | async as formValue" [from]="formValue?.from" [to]="formValue?.to">
    </app-project-hour-difference>
  </ng-container>

  <ng-container buttons>
    <cap-button
      styleClass="secondary"
      [label]="'button.cancel' | translate"
      icon="fas fa-times"
      (click)="cancel()"></cap-button>
    <cap-button
      styleClass="primary"
      [label]="'button.submit' | translate"
      icon="fas fa-save"
      (click)="submit()"></cap-button>
  </ng-container>
</app-dialog-layout>
