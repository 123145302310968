<app-page-layout [title]="'market_discovery.project.selection.import-as-lead.title' | translate">
  <app-page-buttons actions>
    <cap-button styleClass="secondary" [label]="'button.cancel' | translate" [routerLink]="['..']" icon="fas fa-xmark">
    </cap-button>

    <cap-button
      styleClass="primary"
      [label]="'market_discovery.button.import' | translate"
      (onClick)="submit()"
      capButtonLoading
      [icon]="(importBusy$ | async) ? 'fas fa-spinner fa-spin' : 'fas fa-save'"
      [submitting]="importBusy$ | async"
      [disabled]="importBusy$ | async">
    </cap-button>
  </app-page-buttons>

  <div content>
    <div class="col-6 justify-content-center">
      <cap-card [title]="'market_discovery.project.selection.import-as-lead.title' | translate">
        <ng-template capTemplate="content">
          <cpb-form-renderer formKey="form_import_leads" [showHeader]="false" [context]="{ importAsSelection }"></cpb-form-renderer>

          <div class="file-errors" *ngIf="fileErrors">
            {{ fileErrors }}
          </div>
        </ng-template>
      </cap-card>
    </div>
  </div>
</app-page-layout>
