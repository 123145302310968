import { LeadImportComponent } from '@features/project/pages/lead-import/lead-import.component';
import { Component, OnInit } from '@angular/core';
import { AppRoutes } from '@core/enums/routes.enum';

@Component({
  selector: 'app-selection-import',
  templateUrl: './selection-import.component.html',
  styleUrls: ['./selection-import.component.scss'],
})
export class SelectionImportComponent extends LeadImportComponent implements OnInit {
  protected entity = 'selection';
  protected importAsSelection = true;

  public ngOnInit(): void {
    super.ngOnInit();
  }

  protected navigateBack(): void {
    this.router.navigate(['..', AppRoutes.selection], { relativeTo: this.route });
  }
}
