import { BUILD_TIMESTAMP } from './timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Market Discovery',
  production: false,
  hmr: false,
  name: 'Test',
  baseUrl: 'https://marketdiscovery2-test.staging.emendis.nl/api',
  sentryUrl: null,
  databaseName: 'market-discovery',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/',
  url: 'https://marketdiscovery.test.emendis.nl',
  idleDuration: 1800, // 30min
  timeoutDuration: 180, // 3min
};
