import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { ToastService } from '@capturum/ui/api';
import { ManageListComponent } from '@features/manage/pages/manage-list/manage-list.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-questionnaire-list',
  templateUrl: './questionnaire-list.component.html',
  styleUrls: ['./questionnaire-list.component.scss'],
})
export class QuestionnaireListComponent extends ManageListComponent {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected actionService: CapturumBuilderActionService,
    protected toastService: ToastService,
    protected translateService: TranslateService
  ) {
    super(route, router, actionService, toastService, translateService);
  }

  public add(): void {
    this.router.navigate(['add'], { relativeTo: this.route });
  }
}
