import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { ToastService } from '@capturum/ui/api';

@Injectable()
export class WarningInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((response) => {
        if (response.type === HttpEventType.Response) {
          if (response.body?.meta?.warnings) {
            response.body.meta.warnings.forEach((warning) => {
              this.toastService.warning('', warning);
            });
          }
        }
      })
    );
  }
}
