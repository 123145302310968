import { DestroyBase } from '@capturum/shared';
import { LeadAddress } from '@core/interfaces/address.interface';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CapturumFormRendererComponent, FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { CapturumDialogService, ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { filter, takeUntil } from 'rxjs';
import { Contact } from '@core/interfaces/contact.interface';
import { ProjectStateService } from '@features/project/services/project-state.service';

@Component({
  selector: 'app-lead-edit',
  templateUrl: './lead-edit.component.html',
  styleUrls: ['./lead-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LeadEditComponent extends DestroyBase implements OnInit {
  @ViewChild('leadGeneralFormRenderer')
  public formRenderer: CapturumFormRendererComponent;

  public modelId: string;
  public formKey = 'form_lead_general';
  public loadingContacts = true;
  public dialogRef: DynamicDialogRef;
  public contacts: Contact[] = [];
  public companyId: string;
  public addresses: LeadAddress[];
  public projectId: string;

  constructor(
    private route: ActivatedRoute,
    private formSaverService: FormSaverService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private dialogService: CapturumDialogService,
    private formRendererService: FormRendererService,
    private projectStateService: ProjectStateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modelId = this.route.snapshot.paramMap.get('leadId');
    this.projectId = this.projectStateService.getProjectSnapshot().id;

    this.formRendererService
      .getSourceValueByKey(this.formKey)
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.addresses = value.addresses;
        this.contacts = value.contacts;
        this.loadingContacts = false;

        this.companyId = value.company_id;
      });
  }

  public submit(): void {
    this.formSaverService.submit(this.formKey).subscribe(() => {
      this.toastService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant(`market_discovery.entity.toast.updated`)
      );
    });
  }

  public cancel(): void {
    this.formSaverService.cancel(this.formKey);
  }

  public updateData(): void {
    this.formRenderer.getFormSourceData();
  }
}
