import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-url-type',
  templateUrl: './url-type.component.html',
  styleUrls: ['./url-type.component.scss'],
})
export class UrlTypeComponent extends FieldType {
  public navigate(event: Event, url: string): void {
    event.preventDefault();
    let navigateUrl = url;

    if (!url.match(/^https?:\/\//i)) {
      navigateUrl = 'http://' + url;
    }

    window.open(navigateUrl, '_blank');
  }
}
