<app-dialog-layout>
  <ng-container content>
    <cpb-form-renderer
      formKey="form_add_to_calendar"
      [context]="{ lead_id: leadId }"
      [defaultValue]="defaultValue"
      [showHeader]="false"></cpb-form-renderer>
  </ng-container>

  <ng-container buttons>
    <cap-button icon="fas fa-xmark" [label]="'button.cancel' | translate" (onClick)="cancel()" styleClass="secondary">
    </cap-button>

    <cap-button icon="fas fa-save" [label]="'button.submit' | translate" (onClick)="submit()" styleClass="primary">
    </cap-button>
  </ng-container>
</app-dialog-layout>
