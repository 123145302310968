<div class="lead-history">
  <div class="history-header">
    <span class="header-title">
      {{ 'market_discovery.lead.history.title' | translate }}
    </span>

    <cap-button
      styleClass="secondary round"
      [label]="'View history'"
      icon="fas fa-history"
      (onClick)="openLeadHistoryDialog()"></cap-button>
  </div>

  <cpb-form-renderer [showHeader]="false" [readOnly]="true" formKey="form_lead_history" [defaultValue]="lead">
  </cpb-form-renderer>
</div>
