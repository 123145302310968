<div class="cap-dynamic-filter-layout dynamic-calendar-filter">
  <label for="" *ngIf="filterConfig.showLabel">
    {{ filterConfig.label }}
  </label>

  <div class="cap-dynamic-filter-layout-content">
    <p-calendar
      #calendar
      styleClass="cap-calendar cap-rounded p-inputgroup"
      [formControl]="formControl"
      [timeOnly]="false"
      [readonlyInput]="true"
      [hideOnDateTimeSelect]="false"
      [selectionMode]="filterConfig.matchMode | dateFilterSelectionModePipe"
      [showIcon]="false"
      [showTime]="true"
      [view]="filterConfig.view || 'date'"
      [dateFormat]="filterConfig.dateFormat || 'dd-mm-yy'"
      [disabled]="filterConfig.disabled | observablePipe | async"
      [placeholder]="filterConfig.placeholder"
      [minDate]="filterConfig.minDate | observablePipe | async"
      [maxDate]="filterConfig.maxDate | observablePipe | async"
      (onClose)="handleDateFilterChange(filterConfig.matchMode)">
    </p-calendar>

    <div class="filter-icon">
      <i [class]="filterConfig.icon"></i>
    </div>

    <div class="delete-filter" *ngIf="formControl.value && (filterConfig.resetFilter ?? true)" (click)="reset()">
      <i class="fas fa-trash-alt"></i>
    </div>
  </div>
</div>
