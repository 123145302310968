import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@capturum/auth';
import { IndexedDbGuard, PermissionGuard, PublicTranslationResolver } from '@capturum/complete';
import { LoginLayoutComponent } from '@capturum/login';
import { BasicLayoutComponent } from '@core/components/basic-layout/basic-layout.component';
import { AppRoutes } from '@core/enums/routes.enum';
import { ProjectSelectorResolver } from '@core/resolvers/project-selector.resolver';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ManageDetailComponent } from './features/manage/pages/manage-detail/manage-detail.component';
import { ManageListComponent } from './features/manage/pages/manage-list/manage-list.component';

const routes: Routes = [
  {
    path: AppRoutes.auth,
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: PublicTranslationResolver,
    },
    component: LoginLayoutComponent,
    loadChildren: () => {
      return import('./features/auth/auth.module').then((m) => {
        return m.AuthModule;
      });
    },
  },
  {
    path: '',
    canActivate: [IndexedDbGuard, AuthGuard, PermissionGuard],
    resolve: {
      projectSelector: ProjectSelectorResolver,
    },
    children: [
      {
        path: '',
        component: BasicLayoutComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: AppRoutes.project,
          },
          {
            path: 'tenants',
            loadChildren: () => {
              return import('./features/tenant/tenant.module').then((m) => {
                return m.TenantModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: 'tenant.manage',
                redirectTo: '/',
              },
            },
          },
          {
            path: AppRoutes.role,
            loadChildren: () => {
              return import('./features/role/role.module').then((m) => {
                return m.RoleModule;
              });
            },
            data: {
              permissions: {
                only: 'role.manage.tenant',
                redirectTo: '/',
              },
              entity: 'role',
              backUrl: `/${AppRoutes.config}`,
            },
          },
          {
            path: 'translations',
            loadChildren: () => {
              return import('./features/translation/translation.module').then((m) => {
                return m.TranslationModule;
              });
            },
          },
          {
            path: AppRoutes.user,
            canActivate: [NgxPermissionsGuard],
            data: {
              entity: 'user',
              permissions: ['user.manage.tenant', 'user.show.tenant'],
            },
            loadChildren: () => {
              return import('./features/user/user.module').then((m) => {
                return m.UserModule;
              });
            },
          },
          {
            path: AppRoutes.customer,
            canActivate: [NgxPermissionsGuard],
            loadChildren: () => {
              return import('./features/customer/customer.module').then((m) => {
                return m.CustomerModule;
              });
            },
            data: {
              entity: 'customer',
              showActions: false,
              showActionsCustomer: true,
              permissions: {
                only: ['customer.manage'],
                redirectTo: '/',
              },
            },
          },
          {
            path: AppRoutes.baseData,
            loadChildren: () => {
              return import('./features/base-data/base-data.module').then((m) => {
                return m.BaseDataModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['base-data-value.manage.tenant', 'base-data-value.show.tenant'],
                redirectTo: '/',
              },
            },
          },
          {
            path: 'settings',
            loadChildren: () => {
              return import('./features/settings/settings.module').then((m) => {
                return m.SettingsModule;
              });
            },
          },
          {
            path: 'config',
            loadChildren: () => {
              return import('./features/config/config.module').then((m) => {
                return m.ConfigModule;
              });
            },
          },
          {
            path: 'builders',
            loadChildren: () => {
              return import('@capturum/builders/pages').then((m) => {
                return m.CapturumBuilderPagesModule;
              });
            },
          },
          {
            path: AppRoutes.project,
            loadChildren: () => {
              return import('./features/project/project.module').then((m) => {
                return m.ProjectModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: 'project.manage',
                redirectTo: AppRoutes.fieldwork,
              },
              entity: 'project',
            },
          },
          {
            path: AppRoutes.meta,
            canActivate: [NgxPermissionsGuard],
            data: {
              entity: 'meta_key',
              backUrl: `/${AppRoutes.config}`,
              permissions: {
                redirectTo: '/',
              },
            },
            children: [
              {
                path: '',
                component: ManageListComponent,
              },
              {
                path: 'add',
                component: ManageDetailComponent,
              },
              {
                path: ':id',
                component: ManageDetailComponent,
              },
            ],
          },
          {
            path: AppRoutes.selection,
            loadChildren: () => {
              return import('./features/selection/selection.module').then((m) => {
                return m.SelectionModule;
              });
            },
            data: {
              entity: 'selection',
            },
          },
          {
            path: AppRoutes.company,
            loadChildren: () => {
              return import('./features/company/company.module').then((m) => {
                return m.CompanyModule;
              });
            },
            canLoad: [NgxPermissionsGuard],
            data: {
              entity: 'company',
              permissions: {
                only: 'company.show',
                redirectTo: AppRoutes.profile,
              },
            },
          },
          {
            path: 'profile',
            loadChildren: () => {
              return import('./features/profile/profile.module').then((m) => {
                return m.ProfileModule;
              });
            },
          },
          {
            path: AppRoutes.changelog,
            canActivate: [NgxPermissionsGuard],
            data: {
              entity: 'changelog',
              showActions: false,
              permissions: {
                only: ['model-log.show'],
                redirectTo: '/',
              },
            },
            loadChildren: () => {
              return import('./features/change-log/change-log.module').then((m) => {
                return m.ChangeLogModule;
              });
            },
          },
          {
            path: AppRoutes.projectHours,
            canActivate: [NgxPermissionsGuard],
            data: {
              entity: 'project-hours',
              permissions: {
                only: 'project-hour.show',
                redirectTo: AppRoutes.profile,
              },
            },
            loadChildren: () => {
              return import('./features/project-hour/project-hour.module').then((m) => {
                return m.ProjectHourModule;
              });
            },
          },
          {
            path: AppRoutes.agentQuestionnaire,
            canActivate: [NgxPermissionsGuard],
            data: {
              entity: 'agent-questionnaire',
              permissions: {
                only: ['lead.queue'],
                redirectTo: AppRoutes.profile,
              },
            },
            loadChildren: () => {
              return import('./features/agent-questionnaire/agent-questionnaire.module').then((m) => {
                return m.AgentQuestionnaireModule;
              });
            },
          },
          {
            path: 'fieldwork',
            canActivate: [NgxPermissionsGuard],
            loadComponent: () => {
              return import('./features/fieldwork/pages/fieldwork-dashboard/fieldwork-dashboard.component').then(
                (c) => {
                  return c.FieldworkDashboardComponent;
                }
              );
            },
            data: {
              permissions: {
                only: 'fieldwork.show',
                redirectTo: AppRoutes.profile,
              },
            },
          },
          {
            path: AppRoutes.questionnaire,
            canActivate: [NgxPermissionsGuard],
            data: {
              entity: 'questionnaire',
              permissions: {
                only: 'questionnaire.show',
                redirectTo: AppRoutes.profile,
              },
            },
            loadChildren: () => {
              return import('./features/questionnaire/questionnaire.module').then((m) => {
                return m.QuestionnaireModule;
              });
            },
          },
          {
            path: AppRoutes.mutation,
            canActivate: [NgxPermissionsGuard],
            data: {
              entity: 'mutation',
              permissions: {
                only: 'mutation.manage',
                redirectTo: AppRoutes.profile,
              },
            },
            loadChildren: () => {
              return import('./features/mutation/mutation.module').then((m) => {
                return m.MutationModule;
              });
            },
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: AppRoutes.project,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
