import { Component } from '@angular/core';
import { FormSaverService } from '@capturum/builders/form-renderer';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-to-calendar-dialog',
  templateUrl: './add-to-calendar-dialog.component.html',
  styleUrls: ['./add-to-calendar-dialog.component.scss'],
})
export class AddToCalendarDialogComponent {
  public formKey = 'form_add_to_calendar';
  public leadId: string;
  public defaultValue: { title: string; target_at?: string };

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private formSaverService: FormSaverService,
    private translateService: TranslateService
  ) {
    this.leadId = this.dialogConfig.data?.leadId;
  }

  public ngOnInit(): void {
    const lead = this.dialogConfig.data.lead;

    this.defaultValue = { title: `${lead.status.value}: ${lead.name}`, target_at: lead?.target_at };
  }

  public submit(): void {
    this.formSaverService
      .submit(this.formKey)
      .pipe(first())
      .subscribe(({ response }) => {
        if (response?.data?.ics_data) {
          const blob = new Blob([response.data.ics_data.data], { type: 'application/octet-stream' });

          saveAs(blob, response.data.ics_data.fileName);
        }

        this.dialogRef.close(true);
      });
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
