import { Injectable } from '@angular/core';
import { ApiService, ListOptions } from '@capturum/api';
import { responseData } from '@capturum/builders/core';
import { MapItem } from '@capturum/ui/api';
import { map, Observable } from 'rxjs';

import { Questionnaire } from '../interfaces/questionnaire.interface';
import { QuestionAnswer, QuestionnaireUser } from './../interfaces/questionnaire.interface';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireApiService extends ApiService<Questionnaire> {
  protected endpoint = 'questionnaire';

  public duplicateProjectQuestionnaire(
    projectId: string,
    questionnaireId: string,
    isDmu: boolean
  ): Observable<Questionnaire> {
    return this.apiHttp
      .post(`/project/${projectId}/${this.endpoint}/${questionnaireId}/duplicate`, { isDmu })
      .pipe(responseData);
  }

  public getAgentQuestionnaire(
    projectId: string,
    data: { lead_id: string },
    listOptions: ListOptions
  ): Observable<{ questionnaire: Questionnaire; dmu_questionnaire: Questionnaire }> {
    return this.apiHttp
      .post(`/project/${projectId}/start-questionnaire${this.getOptionsQuery(listOptions)}`, data)
      .pipe(responseData);
  }

  public storeAgentQuestionnaire(
    questionnaireId: string,
    data: {
      answers: QuestionAnswer[];
      lead_id: string;
      remarks: string;
      lead_status_base_data_value_id: string;
      questionnaire_id: string;
      dmu_questionnaire_id: string;
      steps_history?: string;
    }
  ): Observable<QuestionnaireUser> {
    return this.apiHttp.post(`/${this.endpoint}/questionnaire-user`, data).pipe(responseData);
  }

  public getCallCenterStatuses(): Observable<MapItem[]> {
    return this.apiHttp.get(`/call-center-statuses`).pipe(
      map((response: { data: { key: string; label: string; value: string }[] }) => {
        return response.data.map((item) => {
          return {
            label: item.label,
            value: item.key,
            key: item.value,
          };
        });
      })
    );
  }
}
