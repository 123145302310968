<app-page-layout [title]="'market_discovery.questionnaire.list.title' | translate" [backRoute]="backUrl">
  <app-page-buttons actions *ngxPermissionsOnly="'questionnaire.manage'">
    <cap-button
      styleClass="primary"
      icon="fa fa-plus"
      [label]="'market_discovery.questionnaire.button_add.label' | translate"
      (onClick)="add()">
    </cap-button>
  </app-page-buttons>

  <ng-container content>
    <cpb-list-renderer [key]="listKey" [context]="context"> </cpb-list-renderer>
  </ng-container>
</app-page-layout>
