import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-password-type',
  templateUrl: './password-type.component.html',
  styleUrls: ['./password-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PasswordTypeComponent extends FieldType implements OnInit, OnDestroy {
  public passwordFormGroup: UntypedFormGroup;
  private destroy$ = new Subject<boolean>();

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  public ngOnInit(): void {
    this.passwordFormGroup = this.formBuilder.group({
      password: [],
      confirm_password: [],
    });

    this.passwordFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((changes) => {
      this.formControl.setValue(changes);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public generatePassword(): void {
    const letters = 4;
    const numbers = 4;
    const specialCharacters = 4;
    const either = 4;
    const lowercaseLetters = 4;
    const chars = [
      'ABCDEFGHIJKLMNOPQRSTUVWXYZ', // letters
      'abcdefghijklmnopqrstuvwxyz', // lowercase letters
      '0123456789', // numbers
      '!@#$%^&*()', // special characters
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789', // either
    ];

    const password = [letters, lowercaseLetters, numbers, specialCharacters, either]
      .map(function (length, i) {
        return Array(length)
          .fill(chars[i])
          .map(function (x) {
            return x[Math.floor(Math.random() * x.length)];
          })
          .join('');
      })
      .concat()
      .join('')
      .split('')
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join('');

    this.passwordFormGroup.setValue({
      password,
      confirm_password: password,
    });
  }
}
