<app-page-layout [title]="'market_discovery.project.menu.stakeholders' | translate">
  <app-page-buttons actions>
    <cap-button
      styleClass="primary"
      icon="fas fa-save"
      (onClick)="saveAll()"
      [label]="'button.submit' | translate"></cap-button>
  </app-page-buttons>

  <div content class="project-stakeholders" *ngIf="project$ | async as project">
    <div class="row">
      <div class="col-lg-6 col-xl-4 mb-3">
        <cap-card [title]="'market_discovery.project.stakeholders.accountmanagers.title' | translate">
          <ng-template capTemplate="content">
            <app-stakeholder-selection
              [allUsers]="accountManagerOptions$ | async"
              [projectStakeholders]="projectAccountManagers$ | async"
              [customerId]="project.customer_id"
              [roleTranslationKey]="'accountmanagers'"
              [roleId]="accountManagerRole$ | async"
              (changeSelection)="handleSelectionChange($event, roles.accountManager)">
            </app-stakeholder-selection>
          </ng-template>
        </cap-card>
      </div>

      <div class="mt-2 mt-lg-0 col-lg-6 col-xl-4">
        <cap-card [title]="'market_discovery.project.stakeholders.salesmanagers.title' | translate">
          <ng-template capTemplate="content">
            <app-stakeholder-selection
              [allUsers]="salesManagerOptions$ | async"
              [customerId]="project.customer_id"
              [roleTranslationKey]="'salesmanagers'"
              [roleId]="salesManagerRole$ | async"
              [projectStakeholders]="projectSalesManagers$ | async"
              (changeSelection)="handleSelectionChange($event, roles.salesManager)">
            </app-stakeholder-selection>
          </ng-template>
        </cap-card>
      </div>

      <div class="mt-2 mt-xl-0 col-lg-6 col-xl-4">
        <cap-card [title]="'market_discovery.project.stakeholders.agents.title' | translate">
          <ng-template capTemplate="content">
            <app-stakeholder-selection
              [allUsers]="agentOptions$ | async"
              [customerId]="project.customer_id"
              [roleId]="agentRole$ | async"
              [roleTranslationKey]="'agents'"
              [projectStakeholders]="projectAgents$ | async"
              (changeSelection)="handleSelectionChange($event, roles.agent)">
            </app-stakeholder-selection>
          </ng-template>
        </cap-card>
      </div>

      <div class="mt-2 mt-xl-0 col-lg-6 col-xl-4">
        <cap-card [title]="'market_discovery.project.stakeholders.managers.title' | translate">
          <ng-template capTemplate="content">
            <app-stakeholder-selection
              [allUsers]="managerOptions$ | async"
              [customerId]="project.customer_id"
              [roleId]="managerRole$ | async"
              [roleTranslationKey]="'managers'"
              [projectStakeholders]="projectManagers$ | async"
              (changeSelection)="handleSelectionChange($event, roles.manager)">
            </app-stakeholder-selection>
          </ng-template>
        </cap-card>
      </div>
    </div>
  </div>
</app-page-layout>
