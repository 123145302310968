<div class="agent-questionnaire-lead-details row" [class.bordered]="isEdit">
  <div class="col-12" [class.col-md-6]="!isEdit">
    <div class="mb-4">
      <cap-card [title]="'market_discovery.agent-questionnaire.lead.company_info.title' | translate">
        <ng-template capTemplate="content">
          <div class="details-grid">
            <div class="grid-body">
              <div class="label">
                {{ 'market_discovery.agent-questionnaire.lead.company.label' | translate }}
              </div>

              <div class="value" [class.updated]="lead.company?.isUpdated">
                {{ lead.company.name }}
                <ng-container *ngIf="lead.company?.isUpdated">
                  <i class="fas fa-pencil-alt edit" *ngIf="isEdit" (click)="goToQuestion(lead.company.questionId)"> </i>
                </ng-container>
              </div>

              <div class="label">
                {{ 'market_discovery.agent-questionnaire.lead.coc_number.label' | translate }}
              </div>

              <div class="value">
                {{ lead.company.coc_number }}
              </div>

              <div class="label">
                {{ 'market_discovery.agent-questionnaire.lead.phone.label' | translate }}
              </div>

              <div class="value">
                {{ lead.phone }}
              </div>
            </div>
          </div>
        </ng-template>
      </cap-card>
    </div>

    <div [class.mb-4]="isEdit">
      <cap-card [title]="'market_discovery.agent-questionnaire.lead.naw_dmu.title' | translate">
        <ng-template capTemplate="content">
          <div class="details-grid">
            <div class="grid-header">
              <div class="header">{{ 'market_discovery.agent-questionnaire.lead.full_name.label' | translate }}</div>
              <div class="header">{{ 'market_discovery.agent-questionnaire.lead.function.label' | translate }}</div>
            </div>

            <div class="grid-body">
              <ng-container *ngFor="let dmu of lead.contacts">
                <div class="value" [class.updated]="dmu.isUpdated">
                  {{
                    dmu.salutation_base_data_value_id
                      ? (dmu.salutation_base_data_value_id | capBaseDataTranslation)
                      : ''
                  }}
                  {{ dmu.initials }}
                  {{ dmu.last_name }}
                  <ng-container *ngIf="dmu.is_main_contact">
                    ({{ 'market_discovery.agent-questionnaire.lead.contact_person.label' | translate }})
                  </ng-container>

                  <i
                    *ngIf="isEdit && dmu.isUpdated"
                    class="fas fa-pencil-alt edit"
                    (click)="goToQuestion(dmu.questionId)">
                  </i>
                </div>

                <div class="value" [class.updated]="dmu.isUpdated">
                  {{
                    dmu.function_base_data_value_id ? (dmu.function_base_data_value_id | capBaseDataTranslation) : '-'
                  }}
                </div>
              </ng-container>
            </div>
          </div>

          <div class="details-grid addresses">
            <div class="grid-header">
              <div class="header">{{ 'market_discovery.agent-questionnaire.lead.address.label' | translate }}</div>
            </div>

            <div class="grid-body">
              <ng-container *ngIf="lead.mainCompanyAddress as companyAddress">
                <div class="value icon" [class.updated]="companyAddress.isUpdated">
                  <i class="fal fa-building"></i>
                  <span>
                    {{ companyAddress.street }} {{ companyAddress.housenumber }} {{ companyAddress.zipcode }}
                    {{ companyAddress.city }}
                  </span>

                  <ng-container *ngIf="companyAddress.isUpdated">
                    <i
                      class="fas fa-pencil-alt edit"
                      *ngIf="isEdit"
                      (click)="goToQuestion(companyAddress.questionId)"></i>
                  </ng-container>
                </div>

                <div class="value icon" [class.updated]="companyAddress.isUpdated">
                  {{ companyAddress.phone }}
                </div>
              </ng-container>

              <ng-container *ngIf="lead.mainMailAddress as mailAddress">
                <div class="value icon" [class.updated]="mailAddress.isUpdated">
                  <i class="fal fa-envelope"></i>
                  <span>
                    {{ mailAddress.street }} {{ mailAddress.housenumber }} {{ mailAddress.zipcode }}
                    {{ mailAddress.city }}
                  </span>

                  <ng-container *ngIf="mailAddress.isUpdated">
                    <i class="fas fa-pencil-alt edit" *ngIf="isEdit" (click)="goToQuestion(mailAddress.questionId)"></i>
                  </ng-container>
                </div>

                <div class="value icon" [class.updated]="mailAddress.isUpdated">
                  {{ mailAddress.phone }}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </cap-card>
    </div>
  </div>
  <div class="col-12" [class.col-md-6]="!isEdit">
    <cap-card [title]="'market_discovery.agent-questionnaire.lead.status.label' | translate">
      <ng-template capTemplate="content">
        <div class="details-grid" *ngIf="!isEdit; else editStatus">
          <div class="grid-body">
            <div class="label">{{ 'market_discovery.agent-questionnaire.lead.updated_at.label' | translate }}</div>
            <div class="value">{{ lead.lead_date | date : 'dd-MM-yyyy' }}</div>

            <div class="label">{{ 'market_discovery.agent-questionnaire.lead.status.label' | translate }}</div>
            <div class="value">
              <ng-container *ngIf="lead.lead_status_base_data_value_id; else statusEmpty">
                {{ lead.lead_status_base_data_value_id | capBaseDataTranslation | default }}
              </ng-container>

              <ng-template #statusEmpty> -</ng-template>
            </div>

            <div class="label">{{ 'market_discovery.agent-questionnaire.lead.remarks.label' | translate }}</div>
            <div class="value pre-wrap" [innerHTML]="lead.md_remarks | default"></div>

            <div class="label">{{ 'market_discovery.agent-questionnaire.lead.callback.label' | translate }}</div>
            <div class="value">
              <div *ngFor="let leadCallback of lead.leadCallbacks">
                {{ leadCallback.date | date : 'dd-MM-yyyy' }}
                <ng-container *ngIf="leadCallback.from || leadCallback.to">
                  {{
                    leadCallback?.from ? (leadCallback?.from | callbackTime : leadCallback.date | date : 'HH:mm') : ''
                  }}
                  -
                  {{ leadCallback.to ? (leadCallback?.to | callbackTime : leadCallback.date | date : 'HH:mm') : '' }}
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <ng-template #editStatus>
          <div class="row lead-status" [formGroup]="form">
            <div class="col-md-6">
              <div class="form-group">
                <div class="label">{{ 'market_discovery.agent-questionnaire.lead.updated_at.label' | translate }}</div>
                <div class="value">{{ lead.lead_date | date : 'dd-MM-yyyy' }}</div>
              </div>

              <div class="form-group">
                <div class="label">{{ 'market_discovery.agent-questionnaire.lead.callback.label' | translate }}</div>
                <div class="value callback-values">
                  <div class="callback-value" *ngFor="let leadCallback of lead.leadCallbacks">
                    {{ leadCallback.date | date : 'dd-MM-yyyy' }}&nbsp;
                    <ng-container *ngIf="leadCallback.from || leadCallback.to">
                      {{
                        leadCallback?.from
                          ? (leadCallback?.from | callbackTime : leadCallback.date | date : 'HH:mm')
                          : ''
                      }}
                      -
                      {{
                        leadCallback.to ? (leadCallback?.to | callbackTime : leadCallback.date | date : 'HH:mm') : ''
                      }}
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <div class="label">{{ 'market_discovery.agent-questionnaire.lead.status.label' | translate }} *</div>
                <div class="value">
                  <cap-dropdown
                    [filter]="true"
                    [placeholder]="'placeholder.select' | translate"
                    [options]="callCenterStatuses"
                    formControlName="lead_status_base_data_value_id"
                    appendTo="body">
                  </cap-dropdown>
                </div>
              </div>

              <div class="form-group">
                <div class="label">{{ 'market_discovery.agent-questionnaire.lead.remarks.label' | translate }}</div>
                <div class="value">
                  <cap-textarea formControlName="remarks"></cap-textarea>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </cap-card>
  </div>
</div>
