<app-page-layout
  [title]="'market_discovery.project.menu.pipeline' | translate"
  [showPrintButton]="true"
  (handlePrintCommand)="exportPipeline()">
  <div content class="pipeline-page">
    <cap-card
      [title]="'market_discovery.pipeline.selection' | translate"
      [class.hide]="hideSelection"
      [buttonLabel]="
        (hideSelection
          ? 'market_discovery.project.full_business.show_selection.label'
          : 'market_discovery.project.full_business.hide_selection.label'
        ) | translate
      "
      [buttonIcon]="hideSelection ? 'fas fa-eye' : 'fas fa-eye-slash'"
      (buttonClick)="hideSelection = !hideSelection">
      <ng-template capTemplate="content">
        <div [hidden]="hideSelection">
          <div class="d-flex justify-content-between">
            <cap-dynamic-filters
              #dynamicFiltersComponent
              (activeFiltersChange)="handleFilterChange($event)"
              [showResetButton]="false"
              storageKey="{{ projectId }}-pipeline-filters"
              filterStyleClass="square-filter"
              [filterConfig]="dynamicFilters">
            </cap-dynamic-filters>

            <div class="d-flex flex-column justify-content-end reset-filter-button">
              <div class="justify-content-center">
                <cap-button
                  styleClass="secondary round float-right"
                  icon="fas fa-trash-alt"
                  (click)="dynamicFiltersComponent.resetFilters()"
                  [label]="'market_discovery.button.reset_filters' | translate">
                </cap-button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </cap-card>

    <div class="totals-table">
      <cap-info-table
        class="simple-table"
        [data]="totalsData$ | async"
        [columns]="infoTableColumns"
        [pagination]="false">
      </cap-info-table>
    </div>

    <div class="totals-table">
      <app-pipeline-status-table
        [pipelineStatuses]="pipelineStatuses"
        [isCurrency]="isProjectValueType"></app-pipeline-status-table>
    </div>
  </div>
</app-page-layout>
