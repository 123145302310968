<app-page-layout [title]="'market_discovery.' + entity + '.list.title' | translate" [backRoute]="backUrl">
  <app-page-buttons actions *ngIf="showActions">
    <cap-button
      styleClass="primary"
      iconPos="left"
      icon="fa fa-plus"
      [label]="'button.add' | translate"
      (click)="add()">
    </cap-button>
  </app-page-buttons>

  <app-page-buttons actions *ngIf="showActionsCustomer">
    <cap-button
      styleClass="primary"
      iconPos="left"
      icon="fa fa-plus"
      [label]="
        'market_discovery.form.add.title' | translate : { entity: 'market_discovery.customer.entity.name' | translate }
      "
      (click)="add()">
    </cap-button>
  </app-page-buttons>

  <ng-container content>
    <cpb-list-renderer [key]="listKey"></cpb-list-renderer>
  </ng-container>
</app-page-layout>

<ng-template #filterIcon let-icon="icon">
  <div class="filter-icon">
    <i [ngClass]="icon"></i>
  </div>
</ng-template>
