import { ProjectStateService } from '@features/project/services/project-state.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CapturumFormRendererModule, FormSaverService } from '@capturum/builders/form-renderer';
import { CapturumSharedModule, ToastService } from '@capturum/ui/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { ProjectModule } from '@features/project/project.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LayoutModule } from '@shared/layout/layout.module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs';
import { DialogActionType } from '@core/enums/dialog-action-type.enum';

@Component({
  selector: 'app-lead-seletion-agents-dialog',
  templateUrl: './lead-selection-agents-dialog.component.html',
  styleUrls: ['./lead-selection-agents-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CapturumFormRendererModule,
    CapturumButtonModule,
    TranslateModule,
    LayoutModule,
    ProjectModule,
    CapturumSharedModule,
  ],
})
export class LeadSelectionAgentsDialogComponent implements OnInit {
  public formKey = 'form_lead_selection_agent';
  public leadIds: string[];
  public context: Record<string, any>;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private formSaverService: FormSaverService,
    private toastService: ToastService,
    private projectStateService: ProjectStateService,
    private translateService: TranslateService
  ) {
    this.leadIds = this.dialogConfig.data?.item.map((item) => {
      return item.id;
    });
  }

  public ngOnInit(): void {
    this.context = {
      project_id: this.projectStateService.getProjectSnapshot().id,
    };
  }

  public handleSave(): void {
    this.formSaverService
      .submit(this.formKey, {
        lead_ids: this.leadIds,
      })
      .pipe(first())
      .subscribe(({ response }) => {
        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant(`market_discovery.entity.toast.created`, {
            entity: this.translateService.instant('market_discovery.project.stakeholders.agents.title'),
          })
        );

        this.dialogRef.close({ action: DialogActionType.submit, data: response?.data });
      });
  }
}
