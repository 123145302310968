import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manage-list',
  templateUrl: './manage-list.component.html',
  styleUrls: ['./manage-list.component.scss'],
})
export class ManageListComponent implements OnInit {
  public listKey: string;
  public entity: string;
  public backUrl: string;
  public context: Record<string, string>;
  public showActions = true;
  public showActionsCustomer = false;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected actionService: CapturumBuilderActionService,
    protected toastService: ToastService,
    protected translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    const routeData = this.route.snapshot?.data || this.route.parent.snapshot.data;

    if (!routeData) {
      return;
    }

    if (routeData?.hasOwnProperty('showActions')) {
      this.showActions = routeData.showActions;
    }

    if (routeData?.hasOwnProperty('showActionsCustomer')) {
      this.showActionsCustomer = routeData.showActionsCustomer;
    }

    this.backUrl = routeData?.backUrl;
    this.entity = routeData?.entity;
    this.listKey = this.getListKey(this.entity);
  }

  public getListKey(entity: string): string {
    return `list_${entity}`;
  }

  public add(): void {
    this.router.navigate(['./add'], { relativeTo: this.route });
  }
}
