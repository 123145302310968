import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormSaverService } from '@capturum/builders/form-renderer';

@Component({
  selector: 'app-import-selection-dialog',
  templateUrl: './import-selection-dialog.component.html',
  styleUrls: ['./import-selection-dialog.component.scss'],
})
export class ImportSelectionDialogComponent {
  public formKey = 'form_lead_import_selection';

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private formSaver: FormSaverService,
    private dialogRef: DynamicDialogRef
  ) {}

  public submit(): void {
    this.formSaver
      .submit(this.formKey, {
        selection_id: this.dialogConfig.data.selectionId,
        project_id: this.dialogConfig.data.projectId,
      })
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
