import { Injectable } from '@angular/core';
import { ApiService } from '@capturum/api';
import { Question } from '@features/questionnaire/interfaces/questionnaire.interface';

@Injectable({
  providedIn: 'root',
})
export class QuestionService extends ApiService<Question> {
  protected endpoint = 'question';
}
