import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

interface CallbackForm {
  date: AbstractControl;
  from: FormControl<string>;
  to: FormControl<string>;
}
@Component({
  selector: 'app-callback-repeat',
  templateUrl: './callback-repeat.component.html',
  styleUrls: ['./callback-repeat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CallbackRepeatComponent extends FieldArrayType implements OnInit {
  public formGroup: FormGroup<CallbackForm>;

  constructor(private translateService: TranslateService) {
    super();
  }

  public ngOnInit(): void {
    this.field.fieldArray = {
      fieldGroupClassName: 'callback-row',
      fieldGroup: [
        {
          key: 'date',
          type: 'cpb-builders-date',
          wrappers: ['default-input-wrapper'],
          templateOptions: {
            required: true,
            label: this.translateService.instant('market_discovery.lead-callback.date.label'),
            date: true,
            min_date: new Date(),
            timezone: false,
          },
        },
        {
          key: 'from',
          wrappers: ['default-input-wrapper'],
          type: 'cpb-timepicker',
          templateOptions: {
            label: this.translateService.instant('market_discovery.lead-callback.from.label'),
            date: true,
          },
        },
        {
          key: 'to',
          wrappers: ['default-input-wrapper'],
          type: 'cpb-timepicker',
          templateOptions: {
            label: this.translateService.instant('market_discovery.lead-callback.to.label'),
            date: true,
          },
        },
      ],
    };

    if (!this.field.defaultValue || !this.field?.defaultValue?.length) {
      this.add();
    }
  }
}
