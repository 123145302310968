<app-page-layout
  [title]="'market_discovery.project.menu.leads' | translate"
  [showPrintButton]="true"
  (handlePrintCommand)="exportLeadsToPdf()">
  <app-page-buttons actions>
    <cap-button
      *ngxPermissionsOnly="['lead.import.file']"
      styleClass="primary"
      [label]="'market_discovery.button.import' | translate"
      icon="fas fa-file-import"
      [routerLink]="['import-lead']">
    </cap-button>

    <cap-button
      *ngxPermissionsOnly="['lead.export.file']"
      styleClass="primary"
      [label]="'market_discovery.button.export' | translate"
      icon="fas fa-file-export"
      (onClick)="exportLeads()">
    </cap-button>
  </app-page-buttons>

  <div content class="project-leads" *ngIf="project$ | async as project">
    <div class="mb-3 selection" [class.hide]="hideSelection">
      <cap-card
        [title]="'market_discovery.project.leads.selection.title' | translate"
        [buttonLabel]="
          (hideSelection
            ? 'market_discovery.project.full_business.show_selection.label'
            : 'market_discovery.project.full_business.hide_selection.label'
          ) | translate
        "
        [buttonIcon]="hideSelection ? 'fas fa-eye' : 'fas fa-eye-slash'"
        (buttonClick)="toggleSelectionCard()">
        <ng-template capTemplate="content">
          <div [hidden]="hideSelection">
            <app-meta-value-filters
              [projectId]="project.id"
              [sourceOptions]="leadSources$ | async"
              (filterChange)="handleMetaFilterChange($event)">
            </app-meta-value-filters>
          </div>
        </ng-template>
      </cap-card>
    </div>

    <cap-card [title]="'market_discovery.project.leads.overview.title' | translate">
      <ng-template capTemplate="content">
        <div class="row project-lead-filters justify-content-between m-0">
          <div class="dynamic-filter-container p-0">
            <cap-dynamic-filters
              #dynamicFiltersComponent
              [filterConfig]="filterConfig"
              filterStyleClass="col-lg-3"
              containerStyleClass="row"
              [showResetButton]="false"
              storageKey="project-leads-{{ project.id }}"
              (activeFiltersChange)="handleFilterChange($event)">
            </cap-dynamic-filters>
          </div>

          <div class="project-lead-filters-buttons">
            <div class="d-flex">
              <cap-button
                [label]="'market_discovery.button.reset_filters' | translate"
                icon="fas fa-trash-alt"
                styleClass="secondary round"
                (onClick)="dynamicFiltersComponent.resetFilters()">
              </cap-button>

              <cap-button
                styleClass="primary round"
                [label]="
                  (hideMap ? 'market_discovery.lead.show_map.label' : 'market_discovery.lead.hide_map.label')
                    | translate
                "
                [icon]="hideMap ? 'fas fa-eye' : 'fas fa-eye-slash'"
                (onClick)="toggleMapCard()">
              </cap-button>
            </div>
          </div>
        </div>
        <div class="project-leads-map" [hidden]="hideMap">
          <app-leads-map [data]="leadPins$ | async"></app-leads-map>
        </div>

        <ng-container *ngxPermissionsOnly="'project.transfer-leads'">
          <app-table-bulk-actions
            entity="leads"
            *ngIf="selectedRows.length > 0 && bulkActions.length > 0"
            [actions]="bulkActions"
            [selectedItems]="selectedRows">
          </app-table-bulk-actions>
        </ng-container>

        <cap-info-table
          #leadsInfoTable
          class="simple-table"
          dataKey="id"
          [columns]="columns"
          (onRowClick)="navigateToLeadDetail($event)"
          [data]="projectLeads$ | async"
          [lazyLoading]="true"
          [lazyLoadOnInit]="false"
          [selectable]="hasTransferLeadsPermission"
          [selectedRows]="selectedRows"
          [rows]="20"
          [loading]="false"
          [paginator]="paginator$ | async"
          stateKey="project-leads-list-{{ project.id }}"
          (onLazyLoad)="loadTableData($event)"
          (selectedRowsChange)="handleRowToggle($event)">
          <ng-template capTemplate="lead_status_base_data_value_id" let-item="item">
            {{ item.lead_status_base_data_value_id | capBaseDataTranslation }}
          </ng-template>

          <ng-template capTemplate="distance" let-item="item">
            <ng-container *ngIf="item.distance !== null"> {{ item.distance | number : '1.1-1' }} km </ng-container>
          </ng-template>

          <ng-template capTemplate="target_at" let-item="item">
            {{ item.target_at | date : 'dd-MM-yyyy' }}
          </ng-template>

          <ng-template capTemplate="mainCompanyAddress.country_base_data_value_id" let-item="item">
            {{
              item.mainCompanyAddress?.country_base_data_value_id
                ? (item.mainCompanyAddress?.country_base_data_value_id | capBaseDataTranslation)
                : '-'
            }}
          </ng-template>
        </cap-info-table>
      </ng-template>
    </cap-card>
  </div>
</app-page-layout>
