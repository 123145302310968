import { Component } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormSaverService } from '@capturum/builders/form-renderer';

@Component({
  selector: 'app-edit-note-dialog',
  templateUrl: './edit-note-dialog.component.html',
})
export class EditNoteDialogComponent {
  public formKey = 'form_project_activity';
  public itemId: string;

  constructor(
    public dialogRef: DynamicDialogRef,
    private readonly dialogConfig: DynamicDialogConfig,
    private formSaverService: FormSaverService
  ) {
    this.itemId = this.dialogConfig?.data?.id;
  }

  public submit(): void {
    this.formSaverService.submit(this.formKey).subscribe(({ response }) => {
      this.dialogRef.close(response.data);
    });
  }
}
