import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { differenceInMinutes } from 'date-fns';

@Component({
  selector: 'app-project-hour-difference',
  templateUrl: './project-hour-difference.component.html',
  styleUrls: ['./project-hour-difference.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProjectHourDifferenceComponent implements OnChanges {
  @Input()
  public from: string;

  @Input()
  public to: string;

  public difference: number;

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.from && this.to && typeof this.from === 'string' && typeof this.to === 'string') {
      const date1 = new Date();
      const date2 = new Date();
      const [hours1, minutes1]: string[] = this.from.split(':');
      const [hours2, minutes2]: string[] = this.to.split(':');

      date1.setMinutes(+minutes1);
      date1.setHours(+hours1);
      date2.setMinutes(+minutes2);
      date2.setHours(+hours2);

      this.difference = differenceInMinutes(date2, date1);
    }
  }
}
