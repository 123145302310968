<div class="unanswered-questions">
  <div class="unanswered-question-title">
    <i class="far fa-triangle-exclamation"></i>&nbsp;{{
      'market_discovery.questionnaire.unanswered-question.title' | translate
    }}
  </div>
  <div class="question-title" *ngFor="let question of questions" (click)="questionClicked.emit(question)">{{ question.order + 1 }} - {{ question.name }}</div>

  <div class="unanswered-question-confirmation">
    {{ 'market_discovery.questionnaire.unanswered-question.confirmation-text' | translate }}
  </div>
</div>
