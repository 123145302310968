import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProjectLeadMetaKeys } from '@features/meta-key/interfaces/meta-key.interface';

@Component({
  selector: 'app-project-info-meta',
  templateUrl: './project-info-meta.component.html',
  styleUrls: ['./project-info-meta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectInfoMetaComponent {
  @Input()
  public leadMetaKeys: ProjectLeadMetaKeys[];
}
