import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, Observable, shareReplay } from 'rxjs';
import { FormRendererService } from '@capturum/builders/form-renderer';
import { ImportFile } from '../../interfaces/import-file.interface';

@Component({
  selector: 'app-imports-detail',
  templateUrl: './imports-detail.component.html',
})
export class ImportsDetailComponent implements OnInit {
  public formKey = 'form_import';
  public modelId: string;
  public backRoute: string;
  public importFileInfo$: Observable<ImportFile>;

  constructor(private route: ActivatedRoute, private formRendererService: FormRendererService) {}

  public ngOnInit(): void {
    this.backRoute = this.route.snapshot.queryParamMap?.get('redirectUrl') || '..';
    this.modelId = this.route.snapshot.paramMap?.get('id');

    this.importFileInfo$ = this.formRendererService
      .getSourceValueByKey(this.formKey)
      .pipe(filter(Boolean), shareReplay(1));
  }
}
