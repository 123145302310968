import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'columnTotal',
  pure: false,
})
export class ColumnTotalPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  public transform(fields: string[], dataSource: any[], fraction = 0, isCurrency?: boolean): number | string {
    const total =
      fields
        .map((field) => {
          return dataSource
            ?.map((row) => {
              return row[field];
            })
            .reduce(calculateTotal, 0);
        })
        .reduce(calculateTotal, 0)
        .toFixed(fraction) || null;

    if (isCurrency) {
      return this.currencyPipe.transform(total, 'EUR', 'symbol', '1.0-0');
    }

    return total;
  }
}

function calculateTotal(previousValue: number, currentValue: number): number {
  return currentValue ? previousValue + Number(currentValue) : previousValue;
}
