import { NotificationBadgeOptions } from '@capturum/ui/api';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-action-required-badge-widget',
  templateUrl: './action-required-badge-widget.component.html',
  styleUrls: ['./action-required-badge-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActionRequiredBadgeWidgetComponent extends ListRendererWidgetBase implements OnInit {
  public notificationBadgeOptions: NotificationBadgeOptions;

  public ngOnInit(): void {
    if (+this.item[this.field] > 0) {
      this.notificationBadgeOptions = {
        badgeSize: '1.2rem',
        backgroundColor: '#fa4331',
        fontColor: '#fff',
        fontSize: '0.6rem',
        showBorder: false,
        showBadge: true,
        label: this.item[this.field] > 99 ? '99+' : this.item[this.field].toString(),
      };
    }
  }
}
