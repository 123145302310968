import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormSaverService } from '@capturum/builders/form-renderer';
import { switchMap, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { CapturumBuilderActionService } from '@capturum/builders/core';

@Component({
  selector: 'app-add-selection-dialog',
  templateUrl: './add-selection-dialog.component.html',
  styleUrls: ['./add-selection-dialog.component.scss'],
})
export class AddSelectionDialogComponent {
  public formKey = 'form_selection';
  public defaultValue = { is_active: true };

  constructor(
    public dialogRef: DynamicDialogRef,
    private formSaver: FormSaverService,
    private actionService: CapturumBuilderActionService
  ) {}

  public submit(): void {
    combineLatest([
      this.formSaver.getFormValueByKey(this.formKey),
      this.formSaver.getFormConfigurationByKey(this.formKey),
    ])
      .pipe(
        tap(() => {
          return this.formSaver.markFormAsSubmitted(this.formKey);
        }),
        switchMap(([value, configuration]) => {
          return this.formSaver.submitToBackend(configuration.submit.endpoint, value, this.formKey);
        })
      )
      .subscribe(() => {
        this.dialogRef.close();
        this.actionService.broadcastActionExecuted(
          { options: undefined, type: 'createSelection', key: 'createSelection' },
          null,
          null
        );
      });
  }
}
