<div [formGroup]="formGroup" *ngIf="options">
  <cap-dropdown
    formControlName="option_id"
    [options]="options"
    [placeholder]="'placeholder.select' | translate"
    optionValue="id"
    optionLabel="value"
    [sortAlphabetically]="false">
  </cap-dropdown>

  <div class="free-text-input" *ngIf="showFreeText">
    <cap-input formControlName="answer_text"></cap-input>
  </div>

  <div class="question-option-description" *ngIf="description$ | async as description">
    {{ description }}
  </div>
</div>
