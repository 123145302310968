import { TranslateService } from '@ngx-translate/core';
import { LeadHistoryDialogComponent } from './../lead-history-dialog/lead-history-dialog.component';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CapturumDialogService } from '@capturum/ui/api';
import { LeadDetails } from '@features/project/interfaces/lead-details.interface';

@Component({
  selector: 'app-lead-history',
  templateUrl: './lead-history.component.html',
  styleUrls: ['./lead-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LeadHistoryComponent {
  @Input()
  public lead: LeadDetails;

  constructor(private dialogService: CapturumDialogService, private translateService: TranslateService) {}

  public openLeadHistoryDialog(): void {
    const title = 'market_discovery.lead.history.title';

    const dialog = this.dialogService.open(LeadHistoryDialogComponent, {
      header: this.translateService.instant(title),
      width: '70%',
      data: {
        leadId: this.lead.id,
      },
    });

    dialog.onClose.subscribe(() => {
      dialog.destroy();
    });
  }
}
