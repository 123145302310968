<app-page-layout
  [title]="'market_discovery.xyz.page.title' | translate"
  (handlePrintCommand)="exportPdf()"
  superscript="®"
  [showPrintButton]="true">
  <div class="xyz-container" content>
    <div class="row">
      <div class="col-6 col-md-9 col-xl-10 filters" *ngIf="project$ | async as project">
        <cap-dynamic-filters
          *ngIf="activeFilters && project.id"
          [filterConfig]="filterConfig"
          [showResetButton]="false"
          storageKey="xyz-{{ project.id }}"
          (activeFiltersChange)="handleFilterChange($event)">
        </cap-dynamic-filters>
      </div>

      <div class="col-6 col-md-3 col-xl-2 reset-button text-right">
        <cap-button
          icon="fas fa-trash-alt"
          styleClass="secondary round"
          [label]="'market_discovery.button.reset_filters' | translate"
          (onClick)="dynamicFiltersComponent.resetFilters()">
        </cap-button>
      </div>
    </div>

    <ng-container *ngIf="xyz$ | async as xyz">
      <app-xyz [xyz]="xyz" [graph]="graph$ | async"></app-xyz>
    </ng-container>
  </div>
</app-page-layout>
