import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Question } from '@features/questionnaire/interfaces/questionnaire.interface';

@Component({
  selector: 'app-unanswered-questions-warning',
  templateUrl: './unanswered-questions-warning.component.html',
  styleUrls: ['./unanswered-questions-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnansweredQuestionsWarningComponent {
  @Input()
  public questions: Question[] = [];

  @Output()
  public questionClicked = new EventEmitter<Question>();
}
