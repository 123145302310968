import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, shareReplay, startWith, takeUntil } from 'rxjs';
import { MapItem, ToastService } from '@capturum/ui/api';
import { CapturumBuilderActionService, toMapItems } from '@capturum/builders/core';
import { SelectionApiService } from '@features/selection/services/selection-api.service';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { CapturumListRendererComponent, ListRendererConfig, ListRendererUtils } from '@capturum/builders/list-renderer';
import { Project } from '@features/project/interfaces/project.interface';
import { LeadApiService } from '@features/lead/services/lead-api.service';
import { TranslateService } from '@ngx-translate/core';
import { DestroyBase } from '@capturum/shared';
import { NotificationService } from '@shared/services/notification.service';
import { DialogService } from 'primeng/dynamicdialog';
import {
  ImportSelectionDialogComponent
} from '../../components/import-selection-dialog/import-selection-dialog.component';
import { ActiveFilters } from '@capturum/ui/dynamic-filters';
import { MetaValueFiltersComponent } from '../../components/meta-value-filters/meta-value-filters.component';
import { ListOptions } from '@capturum/api';
import { ProjectApiService } from '../../services/project-api.service';

@Component({
  selector: 'app-project-selection',
  templateUrl: './project-selection.component.html',
  styleUrls: ['./project-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSelectionComponent extends DestroyBase implements OnInit, AfterViewInit {
  public selectionOptions$: Observable<MapItem[]>;
  public selectionControl = new UntypedFormControl(null);
  @ViewChild(MetaValueFiltersComponent)
  public metaFilterComponent: MetaValueFiltersComponent;

  @ViewChild(CapturumListRendererComponent)
  public listRenderer: CapturumListRendererComponent;

  public project$: Observable<Project>;
  public hideSelection: boolean;
  public sourceOptions$: Observable<MapItem[]>;
  public listKey = 'list_lead_selection';

  public metaFilterQueue = new BehaviorSubject<ActiveFilters[]>([]);

  private selectionImported$ = new BehaviorSubject<boolean>(true);

  constructor(
    private selectionApiService: SelectionApiService,
    private projectStateService: ProjectStateService,
    private leadApiService: LeadApiService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private actionService: CapturumBuilderActionService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private projectApiService: ProjectApiService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.project$ = this.projectStateService.getProject().pipe(filter(Boolean), shareReplay(1));

    this.sourceOptions$ = this.project$.pipe(
      switchMap((project) => {
        return this.selectionImported$.asObservable().pipe(
          map(() => {
            return project;
          })
        );
      }),
      switchMap((project: Project) => {
        return this.projectApiService.listCallCenterSources(project.id);
      })
    );

    this.project$.pipe(first()).subscribe((project: Project) => {
      this.notificationService.setProjectLeadsActionRequiredCount(project.id);
    });

    this.selectionOptions$ = this.actionService.getActionExecutionByActionKey('createSelection').pipe(
      startWith(true),
      switchMap(() => {
        return this.selectionApiService.list();
      }),
      toMapItems
    );

    this.listenForDialogClose();
  }

  public ngAfterViewInit(): void {
    if (this.listRenderer) {
      this.applyMetaFilters();
      this.listRenderer['setApiOptions'] = (options: ListOptions) => {
        let currentMetaValueFilters = this.metaFilterQueue.getValue();

        const filters = options.filters.reduce((acc, filter) => {
          const metaFilter = currentMetaValueFilters.find((activeFilter) => {
            return activeFilter.field === filter.field;
          });

          if (
            acc.some((currentFilter) => {
              return currentFilter.field === filter.field;
            })
          ) {
            return acc;
          }

          if (metaFilter) {
            return [
              ...acc,
              {
                field: metaFilter.field,
                value: metaFilter.value,
                operator: metaFilter.matchMode,
              },
            ];
          }

          if (!metaFilter && filter.field === 'lead_source_base_data_value_id') {
            return acc;
          }

          return [...acc, filter];
        }, []);

        currentMetaValueFilters = currentMetaValueFilters.filter((filter) => {
          return !filters.some((activeFilter) => {
            return filter.field === activeFilter.field;
          });
        });

        const apiOptions: ListOptions = {
          ...options,
          filters: [
            ...filters,
            ...currentMetaValueFilters.map((eventFilter) => {
              return {
                field: eventFilter.field,
                value: eventFilter.value,
                operator: eventFilter.matchMode,
              };
            }),
          ],
        };

        this.listRenderer['apiOptions$'].next(apiOptions);
      };
    }
  }

  public importSelection(): void {
    if (this.selectionControl.value) {
      this.dialogService
        .open(ImportSelectionDialogComponent, {
          header: this.translateService.instant('market_discovery.selection.import.button'),
          data: {
            selectionId: this.selectionControl.value,
            projectId: this.projectStateService.getProjectSnapshot().id,
          },
        })
        .onClose.pipe(first())
        .subscribe((success) => {
          if (success) {
            this.selectionImported$.next(true);
            this.toastService.success(
              this.translateService.instant('toast.success.title'),
              this.translateService.instant('market_discovery.lead.import-leads.success')
            );
            this.listRenderer.refresh();
          }
        });
    }
  }

  public handleMetaFilterChange(event: ActiveFilters[]): void {
    this.metaFilterQueue.next(event);
  }

  public applyMetaFilters(): void {
    combineLatest([
      this.metaFilterQueue.asObservable(),
      (this.listRenderer['config$'] as Observable<ListRendererConfig>).pipe(filter(Boolean), first()),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([event, config]) => {
        const apiOptions = this.listRenderer.currentApiOptions;
        const options = {
          ...ListRendererUtils.setApiOptions(apiOptions, null, config.filters.fields),
          search: apiOptions.search,
        };

        if (event) {
          options.filters = [...apiOptions.filters, ...options.filters];
        }

        options.filters = options.filters.filter((filter) => {
          return !filter.field.startsWith('metaKeys');
        });

        options.filters = [
          ...options.filters,
          ...event.map((eventFilter) => {
            return {
              field: eventFilter.field,
              value: eventFilter.value,
              operator: eventFilter.matchMode,
            };
          }),
        ];

        this.listRenderer['setApiOptions'](options);
      });
  }

  public toggleSelectionCard(): void {
    this.hideSelection = !this.hideSelection;
  }

  public handleFilterChange(filters: Record<string, unknown>): void {
    if (!filters) {
      this.metaFilterComponent.resetFilters();
    }
  }

  private listenForDialogClose(): void {
    this.actionService
      .getActionExecutionByActionKey('submit')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.listRenderer.refresh();
      });
  }
}
