import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-stakeholder-user-list-item',
  templateUrl: './stakeholder-user-list-item.component.html',
  styleUrls: ['./stakeholder-user-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StakeholderUserListItemComponent {
  @Input()
  public label: string;

  @Output()
  public deleteClick = new EventEmitter<void>();
}
