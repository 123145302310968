<app-page-layout
  [backRoute]="'../'"
  [keepQueryParams]="true"
  [title]="
    'market_discovery.form.edit.title' | translate : { entity: 'market_discovery.lead.general-info.label' | translate }
  ">
  <app-page-buttons actions>
    <cap-button icon="fas fa-xmark" [label]="'button.cancel' | translate" (click)="cancel()" styleClass="secondary">
    </cap-button>

    <cap-button icon="fas fa-save" [label]="'button.submit' | translate" (click)="submit()" styleClass="primary">
    </cap-button>
  </app-page-buttons>

  <div content class="row">
    <div class="col-lg-6">
      <cap-card [title]="'market_discovery.lead.general-info.label' | translate">
        <ng-template capTemplate="content">
          <cpb-form-renderer
            [showHeader]="false"
            #leadGeneralFormRenderer
            formKey="form_lead_general"
            [modelId]="modelId"
            [context]="{ isEdit: true, project_id: projectId }">
          </cpb-form-renderer>
        </ng-template>
      </cap-card>
    </div>

    <div class="col-lg-6">
      <app-company-addresses
        (valueUpdated)="updateData()"
        [values]="addresses"
        [leadId]="modelId"
        [entity]="'address'"
        [formKey]="'form_lead_address'"></app-company-addresses>
      <app-company-addresses
        (valueUpdated)="updateData()"
        [values]="contacts"
        [leadId]="modelId"
        [entity]="'contact'"
        [formKey]="'form_lead_contact'"></app-company-addresses>
    </div>
  </div>
</app-page-layout>
