<p-table
  [value]="roiData"
  class="simple-table roi-table"
  styleClass="cap-info-table"
  [autoLayout]="false"
  [hidden]="loading"
  [columns]="tableColumns">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let column of columns; let first = first" [class.roi-first-cell]="first">
        <ng-container *ngIf="first; else defaultTemplate">
          {{ 'market_discovery.roi.realized.label' | translate }}
        </ng-container>

        <ng-template #defaultTemplate>
          {{
            'market_discovery.calendar-locale.month-names.' + (column | date : 'MMM' : null : 'en' | lowercase)
              | translate
          }}
          -{{ column | date : 'yy' }}
        </ng-template>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-item let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <td
        *ngFor="let column of columns; let first = first; let index = index"
        [class.highlight]="item.highlight"
        [class.bold-title]="item.bold"
        [class.grey-cell]="first"
        [class.bold]="item.bold"
        [class.underlined]="item.underlined">
        <div [class.text-center]="!first" [class.text-bold]="item.bold">
          <ng-container *ngIf="first">
            {{ item[column] }}
          </ng-container>

          <ng-container *ngIf="!first">
            <ng-container *ngIf="item[column] > 0">+</ng-container>

            <ng-container *ngIf="showCurrency && item.totalField; else amountTemplate">
              {{ item[column] | currency : 'EUR' : 'symbol' : '1.0-0' }}
            </ng-container>

            <ng-template #amountTemplate>
              {{ item[column] | number : '1.0-1' }}{{ item.percentage ? '%' : '' }}
            </ng-template>
          </ng-container>
        </div>
      </td>
    </tr></ng-template
  >
</p-table>

<cap-info-table-skeleton *ngIf="loading"></cap-info-table-skeleton>
