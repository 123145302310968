import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-callback-dialog',
  templateUrl: './callback-dialog.component.html',
  styleUrls: ['./callback-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CallbackDialogComponent implements OnInit {
  public leadId: string;
  public formKey = 'form_lead_callback';

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private formSaver: FormSaverService,
    private dialogReference: DynamicDialogRef,
    private formRendererService: FormRendererService
  ) {}

  public ngOnInit(): void {
    this.leadId = this.dialogConfig?.data?.leadId;
  }

  public submit(): void {
    const formValue$ = this.formRendererService.getFormValueByKey(this.formKey).pipe(
      map((value) => {
        return {
          leadCallbacks: value.leadCallbacks.map((callback) => {
            let date: Date = callback.date;

            if (!(callback.date instanceof Date)) {
              date = new Date(callback.date);
            }

            date.setHours(12);

            return {
              date: date.toISOString(),
              from: this.getDateBasedOnHours(callback.from, date),
              to: this.getDateBasedOnHours(callback.to, date),
            };
          }),
        };
      })
    );

    combineLatest([formValue$, this.formSaver.getFormConfigurationByKey(this.formKey)])
      .pipe(
        tap(() => {
          return this.formSaver.markFormAsSubmitted(this.formKey);
        }),
        switchMap(([value, configuration]) => {
          return this.formSaver.submitToBackend(configuration.submit.endpoint, value, this.formKey);
        })
      )
      .subscribe((response) => {
        this.dialogReference?.close(response.data?.leadCallbacks);
      });
  }

  private getDateBasedOnHours(time: string, date: Date): string {
    if (time) {
      const setDate = new Date(date);
      const [hours, minutes] = time.split(':');

      if (hours) {
        setDate.setHours(+hours);
        setDate.setMinutes(+minutes);

        return setDate.toISOString();
      }
    }

    return null;
  }
}
