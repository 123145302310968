import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumCardModule } from '@capturum/ui/card';
import { CapturumProgressBarModule } from '@capturum/ui/progress-bar';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { MetaTextInputComponent } from '@core/builders/input-types/meta-text-input/meta-text-input.component';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { FilterByPermissionsPipe } from '@shared/pipes/filter-by-permissions.pipe';
import { NgxPermissionsModule } from 'ngx-permissions';

import { CompanyAddressesComponent } from './components/company-addresses/company-addresses.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ColumnTotalPipe } from './pipes/column-total.pipe';
import { CountSpacerPipe } from './pipes/count-spacer.pipe';
import { DateFilterSelectionModePipe } from './pipes/date-filter-selection-mode.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { MapMarkerIconPipe } from './pipes/map-marker-icon.pipe';
import { TableActionHiddenPipe } from './pipes/table-action-hidden.pipe';
import { NetworkStatusService } from './services/network-status.service';

@NgModule({
  declarations: [
    FilterByPermissionsPipe,
    MapMarkerIconPipe,
    TabsComponent,
    ColumnTotalPipe,
    JoinPipe,
    CompanyAddressesComponent,
    TableActionHiddenPipe,
    CountSpacerPipe,
    DateFilterSelectionModePipe,
  ],
  imports: [
    CommonModule,
    NgxPermissionsModule,
    CapturumButtonModule,
    TranslateModule,
    CapturumSharedModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'metaTextInput',
          component: MetaTextInputComponent,
          wrappers: ['default-input-wrapper'],
        },
      ],
    }),
    CapturumCardModule,
    CapturumFormRendererModule,
    CapturumSkeletonModule,
    CapturumProgressBarModule,
  ],
  exports: [
    FilterByPermissionsPipe,
    MapMarkerIconPipe,
    ColumnTotalPipe,
    NgxPermissionsModule,
    TranslateModule,
    CapturumSharedModule,
    TabsComponent,
    JoinPipe,
    CompanyAddressesComponent,
    TableActionHiddenPipe,
    CountSpacerPipe,
    DateFilterSelectionModePipe,
  ],
  providers: [CurrencyPipe, NetworkStatusService],
})
export class SharedModule {}
