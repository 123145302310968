import { ProjectInfoEditComponent } from './pages/project-info-edit/project-info-edit.component';
import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { ManageListComponent } from '../manage/pages/manage-list/manage-list.component';
import { ProjectLayoutComponent } from './components/project-layout/project-layout.component';
import { ProjectLeadsComponent } from './pages/project-leads/project-leads.component';
import { ProjectDetailComponent } from './pages/project-detail/project-detail.component';
import { ProjectStakeholdersComponent } from '@features/project/pages/project-stakeholders/project-stakeholders.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ProjectPipelineComponent } from '@features/project/pages/project-pipeline/project-pipeline.component';
import { ProjectLogComponent } from '@features/project/pages/project-log/project-log.component';
import { ProjectLeadDetailComponent } from '@features/project/pages/project-lead-detail/project-lead-detail.component';
import { AppRoutes } from '@core/enums/routes.enum';
import { ProjectGripMeterComponent } from './pages/project-grip-meter/project-grip-meter.component';
import { LeadEditComponent } from '@features/project/pages/lead-edit/lead-edit.component';
import { ProjectFullBusinessComponent } from './pages/project-full-business/project-full-business.component';
import { ProjectSelectionComponent } from '@features/project/pages/project-selection/project-selection.component';
import { LeadImportComponent } from '@features/project/pages/lead-import/lead-import.component';
import { RoiOverviewComponent } from '@features/project/pages/roi-overview/roi-overview.component';
import { ProjectXyzComponent } from '@features/project/pages/project-xyz/project-xyz.component';
import { SelectionImportComponent } from '@features/project/pages/selection-import/selection-import.component';
import { FullBusinessGuard } from '@features/project/guards/full-business.guard';
import { LeadAccountmanagerComponent } from '@features/project/pages/lead-accountmanager/lead-accountmanager.component';
import { ProjectHourListComponent } from '@features/project-hour/pages/project-hour-list/project-hour-list.component';
import { ProjectQuestionnaireComponent } from '@features/project/pages/project-questionnaire/project-questionnaire.component';
import { ImportsOverviewComponent } from './pages/imports-overview/imports-overview.component';
import { ImportsDetailComponent } from './pages/imports-detail/imports-detail.component';

function redirectToProjectRoute(
  projectRoute: string
): (rejectedPermissionName: string, activateRouteSnapshot: ActivatedRouteSnapshot) => string {
  return (rejectedPermissionName: string, activateRouteSnapshot: ActivatedRouteSnapshot) => {
    const projectId = activateRouteSnapshot.parent?.params?.id;

    if (projectId) {
      return `${AppRoutes.project}/${projectId}/${projectRoute}`;
    }

    return AppRoutes.project;
  };
}

const routes: Routes = [
  {
    path: '',
    component: ManageListComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'project.create',
        redirectTo: `/${AppRoutes.profile}`,
      },
    },
  },
  {
    path: AppRoutes.add,
    component: ProjectDetailComponent,
  },

  {
    path: ':id',
    component: ProjectLayoutComponent,
    children: [
      {
        path: '',
        component: ProjectDetailComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['project.manage.details'],
            redirectTo: redirectToProjectRoute(AppRoutes.xyz),
          },
        },
      },
      {
        path: `${AppRoutes.leads}/${AppRoutes.importLead}`,
        component: LeadImportComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['lead.import.file'],
          },
        },
      },
      {
        path: AppRoutes.leads,
        children: [
          {
            path: '',
            component: ProjectLeadsComponent,
          },

          {
            path: ':leadId',
            component: ProjectLeadDetailComponent,
          },
          {
            path: `:leadId/${AppRoutes.edit}`,
            component: LeadEditComponent,
          },
          {
            path: ':leadId/account-manager',
            component: LeadAccountmanagerComponent,
            data: {
              entity: 'lead_accountmanager',
              identifier: 'leadId',
            },
          },
          {
            path: ':leadId/project-information',
            component: ProjectInfoEditComponent,
          },
        ],
      },
      {
        path: AppRoutes.log,
        component: ProjectLogComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'activity.manage',
          },
        },
      },
      {
        path: AppRoutes.stakeholders,
        canActivate: [NgxPermissionsGuard],
        component: ProjectStakeholdersComponent,
        data: {
          permissions: {
            only: 'project.account_managers.manage',
            redirectTo: redirectToProjectRoute(AppRoutes.xyz),
          },
        },
      },
      {
        path: AppRoutes.details,
        component: ProjectDetailComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'project.manage.details',
            redirectTo: redirectToProjectRoute(AppRoutes.xyz),
          },
        },
      },
      {
        path: AppRoutes.fullBusiness,
        component: ProjectFullBusinessComponent,
        canActivate: [NgxPermissionsGuard, FullBusinessGuard],
        data: {
          permissions: {
            only: 'project.full-business.show',
          },
        },
      },
      {
        path: AppRoutes.pipeline,
        component: ProjectPipelineComponent,
      },
      {
        path: AppRoutes.gripMeter,
        component: ProjectGripMeterComponent,
      },
      {
        path: AppRoutes.roi,
        component: RoiOverviewComponent,
      },
      {
        path: AppRoutes.imports,
        children: [
          {
            path: '',
            component: ImportsOverviewComponent,
          },

          {
            path: ':id',
            component: ImportsDetailComponent,
          },
        ],
      },
      {
        path: AppRoutes.letter,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: ['letter.manage'],
        },
        loadChildren: () => {
          return import('../letter/letter.module').then((m) => {
            return m.LetterModule;
          });
        },
      },
      {
        path: AppRoutes.selection,
        component: ProjectSelectionComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'project.selection.manage',
            redirectTo: redirectToProjectRoute(AppRoutes.xyz),
          },
        },
      },
      {
        path: AppRoutes.importSelection,
        component: SelectionImportComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'project.selection.manage',
            redirectTo: redirectToProjectRoute(AppRoutes.xyz),
          },
        },
      },
      {
        path: AppRoutes.projectHours,
        component: ProjectHourListComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          entity: 'project-hours',
          permissions: {
            only: 'project-hour.manage',
            redirectTo: redirectToProjectRoute(AppRoutes.xyz),
          },
        },
      },
      {
        path: AppRoutes.questionnaire,
        component: ProjectQuestionnaireComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'project.questionnaire.manage',
          },
        },
      },
      {
        path: AppRoutes.xyz,
        component: ProjectXyzComponent,
      },
      {
        path: AppRoutes.fieldwork,
        loadComponent: () => {
          return import('../../features/fieldwork/pages/fieldwork-dashboard/fieldwork-dashboard.component').then(
            (c) => {
              return c.FieldworkDashboardComponent;
            }
          );
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectRoutingModule {}
