<app-page-layout
  [title]="titleTranslationKey | translate : { entity: 'market_discovery.project.entity.name' | translate }">
  <app-page-buttons actions>
    <ng-container *ngIf="!modelId || !isReadOnly">
      <cap-button icon="fas fa-xmark" [label]="'button.cancel' | translate" (click)="cancel()" styleClass="secondary">
      </cap-button>

      <cap-button
        icon="fas fa-save"
        [label]="'button.submit' | translate"
        (onClick)="submit()"
        [disabled]="isSubmitting$ | async"
        styleClass="primary">
      </cap-button>
    </ng-container>

    <ng-container
      *ngIf="isReadOnly && ((!sourceValue?.is_locked && isReadOnly) || (sourceValue?.is_locked && canUnlockProject))">
      <cap-button icon="fas fa-pencil" [label]="'button.edit' | translate" (onClick)="edit()" styleClass="primary">
      </cap-button>
    </ng-container>
  </app-page-buttons>

  <div content class="project-detail" [class.is-edit]="!isReadOnly">
    <cap-card [title]="'market_discovery.project.details.basic-info' | translate">
      <ng-template capTemplate="content">
        <cpb-form-renderer
          [readOnly]="modelId && isReadOnly"
          formKey="form_project"
          [showHeader]="false"
          [context]="{ project_id: modelId }"
          [modelId]="modelId">
        </cpb-form-renderer>
      </ng-template>
    </cap-card>

    <cap-card title="Units">
      <ng-template capTemplate="content">
        <p-pickList
          #pl
          [source]="metaKeys$ | async"
          [target]="projectMetaKeys"
          filterBy="label"
          [responsive]="true"
          [disabled]="isReadOnly"
          [dragdrop]="true"
          [targetHeader]="'market_discovery.project.meta-keys.chosen' | translate"
          [sourceHeader]="'market_discovery.project.meta-keys.available' | translate">
          <ng-template let-item pTemplate="item">
            <div class="picklist-item">
              {{ item.label }}
            </div>
          </ng-template>
        </p-pickList>
      </ng-template>
    </cap-card>
  </div>
</app-page-layout>
