import { Component, OnInit } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-utc-date',
  templateUrl: './utc-date.component.html',
  styleUrls: ['./utc-date.component.scss'],
})
export class UtcDateComponent extends ListRendererWidgetBase implements OnInit {
  public timezone: string;
  public value: Date;

  constructor() {
    super();

    const date = new Date();

    this.timezone = date.getTimezoneOffset().toString();
  }

  public ngOnInit(): void {
    this.value = this.item?.[this.field] ? new Date(this.item[this.field] + 'Z') : null;
  }
}
