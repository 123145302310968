import { Component, OnInit } from '@angular/core';
import { CustomerApiService } from '../../services/customer-api.service';
import { XyzFormatedResult } from '../../../project/interfaces/xyz.interface';
import { BehaviorSubject, Observable, shareReplay, switchMap } from 'rxjs';
import { ActiveFilters, DynamicFilterConfig, DynamicFilterType } from '@capturum/ui/dynamic-filters';
import { ActivatedRoute } from '@angular/router';
import { FilterMatchMode } from '@capturum/ui/api';
import { map } from 'rxjs/operators';
import { ChartData } from 'chart.js';
import { XyzService } from '../../../xyz/service/xyz.service';
import { TranslateService } from '@ngx-translate/core';
import { ListOptions } from '@capturum/api';
import { ProjectApiService } from '../../../project/services/project-api.service';
import { formatDate } from '@angular/common';
import { Customer } from '../../interfaces/customer.interface';

@Component({
  selector: 'app-customer-xyz',
  templateUrl: './customer-xyz.component.html',
  styleUrls: ['./customer-xyz.component.scss'],
})
export class CustomerXyzComponent implements OnInit {
  public xyz$: Observable<XyzFormatedResult>;
  public filterChange = new BehaviorSubject<ActiveFilters[]>(null);
  public filters: DynamicFilterConfig;
  public graph$: Observable<ChartData>;
  public activeFilters: ActiveFilters[] = [];
  public customer$: Observable<Customer>;

  constructor(
    private customerApiService: CustomerApiService,
    private xyzService: XyzService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private projectService: ProjectApiService
  ) {}

  public ngOnInit(): void {
    const customerId = this.route.snapshot.paramMap.get('id');

    this.customer$ = this.customerApiService.get(customerId);
    this.filters = this.getFilterConfig(customerId);
    this.xyz$ = this.filterChange.pipe(
      switchMap((filters) => {
        return this.customerApiService.getXyz(customerId, { parameters: filters });
      }),
      shareReplay(1)
    );
    this.graph$ = this.xyz$.pipe(
      map(({ graph }) => {
        return this.xyzService.generateGraphData(graph);
      })
    );
  }

  public handleFilterChange(filters: ActiveFilters[]): void {
    this.filterChange.next(filters);
  }

  private getFilterConfig(customerId: string): DynamicFilterConfig {
    const projectFilterValue$: Observable<ListOptions> = this.filterChange.asObservable().pipe(
      map((filters) => {
        const projectFilter = filters.find((filter) => {
          return filter.field === 'project_ids';
        });
        const options: ListOptions = {};

        if (projectFilter) {
          options.filters = [
            {
              field: 'project_ids',
              value: projectFilter.value,
              operator: FilterMatchMode.IN,
            },
          ];
        }

        return options;
      }),
      shareReplay(1)
    );

    const filteredAccountManagers = projectFilterValue$.pipe(
      switchMap((options) => {
        return this.customerApiService.listAccountManagers(customerId, options);
      })
    );

    const filteredSources = projectFilterValue$.pipe(
      switchMap((options) => {
        return this.customerApiService.listSources(customerId, options);
      })
    );

    const minDate$ = this.filterChange.asObservable().pipe(
      switchMap((filters) => {
        const projectFilter = filters.find((filter) => {
          return filter.field === 'project_ids';
        });

        return this.projectService.getMinDateForProjects(customerId, projectFilter?.value ?? []);
      }),
      map((value) => {
        return value ? new Date(value) : null;
      })
    );

    return {
      filters: [
        {
          field: 'project_ids',
          type: DynamicFilterType.multiselect,
          icon: 'fas fa-user',
          matchMode: FilterMatchMode.IN,
          placeholder: this.translateService.instant('market_discovery.customer.project_count.label'),
          options: this.customerApiService.listProjects(customerId),
        },
        {
          field: 'user_ids',
          type: DynamicFilterType.multiselect,
          icon: 'fas fa-user',
          placeholder: this.translateService.instant('market_discovery.lead.account_manager_id.label'),
          styleClass: 'filter-account-manager',
          matchMode: FilterMatchMode.EQUALS,
          label: null,
          options: filteredAccountManagers,
          selectedItemsLabel: '{0} items',
        },
        {
          field: 'start_at',
          type: DynamicFilterType.date,
          icon: 'fas fa-calendar',
          placeholder: this.translateService.instant('market_discovery.pipeline.filter.starts_at.label'),
          styleClass: 'filter-calendar',
          matchMode: FilterMatchMode.GREATER_OR_EQUAL,
          label: null,
          transformValue: (value) => {
            if (value) {
              return formatDate(new Date(value), 'yyyy-MM-dd', 'en');
            }

            return null;
          },
          minDate: minDate$,
          maxDate: this.getDateFilterValue('end_at'),
        },
        {
          field: 'end_at',
          type: DynamicFilterType.date,
          icon: 'fas fa-calendar',
          placeholder: this.translateService.instant('market_discovery.pipeline.filter.ends_at.label'),
          matchMode: FilterMatchMode.LESS_OR_EQUAL,
          label: null,
          styleClass: 'filter-calendar',
          transformValue: (value) => {
            if (value) {
              return formatDate(new Date(value), 'yyyy-MM-dd', 'en');
            }

            return null;
          },
          maxDate: new Date(),
          minDate: this.getDateFilterValue('start_at'),
        },
        {
          field: 'source_ids',
          type: DynamicFilterType.multiselect,
          icon: 'fas fa-globe',
          placeholder: this.translateService.instant('market_discovery.pipeline.filter.source.label'),
          matchMode: FilterMatchMode.EQUALS,
          label: null,
          options: filteredSources,
          selectedItemsLabel: '{0} items',
        },
      ],
    };
  }

  private getDateFilterValue(filterField: string): Date | null {
    const field = this.activeFilters.find((activeFilter) => {
      return activeFilter.field === filterField;
    });

    if (field && field.value) {
      return new Date(field.value);
    }

    return null;
  }
}
