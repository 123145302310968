<div class="lead-section-header-title">
  {{ 'market_discovery.lead.attachments.title' | translate }}
</div>

<div class="lead-file-upload">
  <cap-file-upload
    [id]="'attachments'"
    (onFileChange)="handleFileChange($event)"
    [accept]="inputAcceptAttribute"
    [uploaderContent]="uploadContent"
    [multiple]="true">
  </cap-file-upload>

  <ng-template #uploadContent>
    <div class="file-upload-content">
      <i class="fas fa-cloud-arrow-up"></i>
      <div class="file-upload-placeholder">
        {{ 'file.upload.description' | translate }}
      </div>
    </div>
  </ng-template>

  <div class="preview-files">
    <div class="preview-files">
      <cap-file-preview-list
        [files]="previewFiles"
        [showDelete]="true"
        [showDownload]="true"
        (onDownloadClick)="handleDownloadFile($event)"
        (onDeleteClick)="handleDeleteFile($event)">
      </cap-file-preview-list>
    </div>
  </div>
</div>
