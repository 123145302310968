import { PrintService } from '@shared/services/print.service';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MapItem } from '@capturum/auth';
import { DestroyBase } from '@capturum/shared';
import { FilterMatchMode, ToastService } from '@capturum/ui/api';
import {
  ActiveFilters,
  CapturumDynamicFiltersComponent,
  DynamicFilterConfig,
  DynamicFilterType,
} from '@capturum/ui/dynamic-filters';
import { InfoTableColumn, InfoTableColumnType } from '@capturum/ui/info-table';
import { GripMeter } from '@features/project/interfaces/grip-meter.interface';
import { ProjectApiService } from '@features/project/services/project-api.service';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { TranslateService } from '@ngx-translate/core';
import { TableService } from '@shared/services/table.service';
import { filter, Observable, switchMap, takeUntil, tap } from 'rxjs';
import { Project } from '@features/project/interfaces/project.interface';
import { NgxPermissionsService } from 'ngx-permissions';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-project-grip-meter',
  templateUrl: './project-grip-meter.component.html',
  styleUrls: ['./project-grip-meter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [TableService],
})
export class ProjectGripMeterComponent extends DestroyBase implements OnInit {
  @ViewChild(CapturumDynamicFiltersComponent) public dynamicFiltersRef: CapturumDynamicFiltersComponent;

  public infoTableColumns: InfoTableColumn[];
  public dynamicFilters: DynamicFilterConfig;
  public totalsData$: Observable<{ rowData: GripMeter[]; totalRowData: GripMeter }>;
  public accountManagerOptions$: Observable<MapItem[]>;
  public hideSelection: boolean;
  public canShowTotal: boolean;
  public project$: Observable<Project>;

  private _projectId: string;

  constructor(
    private tableService: TableService,
    private projectApiService: ProjectApiService,
    private projectStateService: ProjectStateService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private printService: PrintService,
    private ngxPermissionService: NgxPermissionsService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.project$ = this.projectStateService.getProject().pipe(filter(Boolean), shareReplay(1));
    this.totalsData$ = this.project$.pipe(
      filter(Boolean),
      tap((project) => {
        this._projectId = project?.id;
        this.accountManagerOptions$ = this.projectApiService.getAccountManagersList(this._projectId);
        this.dynamicFilters = this.getFilterConfig(project);
        this.infoTableColumns = this.getTableColumns();
      }),
      switchMap(() => {
        return this.tableService.getUpdateTable().pipe(filter(Boolean));
      }),
      switchMap((options) => {
        return this.projectApiService.getGripMeterData(this._projectId, options);
      }),
      takeUntil(this.destroy$)
    );

    this.canShowTotal = !this.ngxPermissionService.getPermission('project.grip.filter-user');
  }

  public handleFilterChange(filters: ActiveFilters[]): void {
    // @TODO: Remove this workaround when in Blueprint will be fixed
    const filteredFilters = filters.filter((filter) => {
      return Array.isArray(filter?.value) ? filter?.value?.length : filter?.value;
    });

    if (this.dynamicFiltersRef) {
      this.dynamicFiltersRef.activeFilters = filteredFilters;
    }

    this.tableService.updateTableByFilters(filteredFilters);
  }

  public exportGripMeter(): void {
    this.printService.setPrintBusy(true);

    this.toastService.info(
      this.translateService.instant('market_discovery.project.grip-meter.export'),
      this.translateService.instant('market_discovery.project.grip-meter.export.started')
    );
    this.projectApiService
      .exportGripMeter(this._projectId, { filters: this.dynamicFiltersRef.activeFilters })
      .subscribe((response) => {
        if (response?.finished) {
          this.printService.setPrintBusy(false);
        }
      });
  }

  private getTableColumns(): InfoTableColumn[] {
    return [
      {
        field: 'account_manager_name',
        title: this.translateService.instant('market_discovery.project.account_managers.label'),
        type: InfoTableColumnType.Template,
        titleClass: 'header-cell__account_manager',
        cellClass: 'account_manager-cell',
      },
      {
        field: 'rol_cp',
        title: this.translateService.instant('market_discovery.project.grip-meter.role-cp.title'),
        titleClass: 'text-center',
        cellClass: 'percentage-cell text-center',
        type: InfoTableColumnType.Template,
      },
      {
        field: 'decision_maker',
        title: this.translateService.instant('market_discovery.project.grip-meter.decision-maker.title'),
        titleClass: 'text-center',
        cellClass: 'percentage-cell text-center',
        type: InfoTableColumnType.Template,
      },
      {
        field: 'ok_cp',
        title: this.translateService.instant('market_discovery.project.grip-meter.cp-ok.title'),
        titleClass: 'text-center',
        cellClass: 'percentage-cell text-center',
        type: InfoTableColumnType.Template,
      },
      {
        field: 'opportunities',
        title: this.translateService.instant('market_discovery.project.grip-meter.opportunities.title'),
        titleClass: 'text-center',
        cellClass: 'percentage-cell text-center',
        type: InfoTableColumnType.Template,
      },
      {
        field: 'wbdvdk',
        title: this.translateService.instant('market_discovery.project.grip-meter.motifs.title'),
        titleClass: 'text-center',
        cellClass: 'percentage-cell text-center',
        type: InfoTableColumnType.Template,
      },
      {
        field: 'in_money',
        title: this.translateService.instant('market_discovery.project.grip-meter.benefit.title'),
        titleClass: 'text-center',
        cellClass: 'percentage-cell text-center',
        type: InfoTableColumnType.Template,
      },
      {
        field: 'quick_decision',
        title: this.translateService.instant('market_discovery.project.grip-meter.quick-decisions.title'),
        titleClass: 'text-center',
        cellClass: 'percentage-cell text-center',
        type: InfoTableColumnType.Template,
      },
      {
        field: 'appointment',
        title: this.translateService.instant('market_discovery.project.grip-meter.appointment.title'),
        titleClass: 'text-center',
        cellClass: 'percentage-cell text-center',
        type: InfoTableColumnType.Template,
      },
      {
        field: 'done_everything',
        title: this.translateService.instant('market_discovery.project.grip-meter.all-done.title'),
        titleClass: 'text-center',
        cellClass: 'percentage-cell text-center',
        type: InfoTableColumnType.Template,
      },
      {
        field: 'grip_meter',
        title: this.translateService.instant('market_discovery.project.grip-meter.ttl-grip.title'),
        titleClass: 'text-center',
        cellClass: 'percentage-cell text-center',
        type: InfoTableColumnType.Template,
      },
    ];
  }

  private getFilterConfig(project: Project): DynamicFilterConfig {
    let filters = [];

    if (this.ngxPermissionService.getPermission('project.grip.filter-user')) {
      filters = [
        {
          field: 'account_manager',
          type: DynamicFilterType.multiselect,
          icon: 'fas fa-user',
          showLabel: true,
          label: this.translateService.instant('market_discovery.project.account_managers.label'),
          placeholder: this.translateService.instant('market_discovery.project.account_managers.label'),
          matchMode: FilterMatchMode.IN,
          options: this.accountManagerOptions$,
          selectedItemsLabel: '{0} items',
        },
      ];
    }

    return {
      filters: [
        ...filters,
        {
          field: 'starts_at',
          type: DynamicFilterType.date,
          icon: 'fas fa-calendar',
          showLabel: true,
          label: this.translateService.instant('market_discovery.pipeline.filter.starts_at.label'),
          placeholder: this.translateService.instant('market_discovery.pipeline.filter.starts_at.label'),
          matchMode: FilterMatchMode.DATEEQUALS,
          minDate: new Date(project.started_at || project.created_at),
          maxDate: new Date(),
        },
        {
          field: 'ends_at',
          type: DynamicFilterType.date,
          icon: 'fas fa-calendar',
          showLabel: true,
          label: this.translateService.instant('market_discovery.pipeline.filter.ends_at.label'),
          placeholder: this.translateService.instant('market_discovery.pipeline.filter.ends_at.label'),
          matchMode: FilterMatchMode.DATEEQUALS,
          minDate: new Date(project.started_at || project.created_at),
          maxDate: new Date(),
        },
        {
          field: 'source_id',
          type: DynamicFilterType.multiselect,
          icon: 'fas fa-database',
          showLabel: true,
          label: this.translateService.instant('market_discovery.pipeline.filter.source.label'),
          placeholder: this.translateService.instant('market_discovery.pipeline.filter.source.label'),
          matchMode: FilterMatchMode.IN,
          options: this.projectApiService.listSources(this._projectId),
          selectedItemsLabel: '{0} items',
        },
      ],
    };
  }
}
