import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DestroyBase } from '@capturum/shared';
import { AppRoutes } from '@core/enums/routes.enum';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { startWith } from 'rxjs';

import { ProjectStateService } from '../../../features/project/services/project-state.service';
import { NavBarItem } from './models/nav-bar-item.model';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationBarComponent extends DestroyBase implements OnInit {
  public navItems: NavBarItem[];

  public constructor(
    private readonly translateService: TranslateService,
    private readonly ngxPermissionService: NgxPermissionsService,
    private readonly projectStateService: ProjectStateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.projectStateService
      .getProject()
      .pipe(startWith(null))
      .subscribe((project) => {
        this.navItems = this.getAllMenuItems(project?.id);
      });
  }

  private getAllMenuItems(projectId: string): NavBarItem[] {
    const companyTranslationKey = this.ngxPermissionService.getPermission('project.create')
      ? 'companies'
      : 'track-and-trace';
    const companyIcon = this.ngxPermissionService.getPermission('project.create')
      ? 'far fa-database'
      : 'far fa-arrow-right-to-arc';

    const xyzProjectMenuItem = {
      title: this.translateService.instant('market_discovery.navigation.xyz'),
      routerUrl: `${AppRoutes.project}/${projectId}`,
      icon: 'far fa-rectangle-history',
      permissions: ['project.manage'],
    };

    if (!this.ngxPermissionService.getPermission('project.create')) {
      return [
        xyzProjectMenuItem,
        {
          title: this.translateService.instant(`market_discovery.navigation.${companyTranslationKey}`),
          routerUrl: AppRoutes.company,
          icon: companyIcon,
          permissions: ['company.show'],
        },
        {
          title: this.translateService.instant('market_discovery.fieldwork.page.title'),
          routerUrl: AppRoutes.fieldwork,
          icon: 'far fa-users',
          permissions: ['fieldwork.show'],
        },
        {
          title: this.translateService.instant('market_discovery.project-hours.list.title'),
          routerUrl: AppRoutes.projectHours,
          icon: 'far fa-user',
          permissions: ['project-hour.show'],
        },
        {
          title: this.translateService.instant('market_discovery.navigation.questionnaire'),
          routerUrl: AppRoutes.agentQuestionnaire,
          icon: 'far fa-database',
          permissions: ['lead.queue'],
        },
      ];
    } else {
      return [
        {
          title: this.translateService.instant('config.manage.users'),
          routerUrl: AppRoutes.user,
          icon: 'far fa-users',
          permissions: ['user.show.tenant'],
        },
        {
          title: this.translateService.instant('market_discovery.navigation.customer'),
          routerUrl: AppRoutes.customer,
          icon: 'far fa-user',
          permissions: ['customer.manage'],
        },
        {
          title: this.translateService.instant(`market_discovery.navigation.${companyTranslationKey}`),
          routerUrl: AppRoutes.company,
          icon: companyIcon,
          permissions: ['company.show'],
        },
        {
          title: this.translateService.instant('market_discovery.navigation.projects'),
          routerUrl: AppRoutes.project,
          icon: 'far fa-rectangle-history',
          permissions: ['project.create'],
        },
        {
          title: this.translateService.instant('market_discovery.navigation.selection'),
          routerUrl: 'selection',
          icon: 'fas fa-list-radio',
          permissions: ['selection.manage'],
        },
        {
          title: this.translateService.instant('market_discovery.navigation.questionnaire'),
          routerUrl: AppRoutes.questionnaire,
          icon: 'far fa-rectangle-history',
          permissions: ['questionnaire.manage'],
        },
        {
          title: this.translateService.instant('market_discovery.change-log.overview.title'),
          routerUrl: AppRoutes.changelog,
          icon: 'far fa-arrow-right-to-arc',
          permissions: ['model-log.show'],
        },
        {
          title: this.translateService.instant('market_discovery.project-hours.list.title'),
          routerUrl: AppRoutes.projectHours,
          icon: 'far fa-user',
          permissions: ['project-hour.manage'],
        },
      ];
    }
  }
}
