<app-dialog-layout>
  <ng-container content>
    <cpb-form-renderer
      [showHeader]="false"
      formKey="form_selection_import_as_lead"
      [context]="{ project_id: (project$ | async).id }"></cpb-form-renderer>
  </ng-container>

  <ng-container buttons>
    <cap-button styleClass="primary" [label]="'button.submit' | translate" (click)="submit()"></cap-button>
  </ng-container>
</app-dialog-layout>
