<div class="table-bulk-actions">
  <div class="bulk-action-title">
    {{ 'market_discovery.table.rows_selected.label' | translate : { amount: selectedItems?.length, entity } }}
  </div>

  <div class="button-container">
    <div class="action-panel">
      <div class="bulk-action-toggle-button" (click)="isOpen = !isOpen">
        <span class="placeholder">{{ placeholder }}</span>
        <span class="icon">
          <i class="fas" [class.fa-chevron-up]="isOpen" [class.fa-chevron-down]="!isOpen"></i>
        </span>
      </div>

      <ul class="row-action__items bulk-items" [ngClass]="{ 'row-action__items--open': isOpen }">
        <ng-container *ngFor="let action of actions">
          <li
            *ngIf="(action?.hidden | observablePipe | async) !== true"
            (click)="executeAction(action)"
            [attr.data-test]="action?.key || action?.value">
            <div class="row-action__icon">
              <i [class]="action?.icon"></i>
            </div>

            <div class="row-action__info">
              <p class="title">
                {{ action?.label | observablePipe | async }}
              </p>

              <p *ngIf="action?.description | observablePipe | async as description" class="description">
                {{ description }}
              </p>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
