import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { OnlineStatusType } from '@core/enums/online-status-type.enum';

@Injectable()
export class NetworkStatusService implements OnDestroy {
  public status = new Subject<OnlineStatusType>();

  constructor() {
    window.addEventListener('online', this.onOnline.bind(this));
    window.addEventListener('offline', this.onOffline.bind(this));
  }

  public ngOnDestroy(): void {
    window.removeEventListener('online', this.onOnline.bind(this));
    window.removeEventListener('offline', this.onOffline.bind(this));
  }

  public getStatus(): Observable<OnlineStatusType> {
    return this.status.asObservable();
  }

  private onOnline(): void {
    this.status.next(OnlineStatusType.ONLINE);
  }

  private onOffline(): void {
    this.status.next(OnlineStatusType.OFFLINE);
  }
}
