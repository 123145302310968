import { inject, Injectable, Type } from '@angular/core';
import { BuilderAction, OpenDialogAction } from '@capturum/builders/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class OpenMutationPopupAction extends OpenDialogAction {
  public translateService = inject(TranslateService);

  public execute(
    options: {
      containerType: Type<any>;
      configurationKey: string;
      title: string;
      width?: string;
      action: BuilderAction;
    },
    item: any
  ): void {
    options.title = this.translateService.instant('market_discovery.mutation.dialog.title', {
      lead: item.lead.company.name,
    });

    super.execute(options, item);
  }
}
