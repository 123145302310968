import { LeadDetails } from '@features/project/interfaces/lead-details.interface';
import { LeadApiService } from '@features/lead/services/lead-api.service';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-lead-history-dialog',
  templateUrl: './lead-history-dialog.component.html',
  styleUrls: ['./lead-history-dialog.component.scss'],
})
export class LeadHistoryDialogComponent implements OnInit {
  public leadId: string;
  public leadHistoryLogs$: Observable<LeadDetails[]>;

  constructor(
    public dialogRef: DynamicDialogRef,
    private readonly dialogConfig: DynamicDialogConfig,
    private leadApiService: LeadApiService
  ) {
    this.leadId = this.dialogConfig?.data?.leadId;
  }

  public ngOnInit(): void {
    this.leadHistoryLogs$ = this.leadApiService.getLeadLogs(this.leadId);
  }
}
