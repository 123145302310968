import { formatCurrency, formatDate, formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DestroyBase } from '@capturum/shared';
import { FilterMatchMode, ToastService } from '@capturum/ui/api';
import { ActiveFilters, DynamicFilterConfig, DynamicFilterType } from '@capturum/ui/dynamic-filters';
import { FilterConfigItem } from '@capturum/ui/dynamic-filters/interfaces/dynamic-filter.interface';
import { InfoTableColumn } from '@capturum/ui/info-table';
import { ProjectType } from '@features/project/enums/project-type.enum';
import { PipelineTotals } from '@features/project/interfaces/pipeline.interface';
import { Project } from '@features/project/interfaces/project.interface';
import { ProjectApiService } from '@features/project/services/project-api.service';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@shared/services/auth.service';
import { PrintService } from '@shared/services/print.service';
import { TableService } from '@shared/services/table.service';
import { distinctUntilChanged, Observable, switchMap } from 'rxjs';
import { filter, map, shareReplay, skip, tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { ListOptions } from '@capturum/api';
import { LocalStorageService } from '@shared/services/storage.service';

@Component({
  selector: 'app-project-pipeline',
  templateUrl: './project-pipeline.component.html',
  styleUrls: ['./project-pipeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [TableService],
})
export class ProjectPipelineComponent extends DestroyBase implements OnInit {
  public infoTableColumns: InfoTableColumn[];
  public hideSelection: boolean;

  public dynamicFilters: DynamicFilterConfig;
  public totalsData$: Observable<PipelineTotals[]>;
  public pipelineStatuses: any[];
  public projectId: string;
  public isProjectValueType: boolean;

  private _apiOptions: ListOptions;

  constructor(
    private tableService: TableService,
    private projectApiService: ProjectApiService,
    private translateService: TranslateService,
    private projectStateService: ProjectStateService,
    private authService: AuthService,
    private printService: PrintService,
    private toastService: ToastService,
    private ngxPermissionService: NgxPermissionsService,
    private storageService: LocalStorageService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.totalsData$ = this.projectStateService.getProject().pipe(
      filter(Boolean),
      distinctUntilChanged((first, second) => {
        return first.id === second.id;
      }),
      tap((project) => {
        this.isProjectValueType = project.projectType?.value === ProjectType.value;
        this.infoTableColumns = this.getTableColumns();
        this.projectId = project.id;
        this.dynamicFilters = this.getFilterConfig(project);
      }),
      switchMap((project) => {
        return this.tableService.getUpdateTable().pipe(
          skip(1),
          map((options) => {
            return {
              project,
              ...options,
            };
          })
        );
      }),
      tap(({ filters, sort, page, perPage }) => {
        this._apiOptions = { filters, sort, page, perPage };
        this.storageService.set('apiOptions-project-pipeline', this._apiOptions);
      }),
      switchMap(({ project, filters }) => {
        return this.projectApiService.getPipelineTotals(project.id, { parameters: filters });
      }),
      tap((res) => {
        const numberFields = ['delivered', 'potential', 'invoice'];

        this.pipelineStatuses = res.statuses.map((status) => {
          numberFields.forEach((field) => {
            if (field in status) {
              status[field] = this.isProjectValueType
                ? formatCurrency(status[field] as number, 'nl', '€', 'EUR', '0.0-0')
                : formatNumber(status[field] as number, 'nl', '0.0-0');
            }
          });

          return status;
        });
      }),
      map((res) => {
        return res.totals.map((totals) => {
          return {
            ...totals,
            average_amount: this.isProjectValueType
              ? formatCurrency(totals.average_amount as number, 'nl', '€', 'EUR', '0.0-0')
              : formatNumber(totals.average_amount as number, 'nl', '0.0-2'),
            total_amount: this.isProjectValueType
              ? formatCurrency(totals.total_amount as number, 'nl', '€', 'EUR', '0.0-0')
              : formatNumber(totals.total_amount as number, 'nl', '0.0-0'),
          };
        });
      }),
      shareReplay(1)
    );
  }

  public handleFilterChange(filters: ActiveFilters[]): void {
    this.tableService.updateTableByFilters(filters);
  }

  public exportPipeline(): void {
    this.printService.setPrintBusy(true);

    this.toastService.info(
      this.translateService.instant('market_discovery.pipeline.export'),
      this.translateService.instant('market_discovery.pipeline.export.started')
    );
    this.projectApiService
      .exportPipeline(this.projectId, { filters: this._apiOptions.filters })
      .subscribe((response) => {
        if (response?.finished) {
          this.printService.setPrintBusy(false);
        }
      });
  }

  private getTableColumns(): InfoTableColumn[] {
    return [
      {
        field: 'title',
        title: this.translateService.instant('market_discovery.pipeline.totals.label'),
      },
      {
        field: 'number_of_leads',
        title: this.translateService.instant('market_discovery.pipeline.leads_number.label'),
        titleClass: 'text-right',
      },
      {
        field: 'average_amount',
        title: this.translateService.instant(
          `market_discovery.pipeline.average_amount.${this.getProjectTypeTranslation()}.label`
        ),
        titleClass: 'text-right',
      },
      {
        field: 'total_amount',
        title: this.translateService.instant(
          `market_discovery.pipeline.total_amount.${this.getProjectTypeTranslation()}.label`
        ),
        titleClass: 'text-right',
      },
    ];
  }

  private getFilterConfig(project: Project): DynamicFilterConfig {
    let filters: FilterConfigItem[] = [];

    if (this.ngxPermissionService.getPermission('project.pipeline.filter-user')) {
      filters = [
        {
          field: 'user_ids',
          type: DynamicFilterType.multiselect,
          showLabel: true,
          icon: 'fas fa-user',
          placeholder: this.translateService.instant('market_discovery.roi.user_id.label'),
          label: this.translateService.instant('market_discovery.roi.user_id.label'),
          matchMode: FilterMatchMode.IN,
          options: this.projectApiService.getAccountManagersList(project.id),
        },
      ];
    }

    return {
      filters: [
        ...filters,
        {
          field: 'start_at',
          type: DynamicFilterType.date,
          icon: 'fas fa-calendar',
          showLabel: true,
          minDate: new Date(project.started_at || project.created_at),
          maxDate: new Date(),
          label: this.translateService.instant('market_discovery.pipeline.filter.starts_at.label'),
          placeholder: this.translateService.instant('market_discovery.pipeline.filter.starts_at.label'),
          matchMode: FilterMatchMode.EQUALS,
          transformValue: (value) => {
            if (value) {
              return formatDate(new Date(value), 'yyyy-MM-dd', 'en');
            }

            return null;
          },
        },
        {
          field: 'end_at',
          type: DynamicFilterType.date,
          icon: 'fas fa-calendar',
          showLabel: true,
          minDate: new Date(project.started_at || project.created_at),
          maxDate: new Date(),
          label: this.translateService.instant('market_discovery.pipeline.filter.ends_at.label'),
          placeholder: this.translateService.instant('market_discovery.pipeline.filter.ends_at.label'),
          matchMode: FilterMatchMode.EQUALS,
          transformValue: (value) => {
            if (value) {
              return formatDate(new Date(value), 'yyyy-MM-dd', 'en');
            }

            return null;
          },
        },
        {
          field: 'source_ids',
          type: DynamicFilterType.multiselect,
          icon: 'fas fa-database',
          showLabel: true,
          maxSelectedLabels: 2,
          label: this.translateService.instant('market_discovery.pipeline.filter.source.label'),
          placeholder: this.translateService.instant('market_discovery.pipeline.filter.source.label'),
          matchMode: FilterMatchMode.IN,
          options: this.projectApiService.listSources(project.id),
          selectedItemsLabel: '{0} items',
        },
      ],
    };
  }

  private getProjectTypeTranslation(): string {
    return this.isProjectValueType ? 'value' : 'number';
  }
}
