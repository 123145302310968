<div class="password-fields" [formGroup]="passwordFormGroup">
  <cap-password [toggleMask]="true" formControlName="password"></cap-password>

  <div class="validation-message">
    <cbp-validation-message [field]="field"></cbp-validation-message>
  </div>

  <label>
    {{ 'market_discovery.profile.confirm-password.label' | translate }}
  </label>

  <cap-password [toggleMask]="true" formControlName="confirm_password"></cap-password>
</div>

<div class="generate-password">
  <cap-button
    styleClass="secondary"
    icon="fas fa-key-skeleton"
    [label]="'market_discovery.profile.generate-password.button' | translate"
    (onClick)="generatePassword()"></cap-button>
</div>
