import { Injectable } from '@angular/core';
import { ApiService } from '@capturum/api';
import { Contact } from '@core/interfaces/contact.interface';

@Injectable({
  providedIn: 'root',
})
export class ContactApiService extends ApiService<Contact> {
  protected endpoint = 'contact';
}
