import { InputTypeBuilder } from '@capturum/builders/form-renderer';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { InputConfiguration } from '@capturum/builders/core';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CallbackRepeatInputBuilder extends InputTypeBuilder {
  protected appendOptions(input: FormlyFieldConfig, configuration: InputConfiguration): void {
    super.appendOptions(input, configuration);

    input.wrappers = ['no-label'];
  }
}
