import { PrintService } from '@shared/services/print.service';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent implements OnInit {
  @Input() public title: string;
  @Input() public superscript: string;
  @Input() public backRoute: string;
  @Input() public set showPrintButton(value: boolean) {
    this.printService.setPrintButtonVisible(value);
  }

  @Input() public keepQueryParams = false;

  @Output()
  public handlePrintCommand: EventEmitter<void> = new EventEmitter();

  constructor(private printService: PrintService) {
    this.printService.setPrintButtonVisible(false);
  }

  public ngOnInit(): void {
    this.printService.handlePrintToPdf
      .asObservable()
      .pipe(
        takeWhile(() => {
          return this.printService.printButtonVisibleSubject.getValue() === true;
        })
      )
      .subscribe(() => {
        return this.handlePrintCommand.emit();
      });
  }
}
