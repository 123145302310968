<app-dialog-layout class="mutation-dialog">
  <ng-container content>
    <form *ngIf="formGroup" [formGroup]="formGroup">
      <ng-container *ngIf="mutation" [ngSwitch]="mutation?.type?.value">
        <ng-container *ngSwitchCase="mutationType.companyName">
          <label for="company_name"></label>

          <cap-input
            [label]="'market_discovery.mutation.company_name.label' | translate"
            formControlName="company_name">
          </cap-input>
        </ng-container>

        <ng-container *ngSwitchCase="mutationType.newAddress">
          <cpb-form-renderer
            formKey="form_lead_address"
            [showHeader]="false"
            [defaultValue]="mutation.newAddress"></cpb-form-renderer>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="contactMutationTypes.includes(mutation?.type?.value); else addressForm">
            <cpb-form-renderer
              formKey="form_lead_contact"
              [showHeader]="false"
              [context]="{ isEdit: true }"
              [defaultValue]="mutation.newContact">
            </cpb-form-renderer>
          </ng-container>

          <ng-template #addressForm>
            <app-address-input-type #addressInputType [hideOtherOption]="true"></app-address-input-type>
          </ng-template>
        </ng-container>
      </ng-container>
    </form>
  </ng-container>

  <ng-container buttons>
    <cap-button styleClass="secondary" icon="fas fa-times" [label]="'button.cancel' | translate" (click)="cancel()">
    </cap-button>

    <cap-button styleClass="primary" icon="fas fa-save" [label]="'button.submit' | translate" (click)="submit()">
    </cap-button>
  </ng-container>
</app-dialog-layout>
