import { ProjectLeadMetaKeys } from './../../../meta-key/interfaces/meta-key.interface';
import { ProjectModule } from './../../project.module';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LayoutModule } from '@shared/layout/layout.module';
import { Observable } from 'rxjs';
import { LeadApiService } from '@features/lead/services/lead-api.service';
import { CapturumCardModule } from '@capturum/ui/card';
import { CapturumSharedModule } from '@capturum/ui/api';

@Component({
  selector: 'app-project-lead-selection-dialog',
  templateUrl: './project-lead-selection-dialog.component.html',
  styleUrls: ['./project-lead-selection-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    CapturumFormRendererModule,
    CapturumButtonModule,
    TranslateModule,
    LayoutModule,
    ProjectModule,
    CapturumCardModule,
    CapturumSharedModule,
  ],
})
export class ProjectLeadSelectionDialogComponent implements OnInit {
  public formKey = 'form_lead_selection_details';
  public modelId: string;
  public leadMetaKeys$: Observable<ProjectLeadMetaKeys[]>;

  constructor(private dialogConfig: DynamicDialogConfig, private leadApiService: LeadApiService) {
    this.modelId = this.dialogConfig.data?.item.id;
  }

  public ngOnInit(): void {
    this.leadMetaKeys$ = this.leadApiService.getProjectLeadMetaKeys(this.modelId, { include: ['metaValues,type'] });
  }
}
