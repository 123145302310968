<div class="callback-repeat">
  <div *ngFor="let fieldGroupField of field.fieldGroup; let index = index" class="option-form d-flex">
    <div class="flex-grow-1">
      <formly-field [field]="fieldGroupField"></formly-field>
    </div>

    <div class="remove-btn">
      <i class="fas fa-trash-can" (click)="remove(index)"></i>
    </div>
  </div>

  <div class="repeat-add-button">
    <cap-button
      icon="fas fa-plus"
      styleClass="primary"
      [label]="'market_discovery.meta-key.add-option' | translate"
      (onClick)="add()">
    </cap-button>
  </div>
</div>
