export enum QuestionType {
  OPEN_QUESTION = 'open_question',
  YES_NO = 'yes_no',
  OPEN_QUESTION_LARGE_TEXTBOX = 'open_question_large_textbox',
  MULTIPLE_CHOICE_SINGLE_ANSWER = 'multiple_choice_single_answer',
  MULTIPLE_CHOICE_MULTIPLE_ANSWER = 'multiple_choice_multiple_answer',
  DROPDOWN_SINGLE_ANSWER = 'dropdown_single_answer',
  DROPDOWN_MULTIPLE_ANSWER = 'dropdown_multiple_answer',
  EMPTY = 'empty',
  VISITING_ADDRESS = 'visiting_address',
  MAILING_ADDRESS = 'mailing_address',
  CONTACT = 'contact',
  DMUS = 'dmus',
  COMPANY_NAME = 'company_name',
}
