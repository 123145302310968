import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { QuestionnaireDetailComponent } from './pages/questionnaire-detail/questionnaire-detail.component';
import { QuestionnaireListComponent } from './pages/questionnaire-list/questionnaire-list.component';

const routes: Routes = [
  {
    path: '',
    component: QuestionnaireListComponent,
  },
  {
    path: 'add',
    component: QuestionnaireDetailComponent,
  },
  {
    path: ':id',
    component: QuestionnaireDetailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuestionnaireRoutingModule {}
