import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Observable, startWith } from 'rxjs';
import { FileApiService } from '../../../../features/file/services/file-api.service';
import { ImportFile } from '../../../../features/project/interfaces/import-file.interface';

@Component({
  selector: 'app-downloadablefile-type',
  templateUrl: './downloadablefile-type.component.html',
  styleUrls: ['./downloadablefile-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DownloadableFileTypeComponent extends FieldType implements OnInit {
  public file$: Observable<ImportFile>;

  constructor(public fileService: FileApiService) {
    super();
  }

  public ngOnInit(): void {
    this.file$ = this.formControl.valueChanges.pipe(startWith(this.formControl?.value));
  }

  public downloadFile(fileId: string, fileName: string): void {
    this.fileService.download(fileId, fileName);
  }
}
