<div class="grip-meter-section detail" *ngIf="leadAccountManager">
  <div class="detail-header">
    <span class="title" [innerHtml]="'market_discovery.project.menu.grip_meter' | translate"> </span>

    <div class="actions">
      <button
        pButton
        *ngxPermissionsOnly="'project.grip-meter.manage'"
        class="cap-button round secondary grip-meter-button"
        (click)="openGripMeterModal()"
        [innerHtml]="'market_discovery.project.menu.grip_meter' | translate"></button>
    </div>
  </div>

  <div class="detail-content">
    {{ 'market_discovery.lead.grip-meter.grip-on-this-lead.label' | translate }}
    {{ leadAccountManager?.gripStatus * 100 || 0 }}%
  </div>
</div>
