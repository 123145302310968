import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormSaverService } from '@capturum/builders/form-renderer';
import { ActivatedRoute, Data } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manage-detail',
  templateUrl: './manage-detail.component.html',
  styleUrls: ['./manage-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageDetailComponent implements OnInit {
  public formKey: string;
  public modelId: string;
  public entity: string;
  public titleTranslationKey: string;

  constructor(
    protected formSaverService: FormSaverService,
    protected route: ActivatedRoute,
    protected toastService: ToastService,
    protected translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    const routeData: Data = this.route.snapshot.data || this.route.parent.snapshot.data;

    this.entity = routeData.entity;
    this.modelId = this.route.snapshot.paramMap.get(routeData.identifier || 'id');
    this.formKey = this.getFormKey(this.entity);
    this.titleTranslationKey = this.getTitleTranslationKey();
  }

  public submit(): void {
    this.formSaverService
      .submit(this.formKey)
      .pipe(first())
      .subscribe(() => {
        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant(`market_discovery.entity.toast.${this.modelId ? 'updated' : 'created'}`, {
            entity: this.getEntityTranslation(),
          })
        );
      });
  }

  public cancel(): void {
    this.formSaverService.cancel(this.formKey);
  }

  protected getFormKey(entity: string): string {
    return `form_${entity}`;
  }

  protected getTitleTranslationKey(): string {
    if (this.modelId) {
      return `market_discovery.form.edit.title`;
    } else {
      return `market_discovery.form.add.title`;
    }
  }

  protected getEntityTranslation(): string {
    return this.translateService.instant(`market_discovery.${this.entity}.entity.name`);
  }
}
