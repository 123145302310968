import { ActionOptions, ActionProvider } from '@capturum/builders/core';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { Injectable } from '@angular/core';
import { Project } from '@features/project/interfaces/project.interface';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enums/routes.enum';

@Injectable({ providedIn: 'root' })
export class NavigateProjectDetailAction implements ActionProvider {
  constructor(private projectStateService: ProjectStateService, private router: Router) {}

  public execute(options: ActionOptions, item: Project, context?: Record<string, any>): void {
    this.projectStateService.setProject(item);
    this.router.navigate([`${AppRoutes.project}/${item.id}/xyz`]);
  }
}
