<app-dialog-layout>
  <ng-container content>
    <cpb-form-renderer
      formKey="form_action"
      [showHeader]="false"
      [context]="context"
      [modelId]="actionId"
      [defaultValue]="defaultValue"
      [readOnly]="isReadonly">
    </cpb-form-renderer>
  </ng-container>

  <ng-container buttons *ngIf="!isReadonly">
    <cap-button
      styleClass="secondary"
      [label]="'button.cancel' | translate"
      icon="fas fa-times"
      (onClick)="cancel()"></cap-button>
    <cap-button
      styleClass="primary"
      [label]="'button.submit' | translate"
      icon="fas fa-save"
      (onClick)="submit()"></cap-button>
  </ng-container>
</app-dialog-layout>
