import { TranslateService } from '@ngx-translate/core';
import { Questionnaire } from '@features/questionnaire/interfaces/questionnaire.interface';
import { QuestionnaireApiService } from '@features/questionnaire/services/questionnaire-api.service';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ListOptions } from '@capturum/api';
import { CapturumDialogService, ToastService } from '@capturum/ui/api';
import { DialogActionType } from '@core/enums/dialog-action-type.enum';
import { Lead } from '@features/lead/interfaces/lead.interface';
import { Project } from '@features/project/interfaces/project.interface';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { QuestionnaireStepsDialogComponent } from '@features/questionnaire/components/questionnaire-steps-dialog/questionnaire-steps-dialog.component';
import { BehaviorSubject, filter, first, map, Observable, switchMap, take, tap } from 'rxjs';
import { ProjectApiService } from 'src/app/features/project/services/project-api.service';
import { LeadApiService } from '../../lead/services/lead-api.service';
import { AppRoutes } from '@core/enums/routes.enum';
import { Router } from '@angular/router';
import { Contact } from '@core/interfaces/contact.interface';
import { Address } from '@core/interfaces/address.interface';
import { QuestionType } from '../../questionnaire/enums/question-type.enum';
import { Company } from '../../project/interfaces/company.interface';
import { Question, QuestionAnswer } from '../../questionnaire/interfaces/questionnaire.interface';

@Component({
  selector: 'app-agent-questionnaire',
  templateUrl: './agent-questionnaire.component.html',
  styleUrls: ['./agent-questionnaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentQuestionnaireComponent implements OnInit, OnDestroy {
  public lead$: Observable<Lead>;
  public apiOptions: ListOptions = {
    include: [
      'company',
      'mainCompanyAddress',
      'mainMailAddress',
      'status',
      'contacts',
      'mailAddresses',
      'companyAddresses',
      'leadCallbacks',
      'project.customer',
      'addresses',
      'dmus',
      'mainContact',
      'questionnaireUser.answers.contacts',
      'questionnaireUser.questionnaire.questions.type',
      'questionnaireUser.answers.address.type',
    ],
  };

  public noLeadsResult = false;
  public _lead: Lead;
  public project: Project;
  public updateLead = new BehaviorSubject<boolean>(true);

  constructor(
    private projectStateService: ProjectStateService,
    private projectApiService: ProjectApiService,
    private dialogService: CapturumDialogService,
    private questionnaireApiService: QuestionnaireApiService,
    private translateService: TranslateService,
    private leadService: LeadApiService,
    private router: Router,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.lead$ = this.projectStateService.getProject().pipe(
      filter(Boolean),
      first(),
      switchMap((project) => {
        return this.updateLead.asObservable().pipe(
          map(() => {
            return project;
          })
        );
      }),
      switchMap((project: Project) => {
        return this.projectApiService.getQuestionnaireAvailableLead(project.id, this.apiOptions).pipe(
          tap((result) => {
            this.noLeadsResult = !result;

            if (result) {
              this._lead = result;
            }
          })
        );
      }),
      map((lead: Lead) => {
        if (lead) {
          const addresses = this.getAddressesForLead(lead);

          const questions = lead.questionnaireUser
            .filter((questionniare) => {
              return (
                questionniare.questionnaire_id === this.project.dmu_questionnaire_id ||
                questionniare.questionnaire_id === this.project.questionnaire_id
              );
            })
            .reduce((acc, questionnaireUser) => {
              return [...acc, ...questionnaireUser.questionnaire.questions];
            }, []);

          const answers = lead.questionnaireUser
            .filter((questionniare) => {
              return (
                questionniare.questionnaire_id === this.project.dmu_questionnaire_id ||
                questionniare.questionnaire_id === this.project.questionnaire_id
              );
            })
            .reduce((acc, questionnaireUser) => {
              return [...acc, ...questionnaireUser.answers];
            }, []);

          return {
            ...lead,
            contacts: this.getContactsForLead(lead, questions, answers),
            company: this.getNewCompany(lead, questions, answers),
            ...addresses,
          };
        }

        return lead;
      })
    );
    this.project = this.projectStateService.getProjectSnapshot();
  }

  public ngOnDestroy(): void {
    if (!this.leadService.dequeueIsDone$.getValue()) {
      this.leadService.dequeue().pipe(take(1)).subscribe();
    } else {
      this.leadService.dequeueIsDone$.next(false);
    }
  }

  public startQuestionnaire(): void {
    const projectId = this.project.id;

    this.questionnaireApiService
      .getAgentQuestionnaire(
        projectId,
        { lead_id: this._lead?.id },
        {
          include: ['questionnaireUsers.answers.answerOptions'],
        }
      )
      .subscribe((questionnaires) => {
        if (questionnaires.dmu_questionnaire || questionnaires.questionnaire) {
          this.openQuestionnaireDialog(questionnaires);
        } else {
          this.toastService.error(
            this.translateService.instant('toast.error.title'),
            this.translateService.instant('market_discovery.agent-questionnaire.no-questionnaire-available')
          );
        }
      });
  }

  public getContactsForLead(lead: Lead, questions, answers): Contact[] {
    return [
      ...(this.leadService.getNewDmus(lead, questions, answers) || []),
      ...(this.leadService.getNewContact(lead, questions, answers) || []),
    ].filter((contact, index, contacts) => {
      if (!contact.id) {
        return true;
      }

      return (
        index ===
        contacts.findIndex((item) => {
          return contact.id === item.id;
        })
      );
    });
  }

  private openQuestionnaireDialog(questionnaires: {
    questionnaire: Questionnaire;
    dmu_questionnaire: Questionnaire;
  }): void {
    const firstQuestionnaire = questionnaires.dmu_questionnaire ?? questionnaires.questionnaire;
    const isDmuQuestionnaire = this.project.dmu_questionnaire_id === firstQuestionnaire?.id;
    const header = isDmuQuestionnaire
      ? `${this.translateService.instant('market_discovery.agent-questionnaire.lead.naw_dmu.title')} - ${
          firstQuestionnaire.name
        }`
      : firstQuestionnaire.name;

    const answers = [
      ...(questionnaires.dmu_questionnaire?.questionnaire_users?.[0]?.answers || []),
      ...(questionnaires.questionnaire?.questionnaire_users?.[0]?.answers || []),
    ];

    this.dialogService
      .open(QuestionnaireStepsDialogComponent, {
        header,
        styleClass: 'questionnaire-steps-dialog',
        closable: false,
        data: {
          questionnaires,
          lead: this._lead,
          answers: answers,
        },
      })
      .onClose.pipe(
        first(),
        filter((value) => {
          return value?.action === DialogActionType.submit;
        })
      )
      .subscribe(({ navigateToDashboard }) => {
        if (navigateToDashboard) {
          this.router.navigate([AppRoutes.fieldwork]);
        } else {
          this.updateLead.next(true);
        }
      });
  }

  private getNewCompany(lead: Lead, questions: Question[], answers: QuestionAnswer[]): Company {
    const companyQuestion = questions.find((question: Question) => {
      return question.type.value === QuestionType.COMPANY_NAME;
    });

    if (companyQuestion) {
      const answer = answers.find((answer: QuestionAnswer) => {
        return answer.question_id === companyQuestion.id;
      });

      if (answer?.answer_text) {
        return {
          ...lead.company,
          name: answer.answer_text,
          isUpdated: lead.company.name !== answer.answer_text,
          questionId: companyQuestion.id,
        };
      }

      return {
        ...lead.company,
      };
    }

    return {
      ...lead.company,
    };
  }

  private getAddressesForLead(lead: Lead): { mainMailAddress: Address; mainCompanyAddress: Address } {
    const addresses = {
      mainCompanyAddress: lead.mainCompanyAddress,
      mainMailAddress: lead.mainMailAddress,
    };

    for (const questionnaireUser of lead.questionnaireUser) {
      for (const answer of questionnaireUser.answers) {
        if (answer?.address?.type.value === 'company') {
          addresses.mainCompanyAddress = { ...answer.address, isUpdated: true };
        } else if (answer.address?.type.value === 'mail') {
          addresses.mainMailAddress = { ...answer.address, isUpdated: true };
        }
      }
    }

    return addresses;
  }
}
