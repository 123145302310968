<nav class="navbar navbar-static-top header app-header d-flex" role="navigation">
  <div class="container container-header" id="headerContainer">
    <div class="row no-gutters align-items-center w-100">
      <div class="col d-flex justify-content-start">
        <div class="logo">
          <img [src]="'./assets/images/logo.png'" [routerLink]="homePageUrl" alt="logo" class="align-self-center" />

          <div class="title-wrapper">
            <span class="title" [routerLink]="homePageUrl">
              {{ title }}
              <sup>®</sup>
              <span *ngIf="environmentName"> &nbsp;-&nbsp;{{ environmentName }} </span>
            </span>

            <ng-container *ngIf="options$ | async as options">
              <ng-container *ngIf="options.length > 1">
                <div class="project-selector" *ngxPermissionsExcept="['project.create']">
                  <cap-dropdown
                    [ngModel]="(project$ | async)?.id"
                    (change)="handleProjectChange($event)"
                    [options]="options"
                    [filter]="true"
                    [disabled]="projectSelectorDisabled$ | async"
                    [autofocusFilter]="false"
                    [placeholder]="'market_discovery.project.list.title' | translate"
                    [filterPlaceholder]="'search.placeholder' | translate">
                  </cap-dropdown>
                </div>
              </ng-container>

              <div class="project-name" *ngIf="hasProjectCreatePermission || options.length === 1">
                {{ (project$ | async)?.name }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col d-flex justify-content-end menu">
        <app-user-menu></app-user-menu>
      </div>
    </div>
  </div>

  <div class="container-fluid p-0">
    <div class="row no-gutters align-items-center w-100">
      <div class="col">
        <app-navigation-bar></app-navigation-bar>
      </div>
    </div>
  </div>
</nav>
