<app-page-layout [title]="(importFileInfo$ | async)?.file?.filename" [backRoute]="backRoute">
  <div content class="row">
    <div class="col-lg-5">
      <cap-card [title]="'market_discovery.import.menu.details' | translate" styleClass="mb-4">
        <ng-template capTemplate="content">
          <cpb-form-renderer [formKey]="formKey" [showHeader]="false" [modelId]="modelId"> </cpb-form-renderer>
        </ng-template>
      </cap-card>
    </div>

    <div class="col-lg-7">
      <cap-card [title]="'market_discovery.import.leads-imported.title' | translate">
        <ng-template capTemplate="content">
          <cpb-list-renderer key="list_import_leads" [context]="{ import_id: modelId }"></cpb-list-renderer>
        </ng-template>
      </cap-card>
    </div>
  </div>
</app-page-layout>
