import { Injectable } from '@angular/core';
import { ActionOptions, ActionProvider, NavigateAction } from '@capturum/builders/core';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { filter, first } from 'rxjs/operators';
import { AppRoutes } from '@core/enums/routes.enum';

@Injectable({ providedIn: 'root' })
export class NavigateWithinProjectAction implements ActionProvider {
  constructor(private projectStateService: ProjectStateService, private navigateAction: NavigateAction) {}

  public execute(options: ActionOptions, item: any, context?: Record<string, any>): any {
    this.projectStateService
      .getProject()
      .pipe(filter(Boolean), first())
      .subscribe((project) => {
        const alteredOptions = { ...options, route: `${AppRoutes.project}/${project.id}/${options.route}` };

        this.navigateAction.execute(alteredOptions, item, context);
      });
  }
}
