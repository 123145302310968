import { BatchStatusAction, BatchStatusActionProvider } from '@capturum/complete';
import { ApiHttpService } from '@capturum/api';
import { inject, Injectable } from '@angular/core';
import { ToastService } from '@capturum/ui/api';
import { responseData } from '@capturum/builders/core';

@Injectable({ providedIn: 'root' })
export class BackendToastAction implements BatchStatusActionProvider {
  private http = inject(ApiHttpService);
  private toast = inject(ToastService);

  public execute(action: BatchStatusAction): any {
    if (action.action) {
      this.http
        .get<{ data: { title: string; message: string } }>(action.action)
        .pipe(responseData)
        .subscribe((message) => {
          if (message.message) {
            this.toast.info(message.title, message.message, {
              sticky: true,
            });
          }
        });
    }
  }
}
