<div class="d-flex align-items-center">
  <div class="user-menu">
    <ul class="user-menu__items">
      <ng-container *ngFor="let item of menuItems">
        <li
          *ngIf="item.visible | observablePipe | async"
          [class.disabled]="item.disabled | observablePipe | async"
          (click)="executeCommand(item)">
          <div class="menu-item">
            <i [className]="item?.icon$ | observablePipe | async"></i>
          </div>

          <cap-notification-badge
            *ngIf="item.notificationBadgeOptions"
            [showBadge]="!!item.notificationBadgeOptions.showBadge"
            [options]="item.notificationBadgeOptions">
          </cap-notification-badge>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
