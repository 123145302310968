import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XyzComponent } from './components/xyz/xyz.component';
import { ChartModule } from 'primeng/chart';
import { XyzTableComponent } from './components/xyz-table/xyz-table.component';
import { TableModule } from 'primeng/table';
import { SharedModule } from '@shared/shared.module';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [XyzComponent, XyzTableComponent],
  imports: [CommonModule, ChartModule, TableModule, SharedModule, TooltipModule],
  exports: [XyzComponent],
})
export class XyzModule {}
