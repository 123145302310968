import { DialogActionType } from '@core/enums/dialog-action-type.enum';
import { Component, ViewEncapsulation } from '@angular/core';
import { FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs';

@Component({
  selector: 'app-lead-contact-dialog',
  templateUrl: './lead-contact-dialog.component.html',
  styleUrls: ['./lead-contact-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LeadContactDialogComponent {
  public formKey: string;
  public modelId: string;
  public context: Record<string, any>;
  public itemId: string;

  constructor(
    public dialogRef: DynamicDialogRef,
    private formSaverService: FormSaverService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private config: DynamicDialogConfig,
    private formRendererService: FormRendererService
  ) {
    this.context = {
      isEdit: true,
    };

    if (this.config?.data?.leadId) {
      this.context.lead_id = this.config?.data?.leadId;
    } else if (this.config?.data?.companyId) {
      this.context.company_id = this.config?.data?.companyId;
    }

    this.itemId = this.config?.data?.itemId;
    this.formKey = this.config?.data?.formKey;
  }

  public handleSave(): void {
    if (this.config?.data?.leadId || this.config?.data?.companyId) {
      this.formSaverService
        .submit(this.formKey)
        .pipe(first())
        .subscribe(({ response }) => {
          this.toastService.success(
            this.translateService.instant('toast.success.title'),
            this.translateService.instant(`market_discovery.entity.toast.${this.itemId ? 'updated' : 'created'}`, {
              entity: 'Contact',
            })
          );

          this.dialogRef.close({ action: DialogActionType.submit, data: response?.data });
        });
    } else {
      this.formRendererService
        .getFormValueByKey(this.formKey)
        .pipe(first())
        .subscribe((value) => {
          this.dialogRef.close({ action: DialogActionType.submit, data: value });
        });
    }
  }
}
