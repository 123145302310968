import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttpService, ApiService } from '@capturum/api';
import { map } from 'rxjs/operators';
import { Mutation } from '../interfaces/mutation.interface';

@Injectable({
  providedIn: 'root',
})
export class MutationApiService extends ApiService<Mutation> {
  protected endpoint = 'mutation';

  constructor(apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public getOpenMutationsCount(): Observable<number> {
    return this.apiHttp.get(`/${this.endpoint}/open/count`).pipe(
      map((response: { open_mutations_count: number }) => {
        return response.open_mutations_count;
      })
    );
  }

  public accept(mutationId: string, applyForCompany = false): Observable<void> {
    return this.apiHttp.put(`/${this.endpoint}/${mutationId}/accept`, { apply_for_company: applyForCompany });
  }

  public decline(mutationId: string): Observable<void> {
    return this.apiHttp.put(`/${this.endpoint}/${mutationId}/decline`, null);
  }
}
