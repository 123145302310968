import { Pipe, PipeTransform } from '@angular/core';
import { Lead } from '@features/lead/interfaces/lead.interface';
import { AuthService } from '@capturum/auth';
import { Question, QuestionAnswer } from '../interfaces/questionnaire.interface';
import { LeadApiService } from '@features/lead/services/lead-api.service';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'replaceQuestionTagsPipe',
})
export class ReplaceQuestionTagsPipe implements PipeTransform {
  constructor(
    private authService: AuthService,
    private leadApiService: LeadApiService,
    private translateService: TranslateService
  ) {}

  public transform(
    question: string,
    lead: Lead,
    questions: Question[],
    answers: QuestionAnswer[],
    questionnaireAnswers: Map<string, QuestionAnswer>,
    defaultString = '...'
  ): string {
    const answeredQuestions = [];

    questionnaireAnswers.forEach((answer, key) => {
      answeredQuestions.push({
        question_id: key,
        ...answer,
      });
    });

    let contactName: string;

    const updatedContact = this.leadApiService
      .getNewContact(lead, questions, [...(answers || []), ...answeredQuestions])
      .find((contact) => {
        return contact.isUpdated === true;
      });

    if (updatedContact) {
      const contactSalutation = this.translateService.instant(
        `base-data.${updatedContact.salutation_base_data_value_id}`
      );

      contactName =
        updatedContact.full_name || `${contactSalutation} ${updatedContact.initials} ${updatedContact.last_name}`;
    }

    return question
      .replace(/{{USER_NAME}}/g, this.authService.getUser()?.name || defaultString)
      .replace(/{{COMPANY_NAME}}/g, lead?.project?.name || defaultString)
      .replace(/{{CUSTOMER_COMPANY_NAME}}/g, lead?.company?.name || defaultString)
      .replace(
        /{{CONTACT_NAME}}/g,
        contactName || lead.mainContact?.full_name || lead.contacts?.[0]?.full_name || defaultString
      );
  }
}
