import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Address } from '@core/interfaces/address.interface';
import { from, Observable, switchMap, map, startWith, filter } from 'rxjs';
import { BaseDataIndexedDbModel } from '@capturum/complete';

@Component({
  selector: 'app-addresses-type',
  templateUrl: './addresses-type.component.html',
  styleUrls: ['./addresses-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressesTypeComponent extends FieldType implements OnInit {
  public addresses$: Observable<Address[]>;

  public ngOnInit(): void {
    this.addresses$ = from(BaseDataIndexedDbModel.query().where({ key: 'address_type' }).first())
      .pipe(
        map((baseData) => {
          return baseData.values;
        })
      )
      .pipe(
        switchMap((addressTypes) => {
          return this.formControl.valueChanges.pipe(
            startWith(this.formControl.value),
            filter(Boolean),
            map((addresses: Address[]) => {
              return addresses
                .map((address) => {
                  return {
                    ...address,
                    type: addressTypes.find((addressType) => {
                      return addressType.id === address.type_base_data_value_id;
                    }),
                  };
                })
                .sort((prevAddress, nextAddress) => {
                  const addressType = addressTypes.find((type) => {
                    return type.id === prevAddress.type_base_data_value_id;
                  }).value;

                  if (addressType === 'company') {
                    return -1;
                  } else {
                    return 1;
                  }
                });
            })
          );
        })
      );
  }
}
