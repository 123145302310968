<div class="change-leads-source">
  <app-dialog-layout>
    <ng-container content>
      <div class="change-leads-source-content">
        <p class="description">{{ 'market_discovery.project.change_leads_source.label' | translate }}</p>

        <ul class="change-leads-source-list">
          <li *ngFor="let lead of leads">{{ lead?.name }}</li>
        </ul>

        <div class="dropdown">
          <cap-dropdown
            [options]="sources$ | async"
            #dropdown
            [filter]="true"
            [autofocusFilter]="false"
            [appendTo]="'body'"
            [placeholder]="'market_discovery.project.sources.label' | translate"
            [filterPlaceholder]="'search.placeholder' | translate"
            (change)="handleChangeSelectOption($event)">
          </cap-dropdown>
        </div>
      </div>
    </ng-container>

    <ng-container buttons>
      <cap-button
        icon="fal fa-times"
        styleClass="secondary"
        [label]="'button.cancel' | translate"
        (onClick)="dialogRef.close()">
      </cap-button>

      <cap-button
        styleClass="primary"
        icon="fas fa-floppy-disk"
        [label]="'market_discovery.button.save' | translate"
        [disabled]="!dropdown.value"
        (onClick)="handleSave()">
      </cap-button>
    </ng-container>
  </app-dialog-layout>
</div>
