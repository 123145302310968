import { Injectable } from '@angular/core';
import { BehaviorSubject, mergeMap, Observable, timer, combineLatest, Subscription } from 'rxjs';
import { MutationApiService } from '@features/mutation/services/mutation-api.service';
import { MenuItem } from '@core/interfaces/menu-item.interface';
import { ProjectApiService } from '@features/project/services/project-api.service';
import { NotificationBadgeOptions } from '@capturum/ui/api';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public openMutationsTimer: Subscription;
  private openMutationsCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public defaultBadgeOptions = {
    badgeSize: '1.2rem',
    backgroundColor: '#fa4331',
    fontColor: '#fff',
    fontSize: '0.6rem',
    showBorder: false,
  };

  public actionRequirednBadgeOptionsSubject = new BehaviorSubject<Partial<NotificationBadgeOptions>>(
    this.defaultBadgeOptions
  );

  private readonly intervalSeconds = 10;
  private refreshOpenMutationCounterSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(private mutationApiService: MutationApiService, private projectApiService: ProjectApiService) {}

  public ngOnDestroy(): void {
    this.destroyOpenMutationsTimer();
  }

  public setOpenMutationsTimer(): void {
    this.openMutationsTimer = combineLatest([
      timer(0, this.intervalSeconds * 1000),
      this.refreshOpenMutationCounterSubject.asObservable(),
    ])
      .pipe(
        mergeMap(() => {
          return this.mutationApiService.getOpenMutationsCount();
        })
      )
      .subscribe({
        next: (openMutationsCount: number) => {
          this.setOpenMutationsCount(openMutationsCount);
        },
        error: () => {
          return this.destroyOpenMutationsTimer();
        },
      });
  }

  public refreshOpenMutationsCount(): void {
    this.refreshOpenMutationCounterSubject.next(true);
  }

  public setOpenMutationsCount(count = 0): void {
    this.openMutationsCount.next(count);
  }

  public getOpenMutationsCount(): Observable<number> {
    return this.openMutationsCount.asObservable();
  }

  public destroyOpenMutationsTimer(): void {
    if (this.openMutationsTimer) {
      this.openMutationsTimer.unsubscribe();
    }
  }

  public setProjectLeadsActionRequiredCount(projectId: string): void {
    this.projectApiService.getProjectActionRequiredCount(projectId).subscribe((count) => {
      this.actionRequirednBadgeOptionsSubject.next({
        ...this.actionRequirednBadgeOptionsSubject.getValue(),
        label: count > 99 ? '99+' : count.toString(),
        showBadge: count > 0,
      });
    });
  }

  public getProjectLeadsActionRequiredBadgeOptions(): Observable<Partial<NotificationBadgeOptions>> {
    return this.actionRequirednBadgeOptionsSubject.asObservable();
  }

  public updateOpenMutationsItemsBadge(items: MenuItem[], key: string, label: string): void {
    const menuItemIndex = items.findIndex((item) => {
      return item[key] === label;
    });

    if (menuItemIndex > -1) {
      const updatedMenuItem = {
        ...items[menuItemIndex],
        notificationBadgeOptions: {
          ...items[menuItemIndex].notificationBadgeOptions,
          label: this.openMutationsCount.value > 99 ? '99+' : this.openMutationsCount.value.toString(),
          showBadge: this.openMutationsCount.value > 0,
        },
      };

      items.splice(menuItemIndex, 1, updatedMenuItem);
    }
  }
}
