<div class="row">
  <div class="col-lg-7" #xyzTable>
    <div class="card-background">
      <app-xyz-table [xyz]="xyz?.tableRows"></app-xyz-table>
    </div>
  </div>

  <div class="col-lg-5">
    <div class="card-background graph">
      <p-chart type="line" [data]="graph" [options]="options" [height]="chartHeight"></p-chart>
    </div>
  </div>
</div>
