<app-dialog-layout>
  <ng-container content>
    <cpb-form-renderer [formKey]="formKey" [showHeader]="false" [defaultValue]="defaultValue"></cpb-form-renderer>
  </ng-container>

  <ng-container buttons>
    <cap-button
      icon="fal fa-times"
      styleClass="secondary"
      [label]="'button.cancel' | translate"
      (onClick)="cancel()">
    </cap-button>

    <cap-button
      styleClass="primary"
      icon="fas fa-floppy-disk"
      [label]="'market_discovery.button.save' | translate"
      (onClick)="submit()">
    </cap-button>
  </ng-container>
</app-dialog-layout>
