import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LeadAction } from '@features/project/interfaces/lead-action.interface';

@Component({
  selector: 'app-lead-action-item',
  templateUrl: './lead-action-item.component.html',
  styleUrls: ['./lead-action-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadActionItemComponent {
  @Input()
  public action: LeadAction;

  @Output()
  public edit = new EventEmitter<LeadAction>();

  public editAction(action: LeadAction): void {
    this.edit.emit(action);
  }
}
