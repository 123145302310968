import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DialogActionType } from '@core/enums/dialog-action-type.enum';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { GripMeterInputEnum } from '@features/project/enums/grip-meter-input.enum';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DestroyBase } from '@capturum/shared';
import { LeadApiService } from '@features/lead/services/lead-api.service';
import { LeadGripMeter } from '@features/project/interfaces/grip-meter.interface';

@Component({
  selector: 'app-grip-meter-input-dialog',
  templateUrl: './grip-meter-input-dialog.component.html',
  styleUrls: ['./grip-meter-input-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GripMeterInputDialogComponent extends DestroyBase implements OnInit {
  public isFormReady = false;
  public gripMeterInputForm: UntypedFormGroup;
  public controls: GripMeterInputEnum[] = Object.values(GripMeterInputEnum);
  public gripStatuses: LeadGripMeter[];

  constructor(
    public dialogRef: DynamicDialogRef,
    private leadApiService: LeadApiService,
    private dialogConfig: DynamicDialogConfig
  ) {
    super();
  }

  public ngOnInit(): void {
    this.getGripStatuses();
  }

  public handleSave(): void {
    this.dialogRef.close({
      action: DialogActionType.submit,
      data: this.gripStatuses
        .filter((status) => {
          return !!this.gripMeterInputForm?.value?.[status?.key];
        })
        .map((status) => {
          return status?.id;
        }),
    });
  }

  private getGripStatuses(): void {
    this.leadApiService.getLeadGripInfo(this.dialogConfig.data.leadId).subscribe((gripStatuses) => {
      this.gripStatuses = gripStatuses;
      this.initForm();
    });
  }

  private initForm(): void {
    this.gripMeterInputForm = new UntypedFormGroup({});

    this.controls.forEach((key) => {
      this.gripMeterInputForm.addControl(
        key,
        new UntypedFormControl(
          this.gripStatuses.find((status) => {
            return status?.key === key;
          })?.answered
        )
      );
    });

    this.isFormReady = true;
  }
}
