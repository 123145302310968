<cap-input
  [formControl]="formControl"
  [readonly]="to.readonly"
  [hidden]="to.readonly"
  [hasTooltip]="to.tooltip"
  [selectOnFocus]="to?.select_on_focus"
  [placeholder]="to.readonly ? '' : to.placeholder">
</cap-input>

<cpb-readonly-container [hidden]="!to.readonly">
  <a (click)="navigate($event, formControl.value)" href="">{{ formControl.value }}</a>
</cpb-readonly-container>
