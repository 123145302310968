import { Pipe, PipeTransform } from '@angular/core';

const MARKER_ICON = {
  path: 'M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z',
  width: 384,
  height: 512,
};

@Pipe({
  name: 'mapMarkerIcon',
})
export class MapMarkerIconPipe implements PipeTransform {
  public transform(color: string): google.maps.Symbol {
    return {
      path: MARKER_ICON.path,
      scale: 0.05,
      fillOpacity: 1,
      strokeWeight: 2,
      strokeOpacity: 1,
      strokeColor: 'white',
      fillColor: color,
      anchor: {
        x: MARKER_ICON.width / 2,
        y: MARKER_ICON.height,
      } as google.maps.Point,
    };
  }
}
