<app-page-layout [title]="'market_discovery.questionnaire.entity.name' | translate">
  <app-page-buttons actions>
    <cap-button
      styleClass="primary"
      icon="fas fa-list-radio"
      *ngIf="!noLeadsResult"
      [label]="
        ('transport.transformers.substring.start.label' | translate) +
        ' ' +
        ('market_discovery.questionnaire.entity.name' | translate)
      "
      (onClick)="startQuestionnaire()">
    </cap-button>
  </app-page-buttons>

  <div content>
    <app-agent-questionnaire-lead-detail *ngIf="lead$ | async as lead" [lead]="lead" [isEdit]="false">
    </app-agent-questionnaire-lead-detail>
  </div>

  <div class="text-center no-result-leads" *ngIf="noLeadsResult" content>
    {{ 'market_discovery.agent-questionnaire.lead.no_results.label' | translate }}
  </div>
</app-page-layout>
