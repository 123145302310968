<app-page-layout
  [title]="titleTranslationKey | translate : { entity: ('market_discovery.' + entity + '.entity.name' | translate) }"
  [keepQueryParams]="true"
  backRoute="../">
  <app-page-buttons actions>
    <cap-button icon="fas fa-xmark" [label]="'button.cancel' | translate" (click)="cancel()" styleClass="secondary">
    </cap-button>

    <cap-button
      icon="fas fa-calendar"
      *ngIf="showAddToCalendarButton$ | async"
      [label]="'market_discovery.lead.save-and-add-to-calendar.button' | translate"
      (onClick)="submitAndAddToCalendar()"
      styleClass="primary">
    </cap-button>

    <cap-button icon="fas fa-save" [label]="'button.submit' | translate" (click)="submit()" styleClass="primary">
    </cap-button>
  </app-page-buttons>

  <div class="row" content>
    <div class="col-lg-6">
      <cap-card [title]="'market_discovery.' + entity + '.entity.name' | translate">
        <ng-template capTemplate="content">
          <cpb-form-renderer
            *ngIf="formRendererModelId"
            [formKey]="formKey"
            [showHeader]="false"
            [context]="context"
            [modelId]="formRendererModelId"
            [defaultValue]="defaultValue">
          </cpb-form-renderer>
        </ng-template>
      </cap-card>
    </div>
  </div>
</app-page-layout>
