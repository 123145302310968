import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Question,
  QuestionInputTypeComponent,
  UpdateAnswer,
} from '@features/questionnaire/interfaces/questionnaire.interface';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-question-input-type-empty',
  template: ``,
})
export class QuestionInputTypeEmptyComponent implements QuestionInputTypeComponent {
  @Input()
  public question: Question;

  @Output()
  public updateAnswer: EventEmitter<UpdateAnswer> = new EventEmitter<UpdateAnswer>();

  public answer$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public validate(): boolean {
    this.updateAnswer.emit({
      id: this.question.id,
      value: null,
    });

    return true;
  }

  public getAnswer(): null {
    return null;
  }
}
