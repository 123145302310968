<cap-card
  [title]="'market_discovery.lead.' + entity + '.label' | translate"
  styleClass="mb-4"
  buttonIcon="far fa-plus"
  [buttonLabel]="'market_discovery.lead.' + entity + '_add.label' | translate"
  (buttonClick)="addAddress()">
  <ng-template capTemplate="content">
    <ng-container *ngIf="!loadingAddresses; else skeletons">
      <div class="container-fluid" *ngIf="values$ | async as values">
        <ng-container *ngIf="values.length; else noAddressesTemplate">
          <div class="row lead-contact" *ngFor="let value of values; trackBy: trackByFn">
            <div class="column col-lg-6">
              <cpb-form-renderer
                [showHeader]="false"
                [formConfiguration]="formConfiguration"
                [defaultValue]="value"
                [readOnly]="true">
              </cpb-form-renderer>
            </div>

            <div class="column col-lg-6 buttons">
              <cap-button
                *ngIf="value.is_main_address"
                styleClass="primary round"
                icon="fas fa-home"
                [label]="'market_discovery.lead.address.main_address.label' | translate">
              </cap-button>

              <div class="action-buttons">
                <cap-button
                  styleClass="secondary round"
                  icon="fas fa-pencil"
                  [label]="'button.edit' | translate"
                  (onClick)="edit(value)">
                </cap-button>

                <cap-button
                  styleClass="secondary round"
                  icon="fas fa-trash"
                  *ngxPermissionsOnly="'contact.destroy'"
                  [label]="'builders.action.delete' | translate"
                  (onClick)="delete(value)">
                </cap-button>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #noAddressesTemplate>
          <div>
            {{ 'market_discovery.lead.' + entity + '.no_result.label' | translate }}
          </div>
        </ng-template>
      </div>
    </ng-container>
  </ng-template>
</cap-card>

<ng-template #skeletons>
  <div class="skeletons">
    <cap-skeleton styleClass="cap-mb-2"></cap-skeleton>
    <cap-skeleton width="50%" height="1rem" styleClass="cap-mb-2"></cap-skeleton>
    <cap-skeleton width="70%" height="1.5rem" styleClass="cap-mb-2"></cap-skeleton>
    <cap-skeleton width="90%" height="1rem" styleClass="cap-mb-2"></cap-skeleton>
    <cap-skeleton styleClass="cap-mb-2"></cap-skeleton>
  </div>
</ng-template>
