import { Injectable } from '@angular/core';
import { AuthConfig, AuthService as BlueprintAuthService } from '@capturum/auth';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { User } from '../../features/user/interfaces/user.interface';
import { tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { FullscreenService } from '@shared/services/fullscreen.service';

@Injectable({ providedIn: 'root' })
export class AuthService extends BlueprintAuthService<User> {
  constructor(
    config: AuthConfig,
    http: HttpClient,
    private projectStateService: ProjectStateService,
    private ngxPermissionService: NgxPermissionsService,
    private fullscreenService: FullscreenService
  ) {
    super(config, http);
  }

  public logout(): Observable<boolean> {
    this.projectStateService.resetProject();

    return super.logout().pipe(
      tap(() => {
        localStorage.clear();

        this.fullscreenService.exitFullscreenMode();
      })
    );
  }

  public canAccessFullBusiness(): boolean {
    const user = this.getUser() as User;

    return (
      (!this.ngxPermissionService.getPermission('project.full-business.show-without-checks') &&
        user?.is_full_business_available) ||
      !!this.ngxPermissionService.getPermission('project.full-business.show-without-checks')
    );
  }
}
