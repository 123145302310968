import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-user-status-widget',
  templateUrl: './user-status-widget.component.html',
  styleUrls: ['./user-status-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserStatusWidgetComponent extends ListRendererWidgetBase {}
