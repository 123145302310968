<div class="page-layout">
  <div class="page-layout-header">
    <div class="page-layout-title">
      <i
        *ngIf="backRoute"
        class="page-layout-back-btn fas fa-chevron-left"
        [routerLink]="[backRoute]"
        [queryParamsHandling]="keepQueryParams ? 'merge' : ''"></i>
      <h1 class="page-layout-title-text">{{ title }}</h1>
      <sup *ngIf="superscript">{{ superscript }}</sup>
    </div>

    <div class="page-layout-actions">
      <ng-content select="[actions]"></ng-content>
    </div>
  </div>

  <div class="page-layout-content">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
