import { Pipe, PipeTransform } from '@angular/core';
import { Question } from '@features/questionnaire/interfaces/questionnaire.interface';

@Pipe({
  name: 'getQuestionNumber',
})
export class GetQuestionNumberPipe implements PipeTransform {
  public transform(questionId: string, questions: Question[]): number {
    const index = questions.findIndex((question) => {
      return question.id === questionId;
    });

    if (index > -1) {
      return index + 1;
    }

    return null;
  }
}
