import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { XyzFormatedResult } from '@features/project/interfaces/xyz.interface';
import { ChartData, ChartOptions } from 'chart.js';
import { UIChart } from 'primeng/chart';

@Component({
  selector: 'app-xyz',
  templateUrl: './xyz.component.html',
  styleUrls: ['./xyz.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class XyzComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input()
  public xyz: XyzFormatedResult;

  @Input()
  public graph: ChartData;

  @ViewChild(UIChart, { static: true })
  public xyzChart: UIChart;

  @ViewChild('xyzTable', { static: true })
  public xyzTable: ElementRef;

  public options: ChartOptions;
  public chartHeight = '613px';

  private _resizeObserver: ResizeObserver;
  private _chartHeightOffset = 30;
  private _dataTableElement: HTMLElement;

  constructor(private zone: NgZone, private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.options = {
      plugins: {
        legend: {
          labels: {
            color: '#3a3a3a',
            font: {
              size: 11,
            },
            boxWidth: 19,
            boxHeight: 0,
          },
          position: 'bottom',
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${context.dataset.label}: ${context.formattedValue}%`;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: '#495057',
          },
          grid: {
            color: '#ebedef',
          },
        },
        y: {
          min: 0,
          max: 100,
          ticks: {
            color: '#495057',
            callback: (value) => {
              return `${value}%`;
            },
          },
          grid: {
            color: '#ebedef',
          },
        },
      },
    };
  }

  public ngAfterViewInit(): void {
    this._dataTableElement = this.xyzTable.nativeElement.querySelector('.p-datatable-wrapper');

    if (this._dataTableElement) {
      this._resizeObserver = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          this.zone.run(() => {
            this.chartHeight = `${entries[0].contentRect.height - this._chartHeightOffset}px`;

            this.xyzChart.reinit();
            this.cdr.markForCheck();
          });
        });
      });

      this._resizeObserver.observe(this._dataTableElement);
    }
  }

  public ngOnDestroy(): void {
    if (this._dataTableElement) {
      this._resizeObserver.unobserve(this.xyzTable.nativeElement.querySelector('.p-datatable-wrapper'));
    }
  }
}
