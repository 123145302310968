import { ProjectInfoEditComponent } from './pages/project-info-edit/project-info-edit.component';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumInputModule } from '@capturum/ui/input';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { ProjectSelectorDialogComponent } from './components/project-selector-dialog/project-selector-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectRoutingModule } from './project-routing.module';
import { ProjectLayoutComponent } from './components/project-layout/project-layout.component';
import { CapturumSidebarMenuModule } from '@capturum/ui/sidebar-menu';
import { ProjectLeadsComponent } from './pages/project-leads/project-leads.component';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { CapturumCardModule } from '@capturum/ui/card';
import { BaseDataModule } from '@capturum/complete';
import { CapturumDynamicFiltersModule } from '@capturum/ui/dynamic-filters';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumTimelineModule } from '@capturum/ui/timeline';
import { ProjectDetailComponent } from './pages/project-detail/project-detail.component';
import { LayoutModule } from '@shared/layout/layout.module';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { ProjectLeadsMapComponent } from './components/leads-map/leads-map.component';
import { SharedModule } from '@shared/shared.module';
import { PickListModule } from 'primeng/picklist';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { TableBulkActionsComponent } from './components/table-bulk-actions/table-bulk-actions.component';
import { TransferLeadsDialogComponent } from './components/transfer-leads-dialog/transfer-leads-dialog.component';
import { ProjectStakeholdersComponent } from './pages/project-stakeholders/project-stakeholders.component';
import { StakeholderUserListComponent } from './components/stakeholder-user-list/stakeholder-user-list.component';
import { StakeholderUserListItemComponent } from './components/stakeholder-user-list-item/stakeholder-user-list-item.component';
import { ProjectPipelineComponent } from './pages/project-pipeline/project-pipeline.component';
import { TableModule } from 'primeng/table';
import { PipelineStatusTableComponent } from './components/pipeline-status-table/pipeline-status-table.component';
import { ProjectLeadDetailComponent } from './pages/project-lead-detail/project-lead-detail.component';
import { ProjectGripMeterComponent } from './pages/project-grip-meter/project-grip-meter.component';
import { ProjectFullBusinessComponent } from './pages/project-full-business/project-full-business.component';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { LeadEditComponent } from './pages/lead-edit/lead-edit.component';
import { LeadContactDialogComponent } from './components/lead-contact-dialog/lead-contact-dialog.component';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { LeadActionsComponent } from './components/lead-actions/lead-actions.component';
import { LeadActionDetailComponent } from './components/lead-action-detail/lead-action-detail.component';
import { LeadActionItemComponent } from './components/lead-action-item/lead-action-item.component';
import { LeadAttachmentsComponent } from './components/lead-attachments/lead-attachments.component';
import { GripMeterInputDialogComponent } from './components/grip-meter-input-dialog/grip-meter-input-dialog.component';
import { LeadGripInputComponent } from './components/lead-grip-input/lead-grip-input.component';
import { CapturumFileUploadModule } from '@capturum/ui/file-upload';
import { CapturumFilePreviewListModule } from '@capturum/ui/file-preview-list';
import { LeadImportComponent } from './pages/lead-import/lead-import.component';
import { ProjectSelectionComponent } from './pages/project-selection/project-selection.component';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { SelectionImportDialogComponent } from './components/selection-import-dialog/selection-import-dialog.component';
import { MetaValueFiltersComponent } from './components/meta-value-filters/meta-value-filters.component';
import { CapturumChipsModule } from '@capturum/ui/chips';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { ChipsModule } from 'primeng/chips';
import { ProjectLogComponent } from './pages/project-log/project-log.component';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { RoiOverviewComponent } from './pages/roi-overview/roi-overview.component';
import { RoiDetailDialogComponent } from './component/roi-detail-dialog/roi-detail-dialog.component';
import { CapturumInputNumberModule } from '@capturum/ui/input-number';
import { ProjectXyzComponent } from './pages/project-xyz/project-xyz.component';
import { AddSelectionDialogComponent } from './components/add-selection-dialog/add-selection-dialog.component';
import { EditNoteDialogComponent } from './components/edit-note-dialog/edit-note-dialog.component';
import { RealizedRoiTableComponent } from './components/realized-roi-table/realized-roi-table.component';
import { ChartModule } from 'primeng/chart';
import { SelectionImportComponent } from '@features/project/pages/selection-import/selection-import.component';
import { BulkStatusDialogComponent } from './components/bulk-status-dialog/bulk-status-dialog.component';
import { LeadAccountmanagerComponent } from './pages/lead-accountmanager/lead-accountmanager.component';
import { CapturumTableModule } from '@capturum/ui/table';
import { LeadHistoryComponent } from './components/lead-history/lead-history.component';
import { LeadHistoryDialogComponent } from './components/lead-history-dialog/lead-history-dialog.component';
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { StakeholderSelectionComponent } from './components/stakeholder-selection/stakeholder-selection.component';
import { ProjectQuestionnaireComponent } from '@features/project/pages/project-questionnaire/project-questionnaire.component';
import { QuestionnaireModule } from '@features/questionnaire/questionnaire.module';
import { ChangeLeadsSourceDialogComponent } from './components/change-leads-source-dialog/change-leads-source-dialog.component';
import { XyzModule } from '../xyz/xyz.module';
import { AddToCalendarDialogComponent } from './components/add-to-calendar-dialog/add-to-calendar-dialog.component';
import { CapturumNotificationBadgeModule } from '@capturum/ui/notification-badge';
import { ImportSelectionDialogComponent } from './components/import-selection-dialog/import-selection-dialog.component';
import { NgxPermissionsRestrictStubModule } from 'ngx-permissions';
import { ProjectSelectionLetterDialogComponent } from './components/project-selection-letter-dialog/project-selection-letter-dialog.component';
import { ProjectInfoMetaComponent } from './components/project-info-meta/project-info-meta.component';
import { ImportsOverviewComponent } from './pages/imports-overview/imports-overview.component';
import { ImportsDetailComponent } from './pages/imports-detail/imports-detail.component';

@NgModule({
  declarations: [
    ProjectLayoutComponent,
    ProjectLeadsComponent,
    ProjectDetailComponent,
    ProjectLeadsMapComponent,
    ProjectSelectorDialogComponent,
    TableBulkActionsComponent,
    TransferLeadsDialogComponent,
    ProjectStakeholdersComponent,
    StakeholderUserListComponent,
    StakeholderUserListItemComponent,
    ProjectPipelineComponent,
    PipelineStatusTableComponent,
    ProjectLeadDetailComponent,
    ProjectGripMeterComponent,
    ProjectFullBusinessComponent,
    LeadEditComponent,
    LeadContactDialogComponent,
    LeadAttachmentsComponent,
    LeadActionsComponent,
    LeadActionDetailComponent,
    LeadActionItemComponent,
    MetaValueFiltersComponent,
    GripMeterInputDialogComponent,
    LeadGripInputComponent,
    ProjectSelectionComponent,
    SelectionImportDialogComponent,
    ProjectInfoEditComponent,
    LeadImportComponent,
    SelectionImportComponent,
    ProjectLogComponent,
    RoiOverviewComponent,
    RoiDetailDialogComponent,
    ProjectXyzComponent,
    AddSelectionDialogComponent,
    EditNoteDialogComponent,
    RealizedRoiTableComponent,
    BulkStatusDialogComponent,
    LeadAccountmanagerComponent,
    LeadHistoryComponent,
    LeadHistoryDialogComponent,
    StakeholderSelectionComponent,
    ProjectQuestionnaireComponent,
    ChangeLeadsSourceDialogComponent,
    AddToCalendarDialogComponent,
    ImportSelectionDialogComponent,
    ProjectInfoMetaComponent,
    ProjectSelectionLetterDialogComponent,
    ImportsOverviewComponent,
    ImportsDetailComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ProjectRoutingModule,
    CapturumSidebarMenuModule,
    CapturumInfoTableModule,
    CapturumCardModule,
    CapturumTimelineModule,
    CapturumTextareaModule,
    ReactiveFormsModule,
    BaseDataModule,
    CapturumDynamicFiltersModule,
    TranslateModule,
    CapturumSharedModule,
    ReactiveFormsModule,
    CapturumButtonModule,
    CapturumDropdownModule,
    CapturumInputModule,
    CapturumCalendarModule,
    LayoutModule,
    CapturumFormRendererModule,
    GoogleMapsModule,
    PickListModule,
    CapturumCheckboxModule,
    TableModule,
    CapturumSkeletonModule,
    CapturumFileUploadModule,
    CapturumFilePreviewListModule,
    ReactiveFormsModule,
    CapturumChipsModule,
    CapturumMultiSelectModule,
    ChipsModule,
    CapturumListRendererModule,
    ReactiveFormsModule,
    CapturumInputNumberModule,
    ChartModule,
    CapturumTableModule,
    ButtonModule,
    StyleClassModule,
    QuestionnaireModule,
    XyzModule,
    CapturumNotificationBadgeModule,
    NgxPermissionsRestrictStubModule,
  ],
  exports: [ProjectInfoMetaComponent],
})
export class ProjectModule {}
