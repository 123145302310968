import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { CalendarSelectionMode } from '@capturum/ui/calendar';
import { FieldType } from '@ngx-formly/core';
import { filter, startWith, Subject, takeUntil } from 'rxjs';
import { format, isDate } from 'date-fns';
@Component({
  selector: 'app-date-type',
  templateUrl: './date-type.component.html',
  styleUrls: ['./date-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DateTypeComponent extends FieldType implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(startWith(this.formControl.value), filter(Boolean), takeUntil(this.destroy$))
      .subscribe((value) => {
        if (typeof value === 'string') {
          if (new Date(value).getTime() > 0 || this.to?.timezone) {
            this.formControl.patchValue(new Date(value), { onlySelf: true, emitEvent: false });
          }
        } else if (isDate(value)) {
          this.formControl.patchValue(new Date(this.formatDateValue(value)), { onlySelf: true, emitEvent: false });
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public onClickOutside(): void {
    const dates: string[] = this.formControl.value;

    if (
      this.to?.selectionMode === CalendarSelectionMode.range &&
      dates?.some((date) => {
        return !!date;
      })
    ) {
      if (
        dates.some((date) => {
          return !date;
        })
      ) {
        this.formControl.setValue(
          dates.map((date) => {
            return (
              date ||
              dates.find((date) => {
                return !!date;
              })
            );
          })
        );
      }
    }
  }

  public formatDateValue(date: Date): string {
    if (!this.to?.timezone && !this.to?.time) {
      return format(date, 'yyyy-MM-dd');
    } else if (this.to?.time || this.to?.timezone) {
      return format(date, `yyyy-MM-dd'T'HH:mm:ssxx`);
    }

    return date.toISOString();
  }
}
