import { ActionOptions, ActionProvider } from '@capturum/builders/core';
import { Injectable } from '@angular/core';
import { ProjectApiService } from '@features/project/services/project-api.service';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@capturum/ui/api';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DownloadLetterAction implements ActionProvider {
  constructor(
    private projectApiService: ProjectApiService,
    private projectStateService: ProjectStateService,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {}

  public execute(options: ActionOptions, item: { id: string }, context?: Record<string, any>): void {
    this.toastService.info(
      this.translateService.instant('market_discovery.letter.download'),
      this.translateService.instant('market_discovery.letter.download.started')
    );
    this.projectApiService
      .downloadLetters(this.projectStateService.getProjectSnapshot().id, item.id)
      .pipe(first())
      .subscribe(() => {});
  }
}
