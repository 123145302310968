<div
  class="form-group meta-value-wrapper"
  [class.cpb-readonly]="formState.readonly"
  [class.has-error]="showError"
  [class.position-before]="(to.label_position | observablePipe | async) === labelPosition.Before">
  <div class="label-wrapper">
    <label *ngIf="to.label && to.hideLabel !== true" [attr.for]="id" class="d-block">
      {{ to.label }}
    </label>

    <div class="sub-label" *ngIf="to.subLabel">
      {{ to.subLabel }}
    </div>
  </div>

  <div class="field-wrapper">
    <ng-template #fieldComponent></ng-template>
  </div>
</div>
