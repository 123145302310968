<app-page-layout
  [title]="'market_discovery.project.grip_meter.title' | translate"
  [showPrintButton]="true"
  superscript="©"
  (handlePrintCommand)="exportGripMeter()">
  <div content class="grip-meter-page">
    <cap-card
      [title]="'market_discovery.pipeline.selection' | translate"
      [class.hide]="hideSelection"
      [buttonLabel]="
        (hideSelection
          ? 'market_discovery.project.full_business.show_selection.label'
          : 'market_discovery.project.full_business.hide_selection.label'
        ) | translate
      "
      [buttonIcon]="hideSelection ? 'fas fa-eye' : 'fas fa-eye-slash'"
      (buttonClick)="hideSelection = !hideSelection">
      <ng-template capTemplate="content">
        <div class="row align-items-center" [hidden]="hideSelection" *ngIf="project$ | async as project">
          <div class="col-9">
            <cap-dynamic-filters
              #dynamicFiltersComponent
              containerStyleClass="row"
              filterStyleClass="col-lg-4 col-xl-3"
              [showResetButton]="false"
              [filterConfig]="dynamicFilters"
              [storageKey]="'grip-meter-' + project?.id"
              (activeFiltersChange)="handleFilterChange($event)"
              class="square-filter">
            </cap-dynamic-filters>
          </div>

          <div class="col-3 justify-content-center align-items-end">
            <cap-button
              styleClass="secondary round float-right"
              icon="fas fa-trash-alt"
              (click)="dynamicFiltersComponent.resetFilters()"
              [label]="'market_discovery.button.reset_filters' | translate">
            </cap-button>
          </div>
        </div>
      </ng-template>
    </cap-card>

    <div class="mt-4 grip-meter-table">
      <cap-info-table
        class="simple-table grip-meter-table"
        [class.show-total-row]="canShowTotal"
        [data]="(totalsData$ | async)?.rowData"
        [columns]="infoTableColumns"
        [pagination]="false">
        <ng-template capTemplate="account_manager_name" let-item="item">
          <span>{{ item?.account_manager_name }}</span>
        </ng-template>

        <ng-container *ngFor="let column of infoTableColumns; let index = index">
          <ng-template *ngIf="index >= 1" [capTemplate]="column?.field" let-item="item">
            <span>{{ item[column?.field] | number : '1.2-2' }} %</span>
          </ng-template>
        </ng-container>

        <ng-template capTemplate="infoTableFooter" *ngIf="canShowTotal">
          <tr *ngIf="(totalsData$ | async)?.totalRowData as total" class="footer-total">
            <ng-container *ngFor="let column of infoTableColumns; let index = index; let first = first">
              <ng-container *ngIf="first; else footerCell">
                <td class="total-cell">
                  {{ 'market_discovery.project.grip-meter.total-average.title' | translate }}
                </td>
              </ng-container>

              <ng-template #footerCell>
                <td>
                  <span>{{ total[column?.field] | number : '1.2-2' }} %</span>
                </td>
              </ng-template>
            </ng-container>
          </tr>
        </ng-template>
      </cap-info-table>
    </div>
  </div>
</app-page-layout>
