<div *ngIf="file$ | async as file" class="download-block">
  <i class="fas fa-file-excel"></i>

  <span>{{ file.filename }}</span>

  <cap-button
    icon="fa-solid fa-download"
    [attr.data-test]="'download-file-btn'"
    (onClick)="downloadFile(file.id, file.filename)">
  </cap-button>
</div>
