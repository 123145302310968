import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { FilterMatchMode, MapItem } from '@capturum/ui/api';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { first, shareReplay } from 'rxjs/operators';
import { ActiveFilters } from '@capturum/ui/dynamic-filters';
import { DestroyBase } from '@capturum/shared';
import { LocalStorageService } from '@shared/services/storage.service';
import { MetaKey } from '../../../meta-key/interfaces/meta-key.interface';
import { ProjectApiService } from '../../services/project-api.service';
import { MetaKeyApiService } from '../../../meta-key/services/meta-key-api.service';

@Component({
  selector: 'app-meta-value-filters',
  templateUrl: './meta-value-filters.component.html',
  styleUrls: ['./meta-value-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaValueFiltersComponent extends DestroyBase implements OnInit {
  @Input()
  public projectId: string;

  @Output()
  public filterChange = new EventEmitter<ActiveFilters[]>();

  @Input()
  public showOnlyMetaFilters: boolean;

  @Input()
  public storagekey: string;

  @Input()
  public callCenterSources: boolean;

  public operatorOptions: MapItem[] = [];
  public metaKeys$: Observable<MetaKey[]>;
  public accountManagersOptions$: Observable<MapItem[]>;
  public metaValueOptions$: Observable<MapItem[]>;
  public valuesIsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public form: UntypedFormGroup;
  public chipValues: { label: string; value: string }[] = [];
  public activeFilters: ActiveFilters[] = [];
  private filtersRestored = new Subject<ActiveFilters[]>();
  private sourceOptionsSet = new Subject<MapItem[]>();

  constructor(
    private projectApiService: ProjectApiService,
    private metaKeyService: MetaKeyApiService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private storageService: LocalStorageService
  ) {
    super();
  }

  private _sourceOptions: MapItem[];

  public get sourceOptions(): MapItem[] {
    return this._sourceOptions;
  }

  @Input()
  public set sourceOptions(value: MapItem[]) {
    this._sourceOptions = value;

    this.sourceOptionsSet.next(value);
  }

  public ngOnInit(): void {
    this.listenForSetSourceOptions();
    this.form = this.getForm();
    this.metaKeys$ = this.projectApiService.listMetaKeys(this.projectId).pipe(shareReplay(1));
    this.accountManagersOptions$ = this.projectApiService.getAccountManagersList(this.projectId);
    this.metaValueOptions$ = this.form.get('meta_key_id').valueChanges.pipe(
      switchMap((metaKeyId) => {
        this.valuesIsLoading$.next(true);

        if (!metaKeyId) {
          return of([]);
        }

        return this.projectApiService.listMetaValues(this.projectId, metaKeyId, this.showOnlyMetaFilters).pipe(
          tap(() => {
            this.valuesIsLoading$.next(false);
          })
        );
      }),
      shareReplay(1),
      takeUntil(this.destroy$)
    );
    this.restoreFilters();
  }

  public setOperatorOptions(metaKeyId: string, options: MetaKey[]): void {
    this.form.patchValue({ value: null, operator: null });

    const selectedKey = options?.find((option) => {
      return option.id === metaKeyId;
    });

    if (this.metaKeyService?.getOperatorOptions(selectedKey)) {
      this.operatorOptions = this.metaKeyService?.getOperatorOptions(selectedKey);
    }
  }

  public addFilter(): void {
    if (this.form.valid) {
      this.activeFilters = [
        ...this.activeFilters,
        {
          field: `metaKeys.${this.form.value.meta_key_id}`,
          value: this.form.value.value,
          matchMode: this.form.value.operator,
        },
      ];

      this.metaKeys$.pipe(first()).subscribe((metaKeys) => {
        const selectedMetaKey = metaKeys.find((metaKey) => {
          return metaKey.id === this.form.value.meta_key_id;
        });

        this.chipValues = [
          ...this.chipValues,
          {
            label: `${selectedMetaKey.name} / ${this.translateService.instant(
              `market_discovery.matchmode.${this.form.value.operator}`
            )} / ${this.form.value.itemLabel ?? this.form.value.value}`,
            value: `${selectedMetaKey.id}_${this.form.value.operator}_${this.form.value.value.join('-')}`,
          },
        ];

        this.form.patchValue({
          operator: null,
          meta_key_id: null,
          value: null,
        });
        this.cdr.detectChanges();
      });

      this.storageService.set(this.getStorageKey(), { filters: this.activeFilters, chips: this.chipValues });
      this.filterChange.emit(this.activeFilters);
    }
  }

  public handleFilterChange(field: string): void {
    const filter = this.activeFilters.find((activeFilter) => {
      return activeFilter.field === field;
    });

    if (filter) {
      this.activeFilters = this.activeFilters.map((activeFilter) => {
        if (activeFilter.field === field) {
          return {
            value: this.form.value[field],
            field,
            matchMode: FilterMatchMode.IN,
          };
        }

        return activeFilter;
      });
    } else if (this.form.value[field]) {
      this.activeFilters = [
        ...this.activeFilters,
        {
          value: this.form.value[field],
          field,
          matchMode: FilterMatchMode.IN,
        },
      ];
    } else {
      this.activeFilters = this.activeFilters.filter((activeFilter) => {
        return activeFilter.field !== field && !!activeFilter.value;
      });
    }

    this.activeFilters = this.activeFilters.filter((activeFilter) => {
      if (Array.isArray(activeFilter.value) && activeFilter.value.length === 0) {
        return false;
      } else if (!activeFilter?.value) {
        return false;
      }

      return true;
    });

    this.filterChange.emit(this.activeFilters);

    this.storageService.set(this.getStorageKey(), { filters: this.activeFilters, chips: this.chipValues });
  }

  public handleMetaValueOptionChange(event: { value: string[] }): void {
    this.metaValueOptions$.pipe(take(1)).subscribe((options) => {
      const labels = event.value?.map((eventValue) => {
        return options.find((option) => {
          return option.value === eventValue;
        })?.label;
      });

      this.form.patchValue({ itemLabel: labels?.join(', ') });
    });
  }

  public handleRemoveChip(event: any): void {
    const [key, operator, value]: string[] = event.value.value.split('_');

    this.activeFilters = this.activeFilters.filter((activeFilter) => {
      return !(
        activeFilter.field === `metaKeys.${key}` &&
        activeFilter.matchMode === operator &&
        activeFilter.value.join('-') === value
      );
    });

    this.filterChange.emit(this.activeFilters);
    this.storageService.set(this.getStorageKey(), { filters: this.activeFilters, chips: this.chipValues });
  }

  public resetFilters(): void {
    this.operatorOptions = [];
    this.activeFilters = [];
    this.chipValues = [];

    this.form.reset();
    this.storageService.remove(this.getStorageKey());

    this.filterChange.emit(this.activeFilters);
  }

  private getForm(): UntypedFormGroup {
    return this.formBuilder.group({
      lead_source_base_data_value_id: [],
      operator: [null, Validators.required],
      meta_key_id: [null, Validators.required],
      value: [null, Validators.required],
      account_manager_id: [],
      itemLabel: [null],
    });
  }

  private getStorageKey(): string {
    const prefix = this.storagekey ? `${this.storagekey}-` : '';

    return `${prefix}project-meta-filter-${this.projectId}`;
  }

  private restoreFilters(): void {
    const storedFilters = this.storageService.get<{ chips: MapItem[]; filters: ActiveFilters[] }>(this.getStorageKey());

    this.filtersRestored.next(storedFilters?.filters || []);

    if (storedFilters) {
      if (storedFilters.chips) {
        this.chipValues = storedFilters.chips;
      }
    }
  }

  private listenForSetSourceOptions(): void {
    combineLatest([this.filtersRestored, this.sourceOptionsSet.asObservable()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([restoredFilters, sourceOptions]) => {
        this.filterChange.emit(this.activeFilters);

        let sourceFilterValue = restoredFilters.find((filter) => {
          return filter.field === 'lead_source_base_data_value_id';
        })?.value;

        sourceFilterValue =
          sourceFilterValue?.filter((value) => {
            return sourceOptions.some((option) => {
              return option.value === value;
            });
          }) || [];

        if (!sourceFilterValue?.length) {
          this.activeFilters = restoredFilters.filter((filter) => {
            return filter.field !== 'lead_source_base_data_value_id';
          });
        } else {
          this.activeFilters = restoredFilters.map((filter) => {
            if (filter.field === 'lead_source_base_data_value_id') {
              return {
                ...filter,
                value: sourceFilterValue || [],
              };
            }

            return filter;
          });
        }

        this.filterChange.emit(this.activeFilters);

        this.form.patchValue(
          this.activeFilters.reduce((acc, current) => {
            return {
              ...acc,
              [current.field]: current.value,
            };
          }, {})
        );
      });
  }
}
