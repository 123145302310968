<app-page-layout
  [title]="titleTranslationKey | translate : { entity: ('market_discovery.' + entity + '.entity.name' | translate) }"
  backRoute="/customer">
  <app-page-buttons actions>
    <cap-button icon="fas fa-xmark" [label]="'button.cancel' | translate" (click)="cancel()" styleClass="secondary">
    </cap-button>

    <cap-button icon="fas fa-save" [label]="'button.submit' | translate" (click)="submit()" styleClass="primary">
    </cap-button>
  </app-page-buttons>

  <div class="manage-detail-content" content>
    <div class="container-fluid customer-form-container">
      <div class="row">
        <div class="col-lg-6">
          <div class="customer-form-renderer">
            <cap-card [title]="'market_discovery.lead.general-info.label' | translate">
              <ng-template capTemplate="content">
                <cpb-form-renderer [formKey]="formKey" [showHeader]="false" [modelId]="modelId"> </cpb-form-renderer>
              </ng-template>
            </cap-card>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row customer-list-row">
        <div class="col-lg-6 customer-list-column">
          <div class="customer-list-renderer list_customer_user">
            <cap-card [title]="'market_discovery.user.list.title' | translate">
              <ng-template capTemplate="content">
                <cpb-list-renderer key="list_customer_user" [context]="customerContext"></cpb-list-renderer>
              </ng-template>
            </cap-card>
          </div>
        </div>

        <div class="col-lg-6 customer-list-column">
          <div class="customer-list-renderer">
            <cap-card [title]="'market_discovery.project.list.title' | translate">
              <ng-template capTemplate="content">
                <cpb-list-renderer key="list_customer_project" [context]="customerContext"></cpb-list-renderer>
              </ng-template>
            </cap-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-page-layout>
