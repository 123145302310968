import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Question,
  QuestionInputTypeComponent,
  UpdateAnswer,
} from '@features/questionnaire/interfaces/questionnaire.interface';
import { BehaviorSubject, debounceTime, Subject, takeUntil } from 'rxjs';

interface YesNoAnswer {
  answer_boolean: string;
}

@Component({
  selector: 'app-question-input-type-yes-no',
  templateUrl: './yes-no-input-type.component.html',
  styleUrls: ['./yes-no-input-type.component.scss'],
})
export class QuestionInputTypeYesNoComponent implements QuestionInputTypeComponent<YesNoAnswer>, OnInit, OnDestroy {
  @Input()
  public question: Question;

  @Output()
  public updateAnswer: EventEmitter<UpdateAnswer> = new EventEmitter<UpdateAnswer>();

  public formGroup: UntypedFormGroup;
  public answer$: BehaviorSubject<any> = new BehaviorSubject(null);

  private destroy$: Subject<boolean> = new Subject<boolean>();

  public get answerString(): UntypedFormControl {
    return this.formGroup?.get('answer_boolean') as UntypedFormControl;
  }

  public ngOnInit(): void {
    this.formGroup = this.generateFormGroup();

    this.answerString.valueChanges.pipe(debounceTime(250), takeUntil(this.destroy$)).subscribe((_) => {
      this.updateAnswer.emit({
        id: this.question.id,
        value: this.formGroup.value,
      });
    });

    this.answer$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          this.formGroup.patchValue(value);
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public validate(): boolean {
    if (this.formGroup.valid) {
      this.updateAnswer.emit({
        id: this.question.id,
        value: this.formGroup.value,
      });
    }

    return this.formGroup.valid;
  }

  public getAnswer(): YesNoAnswer {
    return this.formGroup.value;
  }

  private generateFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      answer_boolean: this.question.is_required
        ? new UntypedFormControl(false, Validators.required)
        : new UntypedFormControl(false),
    });
  }
}
