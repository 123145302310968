<div class="xyz-layout">
  <cap-sidebar-menu [menus]="menuItems" class="project-sidebar-menu">
    <div brand></div>

    <ng-template capTemplate="item" let-menu="item">
      <div
        class="sidebar-item"
        *ngIf="!menu?.hidden"
        routerLinkActive="active"
        [attr.data-test]="menu.routerLink"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="'/' + menu.routerLink">
        <i [className]="menu.icon"></i>

        <span [innerHTML]="menu.label | observablePipe | async"></span>

        <cap-notification-badge
          *ngIf="menu.notificationBadgeOptions | async as badgeOptions"
          [showBadge]="!!badgeOptions.showBadge"
          [options]="badgeOptions">
        </cap-notification-badge>
      </div>
    </ng-template>
  </cap-sidebar-menu>

  <ng-container *ngIf="menuItems?.length">
    <router-outlet></router-outlet>
  </ng-container>
</div>
