import { Pipe, PipeTransform } from '@angular/core';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timeDifference',
})
export class TimeDifferencePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(
    time1: string,
    time2: string,
    translationKey = 'market_discovery.project-hour.total-worked-hours'
  ): string {
    if (time1 && time2 && typeof time1 === 'string' && typeof time2 === 'string') {
      const date1 = new Date();
      const date2 = new Date();
      const [hours1, minutes1]: string[] = time1.split(':');
      const [hours2, minutes2]: string[] = time2.split(':');

      date1.setMinutes(+minutes1);
      date1.setHours(+hours1);
      date2.setMinutes(+minutes2);
      date2.setHours(+hours2);

      const hours = differenceInHours(date2, date1);
      const minutes = differenceInMinutes(date2, date1) % 60;
      const hoursTranslation = this.translateService.instant('market_discovery.project-hour.hours.hours', { hours });
      const minutesTranslation = this.translateService.instant('market_discovery.project-hour.hours.minutes', {
        minutes,
      });

      if (translationKey) {
        return this.translateService.instant(translationKey, {
          hours,
          minutes,
        });
      } else {
        return `${hours > 0 ? hoursTranslation : ''} ${minutes > 0 ? minutesTranslation : ''}`;
      }
    }

    return null;
  }
}
