<div [ngSwitch]="item.status?.value" class="text-center">
  <ng-container *ngSwitchCase="mutationStatus.open">
    <a (click)="accept()" class="accept-mutation mr-3 text-success">
      <i class="fas fa-check"></i>
      {{ 'market_discovery.mutation.action.accept' | translate }}
    </a>

    <a (click)="decline()" class="decline-mutation text-secondary">
      <i class="fas fa-times"></i>
      {{ 'market_discovery.mutation.action.decline' | translate }}
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="mutationStatus.accepted">
    <span class="text-success">
      <i class="fas fa-check"></i>
      {{ 'base-data.' + item.status?.id | translate }}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="mutationStatus.declined">
    <span>
      <i class="fas fa-times"></i>
      {{ 'base-data.' + item.status?.id | translate }}
    </span>
  </ng-container>
</div>
