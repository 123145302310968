<cpb-readonly-container>
  <div class="addresses-type">
    <div class="address-type-line mb-2" *ngFor="let address of addresses$ | async">
      <i
        class="fas"
        [ngClass]="{
          'fa-building': address.type.value === 'company',
          'fa-envelope': address.type.value === 'mail'
        }"></i>
      {{ address.street }} {{ address.housenumber }} {{ address.zipcode }} {{ address.city }}
      <ng-container *ngIf="address.country_base_data_value_id"
        >{{ address.country_base_data_value_id | capBaseDataTranslation }}
      </ng-container>
    </div>
  </div>
</cpb-readonly-container>
