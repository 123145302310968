import { Component, OnInit } from '@angular/core';
import { Lead } from '@features/lead/interfaces/lead.interface';
import { Observable } from 'rxjs';
import { MapItem } from '@capturum/ui/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogActionType } from '@core/enums/dialog-action-type.enum';
import { DropdownEvent } from '@features/project/components/transfer-leads-dialog/transfer-leads-dialog.component';

@Component({
  selector: 'app-change-leads-source-dialog',
  templateUrl: './change-leads-source-dialog.component.html',
  styleUrls: ['./change-leads-source-dialog.component.scss'],
})
export class ChangeLeadsSourceDialogComponent implements OnInit {
  public leads: Lead[];
  public selectedSource: string;
  public sources$: Observable<MapItem[]>;

  constructor(public dialogRef: DynamicDialogRef, private dialogConfig: DynamicDialogConfig) {}

  public ngOnInit(): void {
    this.leads = this.dialogConfig.data?.leads;
    this.sources$ = this.dialogConfig.data?.sources;
  }

  public handleChangeSelectOption(event: DropdownEvent): void {
    this.selectedSource = event.value;
  }

  public handleSave(): void {
    this.dialogRef.close({
      action: DialogActionType.submit,
      data: {
        lead_ids: this.leads.map((lead) => {
          return lead.id;
        }),
        lead_source_base_data_value_id: this.selectedSource,
      },
    });
  }
}
