import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CapturumBuilderActionService, toMapItems } from '@capturum/builders/core';
import { CapturumFormRendererComponent, FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { DestroyBase } from '@capturum/shared';
import { MapItem, ToastService } from '@capturum/ui/api';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationBaseService } from '@shared/services/confirmation-base.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { distinctUntilChanged, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { filter, first, map, shareReplay } from 'rxjs/operators';

import { MetaKeyApiService } from '../../../meta-key/services/meta-key-api.service';
import { Project } from '../../interfaces/project.interface';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectDetailComponent extends DestroyBase implements OnInit {
  @ViewChild(CapturumFormRendererComponent)
  public formRenderer: CapturumFormRendererComponent;

  public formKey = 'form_project';
  public modelId: string;
  public titleTranslationKey: string;
  public metaKeys$: Observable<MapItem[]>;
  public projectMetaKeys: MapItem[] = [];
  public isReadOnly = true;
  public sourceValue: Project;
  public canUnlockProject: boolean;
  public isSubmitting$ = new Subject<boolean>();

  constructor(
    private formSaverService: FormSaverService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private translateService: TranslateService,
    private metaKeyApiService: MetaKeyApiService,
    private formRendererService: FormRendererService,
    private actionService: CapturumBuilderActionService,
    private projectStateService: ProjectStateService,
    private permissionsService: NgxPermissionsService,
    private confirmationBaseService: ConfirmationBaseService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.canUnlockProject = !!this.permissionsService.getPermission('project.unlock');
    this.titleTranslationKey = this.getTitleTranslationKey();
    this.metaKeys$ = this.metaKeyApiService.list().pipe(toMapItems, shareReplay(1));

    if (this.route.snapshot.parent.paramMap.get('id')) {
      this.projectStateService
        .getProject()
        .pipe(
          filter(Boolean),
          distinctUntilChanged((oldValue, newValue) => {
            return oldValue.id === newValue.id;
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((projectState) => {
          this.modelId = projectState.id;
          this.titleTranslationKey = projectState.name;

          this.formRendererService
            .getSourceValueByKey(this.formKey)
            .pipe(filter(Boolean), first())
            .subscribe((project: Project) => {
              this.sourceValue = project;
              this.projectMetaKeys = project.metaKeys.map((metaKey) => {
                return {
                  label: metaKey.name,
                  value: metaKey.id,
                };
              });

              this.metaKeys$ = this.metaKeys$.pipe(
                map((metaKeys) => {
                  return metaKeys.filter((metaKey) => {
                    return !this.projectMetaKeys.some((projectMetaKey) => {
                      return projectMetaKey.value === metaKey.value;
                    });
                  });
                })
              );
            });
        });
    } else {
      this.isReadOnly = false;
    }
  }

  public submit(): void {
    this.formRendererService
      .getFormValueByKey<Project>(this.formKey)
      .pipe(first())
      .subscribe((formValue) => {
        if (this.sourceValue && !this.sourceValue.is_locked && formValue.is_locked) {
          this.confirmationBaseService.confirmationService.confirm({
            header: this.translateService.instant('market_discovery.project.confirm-lock.title'),
            message: this.translateService.instant('market_discovery.project.confirm-lock.text'),
            accept: () => {
              return this.submitForm(formValue);
            },
          });
        } else {
          this.titleTranslationKey = formValue.name;

          this.submitForm(formValue);
        }
      });
  }

  public cancel(): void {
    if (!this.modelId) {
      this.formSaverService.cancel(this.formKey);
    } else {
      if (this.formRenderer) {
        this.formRenderer.formModel = this.formRenderer.getFormSourceData();
      }
      this.isReadOnly = true;
    }
  }

  public edit(): void {
    this.isReadOnly = false;
  }

  private getTitleTranslationKey(): string {
    if (this.modelId) {
      return `market_discovery.form.edit.title`;
    } else {
      return `market_discovery.form.add.title`;
    }
  }

  private submitForm(formValue: Project): void {
    this.isSubmitting$.next(true);
    this.formRendererService
      .getFormConfigurationByKey(this.formKey)
      .pipe(
        switchMap((config) => {
          const metaKeys = this.projectMetaKeys.map((metaKey, index) => {
            return {
              id: metaKey.value,
              order: index,
            };
          });
          const adjustedValue = { ...formValue, metaKeys };

          return this.formSaverService.submitToBackend(config.submit.endpoint, adjustedValue, this.formKey).pipe(
            map((response) => {
              return {
                config,
                response,
              };
            })
          );
        })
      )
      .pipe(first())
      .subscribe({
        next: ({ config, response }) => {
          this.toastService.success(
            this.translateService.instant('toast.success.title'),
            this.translateService.instant(`market_discovery.entity.toast.${this.modelId ? 'updated' : 'created'}`, {
              entity: this.translateService.instant('market_discovery.project.entity.name'),
            })
          );

          this.projectStateService.setProject({
            ...this.projectStateService.getProjectSnapshot(),
            ...response.data,
            dmu_questionnaire_id: response?.data?.dmu_questionnaire_id ?? null,
            questionnaire_id: response?.data?.questionnaire_id ?? null,
          });

          if (!this.modelId) {
            this.actionService.executeAction(config.callbacks.submit, response.data, this.formKey);
          } else {
            this.sourceValue = response.data;

            this.isReadOnly = true;
          }

          this.isSubmitting$.next(false);
        },
        error: () => {
          this.isSubmitting$.next(false);
        },
      });
  }
}
