<app-page-layout [title]="'market_discovery.project-hours.list.title' | translate" [backRoute]="backUrl"
                 (handlePrintCommand)="handlePrint()"
                 [showPrintButton]="showPrint">
  <app-page-buttons actions>
    <cap-button *ngxPermissionsOnly="['project-hour.export-excel']"
                styleClass="primary"
                icon="fas fa-file-export"
                [label]="'market_discovery.button.export' | translate"
                (onClick)="exportHours()">
    </cap-button>

    <cap-button *ngxPermissionsOnly="'project-hour.create'"
                styleClass="primary"
                icon="fa fa-plus"
                [label]="'button.add' | translate"
                (onClick)="add()">
    </cap-button>
  </app-page-buttons>

  <ng-container content>
    <cpb-list-renderer
      [storageKeyPrefix]="projectId"
      [key]="listKey"
      [context]="context$ | async"
      (filterChange)="handleFilterChange()"
      (listRendererDataChange)="handleFilterChange()">
      <ng-template let-columns capTemplate="footer">
        <tr>
          <td
            [attr.colspan]="columns.length - (selectable ? 3 : 4)"
            class="footer-row-project-hours empty-totals-cell cap-info-table__column">
          </td>

          <td class="footer-row-project-hours total-label cap-info-table__column">
            {{ 'market_discovery.project-hour.total-hours' | translate }}
          </td>

          <td class="footer-row-project-hours text-left total-value cap-info-table__column" colspan="2">
            {{ totalHours$ | async }}
          </td>
        </tr>
      </ng-template>
    </cpb-list-renderer>
  </ng-container>
</app-page-layout>

<ng-template #filterIcon let-icon="icon">
  <div class="filter-icon">
    <i [ngClass]="icon"></i>
  </div>
</ng-template>
