import { EditNoteDialogComponent } from './../../components/edit-note-dialog/edit-note-dialog.component';
import { ConfirmationBaseService } from '@shared/services/confirmation-base.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateFormat, TableAction, ToastService } from '@capturum/ui/api';
import { BehaviorSubject, Observable, switchMap, takeUntil } from 'rxjs';
import { DestroyBase } from '@capturum/shared';
import { filter, tap } from 'rxjs/operators';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { ProjectApiService } from '@features/project/services/project-api.service';
import { ProjectLog } from '@features/project/interfaces/project-log.interface';
import { AuthService } from '@capturum/auth';
import { CapturumFormRendererComponent, FormSaverService } from '@capturum/builders/form-renderer';
import { CapturumBuildersContextService } from '@capturum/builders/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-project-log',
  templateUrl: './project-log.component.html',
  styleUrls: ['./project-log.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectLogComponent extends DestroyBase implements OnInit {
  @ViewChild(CapturumFormRendererComponent)
  public formRenderer: CapturumFormRendererComponent;

  public projectId: string;
  public projectLogs$: Observable<ProjectLog[]>;
  public DateFormat = DateFormat;
  public formKey = 'form_project_activity';
  public updateData = new BehaviorSubject(true);
  public actions: TableAction[];

  constructor(
    private route: ActivatedRoute,
    private projectApiService: ProjectApiService,
    private projectStateService: ProjectStateService,
    private authService: AuthService,
    private formSaver: FormSaverService,
    private toastService: ToastService,
    private contextService: CapturumBuildersContextService,
    private translateService: TranslateService,
    private confirmationBaseService: ConfirmationBaseService,
    private dialogService: DialogService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.projectLogs$ = this.projectStateService.getProject().pipe(
      filter(Boolean),
      tap((project) => {
        this.projectId = project.id;
        this.setContextService();
      }),
      switchMap(() => {
        return this.updateData.asObservable();
      }),
      switchMap(() => {
        return this.projectApiService.getProjectLogData(this.projectId);
      }),
      takeUntil(this.destroy$)
    );

    this.actions = [
      {
        label: this.translateService.instant('button.edit'),
        icon: 'fas fa-pencil-alt',
        callback: (item) => {
          this.openNoteDetailDialog(item);
        },
      },
      {
        label: this.translateService.instant('button.delete'),
        icon: 'fas fa-trash-can',
        callback: (item) => {
          this.delete(item);
        },
      },
    ];
  }

  public delete(note: ProjectLog): void {
    this.confirmationBaseService.confirmationService.confirm({
      header: this.translateService.instant(`market_discovery.project.log.delete_note.label`),
      message: this.translateService.instant(`market_discovery.project.log.note.delete_confirmation.label`),
      accept: () => {
        return this.deleteItem(note.id);
      },
    });
  }

  public submit(): void {
    if (!this.contextService.getContextByKey(this.formKey)) {
      this.setContextService();
    }

    this.formSaver.submit(this.formKey).subscribe(() => {
      this.formRenderer.form.reset();
      this.updateData.next(true);
      this.showSuccessToast('created');
    });
  }

  private deleteItem(id: string): void {
    this.projectApiService.deleteNote(this.projectId, id).subscribe((result) => {
      this.showSuccessToast('deleted');
      this.updateData.next(true);
    });
  }

  private showSuccessToast(action: string): void {
    this.toastService.success(
      this.translateService.instant('toast.success.title'),
      this.translateService.instant(`market_discovery.entity.toast.${action}`, {
        entity: this.translateService.instant('market_discovery.project.log.note.entity.name'),
      })
    );
  }

  private openNoteDetailDialog(note?: ProjectLog): void {
    const dialog = this.dialogService.open(EditNoteDialogComponent, {
      header: this.translateService.instant('market_discovery.form.edit.title', {
        entity: this.translateService.instant('market_discovery.project.log.note.entity.name'),
      }),
      data: {
        id: note?.id,
      },
    });

    dialog.onClose.subscribe((result) => {
      if (result) {
        this.showSuccessToast('updated');
        this.updateData.next(true);
      }
      dialog.destroy();
    });
  }

  private setContextService(): void {
    this.contextService.setContext(this.formKey, { project_id: this.projectId });
  }
}
