<p-table
  class="simple-table project-pipeline-status-table"
  styleClass="cap-info-table"
  [columns]="columns"
  [autoLayout]="false"
  [value]="pipelineStatuses"
  (onRowExpand)="loadPipelineData($event)"
  dataKey="id">
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let column of columns; let first = first" [ngClass]="column.titleClass">
        <ng-container>
          {{ column.title }}
        </ng-container>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-item let-expanded="expanded">
    <tr>
      <ng-container *ngFor="let column of columns; let first = first; let index = index">
        <td *ngIf="first" colspan="6" class="text-left column-status">
          <div [pRowToggler]="item">
            <i class="fas fa-{{ expanded ? 'chevron-down' : 'chevron-right' }}"></i>
            <span>{{ item.name }}</span>
          </div>
        </td>

        <td *ngIf="index > 5">
          {{ item[column.field] }}
        </td>
      </ng-container>
    </tr>
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-item>
    <tr *ngFor="let data of pipelineData.get(item.id) | async">
      <td
        *ngFor="let column of statusColumns; let first = first"
        [class.first-column]="first"
        [ngClass]="column.cellClass">
        <ng-container [ngSwitch]="column.field">
          <ng-container *ngSwitchCase="'action'">
            <div class="text-center">
              <i class="far fa-circle-exclamation" *ngIf="data[column.field]"></i>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'target_at'">
            {{ data.target_at | date : 'dd-MM-yyyy HH:mm' }}
          </ng-container>

          <ng-container *ngSwitchCase="'name'">
            <a class="company-link" (click)="navigateToLeadDetail(data.id)">
              {{ data | nestedColumnValue : column.field }}
            </a>
          </ng-container>

          <ng-container *ngSwitchDefault>
            {{ data | nestedColumnValue : column.field }}
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>
