export enum GripMeterInputEnum {
  rolCp = 'rol_cp',
  decisionMaker = 'decision_maker',
  okCp = 'ok_cp',
  opportunities = 'opportunities',
  wbdvdk = 'wbdvdk',
  inMoney = 'in_money',
  quickDecision = 'quick_decision',
  appointment = 'appointment',
  doneEverything = 'done_everything',
}
