import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { Observable, switchMap } from 'rxjs';
import { Project } from '@features/project/interfaces/project.interface';
import { filter, first, map } from 'rxjs/operators';
import { FormSaverService } from '@capturum/builders/form-renderer';
import { TabsComponent } from '@shared/components/tabs/tabs.component';
import { Question } from '@features/questionnaire/interfaces/questionnaire.interface';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@capturum/ui/api';
import { QuestionnaireApiService } from '@features/questionnaire/services/questionnaire-api.service';

enum QuestionnaireTypes {
  questionnaireId = 'questionnaire_id',
  dmuQuestionnaireId = 'dmu_questionnaire_id',
}

@Component({
  selector: 'app-project-questionnaire',
  templateUrl: './project-questionnaire.component.html',
  styleUrls: ['./project-questionnaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProjectQuestionnaireComponent implements OnInit {
  @ViewChild(TabsComponent)
  public tabsComponent: TabsComponent;

  public questionnaireId$!: Observable<string>;
  public dmuQuestionnaireId$!: Observable<string>;
  public tabs: { label: string; key: QuestionnaireTypes; id: Observable<string> }[];
  public project$: Observable<Project>;
  public questions: Record<QuestionnaireTypes, Question[]>;
  public questionnaireDisabled: boolean[] = [];

  constructor(
    private projectStateService: ProjectStateService,
    private formSaverService: FormSaverService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private questionnaireApiService: QuestionnaireApiService
  ) {}

  public ngOnInit(): void {
    this.project$ = this.projectStateService.getProject().pipe(filter(Boolean));

    this.questionnaireId$ = this.project$.pipe(
      map((project) => {
        return project?.questionnaire_id;
      })
    );

    this.dmuQuestionnaireId$ = this.project$.pipe(
      map((project) => {
        return project?.dmu_questionnaire_id;
      })
    );

    this.tabs = [
      {
        label: this.translateService.instant('market_discovery.project.questionnaire_id.label'),
        key: QuestionnaireTypes.questionnaireId,
        id: this.questionnaireId$,
      },
      {
        label: this.translateService.instant('market_discovery.project.dmu_questionnaire_id.label'),
        key: QuestionnaireTypes.dmuQuestionnaireId,
        id: this.dmuQuestionnaireId$,
      },
    ];
  }

  public handleQuestionChanged(questions: Question[], type: QuestionnaireTypes | string): void {
    this.questions = {
      ...this.questions,
      [type]: questions,
    };
  }

  public submit(): void {
    const project = this.projectStateService.getProjectSnapshot();

    this.formSaverService
      .submit(this.tabs[this.tabsComponent.activeTab].key, {
        project_id: project.id,
        id: project[this.tabs[this.tabsComponent.activeTab].key],
        questions: this.questions?.[this.tabs[this.tabsComponent.activeTab].key],
      })
      .subscribe((response) => {
        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant(`market_discovery.entity.toast.updated`)
        );

        this.projectStateService.setProject({
          ...this.projectStateService.getProjectSnapshot(),
          [this.tabs[this.tabsComponent.activeTab].key]: response.response.data.id,
        });
      });
  }

  public duplicate(): void {
    const isDmu = this.tabsComponent.activeTab === 1;
    const id$ = isDmu ? this.dmuQuestionnaireId$ : this.questionnaireId$;

    id$
      .pipe(
        first(),
        switchMap((id) => {
          return this.questionnaireApiService.duplicateProjectQuestionnaire(
            this.projectStateService.getProjectSnapshot().id,
            id,
            isDmu
          );
        })
      )
      .subscribe((questionnaire) => {
        this.projectStateService.setProject({
          ...this.projectStateService.getProjectSnapshot(),
          [this.tabs[this.tabsComponent.activeTab].key]: questionnaire.id,
        });
      });
  }
}
