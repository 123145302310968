import { ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { filter, switchMap, takeUntil } from 'rxjs';
import { CapturumDialogService, ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { GripMeterInputDialogComponent } from '@features/project/components/grip-meter-input-dialog/grip-meter-input-dialog.component';
import { DialogActionType } from '@core/enums/dialog-action-type.enum';
import { DialogAction } from '@core/interfaces/dialog-action.interface';
import { DestroyBase } from '@capturum/shared';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { LeadDetails } from '@features/project/interfaces/lead-details.interface';
import { LeadApiService } from '@features/lead/services/lead-api.service';

@Component({
  selector: 'app-lead-grip-input',
  templateUrl: './lead-grip-input.component.html',
  styleUrls: ['./lead-grip-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LeadGripInputComponent extends DestroyBase {
  @Input()
  public leadAccountManager: LeadDetails;

  @Input()
  public leadId: string;

  private dialogRef: DynamicDialogRef;

  constructor(
    private leadApiService: LeadApiService,
    private dialogService: CapturumDialogService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  public openGripMeterModal(): void {
    this.dialogRef = this.dialogService.open(GripMeterInputDialogComponent, {
      header: this.translateService.instant('market_discovery.lead.grip-meter.grip-meter-input.title'),
      styleClass: 'grip-meter-input-dialog',
      data: {
        leadId: this.leadId,
      },
    });

    this.dialogRef.onClose
      .pipe(
        filter((result: DialogAction) => {
          return result?.action === DialogActionType.submit;
        }),
        switchMap(({ data }) => {
          return this.leadApiService.saveGripMeterInfo(this.leadId, data);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((gripStatus) => {
        this.leadAccountManager.gripStatus = gripStatus;

        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('market_discovery.entity.toast.updated')
        );

        this.changeDetector.detectChanges();

        this.dialogRef.destroy();
      });
  }
}
