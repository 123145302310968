<app-dialog-layout>
  <ng-container content>
    <div class="grip-meter-input-dialog-content">
      <p class="description">{{ 'market_discovery.lead.grip-meter.grip-meter-input-description.label' | translate }}</p>

      <form *ngIf="isFormReady" [formGroup]="gripMeterInputForm">
        <ng-container *ngFor="let control of controls">
          <cap-checkbox
            class="grip-question"
            [formControlName]="control"
            [label]="'market_discovery.lead.grip-meter.' + control + '.label' | translate">
          </cap-checkbox>
        </ng-container>
      </form>
    </div>
  </ng-container>

  <ng-container buttons>
    <cap-button
      icon="fal fa-times"
      styleClass="secondary"
      [label]="'button.cancel' | translate"
      (onClick)="dialogRef.close()">
    </cap-button>

    <cap-button
      styleClass="primary"
      icon="fas fa-save"
      [label]="'market_discovery.button.save' | translate"
      (onClick)="handleSave()">
    </cap-button>
  </ng-container>
</app-dialog-layout>
