<app-dialog-layout [showFooter]="false">
  <ng-container content>
    <div class="project-lead-selection-details">
      <cap-card [title]="'market_discovery.lead.general-info.label' | translate" styleClass="mb-4">
        <ng-template capTemplate="content">
          <cpb-form-renderer [showHeader]="false" [formKey]="formKey" [modelId]="modelId" [readOnly]="true">
          </cpb-form-renderer>
        </ng-template>
      </cap-card>

      <cap-card [title]="'market_discovery.lead.project_info.label' | translate" class="project-details">
        <ng-template capTemplate="content">
          <app-project-info-meta [leadMetaKeys]="leadMetaKeys$ | async"></app-project-info-meta>
        </ng-template>
      </cap-card>
    </div>
  </ng-container>
</app-dialog-layout>
