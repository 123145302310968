import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { Observable, switchMap } from 'rxjs';
import { BatchStatusService, FinishedBatchStatus } from '@capturum/complete';

@Injectable({
  providedIn: 'root',
})
export class SelectionApiService extends ApiService<any> {
  protected endpoint = 'selection';

  constructor(apiHttp: ApiHttpService, private batchStatusService: BatchStatusService) {
    super(apiHttp);
  }

  public export(id: string): Observable<FinishedBatchStatus> {
    return this.apiHttp.get(`/${this.endpoint}/${id}/companies/export`).pipe(
      switchMap((response: any) => {
        return this.batchStatusService.getIsUpdatedBatch(
          response.data.batch_id,
          true,
          'market_discovery.selection.export.success',
          true
        );
      })
    );
  }

  public exportSelection(projectId: string, listOptions?: ListOptions): Observable<FinishedBatchStatus> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this.apiHttp
      .post(`/project/${projectId}/active-selection/export${this.getOptionsQuery(listOptions)}`, { timezone })
      .pipe(
        switchMap((response: any) => {
          return this.batchStatusService.getIsUpdatedBatch(
            response.batch_id,
            true,
            'market_discovery.selection.export.success',
            true
          );
        })
      );
  }
}
