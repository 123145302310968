<div [formGroup]="formGroup" *ngIf="options" class="radio-buttons">
  <div *ngFor="let option of options" class="p-field-radiobutton radio-button">
    <div class="radio-option-container">
      <p-radioButton [value]="option.id" formControlName="option_id" [inputId]="option.id" name="option_id">
      </p-radioButton>

      <label [for]="option.id">{{ option.value }}</label>
    </div>

    <div class="question-option-description" *ngIf="formGroup.get('option_id').value === option.id">
      {{ option.description }}
    </div>

    <div class="free-text-input" *ngIf="formGroup.get('option_id').value === option.id && option.is_free_text">
      <cap-input formControlName="answer_text"></cap-input>
    </div>
  </div>
</div>
