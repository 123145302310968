import { DialogActionType } from './../../../../core/enums/dialog-action-type.enum';
import { Component, OnInit } from '@angular/core';
import { MapItem } from '@capturum/ui/api';
import { Lead } from '@features/lead/interfaces/lead.interface';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';

export interface DropdownEvent {
  originalEvent: Event;
  value: string;
}

@Component({
  selector: 'app-transfer-leads-dialog',
  templateUrl: './transfer-leads-dialog.component.html',
  styleUrls: ['./transfer-leads-dialog.component.scss'],
})
export class TransferLeadsDialogComponent implements OnInit {
  public leads: Lead[];
  public selectedAccountManager: string;
  public accountManagers$: Observable<MapItem[]>;

  constructor(public dialogRef: DynamicDialogRef, private dialogConfig: DynamicDialogConfig) {}

  public ngOnInit(): void {
    this.leads = this.dialogConfig.data?.leads;
    this.accountManagers$ = this.dialogConfig.data?.accountManagers;
  }

  public handleChangeSelectOption(event: DropdownEvent): void {
    this.selectedAccountManager = event.value;
  }

  public handleSave(): void {
    this.dialogRef.close({
      action: DialogActionType.submit,
      data: {
        lead_ids: this.leads.map((lead) => {
          return lead.id;
        }),
        account_manager_id: this.selectedAccountManager,
      },
    });
  }
}
