import { Injectable } from '@angular/core';
import { InputConfiguration } from '@capturum/builders/core';
import { CheckboxInputTypeBuilder } from '@capturum/builders/form-renderer';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({ providedIn: 'root' })
export class CheckboxInputBuilder extends CheckboxInputTypeBuilder {
  protected appendOptions(input: FormlyFieldConfig, configuration: InputConfiguration): void {
    super.appendOptions(input, configuration);

    input.props.activeTranslationKey = 'market_discovery.checkbox.active';
    input.props.inactiveTranslationKey = 'market_discovery.checkbox.inactive';
  }
}
