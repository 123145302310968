<div class="lead-actions">
  <div class="lead-action-header">
    <div class="lead-action-header-title">
      {{ 'market_discovery.action.overview.title' | translate }}
    </div>

    <cap-button
      styleClass="secondary round"
      *ngxPermissionsOnly="'action.create'"
      [label]="'market_discovery.action.button.new' | translate"
      icon="fas fa-plus"
      (onClick)="addAction()"></cap-button>
  </div>

  <div class="lead-action-list open-list" *ngIf="openActions$ | async as openActions">
    <ng-container *ngIf="openActions.length; else noActions">
      <div *ngFor="let action of openActions" class="lead-action-item">
        <app-lead-action-item [action]="action" (edit)="editAction($event)"></app-lead-action-item>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="completedActions$ | async as completedActions">
    <ng-container *ngIf="completedActions.length">
      <div class="lead-action-header">
        <div class="lead-action-header-title">
          {{ 'market_discovery.action.previous-actions' | translate }}
        </div>
      </div>

      <div class="lead-action-list">
        <div *ngFor="let action of completedActions" class="lead-action-item">
          <app-lead-action-item [action]="action" (edit)="editAction($event)"></app-lead-action-item>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #noActions>
  {{ 'market_discovery.action.no-actions' | translate }}
</ng-template>
