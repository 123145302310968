import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FullscreenService {
  private renderer: Renderer2;
  private isFullscreen$ = new Subject<boolean>();

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public getIsFullScreen(): Observable<boolean> {
    return this.isFullscreen$.asObservable();
  }

  public openFullscreenMode(appendClassToBody = false): void {
    const doc = document.documentElement;

    if (doc.requestFullscreen) {
      doc.requestFullscreen();

      if (appendClassToBody) {
        this.renderer.addClass(document.body, 'fullscreen');
      }

      this.isFullscreen$.next(true);
      addEventListener('fullscreenchange', this.handleExitFullscreen.bind(this));
    }
  }

  public exitFullscreenMode(): void {
    if (document.exitFullscreen && this.isFullscreen()) {
      document.exitFullscreen();

      this.renderer.removeClass(document.body, 'fullscreen');
    }

    this.isFullscreen$.next(false);
  }

  public isFullscreen(): boolean {
    return window.innerHeight === screen.height;
  }

  private handleExitFullscreen(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (!document.fullscreenElement) {
      this.renderer.removeClass(document.body, 'fullscreen');
      this.isFullscreen$.next(false);
    }

    removeEventListener('fullscreenchange', this.handleExitFullscreen);
  }
}
