import { TranslateService } from '@ngx-translate/core';
import { MetaValue } from './../../../meta-key/interfaces/meta-key.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LeadApiService } from '@features/lead/services/lead-api.service';
import { ProjectLeadMetaKeys } from '@features/meta-key/interfaces/meta-key.interface';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MapItem, ToastService } from '@capturum/ui/api';
import { LabelPosition } from '@capturum/builders/core';

const keyFilterMap = {
  integer: 'int',
  double: 'num',
};

@Component({
  selector: 'app-project-info-edit',
  templateUrl: './project-info-edit.component.html',
  styleUrls: ['./project-info-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectInfoEditComponent implements OnInit {
  public leadId: string;
  public leadMetaKeys$: Observable<ProjectLeadMetaKeys[]>;
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public model: unknown = {};
  public fields: FormlyFieldConfig[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private leadApiService: LeadApiService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.leadId = this.route.snapshot.paramMap.get('leadId');
    this.leadApiService
      .getProjectLeadMetaKeys(this.leadId, { include: ['metaValues,type', 'options'] })
      .subscribe((result) => {
        this.generateFields(result);
      });
  }

  public submit(): void {
    const formData: MetaValue[] = Object.keys(this.model).map((key) => {
      return {
        meta_key_id: key,
        value: this.model[key],
      };
    });

    this.leadApiService.storeProjectLeadMetaKeys(this.leadId, formData).subscribe((response) => {
      if (response) {
        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('market_discovery.entity.toast.updated')
        );

        this.navigateBack();
      }
    });
  }

  public navigateBack(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private generateFields(metaKeys: ProjectLeadMetaKeys[]): void {
    const typeMapping = {
      dropdown: 'cpb-dropdown',
      integer: 'metaTextInput',
      string: 'metaTextInput',
      double: 'metaTextInput',
      boolean: 'cpb-dropdown',
    };
    const translation: string = this.translateService.instant('market_discovery.meta-value.initial-value');

    this.fields = metaKeys.map((meta) => {
      const value = meta.metaValues[0]?.value;
      const field = {
        key: meta.id,
        type: typeMapping[meta.type?.value.toLowerCase()],
        defaultValue: meta.metaValues[0]?.meta_option_id ?? value,
        wrappers: ['meta-value-wrapper'],
        templateOptions: {
          label_position: LabelPosition.Above,
          label: meta.name,
          subLabel: value ? `${translation} ${value}` : '',
          keyFilter: keyFilterMap[meta.type?.value.toLowerCase()] || new RegExp(''),
          sort_asc: false,
          options$:
            meta.type?.value.toLowerCase() === 'boolean'
              ? new BehaviorSubject<MapItem[]>([
                  {
                    key: this.translateService.instant('general.yes'),
                    label: this.translateService.instant('general.yes'),
                    value: this.translateService.instant('general.yes'),
                  },
                  {
                    value: this.translateService.instant('general.no'),
                    label: this.translateService.instant('general.no'),
                    key: this.translateService.instant('general.no'),
                  },
                ])
              : of(
                  meta?.options?.map((option) => {
                    return {
                      value: option.id,
                      key: option.id,
                      label: option.name,
                    };
                  })
                ) ?? [],
        },
      };

      return field;
    });
  }
}
