import { ProjectApiService } from 'src/app/features/project/services/project-api.service';
import { DestroyBase } from '@capturum/shared';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { of, switchMap, takeUntil } from 'rxjs';
import { ProjectStateService } from 'src/app/features/project/services/project-state.service';
import { AuthService, MapItem } from '@capturum/auth';
import User from '@capturum/auth/lib/user.interface';
import { Project } from '../../interfaces/project.interface';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enums/routes.enum';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-project-selector-dialog',
  templateUrl: './project-selector-dialog.component.html',
  styleUrls: ['./project-selector-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSelectorDialogComponent extends DestroyBase implements OnInit, OnDestroy {
  public options: MapItem[];
  public user: User;
  public selectedProject: string;

  constructor(
    public dialogRef: DynamicDialogRef,
    private renderer: Renderer2,
    private projectApiService: ProjectApiService,
    private projectStateService: ProjectStateService,
    private authService: AuthService,
    private router: Router,
    private dialogConfig: DynamicDialogConfig
  ) {
    super();
  }

  public ngOnInit(): void {
    this.renderer.addClass(document.body, 'dialog-open');
    this.user = this.authService.getUser();

    this.options = this.dialogConfig.data.projectList;

    this.dialogRef.onClose
      .pipe(
        takeUntil(this.destroy$),
        switchMap((action) => {
          if (action && action === 'select') {
            return this.projectApiService.get(this.selectedProject).pipe(
              map((project) => {
                return {
                  action,
                  project,
                };
              })
            );
          }

          return of({ action, project: null });
        })
      )
      .subscribe(({ action, project }: { action: string; project: Project }) => {
        if (action && action === 'select' && project) {
          this.projectStateService.setProject(project);
          this.router.navigate([`${AppRoutes.project}/${project.id}/${AppRoutes.xyz}`]);
        }
      });
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'dialog-open');
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
