import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';

import { hmrBootstrap } from './hmr';
import 'hammerjs';

import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  enableProdMode();
}

const shareReplay = (environment.name === 'Test' || environment.name === 'Acceptatie') ? {
  replaysSessionSampleRate: 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
} : null;

Sentry.init({
  dsn: environment.sentryUrl,
  normalizeDepth: 0,
  environment: environment.name,
  ignoreErrors: [
    'Non-Error exception captured',
    'NG0100: ExpressionChangedAfterItHasBeenCheckedError',
  ],
  ...shareReplay,
});

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
  }
} else {
  bootstrap().catch(err => console.error(err));
}
