import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { Customer } from '@features/customer/interfaces/customer.interface';
import { MapItem } from '@capturum/ui/api';
import { Observable } from 'rxjs';
import { responseData, toMapItems } from '@capturum/builders/core';
import { XyzFormatedResult, XyzResponse } from '@features/project/interfaces/xyz.interface';
import { map } from 'rxjs/operators';
import { XyzService } from '@features/xyz/service/xyz.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerApiService extends ApiService<Customer> {
  protected endpoint = 'customer';

  constructor(private apiHttpService: ApiHttpService, private xyzService: XyzService) {
    super(apiHttpService);
  }

  public listAccountManagers(customerId: string, options?: ListOptions): Observable<MapItem[]> {
    return this.apiHttp
      .get(`/${this.endpoint}/${customerId}/account-managers${this.getOptionsQuery(options)}`)
      .pipe(toMapItems);
  }

  public listSalesManagers(customerId: string): Observable<MapItem[]> {
    return this.apiHttp.get(`/${this.endpoint}/${customerId}/sales-managers`).pipe(toMapItems);
  }

  public listAgents(customerId: string): Observable<MapItem[]> {
    return this.apiHttp.get(`/${this.endpoint}/${customerId}/agents`).pipe(toMapItems);
  }

  public listManagers(customerId: string): Observable<MapItem[]> {
    return this.apiHttp.get(`/${this.endpoint}/${customerId}/managers`).pipe(toMapItems);
  }

  public listProjects(customerId: string): Observable<MapItem[]> {
    return this.apiHttp.get(`/${this.endpoint}/${customerId}/projects`).pipe(toMapItems);
  }

  public getXyz(customerId: string, options: ListOptions): Observable<XyzFormatedResult> {
    const apiOptions = {
      ...options,
      parameters: options.parameters.map((parameter) => {
        if (Array.isArray(parameter?.value)) {
          return {
            ...parameter,
            value: parameter.value.join(','),
          };
        }

        return parameter;
      }),
    };

    return this.apiHttp
      .get<{ data: XyzResponse }>(`/${this.endpoint}/${customerId}/xyz${this.getOptionsQuery(apiOptions)}`)
      .pipe(
        responseData,
        map((xyz) => {
          return this.xyzService.formatXyz(xyz);
        })
      );
  }

  public listSources(customerId: string, options: ListOptions): Observable<MapItem[]> {
    return this.apiHttp.get(`/${this.endpoint}/${customerId}/sources${this.getOptionsQuery(options)}`).pipe(toMapItems);
  }
}
