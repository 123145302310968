import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListOptions } from '@capturum/api';
import { CapturumBuildersContextService } from '@capturum/builders/core';
import { FormRendererService } from '@capturum/builders/form-renderer';
import { DestroyBase } from '@capturum/shared';
import { MapItem, ToastService } from '@capturum/ui/api';
import { AppRoutes } from '@core/enums/routes.enum';
import { Lead } from '@features/lead/interfaces/lead.interface';
import { LeadApiService } from '@features/lead/services/lead-api.service';
import { LeadDetails } from '@features/project/interfaces/lead-details.interface';
import { Project } from '@features/project/interfaces/project.interface';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { TranslateService } from '@ngx-translate/core';
import { PrintService } from '@shared/services/print.service';
import { LocalStorageService } from '@shared/services/storage.service';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  map,
  Observable,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
  zip,
} from 'rxjs';

import { PrevNextLead } from './../../../lead/interfaces/lead.interface';
import { ProjectLeadMetaKeys } from './../../../meta-key/interfaces/meta-key.interface';
import { BaseDataService } from '@shared/services/base-data.service';

@Component({
  selector: 'app-project-lead-detail',
  templateUrl: './project-lead-detail.component.html',
  styleUrls: ['./project-lead-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectLeadDetailComponent extends DestroyBase implements OnInit {
  public leadId: string;
  public lead$: Observable<Lead>;
  public leadAccountManager$: Observable<LeadDetails>;
  public leadMetaKeys$: Observable<ProjectLeadMetaKeys[]>;
  public isFirst: Observable<boolean>;
  public isLast: Observable<boolean>;
  public project$: Observable<Project>;
  public apiOptions: ListOptions;
  public previousLead$: Observable<PrevNextLead>;
  public nextLead$: Observable<PrevNextLead>;
  public isNavigating = true;
  public accountManagerContext: { lead_id: string; is_edit: boolean; projectType: string; project_id: string };
  public projectId: string;
  public backRoute: string;
  public loadingData = true;
  public loadingAccountManagerData = true;
  public loadingMetaData = true;

  private projectTypeBaseData: MapItem[] = [];
  private leadDataReadySubject = new BehaviorSubject<boolean>(false);
  private leadAccountManagerDataReadySubject = new BehaviorSubject<boolean>(false);
  private leadMetaDataReadySubject = new BehaviorSubject<boolean>(false);

  constructor(
    private route: ActivatedRoute,
    private formRendererService: FormRendererService,
    private leadApiService: LeadApiService,
    private projectStateService: ProjectStateService,
    private storageService: LocalStorageService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private printService: PrintService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private contextService: CapturumBuildersContextService,
    private baseDataService: BaseDataService
  ) {
    super();
    this.apiOptions = this.storageService.get('apiOptions-project-leads');
  }

  public ngOnInit(): void {
    this.backRoute = this.route.snapshot.queryParamMap.get('redirectUrl') || '..';

    this.project$ = this.projectStateService.getProject().pipe(
      filter(Boolean),
      tap((project) => {
        return (this.projectId = project.id);
      }),
      takeUntil(this.destroy$),
      shareReplay(1)
    );

    combineLatest([
      this.project$.pipe(
        switchMap((project) => {
          return this.route.paramMap.pipe(
            map((params) => {
              return {
                params,
                project,
              };
            })
          );
        })
      ),
      this.baseDataService.list('project_type'),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([route, projectTypes]) => {
        // set context to null first to detect changes if navigate to previous or next lead
        this.accountManagerContext = null;

        this.projectTypeBaseData = projectTypes;

        this.loadLeadDetails(route.params.get('leadId'), route.project.id);
      });
  }

  public next(): void {
    this.isNavigating = true;

    this.setLeadDataReady(false);
    this.nextLead$.pipe(first()).subscribe((nextLead) => {
      this.apiOptions.page = nextLead.new_page;

      this.navigateToLead(nextLead.id);
    });
  }

  public previous(): void {
    this.isNavigating = true;

    this.setLeadDataReady(false);
    this.previousLead$.pipe(first()).subscribe((previousLead) => {
      this.apiOptions.page = previousLead.new_page;

      this.navigateToLead(previousLead.id);
    });
  }

  public exportLead(): void {
    this.printService.setPrintBusy(true);

    this.toastService.info(
      this.translateService.instant('market_discovery.button.export'),
      this.translateService.instant('market_discovery.lead.export.started')
    );

    this.leadApiService
      .exportDetailsPdf(this.projectStateService.getProjectSnapshot().id, this.leadId)
      .subscribe((response) => {
        if (response.finished) {
          this.printService.setPrintBusy(false);
        }
      });
  }

  public searchGoogle(): void {
    this.lead$.pipe(first()).subscribe((lead) => {
      window.open(`https://www.google.com/search?q=${lead.name}`, '_blank');
    });
  }

  private loadLeadDetails(leadId: string, projectId: string): void {
    this.loadingData = true;
    this.loadingAccountManagerData = true;
    this.loadingMetaData = true;

    this.setLeadDataReady(false);
    this.changeDetectorRef.detectChanges();

    this.leadId = leadId;

    const projectSnapshot = this.projectStateService.getProjectSnapshot();

    const projectType =
      projectSnapshot?.projectType?.value ||
      this.projectTypeBaseData
        ?.find((type) => {
          return type.value === projectSnapshot.project_type_base_data_value_id;
        })
        ?.label.toLowerCase();

    if (projectType) {
      this.contextService.setContext('form_lead_accountmanager', {
        lead_id: leadId,
        is_edit: false,
        projectType: projectType,
        project_id: projectId,
      });

      this.accountManagerContext = {
        lead_id: leadId,
        is_edit: false,
        projectType: projectType,
        project_id: projectId,
      };
    }

    this.lead$ = this.formRendererService.getSourceValueByKey('form_lead_general').pipe(
      filter(Boolean),
      tap((value) => {
        if (value.id === leadId) {
          this.loadingData = false;
          this.leadDataReadySubject.next(true);
        }
      }),
      shareReplay(1)
    );
    this.leadAccountManager$ = this.formRendererService.getSourceValueByKey('form_lead_accountmanager').pipe(
      filter(Boolean),
      tap((value) => {
        if (value.id === leadId) {
          this.loadingAccountManagerData = false;
          this.leadAccountManagerDataReadySubject.next(true);
        }
      }),
      shareReplay(1)
    );
    this.leadMetaKeys$ = this.leadApiService.getProjectLeadMetaKeys(this.leadId, { include: ['metaValues,type'] }).pipe(
      tap(() => {
        this.loadingMetaData = false;
        this.leadMetaDataReadySubject.next(true);
      })
    );

    zip(this.leadDataReadySubject, this.leadAccountManagerDataReadySubject, this.leadMetaDataReadySubject)
      .pipe(
        filter((value) => {
          return value.every((ready) => {
            return ready;
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.isNavigating = false;
      });

    this.previousLead$ = this.project$.pipe(
      switchMap((project) => {
        return this.leadApiService.getPreviousLead(project.id, this.leadId, this.apiOptions);
      }),
      shareReplay(1)
    );
    this.nextLead$ = this.project$.pipe(
      switchMap((project) => {
        return this.leadApiService.getNextLead(project.id, this.leadId, this.apiOptions);
      }),
      shareReplay(1)
    );
    this.isFirst = this.previousLead$.pipe(
      map((prevLead) => {
        return prevLead.id === null;
      })
    );
    this.isLast = this.nextLead$.pipe(
      map((nextLead) => {
        return nextLead.id === null;
      })
    );

    this.changeDetectorRef.detectChanges();
  }

  private navigateToLead(leadId: string): void {
    if (leadId) {
      this.router.navigate([`/${AppRoutes.project}/${this.projectId}/leads/${leadId}`]).then(() => {
        setTimeout(() => {
          this.changeDetectorRef.detectChanges();
        }, 300);
      });
    } else {
      this.isNavigating = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  private setLeadDataReady(ready: boolean): void {
    this.leadDataReadySubject.next(ready);
    this.leadAccountManagerDataReadySubject.next(ready);
    this.leadMetaDataReadySubject.next(ready);
  }
}
