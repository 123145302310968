import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, tap } from 'rxjs';
import { Roles } from '@core/enums/roles.enum';
import { MapItem } from '@capturum/ui/api';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from '@core/enums/routes.enum';

@Component({
  selector: 'app-stakeholder-selection',
  templateUrl: './stakeholder-selection.component.html',
  styleUrls: ['./stakeholder-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StakeholderSelectionComponent implements OnInit {
  @Input()
  public roleId: string;

  @Input()
  public customerId: string;

  @Input()
  public roleKey: Roles;

  @Input()
  public roleTranslationKey: string;

  @Output()
  public save = new EventEmitter<MapItem[]>();

  @Output()
  public delete = new EventEmitter<MapItem>();

  @Output()
  public changeSelection = new EventEmitter<MapItem[]>();

  public availableStakeholders$: Observable<MapItem[]>;
  public selectedOptions = new BehaviorSubject<MapItem[]>([]);
  public selectedOptions$: Observable<MapItem[]>;
  public availableOptions$: Observable<MapItem[]>;

  private stakeholders$ = new BehaviorSubject<MapItem[]>([]);
  private allUsers$ = new BehaviorSubject<MapItem[]>([]);

  constructor(private route: ActivatedRoute, private router: Router) {}

  @Input()
  public set projectStakeholders(value: MapItem[]) {
    if (value) {
      this.stakeholders$.next(value);
    }
  }

  @Input()
  public set allUsers(value: MapItem[]) {
    if (value) {
      this.allUsers$.next(value);
    }
  }

  public ngOnInit(): void {
    this.selectedOptions$ = this.stakeholders$.pipe(
      switchMap((userList) => {
        this.selectedOptions.next([...userList, ...(this.selectedOptions.getValue() || [])]);

        return this.selectedOptions.asObservable();
      }),
      tap(() => {
        this.emitChanges();
      }),
      shareReplay()
    );

    this.availableStakeholders$ = this.allUsers$.pipe(
      tap((options: MapItem[]) => {
        const queryParams = this.route.snapshot.queryParams;

        if (
          queryParams?.userId &&
          !this.selectedOptions.getValue().some((selectedOption) => {
            return selectedOption.value === queryParams.userId;
          })
        ) {
          const user = options.find((option) => {
            return option.value === queryParams.userId;
          });

          if (user) {
            this.selectedOptions.next([...this.selectedOptions.getValue(), user]);
          }
        }
      })
    );

    this.availableOptions$ = combineLatest([
      this.selectedOptions$.pipe(filter(Boolean)),
      this.availableStakeholders$.pipe(filter(Boolean)),
    ]).pipe(
      map(([selectedOptions, allOptions]) => {
        return allOptions.filter((option) => {
          return !selectedOptions.some((selectedOption) => {
            return selectedOption.value === option.value;
          });
        });
      })
    );
  }

  public handleDelete(value: MapItem): void {
    this.selectedOptions.next(
      this.selectedOptions.getValue().filter((option) => {
        return option.value !== value.value;
      })
    );
  }

  public handleSelectStakeholder(value: string, options: any[]): void {
    const selectedItem = options.find((option) => {
      return option.value === value;
    });

    if (selectedItem) {
      this.selectedOptions.next([...this.selectedOptions.getValue(), selectedItem]);
    }
  }

  public createNewUser(): void {
    this.router.navigate([`${AppRoutes.user}/add`], {
      queryParams: {
        redirectUrl: this.router.url.split('?')[0],
        current_role_id: this.roleId,
        customer_id: this.customerId,
      },
    });
  }

  private emitChanges(): void {
    this.changeSelection.emit(this.selectedOptions.getValue());
  }
}
