import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { format, isValid, isMatch } from 'date-fns';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerComponent extends FieldType implements OnInit {
  public innerFormControl = new UntypedFormControl();
  private destroy$ = new Subject<boolean>();

  public ngOnInit(): void {
    const defaultValue = (this.formControl as any).defaultValue || this.formControl.value;

    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.setFieldValue(value);
      }
    });

    if (defaultValue) {
      this.setFieldValue(defaultValue);
    }

    this.innerFormControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((value) => {
          return isValid(value);
        })
      )
      .subscribe((value) => {
        this.formControl.setValue(format(value, 'HH:mm'), { emitEvent: true });
      });
  }

  private setFieldValue(value: string): void {
    const [hours, minutes]: string[] = value.split(':');
    const date = new Date();

    if (hours !== undefined && minutes !== undefined) {
      date.setMinutes(+minutes);
      date.setHours(+hours);
      this.innerFormControl.setValue(date, { emitEvent: false });
    }
  }
}
