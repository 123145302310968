import { QuestionType } from './../../enums/question-type.enum';
import { QuestionInputTypeDmusComponent } from './dmus-input-type/dmus-input-type.component';
import { QuestionInputTypeDropdownSingleComponent } from './dropdown-single-input-type/dropdown-single-input-type.component';
import { QuestionInputTypeEmptyComponent } from './empty-input-type/empty-input-type.component';
import { QuestionInputTypeMultipleChoiceSingleComponent } from './multiple-choice-single-input-type/multiple-choice-single-input-type.component';
import { QuestionInputTypeOpenQuestionComponent } from './open-question-input-type/open-question-input-type.component';
import { QuestionInputTypeOpenQuestionLargeComponent } from './open-question-large-input-type/open-question-large-input-type.component';
import { AddressInputTypeComponent } from '@features/questionnaire/components/question-input-types/address-input-type/address-input-type.component';
import { ContactInputTypeComponent } from '@features/questionnaire/components/question-input-types/contact-input-type/contact-input-type.component';

export const questionInputTypeComponents = [
  { name: QuestionType.OPEN_QUESTION, component: QuestionInputTypeOpenQuestionComponent },
  { name: QuestionType.OPEN_QUESTION_LARGE_TEXTBOX, component: QuestionInputTypeOpenQuestionLargeComponent },
  { name: QuestionType.MULTIPLE_CHOICE_SINGLE_ANSWER, component: QuestionInputTypeMultipleChoiceSingleComponent },
  { name: QuestionType.DROPDOWN_SINGLE_ANSWER, component: QuestionInputTypeDropdownSingleComponent },
  { name: QuestionType.YES_NO, component: QuestionInputTypeMultipleChoiceSingleComponent },
  { name: QuestionType.EMPTY, component: QuestionInputTypeEmptyComponent },
  { name: QuestionType.DMUS, component: QuestionInputTypeDmusComponent },
  { name: QuestionType.VISITING_ADDRESS, component: AddressInputTypeComponent },
  { name: QuestionType.MAILING_ADDRESS, component: AddressInputTypeComponent },
  { name: QuestionType.COMPANY_NAME, component: QuestionInputTypeOpenQuestionComponent },
  { name: QuestionType.CONTACT, component: ContactInputTypeComponent },
];
