import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  Question,
  QuestionAnswer,
  QuestionInputTypeComponent,
  QuestionOption,
  UpdateAnswer,
} from '@features/questionnaire/interfaces/questionnaire.interface';
import { Address } from '@core/interfaces/address.interface';
import { BehaviorSubject, filter, map, Observable, takeUntil } from 'rxjs';
import { Lead } from '@features/lead/interfaces/lead.interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseDataKeyService } from '@capturum/complete';
import { MapItem, ToastService } from '@capturum/ui/api';
import { DestroyBase, FormHelper } from '@capturum/shared';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@capturum/auth';
import { QuestionType } from '@features/questionnaire/enums/question-type.enum';

interface AddressAnswer {
  address_id: string;
  address?: Partial<Address>;
}

interface AddressQuestionFormGroup {
  address_id: FormControl<string>;
  address: FormControl<Address>;
}

interface AddressFormGroup {
  street: FormControl<string>;
  phone: FormControl<string>;
  housenumber: FormControl<string>;
  city: FormControl<string>;
  zipcode: FormControl<string>;
  country_base_data_value_id: FormControl<string>;
}

@Component({
  selector: 'app-address-input-type',
  templateUrl: './address-input-type.component.html',
  styleUrls: ['./address-input-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddressInputTypeComponent
  extends DestroyBase
  implements QuestionInputTypeComponent<AddressAnswer>, OnInit
{
  @Input()
  public hideOtherOption: boolean;

  public answer$: BehaviorSubject<AddressAnswer> = new BehaviorSubject<AddressAnswer>(null);
  public lead$: BehaviorSubject<Lead> = new BehaviorSubject<Lead>(null);
  public options: Observable<QuestionOption[]> | QuestionOption[];
  public question: Question;
  public updateAnswer: EventEmitter<UpdateAnswer> = new EventEmitter<UpdateAnswer>();
  public formGroup: FormGroup<AddressQuestionFormGroup>;
  public showAddressForm: boolean;
  public addressForm: FormGroup<AddressFormGroup>;
  public countries$: Observable<MapItem[]>;
  public newAddressValue = 'new';
  public addresses$: Observable<Address[]>;

  constructor(
    private formBuilder: FormBuilder,
    private baseDataKeyService: BaseDataKeyService,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {
    super();

    this.baseDataKeyService.extractBaseDataKeys(this.authService.getUser());
  }

  public ngOnInit(): void {
    this.formGroup = this.getForm();
    this.addressForm = this.getAddressForm();
    this.countries$ = this.baseDataKeyService.getBaseDataKeyValues('country');

    this.addresses$ = this.lead$.pipe(
      filter(Boolean),
      map((lead) => {
        if (this.question?.type?.value === QuestionType.VISITING_ADDRESS) {
          return lead.companyAddresses;
        }

        return lead.mailAddresses;
      })
    );

    this.answer$.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe((answer) => {
      return this.setAnswer(answer);
    });

    this.formGroup
      .get('address_id')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.showAddressForm = value === this.newAddressValue;
      });
  }

  public validate(): boolean {
    FormHelper.markAsTouched(this.addressForm);
    this.cdr.detectChanges();

    if (this.formGroup.valid) {
      if (this.formGroup.value.address_id === 'new') {
        if (this.addressForm.valid) {
          this.updateAnswer.emit({
            id: this.question.id,
            value: {
              address_id: null,
              address: this.addressForm.value,
            },
          });

          return true;
        } else {
          this.toastService.error(
            this.translateService.instant('toast.error.title'),
            this.translateService.instant('market_discovery.validation.questionnaire.question.required')
          );
        }
      } else {
        this.updateAnswer.emit({
          id: this.question.id,
          value: {
            address_id: this.formGroup.value.address_id,
            address: null,
          },
        });

        return true;
      }
    }

    return false;
  }

  public setAnswer(answer: QuestionAnswer): void {
    this.formGroup.patchValue(answer);

    if (answer.address) {
      this.addressForm.patchValue(answer.address);
      this.formGroup.patchValue({ address_id: this.newAddressValue });
      this.showAddressForm = true;
    }
  }

  public getAddressForm(): FormGroup<AddressFormGroup> {
    return this.formBuilder.group({
      street: ['', Validators.required],
      zipcode: ['', Validators.required],
      country_base_data_value_id: ['', Validators.required],
      city: ['', Validators.required],
      phone: ['', Validators.required],
      housenumber: ['', Validators.required],
    });
  }

  public getAnswer(): AddressAnswer {
    const addressId = this.formGroup.value.address_id;

    return {
      address_id: addressId,
      address: addressId === this.newAddressValue ? this.addressForm.value : null,
    };
  }

  private getForm(): FormGroup<AddressQuestionFormGroup> {
    return this.formBuilder.group({
      address: [],
      address_id: ['', this.question?.is_required ? Validators.required : null],
    });
  }
}
