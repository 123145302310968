import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  public printButtonVisible$: Observable<boolean>;
  public handlePrintToPdf: Subject<void> = new Subject<void>();
  public printButtonVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _printBusySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.printButtonVisible$ = this.printButtonVisibleSubject.asObservable();
  }

  public getPrintBusy(): Observable<boolean> {
    return this._printBusySubject.asObservable();
  }

  public setPrintBusy(busy: boolean) {
    this._printBusySubject.next(busy);
  }

  public printWindow(targetWindow?: Window): void {
    const target = targetWindow ? targetWindow : window;

    target.print();
  }

  public setPrintButtonVisible(value: boolean): void {
    this.printButtonVisibleSubject.next(value);
  }

  public printToPdf(): void {
    this.handlePrintToPdf.next();
  }
}
