import { NotificationService } from '@shared/services/notification.service';
import { LocalStorageService } from '@shared/services/storage.service';
import { OnlineStatusType } from './../../enums/online-status-type.enum';
import { environment } from '@environments/environment';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { IdleUserService } from '@capturum/auth';
import { AppRoutes } from '@core/enums/routes.enum';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { catchError, finalize, first, of, switchMap, takeUntil, Subject, Observable, filter } from 'rxjs';
import { NetworkStatusService } from '@shared/services/network-status.service';
import { getTime } from 'date-fns';
import { AuthService } from '@shared/services/auth.service';
import { DialogService } from 'primeng/dynamicdialog';
import { CapturumDialogService } from '@capturum/ui/api';
import { LeadApiService } from '../../../features/lead/services/lead-api.service';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
  providers: [DialogService, CapturumDialogService],
})
export class BasicLayoutComponent implements OnInit, OnDestroy {
  public idleToastKey = 'idle-toast';
  public expiryKey = 'user.idle.expiry';

  private timeoutDurationInMilliseconds = environment.timeoutDuration * 1000;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private idleExpiration: number;
  private loggedOutSubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly translateService: TranslateService,
    private idleUserService: IdleUserService,
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router,
    private networkStatusService: NetworkStatusService,
    private storageService: LocalStorageService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private capturumDialogService: CapturumDialogService,
    private leadService: LeadApiService
  ) {}

  @HostListener('window:pagehide', ['$event'])
  public pageHide(event: PageTransitionEvent): void {
    // setTimeout is needed here, setTimeout will not be triggered on refresh page
    // check https://wd.imgix.net/image/eqprBhZUGfb8WYnumQ9ljAxRrA72/1mN2saGWo57O6vIOdcGy.svg for Page lifecycle diagram
    setTimeout(() => {
      if (document.visibilityState === 'hidden') {
        this.storageService.clear();
      }
    }, 0);
  }

  public ngOnInit(): void {
    this.idleExpiration = this.storageService.get(this.expiryKey);

    this.checkIdleExpiration();
    this.notificationService.setOpenMutationsTimer();
    this.idleUserService.watchIdle();

    this.listenIdleTimeout();
    this.checkNetworkStatus();
  }

  public ngOnDestroy(): void {
    this.notificationService.destroyOpenMutationsTimer();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public idleToastClose(): void {
    this.messageService.clear();
    this.idleUserService.resetTimer();
    this.listenIdleTimeout();
  }

  private listenIdleTimeout(): void {
    this.idleUserService
      .getIdleStart()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.idleExpiration = this.storageService.get(this.expiryKey);
      });

    this.idleUserService
      .getIdleTimeoutWarning()
      .pipe(first())
      .subscribe(() => {
        this.messageService.add({
          key: this.idleToastKey,
          sticky: true,
          severity: 'warn',
          summary: this.translateService.instant('market_discovery.idle.toast.message.summary.label'),
          detail: this.translateService.instant('market_discovery.idle.toast.message.details.label'),
        });

        this.checkIdleExpiration();
      });

    this.idleUserService
      .getIdleTimeout()
      .pipe(
        first(),
        takeUntil(this.loggedOutSubject),
        switchMap(() => {
          return this.logOut();
        })
      )
      .subscribe();
  }

  private checkNetworkStatus(): void {
    this.networkStatusService
      .getStatus()
      .pipe(
        filter((status) => {
          return status === OnlineStatusType.ONLINE;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((status: OnlineStatusType) => {
        this.checkIdleExpiration();
      });
  }

  private logOut(): Observable<boolean> {
    this.messageService.clear();
    this.dialogService.dialogComponentRefMap.forEach((dialog) => {
      dialog.destroy();
    });

    this.capturumDialogService.dialogComponentRefMap.forEach((dialog) => {
      dialog.destroy();
    });
    this.idleUserService.stopWatching();

    const isAgentQuestionnaire = this.router.url.includes('agent-questionnaire');

    if (isAgentQuestionnaire) {
      this.leadService.dequeueIsDone$.next(true);
    }

    const method = isAgentQuestionnaire ? this.leadService.dequeue() : of(null);

    return method.pipe(
      switchMap(() => {
        return this.authService.isAuthenticated()
          ? this.authService.logout().pipe(
              catchError(() => {
                return of(null);
              })
            )
          : of(null);
      }),
      finalize(() => {
        this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]);
      }),
      first()
    );
  }

  private checkIdleExpiration(): void {
    if (this.idleExpiration) {
      const nowInMilliSeconds = getTime(new Date());
      const totalIdleTime = this.idleExpiration + this.timeoutDurationInMilliseconds;

      if (nowInMilliSeconds >= totalIdleTime) {
        this.loggedOutSubject.next(true);
        this.logOut().subscribe();
      }
    }
  }
}
