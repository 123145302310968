import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Address, LeadAddress } from '@core/interfaces/address.interface';
import { LeadContactDialogComponent } from '../../../features/project/components/lead-contact-dialog/lead-contact-dialog.component';
import { BehaviorSubject, filter, Observable, tap } from 'rxjs';
import { DialogAction } from '@core/interfaces/dialog-action.interface';
import { DialogActionType } from '@core/enums/dialog-action-type.enum';
import { CapturumDialogService, ToastService } from '@capturum/ui/api';
import { ConfirmationBaseService } from '@shared/services/confirmation-base.service';
import { AddressApiService } from '../../../features/address/services/address-api.service';
import { TranslateService } from '@ngx-translate/core';
import { FormRendererApiService, FormSaverService } from '@capturum/builders/form-renderer';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Contact } from '@core/interfaces/contact.interface';
import { CapturumBuildersContextService, FormBuilderConfig } from '@capturum/builders/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ContactApiService } from '@shared/services/contact-api.service';
import { ApiService } from '@capturum/api';

type AddressOrContact = LeadAddress | Contact;

@Component({
  selector: 'app-company-addresses',
  templateUrl: './company-addresses.component.html',
  styleUrls: ['./company-addresses.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompanyAddressesComponent implements OnInit {
  @Input()
  public companyId: string;

  @Input()
  public formKey = 'form_lead_address';

  @Input()
  public entity: 'contact' | 'address';

  @Output()
  public valueUpdated = new EventEmitter<void>();

  @Input()
  public leadId: string;

  public formConfiguration: FormBuilderConfig;
  public dialogRef: DynamicDialogRef;
  public modelId: string;
  public valuesSubject: BehaviorSubject<AddressOrContact[]> = new BehaviorSubject<AddressOrContact[]>([]);
  public values$: Observable<AddressOrContact[]> = this.valuesSubject.asObservable();
  public loadingAddresses = false;

  constructor(
    private route: ActivatedRoute,
    private formSaverService: FormSaverService,
    private toastService: ToastService,
    private dialogService: CapturumDialogService,
    private confirmationBaseService: ConfirmationBaseService,
    private addressApiService: AddressApiService,
    private translateService: TranslateService,
    private formRendererApiService: FormRendererApiService,
    private contactApiService: ContactApiService,
    private contextService: CapturumBuildersContextService
  ) {}

  private _values: AddressOrContact[];

  get values(): AddressOrContact[] {
    return this._values;
  }

  @Input()
  set values(value: AddressOrContact[]) {
    this._values = value;

    this.valuesSubject.next(value);
  }

  public ngOnInit(): void {
    this.getFormConfigurations();
  }

  public addAddress(): void {
    this.openDialog(this.formKey, `market_discovery.lead.${this.entity}_add.label`);
  }

  public edit(address: AddressOrContact): void {
    this.openDialog(this.formKey, `market_discovery.lead.${this.entity}_edit.label`, true, address.id);
  }

  public delete(address: AddressOrContact): void {
    this.confirmationBaseService.confirmationService.confirm({
      header: this.translateService.instant(`market_discovery.lead.${this.entity}_delete.label`),
      message: this.translateService.instant(`market_discovery.lead.${this.entity}_delete_confirmation.label`),
      accept: () => {
        return this.deleteItem(address.id);
      },
    });
  }

  public trackByFn(index: number, item: AddressOrContact): string {
    return item.id;
  }

  private openDialog(formKey: string, header: string, isEdit = false, itemId?: string): void {
    this.dialogRef = this.dialogService.open(LeadContactDialogComponent, {
      header: this.translateService.instant(header),
      styleClass: 'dialog-md project-leads-contact-dialog',
      data: {
        formKey,
        leadId: this.leadId,
        companyId: this.companyId,
        itemId,
      },
    });

    this.dialogRef?.onClose
      .pipe(
        filter((result: DialogAction) => {
          return result?.action === DialogActionType.submit;
        }),
        tap(() => {
          this.loadingAddresses = formKey === this.formKey;
        })
      )
      .subscribe(({ data }) => {
        this.handleAfterDialogClose(data, formKey);
      });
  }

  private deleteItem(id: string): void {
    const service: ApiService<Contact | Address> =
      this.entity === 'address' ? this.addressApiService : this.contactApiService;

    service.delete(id).subscribe(() => {
      const filteredAddresses = this.valuesSubject.getValue().filter((value) => {
        return value.id !== id;
      });

      this.valuesSubject.next(filteredAddresses);

      this.toastService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant(`market_discovery.entity.toast.deleted`, {
          entity: this.translateService.instant(`market_discovery.${this.entity}.entity.name`),
        })
      );
    });
  }

  private getFormConfigurations(): void {
    this.contextService.setContext(this.formKey, { isEdit: true });
    this.formRendererApiService
      .getFormBuilderByKey(this.formKey)
      .pipe(first())
      .subscribe((addressConfig) => {
        this.formConfiguration = addressConfig;
      });
  }

  private handleAfterDialogClose(data: AddressOrContact, formKey: string): void {
    const items: AddressOrContact[] = this.valuesSubject.getValue();
    let findIndex = -1;

    findIndex = items.findIndex((item) => {
      return item.id === data.id;
    });

    this.loadingAddresses = false;

    if (findIndex > -1) {
      items[findIndex] = data;
    } else {
      items.unshift(data);
    }

    this.valuesSubject.next([...items]);
    this.valueUpdated.emit();
  }
}
