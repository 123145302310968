import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { MutationStatus } from '@features/mutation/enums/mutation-status.enum';
import { CapturumBuilderActionService } from '@capturum/builders/core';

@Component({
  selector: 'app-mutation-approval-widget',
  templateUrl: './mutation-approval-widget.component.html',
  styleUrls: ['./mutation-approval-widget.component.scss'],
})
export class MutationApprovalWidgetComponent extends ListRendererWidgetBase {
  public mutationStatus = MutationStatus;

  constructor(private actionService: CapturumBuilderActionService) {
    super();
  }

  public accept(): void {
    this.actionService.broadcastActionExecuted(
      {
        key: 'accept',
        type: null,
        options: null,
      },
      this.item,
      null
    );
  }

  public decline(): void {
    this.actionService.broadcastActionExecuted(
      {
        key: 'decline',
        type: null,
        options: null,
      },
      this.item,
      null
    );
  }
}
