import { NotificationService } from '@shared/services/notification.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormSaverService } from '@capturum/builders/form-renderer';
import { filter, map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { CapturumBuilderActionService, FormBuilderConfig } from '@capturum/builders/core';
import { ProjectStateService } from '../../services/project-state.service';
import { Project } from '../../interfaces/project.interface';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-selection-import-dialog',
  templateUrl: './selection-import-dialog.component.html',
  styleUrls: ['./selection-import-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionImportDialogComponent implements OnInit {
  public formKey = 'form_selection_import_as_lead';
  public project$: Observable<Project>;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private formSaver: FormSaverService,
    private dialogRef: DynamicDialogRef,
    private projectStateService: ProjectStateService,
    private actionService: CapturumBuilderActionService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.project$ = this.projectStateService.getProject().pipe(filter(Boolean));
  }

  public submit(): void {
    combineLatest([
      this.formSaver.getFormValueByKey(this.formKey),
      this.formSaver.getFormConfigurationByKey(this.formKey),
    ])
      .pipe(
        map<[any, FormBuilderConfig], { formData: ImportAsLeadRequest; configuration: FormBuilderConfig }>(
          ([formValue, configuration]) => {
            return {
              formData: {
                ...formValue,
                lead_ids: this.dialogConfig.data.item.map((item) => {
                  return item.id;
                }),
              },
              configuration,
            };
          }
        ),
        switchMap(({ configuration, formData }) => {
          return this.formSaver.submitToBackend(configuration.submit.endpoint, formData, this.formKey).pipe(
            map((response) => {
              return {
                response,
                configuration,
              };
            })
          );
        })
      )
      .subscribe({
        next: ({ response, configuration }) => {
          this.actionService.executeAction(configuration.callbacks.submit, response, this.formKey);
          this.toastService.success(
            this.translateService.instant('toast.success.title'),
            this.translateService.instant('market_discovery.selection.import-as-lead.toast')
          );

          const projectId = this.projectStateService.getProjectSnapshot().id;

          this.notificationService.setProjectLeadsActionRequiredCount(projectId);
          this.dialogRef.close();
        },
        error: () => {
          this.formSaver.markFormAsSubmitted(this.formKey);
        },
      });
  }
}

interface ImportAsLeadRequest {
  lead_ids: string[];
  lead_source_base_data_value_id: string;
  source: string;
}
