<app-page-layout
  [title]="'market_discovery.project.menu.full_business' | translate"
  [showPrintButton]="true"
  (handlePrintCommand)="exportFullBusiness()">
  <app-page-buttons actions>
    <ng-container *ngxPermissionsOnly="['project.full-business.manage']">
      <cap-button
        *ngIf="!isEdit"
        icon="fas fa-pencil"
        styleClass="primary"
        [label]="'button.edit' | translate"
        (onClick)="edit()">
      </cap-button>

      <cap-button
        *ngIf="isEdit"
        icon="fas fa-save"
        styleClass="primary"
        [label]="'button.submit' | translate"
        (onClick)="submit()">
      </cap-button>

      <cap-button
        *ngIf="isEdit"
        icon="fas fa-ban"
        styleClass="secondary"
        [label]="'button.cancel' | translate"
        (onClick)="cancel()">
      </cap-button>
    </ng-container>
  </app-page-buttons>

  <div content class="project-full-business">
    <div class="mb-3 selection" [class.hide]="hideSelection">
      <cap-card
        [title]="'market_discovery.project.leads.selection.title' | translate"
        *ngxPermissionsOnly="'project.full-business.filter-user'"
        [buttonLabel]="
          (hideSelection
            ? 'market_discovery.project.full_business.show_selection.label'
            : 'market_discovery.project.full_business.hide_selection.label'
          ) | translate
        "
        [buttonIcon]="hideSelection ? 'fas fa-eye' : 'fas fa-eye-slash'"
        (buttonClick)="hideSelection = !hideSelection">
        <ng-template capTemplate="content">
          <div [hidden]="hideSelection" *ngIf="project$ | async as project">
            <div class="selection">
              <div class="row">
                <div class="col-6 col-md-4">
                  <cap-dynamic-filters
                    #dynamicFiltersComponent
                    (activeFiltersChange)="handleFilterChange($event)"
                    [showResetButton]="false"
                    storageKey="{{ project.id }}-pipeline-filters"
                    [filterConfig]="dynamicFilters"
                    class="square-filter">
                  </cap-dynamic-filters>
                </div>
                <div class="col-6 col-md-8 reset-button">
                  <cap-button
                    icon="fas fa-trash-alt"
                    styleClass="secondary round"
                    [label]="'market_discovery.button.reset_filters' | translate"
                    (onClick)="resetFilters()">
                  </cap-button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </cap-card>
    </div>

    <div class="simple-table">
      <p-table
        styleClass="cap-info-table"
        #table
        dataKey="company_id"
        [class.edit-mode]="isEdit"
        [ngClass]="{ 'cap-info-table--hidden': loading }"
        [columns]="columns"
        [value]="fullBusiness?.rows"
        [lazy]="true"
        [rows]="100"
        [loading]="loading"
        [paginator]="false"
        (onLazyLoad)="loadTableData($event)">
        <ng-template pTemplate="header" let-columns>
          <tr *ngIf="fullBusiness?.header as headers">
            <th *ngFor="let column of columns">
              <div class="cap-info-table__column-header">
                <ng-container *ngIf="column.editableHeader; else defaultHeader">
                  <cap-info-table-cell-editor [editTable]="canEditHeader" [isEdit]="isEdit">
                    <ng-template capTemplate="input">
                      <cap-input [(ngModel)]="headers[column.title]"></cap-input>
                    </ng-template>

                    <ng-template capTemplate="output">
                      {{ headers[column.title] }}
                    </ng-template>
                  </cap-info-table-cell-editor>
                </ng-container>

                <ng-template #defaultHeader>
                  {{ column.title }}
                </ng-template>
              </div>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex" let-editing="editing">
          <tr class="cap-info-table__row">
            <ng-container *ngFor="let column of columns; let index = index">
              <td
                *ngIf="!column?.hidden"
                class="cap-info-table__column cap-info-table__column-{{ column?.field }} {{ column?.cellClass }}"
                [attr.data-test]="'cap-info-table__column_' + column?.field">
                <ng-container [ngSwitch]="column?.type">
                  <ng-container *ngSwitchCase="DataTableType.Template">
                    <cap-info-table-cell-editor [editTable]="true" [isEdit]="isEdit">
                      <ng-template capTemplate="input">
                        <ng-container
                          *ngIf="templates | columnTemplate : 'cellEdit_' + column.field as cellEditTemplate"
                          [ngTemplateOutletContext]="{ item: item, index: rowIndex }"
                          [ngTemplateOutlet]="cellEditTemplate">
                        </ng-container>
                      </ng-template>

                      <ng-template capTemplate="output">
                        <cap-cell-data [column]="column">
                          <span class="cap-cell-tooltip-container">
                            <span [capCellTooltip]="item | propertyValue : column?.field" [loading]="false">
                              <ng-container *ngIf="isCurrency && index !== 7; else defaultTemplate">
                                {{ item | propertyValue : column?.field | currency : 'EUR' : 'symbol' : '1.0-0' }}
                              </ng-container>

                              <ng-template #defaultTemplate>
                                {{ item | propertyValue : column?.field }}
                              </ng-template>
                            </span>
                          </span>
                        </cap-cell-data>
                      </ng-template>
                    </cap-info-table-cell-editor>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <cap-cell-data [column]="column">
                      <span class="cap-cell-tooltip-container">
                        <span *ngIf="column.field !== 'updated_at'"
                              [capCellTooltip]="item | propertyValue : column?.field"
                              [loading]="false">
                          {{ item | propertyValue : column?.field }}
                        </span>

                        <span *ngIf="column.field === 'updated_at'" [capCellTooltip]="item | propertyValue : column?.field"
                              [loading]="false">
                          {{ item.updated_at | date : 'dd-MM-yyyy hh:mm' }}
                        </span>
                      </span>
                    </cap-cell-data>
                  </ng-container>
                </ng-container>
              </td>
            </ng-container>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer" let-columns>
          <tr class="total-row">
            <td *ngFor="let column of columns; let index = index">
              <ng-container *ngIf="index === 0">
                {{
                  (isCurrency
                    ? 'market_discovery.project.full_business.total_amount.value.label'
                    : 'table.paginator.total'
                  ) | translate
                }}
              </ng-container>

              <ng-container *ngIf="column.total">
                {{ [column.field] | columnTotal : table.value : 0 : isCurrency && index !== 7 }}
              </ng-container>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="cell-offset"></td>
            <td colspan="2">
              {{ ['value_3', 'value_4'] | columnTotal : table.value : 0 : isCurrency }}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr class="empty-message-row text-center">
            <td [attr.colspan]="columns.length">
              {{ 'table.no_results' | translate }}
            </td>
          </tr>
        </ng-template>
      </p-table>

      <ng-container *ngIf="loading">
        <cap-info-table-skeleton></cap-info-table-skeleton>
      </ng-container>
    </div>
  </div>
</app-page-layout>

<ng-template capTemplate="cellEdit_value_1" let-item="item">
  <cap-input [(ngModel)]="item.value_1" filter="pint"></cap-input>
</ng-template>

<ng-template capTemplate="cellEdit_value_2" let-item="item">
  <cap-input [(ngModel)]="item.value_2" filter="pint"></cap-input>
</ng-template>

<ng-template capTemplate="cellEdit_value_3" let-item="item">
  <cap-input [(ngModel)]="item.value_3" filter="pint"></cap-input>
</ng-template>

<ng-template capTemplate="cellEdit_value_4" let-item="item">
  <cap-input [(ngModel)]="item.value_4" filter="pint"></cap-input>
</ng-template>

<ng-template capTemplate="cellEdit_value_5" let-item="item">
  <cap-input [(ngModel)]="item.value_5" filter="pint"></cap-input>
</ng-template>

<ng-template capTemplate="cellEdit_mutated_at" let-item="item">
  <cap-calendar [(ngModel)]="item.mutated_at" [dateFormat]="'dd-mm-yy'" appendTo="body" styleClass="calendar-input">
  </cap-calendar>
</ng-template>
