export enum MutationType {
  address = 'address',
  newAddress = 'new_address',
  contact = 'contact',
  newContact = 'new_contact',
  companyName = 'company_name',
  newDmu = 'new_dmu',
  dmu = 'dmu',
  removeDmu = 'remove_dmu',
  removeContact = 'remove_contact',
}
