<app-dialog-layout *ngIf="questions$ | async as questions">
  <div class="content" content>
    <ng-container *ngIf="!showSummary; else summary">
      <div class="content-body">
        <div class="questions-stepper">
          <ng-container *ngIf="!showWarningScreen; else warningScreen">
            <div class="question-step" *ngFor="let question of questions; let index = index; trackBy: trackByQuestion">
              <ng-container *ngIf="selectedIndex === index">
                <label class="title">
                  <span class="number">{{ index + 1 }}.</span
                  ><span class="name"
                    >{{ question.name | replaceQuestionTagsPipe : lead : questions : answeredQuestions : answers }}
                    <span *ngIf="question.is_required">&nbsp;*</span></span
                  >
                </label>

                <div class="description" *ngIf="question.description" [innerHTML]="question.description"></div>

                <app-question-input-types
                  #questionInputTypes
                  [answers]="answers"
                  [question]="question"
                  [lead]="_originalLead"
                  (updateAnswer)="updateAnswer($event)">
                </app-question-input-types>
              </ng-container>
            </div>
          </ng-container>

          <ng-template #warningScreen>
            <app-unanswered-questions-warning
              [questions]="unansweredQuestions"
              (questionClicked)="navigateToQuestion($event)"></app-unanswered-questions-warning>
          </ng-template>
        </div>
      </div>

      <div class="content-footer">
        <cap-progress-bar
          [value]="((selectedIndex + 1) / questions.length) * 100"
          [showValue]="false"
          mode="determinate">
        </cap-progress-bar>

        <div class="pagination">{{ selectedIndex + 1 }}/{{ questions.length }}</div>
      </div>
    </ng-container>
  </div>

  <ng-template #summary>
    <app-agent-questionnaire-lead-detail
      #leadDetails
      [lead]="lead"
      [callCenterStatuses]="callCenterStatuses$ | async"
      [isEdit]="true"
      (navigateToQuestion)="navigateToQuestion($event)">
    </app-agent-questionnaire-lead-detail>
  </ng-template>

  <ng-container buttons>
    <div class="button-container" *ngIf="!showSummary; else summaryButtons">
      <div class="left">
        <cap-button
          icon="fal fa-chevron-left"
          styleClass="secondary"
          *ngIf="selectedIndex !== 0"
          [label]="'button.previous' | translate"
          (onClick)="previous()">
        </cap-button>
      </div>
      <div class="center">
        <cap-button
          styleClass="primary"
          icon="fas fa-phone-slash"
          [label]="'market_discovery.button.end_call' | translate"
          (onClick)="stop()">
        </cap-button>
      </div>
      <div class="right">
        <cap-button
          styleClass="primary"
          *ngIf="selectedIndex + 1 < questions.length"
          icon="fas fa-chevron-right"
          iconPos="right"
          [label]="'button.next' | translate"
          (onClick)="next()">
        </cap-button>

        <cap-button
          styleClass="primary"
          *ngIf="selectedIndex + 1 === questions.length"
          icon="fas fa-chevron-right"
          iconPos="right"
          [label]="'market_discovery.button.confirm_and_finish' | translate"
          (onClick)="finish()">
        </cap-button>
      </div>
    </div>

    <ng-template #summaryButtons>
      <div class="button-container">
        <div class="left">
          <cap-button
            icon="fal fa-times"
            styleClass="secondary"
            [disabled]="submitting$ | async"
            [label]="'market_discovery.button.save_and_stop' | translate"
            (onClick)="saveAndStop()">
          </cap-button>
        </div>
        <div class="center">
          <cap-button
            styleClass="primary"
            icon="fas fa-phone-missed"
            *ngIf="showCallbackButton$ | async"
            [label]="'market_discovery.button.plan_callback_appointment' | translate"
            (onClick)="addCallBackAppointment()">
          </cap-button>
        </div>
        <div class="right">
          <cap-button
            styleClass="primary"
            icon="fas fa-save"
            [disabled]="submitting$ | async"
            [label]="'market_discovery.button.save_and_next' | translate"
            (onClick)="saveAndNext()">
          </cap-button>
        </div>
      </div>
    </ng-template>
  </ng-container>
</app-dialog-layout>
