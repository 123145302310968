import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormSaverService } from '@capturum/builders/form-renderer';

@Component({
  selector: 'app-roi-detail-dialog',
  templateUrl: './roi-detail-dialog.component.html',
  styleUrls: ['roi-detail-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoiDetailDialogComponent implements OnInit {
  public formKey = 'form_roi_month';
  public context: { project_id: string };

  constructor(
    public dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private formSaver: FormSaverService
  ) {}

  public ngOnInit(): void {
    this.context = { project_id: this.dialogConfig?.data?.projectId };
  }

  public handleSave(): void {
    this.formSaver.submit(this.formKey).subscribe(() => {
      this.dialogRef.close(true);
    });
  }
}
