<app-page-layout [title]="'market_discovery.navigation.selection' | translate">
  <app-page-buttons actions>
    <cap-button
      styleClass="primary"
      [label]="'market_discovery.button.import' | translate"
      icon="fas fa-file-import"
      [routerLink]="['../import-selection']">
    </cap-button>
  </app-page-buttons>

  <div class="project-selection" content *ngIf="project$ | async as project">
    <div class="col-6 manage-selections">
      <cap-card [title]="'market_discovery.project.selection.manage.title' | translate">
        <ng-template capTemplate="content">
          <cap-dropdown
            [placeholder]="'placeholder.select' | translate"
            [options]="selectionOptions$ | async"
            [formControl]="selectionControl"></cap-dropdown>

          <div class="mt-2">
            <cap-button
              [label]="'market_discovery.selection.import.button' | translate"
              styleClass="secondary import"
              (onClick)="importSelection()"></cap-button>
          </div>
        </ng-template>
      </cap-card>
    </div>

    <div class="mb-3 selection" [class.hide]="hideSelection">
      <cap-card
        [title]="'market_discovery.project.leads.selection.title' | translate"
        [buttonLabel]="
          (hideSelection
            ? 'market_discovery.project.full_business.show_selection.label'
            : 'market_discovery.project.full_business.hide_selection.label'
          ) | translate
        "
        [buttonIcon]="hideSelection ? 'fas fa-eye' : 'fas fa-eye-slash'"
        (buttonClick)="toggleSelectionCard()">
        <ng-template capTemplate="content">
          <div [hidden]="hideSelection">
            <app-meta-value-filters
              [callCenterSources]="true"
              [sourceOptions]="sourceOptions$ | async"
              [showOnlyMetaFilters]="true"
              [projectId]="project.id"
              storagekey="lead_selection"
              (filterChange)="handleMetaFilterChange($event)">
            </app-meta-value-filters>
          </div>
        </ng-template>
      </cap-card>
    </div>

    <cap-card class="active-selection" [title]="'market_discovery.project.selection.active-selection' | translate">
      <ng-template capTemplate="content">
        <ng-container>
          <cpb-list-renderer
            [storageKeyPrefix]="project.id"
            [key]="'list_lead_selection'"
            [context]="{ project_id: project.id }"
            (filterChange)="handleFilterChange($event)"></cpb-list-renderer>
        </ng-container>
      </ng-template>
    </cap-card>
  </div>
</app-page-layout>
