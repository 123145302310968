import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable()
export class ProjectMetaInterceptor implements HttpInterceptor {
  constructor(private projectService: ProjectStateService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.url.startsWith(environment.baseUrl) &&
      req.method !== 'GET' &&
      !!this.projectService.getProjectSnapshot()?.id
    ) {
      return next.handle(
        req.clone({ setParams: { '_meta[project_id]': this.projectService.getProjectSnapshot()?.id } })
      );
    }

    return next.handle(req);
  }
}
