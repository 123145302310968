import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { MapItem, ToastService } from '@capturum/ui/api';
import { ProjectApiService } from '@features/project/services/project-api.service';
import { distinctUntilKeyChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { Project } from '@features/project/interfaces/project.interface';
import { TranslateService } from '@ngx-translate/core';
import { Roles } from '@core/enums/roles.enum';
import { StakeholdersResponse } from '../../interfaces/stakeholders.interface';

@Component({
  selector: 'app-project-stakeholders',
  templateUrl: './project-stakeholders.component.html',
  styleUrls: ['./project-stakeholders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProjectStakeholdersComponent implements OnInit {
  public stakeholders$: Observable<StakeholdersResponse>;
  public accountManagerOptions$: Observable<MapItem[]>;
  public salesManagerOptions$: Observable<MapItem[]>;
  public agentOptions$: Observable<MapItem[]>;
  public managerOptions$: Observable<MapItem[]>;
  public project$: Observable<Project>;
  public roles = Roles;

  public projectSalesManagers$: Observable<MapItem[]>;
  public projectAccountManagers$: Observable<MapItem[]>;
  public projectManagers$: Observable<MapItem[]>;
  public projectAgents$: Observable<MapItem[]>;

  public accountManagerRole$: Observable<string>;
  public agentRole$: Observable<string>;
  public salesManagerRole$: Observable<string>;
  public managerRole$: Observable<string>;
  public selectedUsers: Record<string, string[]> = {
    [Roles.agent]: [],
    [Roles.accountManager]: [],
    [Roles.salesManager]: [],
    [Roles.manager]: [],
  };

  constructor(
    private projectApiService: ProjectApiService,
    private router: Router,
    private route: ActivatedRoute,
    private projectStateService: ProjectStateService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.project$ = this.projectStateService.getProject().pipe(filter(Boolean), distinctUntilKeyChanged('id'));

    this.stakeholders$ = this.project$.pipe(
      switchMap((project) => {
        return this.projectApiService.getStakeholderData(project.id);
      }),
      shareReplay(1)
    );

    this.projectAccountManagers$ = this.stakeholders$.pipe(
      map((response) => {
        return response.accountmanager.selected;
      })
    );

    this.projectSalesManagers$ = this.stakeholders$.pipe(
      map((response) => {
        return response.salesmanager.selected;
      })
    );

    this.projectAgents$ = this.stakeholders$.pipe(
      map((response) => {
        return response.agent.selected;
      })
    );

    this.projectManagers$ = this.stakeholders$.pipe(
      map((response) => {
        return response.manager.selected;
      })
    );

    this.accountManagerRole$ = this.stakeholders$.pipe(
      map((response) => {
        return response.accountmanager.role_id;
      })
    );

    this.salesManagerRole$ = this.stakeholders$.pipe(
      map((response) => {
        return response.salesmanager.role_id;
      })
    );

    this.agentRole$ = this.stakeholders$.pipe(
      map((response) => {
        return response.agent.role_id;
      })
    );

    this.managerRole$ = this.stakeholders$.pipe(
      map((response) => {
        return response.manager.role_id;
      })
    );

    this.accountManagerOptions$ = this.stakeholders$.pipe(
      map((response) => {
        return response.accountmanager.available;
      })
    );

    this.salesManagerOptions$ = this.stakeholders$.pipe(
      map((response) => {
        return response.salesmanager.available;
      })
    );

    this.agentOptions$ = this.stakeholders$.pipe(
      map((response) => {
        return response.agent.available;
      })
    );

    this.managerOptions$ = this.stakeholders$.pipe(
      map((response) => {
        return response.manager.available;
      })
    );
  }

  public saveAll(): void {
    const postData: string[] = Object.keys(this.selectedUsers).reduce((acc, key) => {
      return [...acc, ...this.selectedUsers[key]];
    }, []);

    this.projectApiService.saveUsers(this.projectStateService.getProjectSnapshot().id, postData).subscribe((res) => {
      this.toastService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('market_discovery.entity.toast.updated')
      );
    });
  }

  public handleSelectionChange(selectedItems: MapItem[], roleKey: Roles): void {
    if (this.selectedUsers.hasOwnProperty(roleKey)) {
      this.selectedUsers = {
        ...this.selectedUsers,
        [roleKey]: selectedItems.map((item) => {
          return item.value;
        }),
      };
    }
  }
}
