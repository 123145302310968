import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MapItem } from '@capturum/auth';
import { Address } from '@core/interfaces/address.interface';
import { Contact } from '@core/interfaces/contact.interface';
import { Lead } from '@features/lead/interfaces/lead.interface';
import { AgentQuestionnaireStateService } from '@features/questionnaire/services/agent-questionnaire-state.service';
import { DestroyBase } from '@capturum/shared';
import { takeUntil } from 'rxjs';

interface StatusRemarksFormGroup {
  lead_status_base_data_value_id: FormControl<string>;
  remarks: FormControl<string>;
}

interface StatusRemarksFormGroupValue {
  lead_status_base_data_value_id: string;
  remarks: string;
}

@Component({
  selector: 'app-agent-questionnaire-lead-detail',
  templateUrl: './agent-questionnaire-lead-detail.component.html',
  styleUrls: ['./agent-questionnaire-lead-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AgentQuestionnaireLeadDetailComponent extends DestroyBase implements OnInit, OnDestroy {
  @Input()
  public lead: Lead & { mainCompanyAddress?: Address; mainMailAddress?: Address; contacts?: Contact[] };

  @Input()
  public isEdit: boolean;

  public remarks: string;
  public form: FormGroup<StatusRemarksFormGroup>;
  @Input()
  public callCenterStatuses: MapItem[];

  @Output()
  public navigateToQuestion: EventEmitter<string> = new EventEmitter<string>();

  constructor(private formBuilder: FormBuilder, private agentQuestionnaireState: AgentQuestionnaireStateService) {
    super();
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      lead_status_base_data_value_id: [this.lead?.lead_status_base_data_value_id, Validators.required],
      remarks: [this.lead?.md_remarks || null],
    });

    this.form.valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe((value) => {
      this.agentQuestionnaireState.setLeadDetails(value as any);
    });
  }

  public goToQuestion(questionId: string): void {
    this.navigateToQuestion.emit(questionId);
  }
}
