import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Question,
  QuestionInputTypeComponent,
  QuestionOption,
  UpdateAnswer,
} from '@features/questionnaire/interfaces/questionnaire.interface';
import { BehaviorSubject, debounceTime, Observable, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';

interface DropdownSingleAnswer {
  option_id: string;
  answer_text: string;
}

@Component({
  selector: 'app-dropdown-single-input-type',
  templateUrl: './dropdown-single-input-type.component.html',
  styleUrls: ['./dropdown-single-input-type.component.scss'],
})
export class QuestionInputTypeDropdownSingleComponent
  implements QuestionInputTypeComponent<DropdownSingleAnswer>, OnInit, OnDestroy {
  @Output()
  public updateAnswer: EventEmitter<UpdateAnswer> = new EventEmitter<UpdateAnswer>();
  public formGroup: UntypedFormGroup;
  public options: QuestionOption[];
  public answer$: BehaviorSubject<DropdownSingleAnswer> = new BehaviorSubject<DropdownSingleAnswer>(null);
  public showFreeText: boolean;
  public description$: Observable<string>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  private _question: Question;

  @Input()
  public set question(question: Question) {
    if (question) {
      this._question = question;
      this.options = question.question_options;
    }
  }

  public ngOnInit(): void {
    this.formGroup = this.generateFormGroup();

    this.formGroup.valueChanges
      .pipe(debounceTime(200), takeUntil(this.destroy$))
      .subscribe((value: DropdownSingleAnswer) => {
        this.showFreeText = this.options.find((option) => {
          return option.id === value.option_id;
        })?.is_free_text;
        const updatedValue = {
          ...value,
          answer_text: this.showFreeText ? value.answer_text : null,
        };

        this.updateAnswer.emit({
          id: this._question.id,
          value: updatedValue,
        });
      });

    this.answer$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: DropdownSingleAnswer) => {
        if (value) {
          this.formGroup.patchValue(value);
        }
      });

    this.description$ = this.formGroup.get('option_id').valueChanges.pipe(
      map((value) => {
        const option = this.options.find((questionOption) => value === questionOption.id);

        return option?.description || null;
      }),
      takeUntil(this.destroy$),
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public validate(): boolean {
    if (this.formGroup.valid) {
      this.updateAnswer.emit({
        id: this._question.id,
        value: this.formGroup.value,
      });
    }

    return this.formGroup.valid;
  }

  public getAnswer(): DropdownSingleAnswer {
    return this.formGroup.value;
  }

  private generateFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      option_id: this._question.is_required
        ? new UntypedFormControl(null, Validators.required)
        : new UntypedFormControl(null),
      answer_text: new UntypedFormControl(null),
    });
  }
}
