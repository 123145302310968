<div class="lead-action-item">
  <a class="lead-action-item-link" *ngIf="action?.is_completed; else actionText" (click)="editAction(action)">
    <ng-container [ngTemplateOutlet]="actionText"></ng-container>
  </a>

  <ng-container *ngxPermissionsOnly="['action.create', 'action.manage']">
    <i class="fas fa-pencil" *ngIf="!action?.is_completed" (click)="editAction(action)"></i>
  </ng-container>
</div>

<ng-template #actionText>
  <div class="description d-inline">{{ action?.description }}</div>
  &nbsp;<b>{{ action?.due_at | date : 'dd-MM-yyyy' }}</b>
</ng-template>
