import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MapItem } from '@capturum/ui/api';

@Component({
  selector: 'app-stakeholder-user-list',
  templateUrl: './stakeholder-user-list.component.html',
  styleUrls: ['./stakeholder-user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StakeholderUserListComponent {
  @Input()
  public items: MapItem[];

  @Output()
  public deleteItemClick = new EventEmitter<MapItem>();

  public handleDeleteClick(item: MapItem): void {
    this.deleteItemClick.emit(item);
  }
}
