<div class="address-input-type">
  <form [formGroup]="formGroup">
    <div class="lead-addresses" *ngIf="addresses$ | async as addresses">
      <div class="address-option" *ngFor="let address of addresses">
        <p-radioButton [value]="address.id" name="address_id" [inputId]="address.id" formControlName="address_id">
        </p-radioButton>
        <label [for]="address.id">
          {{ address.street }} {{ address.housenumber }} {{ address.zipcode }} {{ address.city }},
          {{ address.country_base_data_value_id | capBaseDataTranslation | default }}
          <ng-container *ngIf="address.phone">, {{ address.phone }}</ng-container>
        </label>
      </div>

      <div class="address-option" *ngIf="!hideOtherOption">
        <p-radioButton inputId="new" value="new" name="address_id" formControlName="address_id"></p-radioButton>
        <label for="new">{{ 'market_discovery.questionnaire.address.other' | translate }}</label>
      </div>
    </div>

    <div class="new-address-form" [formGroup]="addressForm" *ngIf="showAddressForm">
      <div class="row">
        <div class="col-3">
          <label>{{ 'market_discovery.lead.address.street.label' | translate }}*</label>
          <cap-input formControlName="street"></cap-input>
        </div>

        <div class="col-3">
          <label>{{ 'market_discovery.lead.address.house_number.label' | translate }}*</label>
          <cap-input formControlName="housenumber"></cap-input>
        </div>

        <div class="col-3">
          <label>{{ 'market_discovery.lead.address.city.label' | translate }}*</label>
          <cap-input formControlName="city"></cap-input>
        </div>

        <div class="col-3">
          <label>{{ 'market_discovery.lead.address.zipcode.label' | translate }}*</label>
          <cap-input formControlName="zipcode"></cap-input>
        </div>

        <div class="col-3">
          <label>{{ 'market_discovery.address.country_base_data_value_id.label' | translate }}*</label>
          <cap-dropdown
            [autoDisplayFirst]="false"
            [options]="countries$ | async"
            formControlName="country_base_data_value_id">
          </cap-dropdown>
        </div>

        <div class="col-3">
          <label>{{ 'market_discovery.lead.address.phone.label' | translate }}*</label>
          <cap-input formControlName="phone"></cap-input>
        </div>
      </div>
    </div>
  </form>
</div>
