import { PrintService } from '@shared/services/print.service';
import {
  FullBusinessData,
  FullBusinessRow,
  FullBusinessTableColumn,
  FullBusinessTableHeader,
} from '@features/project/interfaces/full-business.interface';
import { TranslateService } from '@ngx-translate/core';
import { ProjectApiService } from '@features/project/services/project-api.service';
import { DestroyBase } from '@capturum/shared';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Component, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { InfoTableColumnType } from '@capturum/ui/info-table';
import { Project } from '@features/project/interfaces/project.interface';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { TableService } from '@shared/services/table.service';
import { LazyLoadEvent } from 'primeng/api';
import { Observable, switchMap, takeUntil, tap } from 'rxjs';
import { getYear } from 'date-fns';
import { CapturumTemplateDirective, FilterMatchMode, ToastService } from '@capturum/ui/api';
import { Table } from 'primeng/table';
import {
  ActiveFilters,
  CapturumDynamicFiltersComponent,
  DynamicFilterConfig,
  DynamicFilterType,
} from '@capturum/ui/dynamic-filters';
import { NgxPermissionsService } from 'ngx-permissions';
import { ProjectType } from '@features/project/enums/project-type.enum';

@Component({
  selector: 'app-project-full-business',
  templateUrl: './project-full-business.component.html',
  styleUrls: ['./project-full-business.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TableService],
})
export class ProjectFullBusinessComponent extends DestroyBase implements OnInit {
  @ViewChild('table')
  public infoTable: Table;

  @ViewChildren(CapturumTemplateDirective)
  public templates: QueryList<CapturumTemplateDirective>;

  @ViewChild('dynamicFiltersComponent')
  public dynamicFiltersComponent: CapturumDynamicFiltersComponent;

  public isEdit = false;
  public columns: FullBusinessTableColumn[];
  public project$: Observable<Project>;
  public fullBusiness: FullBusinessData;
  public DataTableType: typeof InfoTableColumnType = InfoTableColumnType;
  public hideSelection = false;
  public dynamicFilters: DynamicFilterConfig;
  public loading = true;
  public canEditHeader: boolean;
  public isCurrency: boolean;

  private _originalData: FullBusinessRow[];
  private _originalHeadersData: FullBusinessTableHeader;
  private _currentYear: number;
  private _projectId: string;
  private _filters: {
    field: string;
    value: any;
    operator?: string;
  }[];

  constructor(
    private projectStateService: ProjectStateService,
    private tableService: TableService,
    private projectApiService: ProjectApiService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private printService: PrintService,
    private ngxPermissionService: NgxPermissionsService
  ) {
    super();
    this._currentYear = getYear(new Date());
  }

  public ngOnInit(): void {
    this.project$ = this.projectStateService.getProject().pipe(filter(Boolean), shareReplay(1));
    this.canEditHeader = !!this.ngxPermissionService.getPermission('project.full-business.headers.manage');

    this.project$
      .pipe(
        tap((project) => {
          this._projectId = project.id;
          this.dynamicFilters = this.getFilterConfig();
          this.isCurrency = project.projectType?.value === ProjectType.value;
        }),
        switchMap((project) => {
          return this.tableService.getUpdateTable().pipe(
            tap(() => {
              return (this.loading = true);
            }),
            filter(Boolean),
            map((apiOptions) => {
              return {
                projectId: project.id,
                ...apiOptions,
              };
            })
          );
        }),
        tap(({ filters }) => {
          return (this._filters = filters);
        }),
        switchMap(({ projectId, filters, sort, page, perPage }) => {
          return this.projectApiService.getProjectFullBusiness(projectId, {
            include: ['contact'],
            filters,
            sort,
            page,
            perPage,
          });
        }),
        map((response) => {
          return response.data;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.fullBusiness = data;
        this.loading = false;
      });

    this.columns = [
      {
        title: this.translateService.instant('market_discovery.company.name.label'),
        field: 'company_name',
      },
      {
        title: this.translateService.instant('market_discovery.project.account_managers.label'),
        field: 'account_manager_name',
      },
      {
        field: 'contact_name',
        title: this.translateService.instant('market_discovery.project.full_business.contact.label'),
      },
      {
        field: 'value_1',
        title: 'header_1',
        type: InfoTableColumnType.Template,
        cellClass: 'text-center',
        titleClass: 'text-center',
        total: true,
        editableHeader: true,
      },
      {
        field: 'value_2',
        title: 'header_2',
        type: InfoTableColumnType.Template,
        cellClass: 'text-center',
        titleClass: 'text-center',
        total: true,
        editableHeader: true,
      },
      {
        field: 'value_3',
        title: 'header_3',
        type: InfoTableColumnType.Template,
        cellClass: 'text-center',
        titleClass: 'text-center',
        total: true,
        editableHeader: true,
      },
      {
        field: 'value_4',
        title: 'header_4',
        type: InfoTableColumnType.Template,
        cellClass: 'text-center',
        titleClass: 'text-center',
        total: true,
        editableHeader: true,
      },
      {
        field: 'value_5',
        title: 'header_5',
        type: InfoTableColumnType.Template,
        cellClass: 'text-center',
        titleClass: 'text-center',
        total: true,
        editableHeader: true,
      },
      {
        field: 'updated_at',
        title: this.translateService.instant('market_discovery.project.full_business.transaction_date.label'),
      },
    ];
  }

  public loadTableData(event: LazyLoadEvent): void {
    this.tableService.updateTableByLazyLoadEvent(event);
  }

  public handleFilterChange(filters: ActiveFilters[]): void {
    this.tableService.updateTableByFilters(filters);
  }

  public resetFilters(): void {
    this.dynamicFiltersComponent.resetFilters();
  }

  public edit(): void {
    // clone table data and headers data
    this._originalData = JSON.parse(JSON.stringify(this.infoTable?.value ?? null));
    this._originalHeadersData = JSON.parse(JSON.stringify(this.fullBusiness?.header ?? null));
    this.isEdit = true;
  }

  public submit(): void {
    this.projectApiService.submitProjectFullBusiness(this._projectId, this.fullBusiness).subscribe({
      next: (result) => {
        if (result) {
          this.toastService.success(
            this.translateService.instant('toast.success.title'),
            this.translateService.instant('market_discovery.entity.toast.updated')
          );

          this.fullBusiness = result;
          this.isEdit = false;

          if (this.dynamicFiltersComponent?.activeFilters) {
            this.tableService.updateTableByFilters(this.dynamicFiltersComponent.activeFilters);
          }
        }
      },
      error: (error) => {
        this.cancel();
      },
    });
  }

  public cancel(): void {
    this.isEdit = false;
    this.fullBusiness.rows = [...this._originalData];
    this.fullBusiness.header = { ...this._originalHeadersData };
  }

  public exportFullBusiness(): void {
    this.printService.setPrintBusy(true);

    this.toastService.info(
      this.translateService.instant('market_discovery.project.full_business.export'),
      this.translateService.instant('market_discovery.project.full_business.export.started')
    );
    this.projectApiService.exportFullBusiness(this._projectId, { filters: this._filters }).subscribe((response) => {
      if (response?.finished) {
        this.printService.setPrintBusy(false);
      }
    });
  }

  private getFilterConfig(): DynamicFilterConfig {
    return {
      filters: [
        {
          field: 'account_manager_id',
          type: DynamicFilterType.multiselect,
          icon: 'fas fa-user',
          label: this.translateService.instant('market_discovery.project.account_managers.label'),
          placeholder: this.translateService.instant('market_discovery.project.account_managers.label'),
          matchMode: FilterMatchMode.EQUALS,
          options: this.projectApiService.getAccountManagersList(this._projectId),
          selectedItemsLabel: '{0} items',
        },
      ],
    };
  }
}
