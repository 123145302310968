<app-page-layout [title]="'market_discovery.project.menu.log' | translate">
  <div content class="project-log h-100">
    <div class="row h-100">
      <div class="col-lg-6 h-100">
        <cap-card [title]="'market_discovery.project.log.add_note.label' | translate" class="h-100">
          <ng-template capTemplate="content">
            <div class="from-wrap h-100">
              <cpb-form-renderer [formKey]="formKey" [showHeader]="false"> </cpb-form-renderer>

              <cap-button
                icon="fas fa-save"
                class="ml-3"
                styleClass="primary"
                [label]="'market_discovery.button.save' | translate"
                (click)="submit()">
              </cap-button>
            </div>
          </ng-template>
        </cap-card>
      </div>

      <div class="col-lg-6 h-100">
        <cap-card [title]="'market_discovery.project.log.notes.label' | translate" class="h-100">
          <ng-template capTemplate="content">
            <cap-timeline class="notes-timeline" [divider]="true" [items]="projectLogs$ | async">
              <ng-template capTemplate="content" let-timeline="timeline">
                <div class="timeline__actions">
                  <cap-row-action [actions]="actions" [item]="timeline"></cap-row-action>
                </div>

                <div class="timeline__created mb-2 d-flex">
                  <span class="created-at">
                    {{ timeline?.created_at | capDate : DateFormat.general }}
                  </span>

                  <span class="user-name ml-3">
                    {{ timeline?.createdByUser?.name }}
                  </span>
                </div>

                <div class="timeline__title mb-2">
                  {{ timeline?.title }}
                </div>

                <div class="timeline__context mb-3">
                  {{ timeline?.description }}
                </div>
              </ng-template>
            </cap-timeline>
          </ng-template>
        </cap-card>
      </div>
    </div>
  </div>
</app-page-layout>
