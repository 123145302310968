import { CalendarFilterComponent } from './core/builders/filters/calendar-filter/calendar-filter.component';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService as BlueprintAuthService } from '@capturum/auth';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { CapturumBuilderDateInputComponent, CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { PermissionGuard, TranslationLoader } from '@capturum/complete';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumToastModule } from '@capturum/ui/toast';
import { DownloadLetterAction } from '@core/builders/actions/download-letter.action';
import { DownloadSelectionAction } from '@core/builders/actions/download-selection.action';
import { NavigateProjectDetailAction } from '@core/builders/actions/navigate-project-detail.action';
import { NavigateWithinProjectAction } from '@core/builders/actions/navigate-within-project.action';
import { DataCollectiefWidgetComponent } from '@core/builders/column-types/data-collectief-widget/data-collectief-widget.component';
import { IsActiveWidgetComponent } from '@core/builders/column-types/is-active-widget/is-active-widget.component';
import { TimeDifferenceWidgetComponent } from '@core/builders/column-types/time-difference-widget/time-difference-widget.component';
import { UserStatusWidgetComponent } from '@core/builders/column-types/user-status-widget/user-status-widget.component';
import { UtcDateComponent } from '@core/builders/column-types/utc-date/utc-date.component';
import { CheckboxInputBuilder } from '@core/builders/input-builders/checkbox-input.builder';
import { HiddenInputBuilder } from '@core/builders/input-builders/hidden-input.builder';
import { AddressesTypeComponent } from '@core/builders/input-types/addresses-type/addresses-type.component';
import { ContactsTypeComponent } from '@core/builders/input-types/contacts-type/contacts-type.component';
import { HiddenTypeComponent } from '@core/builders/input-types/hidden-type/hidden-type.component';
import { MetaValueOptionTypeComponent } from '@core/builders/input-types/meta-value-option-type/meta-value-option-type.component';
import { PasswordTypeComponent } from '@core/builders/input-types/password-type/password-type.component';
import { TimePickerComponent } from '@core/builders/input-types/time-picker/time-picker.component';
import { UrlTypeComponent } from '@core/builders/input-types/url-type/url-type.component';
import { HiddenWrapperComponent } from '@core/builders/wrappers/hidden-wrapper.component';
import { MetaValueWrapperComponent } from '@core/builders/wrappers/meta-value-wrapper/meta-value-wrapper.component';
import { CoreModule } from '@core/core.module';
import { environment } from '@environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { LayoutModule } from '@shared/layout/layout.module';
import { AuthService } from '@shared/services/auth.service';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { AppInjector } from './app-injector.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigateCompanyDetailAction } from './core/builders/actions/navigate-company-detail.action';
import { QuestionPlaceholderComponent } from './core/builders/input-types/question-placeholder/question-placeholder.component';
import { ManageModule } from './features/manage/manage.module';
import { ProjectHourDialogComponent } from './features/project-hour/pages/project-hour-dialog/project-hour-dialog.component';
import { AddSelectionDialogComponent } from './features/project/components/add-selection-dialog/add-selection-dialog.component';
import { BulkStatusDialogComponent } from './features/project/components/bulk-status-dialog/bulk-status-dialog.component';
import { SelectionImportDialogComponent } from './features/project/components/selection-import-dialog/selection-import-dialog.component';
import { CallbackRepeatComponent } from '@core/builders/input-types/callback-repeat/callback-repeat.component';
import { NoLabelWrapperComponent } from '@core/builders/wrappers/no-label-wrapper.component';
import { CallbackRepeatInputBuilder } from '@core/builders/input-builders/callback-repeat-input.builder';
import { CustomerXyzComponent } from './features/customer/pages/customer-xyz/customer-xyz.component';
import { CapturumDynamicFiltersModule, DynamicRangeFilterComponent } from '@capturum/ui/dynamic-filters';
import { ProjectModule } from './features/project/project.module';
import { ChartModule } from 'primeng/chart';
import { XyzModule } from './features/xyz/xyz.module';
import { ExportSelectionAction } from '@core/builders/actions/export-selection.action';
import { DateTypeComponent } from '@core/builders/input-types/date-type/date-type.component';
import { MutationApprovalWidgetComponent } from '@core/builders/column-types/mutation-approval-widget/mutation-approval-widget.component';
import { MutationStatus } from './features/mutation/enums/mutation-status.enum';
import { MutationDialogComponent } from './features/mutation/components/mutation-dialog/mutation-dialog.component';
import { OpenMutationPopupAction } from '@core/builders/actions/open-mutation-popup.action';
import { ImportRequestTypeComponent } from '@core/builders/column-types/import-request-type/import-request-type.component';
import { ActionRequiredBadgeWidgetComponent } from '@core/builders/column-types/action-required-badge-widget/action-required-badge-widget.component';
import { ProjectSelectionLetterDialogComponent } from './features/project/components/project-selection-letter-dialog/project-selection-letter-dialog.component';
import { ProjectLeadSelectionDialogComponent } from '@features/project/components/project-lead-selection-dialog/project-lead-selection-dialog.component';
import { LeadSelectionAgentsDialogComponent } from '@features/project/components/lead-selection-agents-dialog/lead-selection-agents-dialog.component';
import { LeadAgendaItemComponent } from '@core/builders/input-types/lead-agenda-item/lead-agenda-item.component';
import { MutationType } from './features/mutation/enums/mutation-type.enum';
import { getErrorHandler } from '@core/error-handlers/SentryErrorHandler';
import { DownloadableFileTypeComponent } from '@core/builders/input-types/downloadablefile-type/downloadablefile-type.component';

registerLocaleData(localeNL);

Sentry.init({
  dsn: environment.sentryUrl,
  environment: environment.name,
  ignoreErrors: ['Non-Error exception captured', 'NG0100: ExpressionChangedAfterItHasBeenCheckedError'],
});

@NgModule({
  declarations: [AppComponent, CustomerXyzComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    CapturumToastModule,
    CapturumDynamicFiltersModule.forRoot({
      components: [
        {
          name: 'range',
          component: DynamicRangeFilterComponent,
        },
      ],
    }),
    CapturumListRendererModule.forRoot({
      widgets: {
        userStatus: UserStatusWidgetComponent,
        isActive: IsActiveWidgetComponent,
        utcDate: UtcDateComponent,
        isDataCollectief: DataCollectiefWidgetComponent,
        timeDifference: TimeDifferenceWidgetComponent,
        mutationApproval: MutationApprovalWidgetComponent,
        importRequestType: ImportRequestTypeComponent,
        actionRequiredBadge: ActionRequiredBadgeWidgetComponent,
      },
      list_questionnaire: {
        actions: {
          delete: {
            hiddenExpression: (row: any) => {
              return row.is_active === true || (!row.is_active && row.usedByProjects !== '');
            },
          },
        },
      },
      list_mutation: {
        styleClassExpression: (row: any) => {
          return row.status?.value === MutationStatus.open ? 'font-weight-bolder' : null;
        },
        actions: {
          edit: {
            hiddenExpression: (item: any) => {
              const types = [MutationType.removeDmu, MutationType.removeContact];

              return item.status?.value !== MutationStatus.open || types.includes(item.type?.value);
            },
          },
        },
      },
      'list_project-hours': {
        actions: {
          edit: {
            hiddenExpression: (item: any) => {
              return item.status.value !== 'open';
            },
          },
        },
      },
      filters: [
        {
          name: 'datetime',
          component: CalendarFilterComponent,
        },
      ],
    }),
    ConfirmDialogModule,
    CapturumButtonModule,
    CapturumBuilderCoreModule.forRoot({
      baseUrl: 'builders',
      components: {
        list_lead_selection_importAsLead: SelectionImportDialogComponent,
        list_lead_selection_createSelection: AddSelectionDialogComponent,
        list_lead_selection_bulkStatus: BulkStatusDialogComponent,
        'list_project-hours_edit': ProjectHourDialogComponent,
        list_mutation_edit: MutationDialogComponent,
        list_lead_selection_letter: ProjectSelectionLetterDialogComponent,
        list_lead_selection_row_click: ProjectLeadSelectionDialogComponent,
        list_lead_selection_assign_agents: LeadSelectionAgentsDialogComponent,
      },
      actions: {
        navigate_project: NavigateProjectDetailAction,
        navigate_within_project: NavigateWithinProjectAction,
        navigate_company: NavigateCompanyDetailAction,
        download_letter: DownloadLetterAction,
        download_selection: DownloadSelectionAction,
        export_selection: ExportSelectionAction,
        open_mutation_popup: OpenMutationPopupAction,
      },
    }),
    ManageModule,
    CoreModule,
    CapturumFormRendererModule.forRoot({
      defaultEmptyValue: '-',
      types: [
        {
          name: 'password',
          component: PasswordTypeComponent,
        },
        {
          name: 'hidden',
          component: HiddenTypeComponent,
          wrappers: ['hidden'],
        },
        {
          name: 'url',
          component: UrlTypeComponent,
        },
        {
          name: 'addresses',
          component: AddressesTypeComponent,
        },
        {
          name: 'contacts',
          component: ContactsTypeComponent,
        },
        {
          name: 'timepicker',
          component: TimePickerComponent,
        },
        {
          name: 'questionplaceholder',
          component: QuestionPlaceholderComponent,
        },
        {
          name: 'metavalueoptiontype',
          component: MetaValueOptionTypeComponent,
        },
        {
          name: 'callbackrepeat',
          component: CallbackRepeatComponent,
          wrappers: ['no-label'],
        },
        {
          name: 'date',
          component: DateTypeComponent,
        },
        {
          name: 'builders-date',
          component: CapturumBuilderDateInputComponent,
        },
        {
          name: 'leadagendaitem',
          component: LeadAgendaItemComponent,
        },
        {
          name: 'downloadablefile',
          component: DownloadableFileTypeComponent,
        },
      ],
      wrappers: [
        { name: 'hidden', component: HiddenWrapperComponent },
        { name: 'meta-value-wrapper', component: MetaValueWrapperComponent },
        { name: 'no-label', component: NoLabelWrapperComponent },
      ],
      builders: {
        checkbox: CheckboxInputBuilder,
        hidden: HiddenInputBuilder,
        callbackrepeat: CallbackRepeatInputBuilder,
      },
      translationKeyPrefix: '',
      layoutTemplates: {
        three_column: {
          amountOfContentGroups: 3,
          locations: {
            content: {
              containerClass: 'row border-columns',
              groupClass: 'col-lg-4 border-column',
            },
          },
        },
      },
    }),
    ConfirmDialogModule,
    CapturumButtonModule,
    CapturumBuilderCoreModule.forRoot({
      baseUrl: '/builders',
    }),
    ManageModule,
    NgxsModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    LayoutModule,
    ProjectModule,
    ChartModule,
    XyzModule,
  ],
  providers: [
    ConfirmationService,
    DecimalPipe,
    PermissionGuard,
    {
      provide: ErrorHandler,
      useFactory: getErrorHandler,
    },
    {
      provide: BlueprintAuthService,
      useClass: AuthService,
    },
    {
      provide: LOCALE_ID,
      useValue: 'nl',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
