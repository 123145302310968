export enum LeadStatus {
  tb = 'TB',
  x1 = 'X1',
  x2 = 'X2',
  x3 = 'X3',
  y1 = 'Y1',
  y2 = 'Y2',
  y3 = 'Y3',
  z1 = 'Z1',
  z2 = 'Z2',
  z3 = 'Z3',
  empty = 'EMPTY',
  a1 = 'A1',
  a2 = 'A2',
  a3 = 'A3',
  a5 = 'A5',
  a6 = 'A6',
  a7 = 'A7',
  b0 = 'B0',
  b1 = 'B1',
  b2 = 'B2',
  c0 = 'C0',
  c1 = 'C1',
  c2 = 'C2',
  d1 = 'D1',
  e1 = 'E1',
  e2 = 'E2',
  e3 = 'E3',
  e4 = 'E4',
  e5 = 'E5',
}
