import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableAction } from '@capturum/ui/api';
import { Lead } from '@features/lead/interfaces/lead.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-bulk-actions',
  templateUrl: './table-bulk-actions.component.html',
  styleUrls: ['./table-bulk-actions.component.scss'],
})
export class TableBulkActionsComponent {
  public isOpen = false;
  @Input()
  public actions: TableAction[] = [];

  @Input()
  public selectedItems: Lead[] = [];

  @Input()
  public entity: string;

  @Input()
  public placeholder: string;

  @Output()
  public cancelSelection = new EventEmitter<void>();

  constructor(private translateService: TranslateService) {
    this.placeholder = this.translateService.instant('market_discovery.table.bulk_actions.placeholder');
  }

  public executeAction(action: TableAction): void {
    action.callback(this.selectedItems);
    this.isOpen = false;
  }

  public handleCancelSelection(): void {
    this.cancelSelection.emit();
  }
}
