import { Pipe, PipeTransform } from '@angular/core';
import { TableAction } from '@capturum/ui/api';

@Pipe({
  name: 'tableActionHidden',
})
export class TableActionHiddenPipe implements PipeTransform {
  public transform(
    tableActions: TableAction[],
    key: string,
    item: any,
    property: string,
    itemValue: any
  ): TableAction[] {
    return tableActions.map((tableAction) => {
      return {
        ...tableAction,
        hidden: tableAction.key === key && item[property] === itemValue,
      };
    });
  }
}
