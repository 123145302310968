import { Component, OnInit } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { first, map } from 'rxjs/operators';
import { ProjectStateService } from '../../services/project-state.service';
import { ManageListComponent } from '../../../manage/pages/manage-list/manage-list.component';

@Component({
  selector: 'app-imports-overview',
  templateUrl: './imports-overview.component.html',
  styleUrls: ['./imports-overview.component.scss'],
})
export class ImportsOverviewComponent extends ManageListComponent implements OnInit {
  public context$: Observable<{ project_id: string }>;

  constructor(
    private route2: ActivatedRoute,
    private router2: Router,
    private projectStateService: ProjectStateService,
    protected actionService: CapturumBuilderActionService,
    protected toastService: ToastService,
    protected translateService: TranslateService
  ) {
    super(route2, router2, actionService, toastService, translateService);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.context$ = this.projectStateService.getProject().pipe(
      filter(Boolean),
      first(),
      map((project) => {
        return {
          project_id: project.id,
        };
      })
    );
  }
}
