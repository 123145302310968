<div class="form-element">
  <p-calendar
    styleClass="cap-calendar p-inputgroup"
    panelStyleClass="cap-calendar-panel app-date-type"
    [class.readOnly]="!!formState.readonly"
    [attr.data-test]="'builder-' + field.key"
    [hidden]="to.readonly"
    [formControl]="formControl"
    [name]="field.key"
    [timeOnly]="!to.date"
    [hideOnDateTimeSelect]="!to.time"
    [selectionMode]="to.selectionMode ?? 'single'"
    [minDate]="to.min_date"
    [maxDate]="to.max_date"
    [showIcon]="true"
    [showTime]="to.time"
    [appendTo]="to.appendTo || null"
    [dateFormat]="'dd-mm-yy'"
    (onClickOutside)="onClickOutside()">
  </p-calendar>

  <cpb-readonly-container [hidden]="!to.readonly" [attr.data-test]="'builder-readonly-' + field.key">
    <ng-container *ngIf="!to?.date; else showDate">
      {{ formControl.value | default : to?.defaultEmptyValue }}
    </ng-container>

    <ng-template #showDate>
      <ng-container
        *ngIf="
          !to.selectionMode ||
            to?.selectionMode === 'single' ||
            (to?.selectionMode !== 'single' && !formControl?.value?.length);
          else rangeDate
        ">
        {{ formControl.value | date : (to.time ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy') }}
      </ng-container>

      <ng-template #rangeDate>
        {{ formControl.value[0] | date : 'dd-MM-yyyy' }} - {{ formControl.value[1] | date : 'dd-MM-yyyy' }}
      </ng-template>
    </ng-template>
  </cpb-readonly-container>
</div>
